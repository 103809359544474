import produce from "immer";
import {
  SUBMISSION_FETCHED,
  SUBMISSION_QUESTION_SWITCHED,
  SUBMISSION_SCORE_GIVEN,
} from "./type";
import flatten from "lodash/flatten";
import omit from "lodash/omit";
import { v4 as uuidv4 } from "uuid";
import { mapValues, values } from "lodash";

const initialAnswer = {
  multichoice: {
    uuid: null,
    id: null,
    questionUUID: null,
    key: false,
    content: null,
  },
  multianswer: {
    uuid: null,
    id: null,
    questionUUID: null,
    key: false,
    content: null,
  },
  truefalse: {
    uuid: null,
    questionUUID: null,
    answer: null,
  },
  essay: {
    uuid: null,
    questionUUID: null,
    content: null,
  },
};

const initialState = {
  currentQuestion: { sectionId: null, uuid: null },
  sectionOrder: [],
  questionOrder: {},
  answerOrder: {},
  data: {
    submission: {
      id: null,
      total_questions: null,
      total_filleds: null,
      score: null,
    },
    student: {},
    exam: {},
    sections: [],
    questions: {},
    answers: {},
  },
};

export default produce((state, action = {}) => {
  switch (action.type) {
    case SUBMISSION_FETCHED: {
      const questionsFlatten = flatten(
        action.data.sections.map((section) =>
          section.questions.map((question) =>
            omit({
              ...question,
              uuid: uuidv4(),
              sectionId: section.id,
            })
          )
        )
      );
      const questions = questionsFlatten.reduce(
        (accumulator, question) => ({
          ...accumulator,
          [question.uuid]: question,
        }),
        {}
      );

      let answersGroupedByQuestion = questionsFlatten
        .filter(({ type }) => type === "multichoice" || type === "multianswer")
        .reduce(
          (accumulator, question) => ({
            ...accumulator,
            [question.uuid]: question.choices.map((choice) => ({
              ...initialAnswer[question.type],
              ...choice,
              uuid: uuidv4(),
              questionUUID: question.uuid,
            })),
          }),
          {}
        );
      answersGroupedByQuestion = {
        ...answersGroupedByQuestion,
        ...questionsFlatten
          .filter(({ type }) => type === "truefalse")
          .reduce(
            (accumulator, question) => ({
              ...accumulator,
              [question.uuid]: [
                {
                  ...initialAnswer[question.type],
                  uuid: uuidv4(),
                  questionUUID: question.uuid,
                  id: question.answer.id,
                  answer: question.answer.answer,
                },
              ],
            }),
            {}
          ),
      };
      answersGroupedByQuestion = {
        ...answersGroupedByQuestion,
        ...questionsFlatten
          .filter(({ type }) => type === "essay")
          .reduce(
            (accumulator, question) => ({
              ...accumulator,
              [question.uuid]: [
                {
                  ...initialAnswer[question.type],
                  uuid: uuidv4(),
                  questionUUID: question.uuid,
                  id: question.answer.id,
                  content: question.answer.answer,
                },
              ],
            }),
            {}
          ),
      };

      const sectionOrder = action.data.sections.map(({ id }) => id);
      const questionOrder = action.data.sections.reduce(
        (accumulator, section) => ({
          ...accumulator,
          [section.id]: questionsFlatten
            .filter(({ sectionId }) => sectionId === section.id)
            .map(({ uuid }) => uuid),
        }),
        {}
      );

      const firstQuestionUUID = sectionOrder.reduce(
        (accumulator, sectionId) => [
          ...accumulator,
          ...questionOrder[sectionId],
        ],
        []
      )[0];

      return {
        currentQuestion: {
          sectionId: firstQuestionUUID
            ? questions[firstQuestionUUID].sectionId
            : sectionOrder[0],
          uuid: firstQuestionUUID || null,
        },
        answerOrder: mapValues(answersGroupedByQuestion, (answers) =>
          answers.map((answer) => answer.uuid)
        ),
        sectionOrder,
        questionOrder,
        data: {
          submission: {
            id: action.data.id,
            total_questions: action.data.total_questions,
            total_filleds: action.data.total_filleds,
            score: action.data.score,
          },
          student: { ...action.data.student },
          exam: { ...action.data.exam },
          sections: action.data.sections.reduce(
            (accumulator, section) => ({
              ...accumulator,
              [section.id]: omit(section, "questions"),
            }),
            {}
          ),
          questions,
          answers: flatten(values(answersGroupedByQuestion)).reduce(
            (accumulator, answer) => ({
              ...accumulator,
              [answer.uuid]: { ...answer },
            }),
            {}
          ),
        },
      };
    }
    case SUBMISSION_QUESTION_SWITCHED: {
      state.currentQuestion = {
        sectionId: action.sectionId,
        uuid: action.uuid,
      };
      return;
    }
    case SUBMISSION_SCORE_GIVEN: {
      state.data.questions[action.question_uuid].total_gained_points =
        action.score;
      state.data.questions[action.question_uuid].status = action.status;
    }
    default:
      return state;
  }
}, initialState);
