import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "components/Button/IconButton";
import SendCuricculumModal from "./SendCuricculumModal";

export default function SendCuricculumButton(props) {
  const [isModalShow, setIsModalShow] = useState(false);

  return (
    <>
      <IconButton
        data-testid="SendCuricculumButton__showButton"
        round
        icon="send"
        variant="info"
        disabled={props.selectedQuestions.length === 0}
        onClick={() => setIsModalShow(true)}
      >
        Kirim ke Kurikulum ({props.selectedQuestions.length})
      </IconButton>

      {isModalShow && (
        <SendCuricculumModal
          selectedQuestions={props.selectedQuestions}
          removeSelectedQuestion={props.removeSelectedQuestion}
          changeSelectedQuestion={props.changeSelectedQuestion}
          onClose={() => setIsModalShow(false)}
        />
      )}
    </>
  );
}

SendCuricculumButton.propTypes = {};
