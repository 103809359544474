import React from "react";
import PropTypes from "prop-types";
import Link from "../Link/Link";
import Button from "./Button";

const LinkButton = ({ children, to, className = null, ...props }) => (
  <Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
    <Button {...props}>{children}</Button>
  </Link>
);

LinkButton.propTypes = {};

export default LinkButton;
