import React from "react";
import PropTypes from "prop-types";

const EmptyRow = ({ colSpan }) => (
  <tr>
    <td colSpan={colSpan} className="text-center">
      Tidak ada data untuk ditampilkan.
    </td>
  </tr>
);

export default EmptyRow;
