import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
// import { motion } from "framer-motion";
import TextareaAutosize from "react-textarea-autosize";

const Container = styled.div`
  display: inline-block;
  width: 100%;

  & textarea {
    &::placeholder {
      color: blue;
    }

    display: inline-block;
    all: inherit;
    background: transparent;
    border: 1px dashed #a4a2a2;
    border-radius: 4px;
    padding: 0 2px 0 2px;
    width: 100%;
    height: ${(props) => props.height};
    overflow: hidden;

    &.show-off {
      background: #f4bd0e;
      color: white;
    }

    &.filled:not(:hover) {
      background: inherit;
      border-color: transparent;
    }
  }
`;

class SkeletonTextfield extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilled: isEmpty(this.props.defaultValue) ? false : true,
      isActive: false,
      height: "auto",
    };
  }

  handleChange = (e) => {
    this.setState({ isFilled: !isEmpty(e.target.value) });
    this.props.onChange && this.props.onChange(e);
  };

  handleFocus = (e) => this.setState({ isActive: true });

  handleBlur = (e) => {
    this.setState({ isActive: false });
    this.props.onBlur && this.props.onBlur(e);
  };

  handleKeyPress = (e) => e.key === "Enter" && e.preventDefault();

  render() {
    const { isShowOff, className, defaultValue, ...props } = this.props;
    return (
      <Container>
        <TextareaAutosize
          {...props}
          defaultValue={defaultValue}
          spellCheck={false}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyPress={this.handleKeyPress}
          className={cx({
            [className || ""]: true,
            "show-off": isShowOff,
            filled: this.state.isFilled && !this.state.isActive,
          })}
        />
      </Container>
    );
  }
}

SkeletonTextfield.propTypes = {};

export default SkeletonTextfield;
