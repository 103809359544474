import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Col = ({ size, children, className = "", ...props }) => {
  let columnClass = "";
  if (size.trim().split(" ").length === 1) {
    columnClass = `col-${size}`;
  } else {
    columnClass = size
      .split(" ")
      .filter((_string) => Number.isInteger(parseInt(_string)))
      .map((_number) => parseInt(_number))
      .map((size, index) => {
        switch (index) {
          case 1: {
            return `col-sm-${size}`;
          }
          case 2: {
            return `col-md-${size}`;
          }
          case 3: {
            return `col-lg-${size}`;
          }
          case 4: {
            return `col-xl-${size}`;
          }
        }
      })
      .join(" ");
  }

  return (
    <div className={cx(columnClass, className)} {...props}>
      {children}
    </div>
  );
};

export default Col;
