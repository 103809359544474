import React from "react";
import PropTypes from "prop-types";
import DecimalValue from "./DecimalValue";
import InputDecimalView from "./InputDecimalView";

export default class InputDecimalViewModel extends React.Component {
  static defaultProps = {
    maxDecimal: 0,
    min: null,
    max: null,
    defaultValue: "",
  };

  static propTypes = {
    maxDecimal: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);
    this.state = { value: this.props.defaultValue };
  }

  setValue = (value) => {
    value = value.trim();
    if (value === "") {
      this.setState({ value });
      return;
    }

    try {
      const decimal = new DecimalValue(value);
      if (this.props.min) {
        if (decimal.getFloat() < this.props.min) {
          this.setState((state) => ({ value: state.value }));
          return;
        }
      }
      if (this.props.max) {
        if (decimal.getFloat() > this.props.max) {
          this.setState((state) => ({ value: state.value }));
          return;
        }
      }
      this.setState({
        value: decimal.getTruncatedFraction(parseInt(this.props.maxDecimal)),
      });
    } catch (error) {
      /* keep last value unchanged */
      if (error.name !== "InvalidValueError") throw error;
    }
  };

  render() {
    const { maxDecimal, min, max, defaultValue, value, ...props } = this.props;
    return (
      <InputDecimalView
        value={this.state.value}
        viewModel={{
          setValue: this.setValue,
        }}
        {...props}
      />
    );
  }
}
