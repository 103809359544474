import React from "react";
import PropTypes from "prop-types";
import Alert from "components/Alert/Alert";
import Log from "domain/Log/Log";
// import FileViewer from "react-file-viewer";

const GoogleViewer = ({ url }) => (
  <iframe
    src={"https://docs.google.com/gview?url=" + url + "&embedded=true"}
    style={{ width: "100%", height: "60vh" }}
  />
);

const Document = ({ url, extension }) => {
  const viewers = {
    // pdf: <FileViewer fileType={extension} filePath={"https://file-examples-com.github.io/uploads/2017/02/file-sample_100kB.docx"} />,
    // csv: <FileViewer fileType={extension} filePath={"https://file-examples-com.github.io/uploads/2017/02/file-sample_100kB.docx"} />,
    // xlsx: <FileViewer fileType={extension} filePath={"https://file-examples-com.github.io/uploads/2017/02/file-sample_100kB.docx"} />,
    // docx: <FileViewer fileType={extension} filePath={"https://file-examples-com.github.io/uploads/2017/02/file-sample_100kB.docx"} />,
    pdf: <GoogleViewer url={url} />,
    csv: <GoogleViewer url={url} />,
    xlsx: <GoogleViewer url={url} />,
    xls: <GoogleViewer url={url} />,
    docx: <GoogleViewer url={url} />,
    doc: <GoogleViewer url={url} />,
    ppt: <GoogleViewer url={url} />,
    pptx: <GoogleViewer url={url} />,
  };

  if (!viewers[extension]) {
    Log(`Ekstensi ${extension} tidak valid.`);
  }

  return (
    <>
      <div className="clearfix mb-2" />
      {viewers[extension] || (
        <Alert variant="warning">
          <p>Kami tidak bisa menampilkan dokumen ini.</p>
        </Alert>
      )}
    </>
  );
};

export default Document;
