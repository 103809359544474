import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ExamSectionEditForm from "./ExamSectionEditForm";
import ExamSectionRemove from "./ExamSectionRemove";
import prettyFloat from "domain/Float/PrettyFloat";
import { connect } from "react-redux";
import SectionEntity from "domain/Exam/SectionEntity";

const Div = styled.div`
  cursor: pointer;
  border-bottom: 1px solid #dbdfea !important;
  background: #f5f6fa;
  padding: 1rem;
  &:hover {
    background: #eaebee;
  }
`;

const Span = styled.span`
  /* font-size: 70%; */
  font-weight: normal;
  font-family: "Roboto", sans-serif, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
`;

class SectionHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = () => this.props.onClick();

  render() {
    const { section, questionOrder, questions } = this.props;

    const sectionEntity = new SectionEntity(section)
      .setQuestionOrder(questionOrder)
      .setQuestions(questions);

    return (
      <>
        <Div className="card-inner" onClick={this.handleClick}>
          <div className="d-flex">
            <h6 className="mb-0" style={{ flexGrow: 1 }}>
              {sectionEntity.title}
              <br />
              <Span className="text-soft fs-11px">
                {sectionEntity.getTotalQuestion()} soal dan{" "}
                {prettyFloat(sectionEntity.getTotalPoint())} poin
              </Span>
            </h6>
            <h6>
              <ExamSectionEditForm section={sectionEntity} />
              {this.props.totalSection > 1 && (
                <ExamSectionRemove section={sectionEntity} />
              )}
            </h6>
          </div>
          {/* {section.description && <p>{section.description}</p>} */}
        </Div>
      </>
    );
  }
}

SectionHeader.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  totalSection: state.exam.sectionOrder.length,
  section: state.exam.data.sections[ownProps.sectionId],
  questionOrder: state.exam.questionOrder,
  questions: state.exam.data.questions,
});

export default connect(mapStateToProps)(SectionHeader);
