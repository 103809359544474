import { USER_LOGGED_IN, USER_LOGGED_OUT } from "./type";
import api from "api";

export const userLoggedIn = (auth) => ({
  type: USER_LOGGED_IN,
  auth,
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const login = (credentials) => {
  return (dispatch) =>
    api.user.login(credentials).then((auth) => {
      localStorage.setItem("auth", JSON.stringify(auth));
      dispatch(userLoggedIn(auth));
    });
};

export const logout = (accessToken) => {
  return (dispatch) =>
    api.user.logout(accessToken).then(() => {
      localStorage.removeItem("auth");
      dispatch(userLoggedOut());
    });
};
