import React from "react";
import PropTypes from "prop-types";
import Shower from "components/Shower";
import moment from "moment-timezone";
import LinkButton from "components/Button/LinkButton";
import ButtonGroup from "components/ButtonGroup/ButtonGroup";
import Button from "components/Button/Button";
import ConfirmModal from "components/Modal/ConfirmModal";
import Badge from "components/Badge/Badge";
import { withRouter } from "react-router-dom";
import PrettyFloat from "domain/Float/PrettyFloat";

class Row extends React.Component {
  state = {
    isConfirmModalShow: false,
  };

  constructor(props) {
    super(props);
    this.schoolAcademicId = this.props.match.params.schoolAcademicId;
    this.examId = this.props.match.params.examId;
    this.studentId = this.props.match.params.studentId;
  }

  showConfirmModal = () => this.setState({ isConfirmModalShow: true });
  hideConfirmModal = () => this.setState({ isConfirmModalShow: false });

  render() {
    const { data, row, index, onSubmissionScoreSelected } = this.props;
    return (
      <React.Fragment>
        <tr>
          <td className="text-center">{data.length - index}</td>
          <td>{moment(row.started_at).format("D MMMM HH:mm z")}</td>
          <td style={{ lineHeight: "1.2" }}>
            {row.finished_at ? (
              moment(row.finished_at).format("D MMMM HH:mm z")
            ) : (
              <>
                <strong className="text-base">Tak diketahui</strong>
                <small className="d-block">
                  Keluar sistem atau sedang mengerjakan
                </small>
              </>
            )}
          </td>
          <td>
            <div>{PrettyFloat(row.score)}</div>
            {row.total_scored_questions < row.total_questions && (
              <>
                <strong className="text-base">Esai belum dinilai</strong>
                <small className="d-block">Mohon beri nilai di ulasan</small>
              </>
            )}
          </td>
          <td className="text-nowrap">
            <ButtonGroup>
              <Button
                variant="primary"
                size="sm"
                onClick={this.showConfirmModal}
              >
                Jadikan Nilai Akhir
              </Button>
              <LinkButton
                variant="warning"
                to={`/class/${this.schoolAcademicId}/academic/exam/${this.examId}/student/${this.studentId}/submission/${row.id}`}
                size="sm"
              >
                Ulasan
              </LinkButton>
            </ButtonGroup>
          </td>
        </tr>

        {this.state.isConfirmModalShow && (
          <ConfirmModal
            variant="primary"
            acceptLabel="Ya, Ubah"
            onAccept={() =>
              onSubmissionScoreSelected({
                submissionId: row.id,
                submissionScore: row.score,
              }).then(() => this.hideConfirmModal())
            }
            onCancel={this.hideConfirmModal}
            message={
              <>
                <strong className="text-center">
                  Nilai <span className="text-danger">{row.score}</span> Akan
                  Menjadi Nilai Akhir
                </strong>
                <br />
                Guru akan mengubah nilai pengerjaan tersebut menjadi nilai akhir
                siswa.
              </>
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Row);
