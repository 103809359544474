import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template: auto / 30px auto 70px;
  grid-gap: 10px;
  width: 100%;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
