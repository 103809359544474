import { combineReducers } from "redux";
import auth from "./auth/reducer";
import { lessons as examLessons, exams } from "./examList/reducer";
import { submission } from "./submission/reducer";
import createQuestionBank from "./createQuestionBank/reducer";
import exam from "./exam/reducer";
import examSubmissionReview from "./examSubmissionReview/reducer";
import page from "./page/reducer";
// import masterQuestion from "./master/question/reducer";
import masterQuestionCategories from "./master/questionCategories/reducer";
import masterQuestionTypes from "./master/questionTypes/reducer";
import schoolGrades from "./school/grades/reducer";

const appReducer = combineReducers({
  auth,
  page,
  // examLessons,
  // exams,
  // submission,
  createQuestionBank,
  exam,
  examSubmissionReview,
  // masterQuestion,
  masterQuestionCategories,
  masterQuestionTypes,
  schoolGrades,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
