import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AccomplishContext from "../AccomplishContext";
import { connect } from "react-redux";
import { initQuestion, initAnswer } from "redux/exam/action";
import Label from "components/Form/Label";
import FloatModal from "components/Modal/FloatModal";
import { v4 as uuidv4 } from "uuid";
import ErrorBoundary from "components/Error/ErrorBoundary";
import QuestionImporter from "pages/TeacherExamPage/QuestionImporter";

const Container = styled.div`
  width: 100%;
  height: 100%;
  font-size: 85% !important;
`;

class NewQuestionAsker extends React.Component {
  static contextType = AccomplishContext;

  constructor(props) {
    super(props);
    this.createButtonRef = React.createRef();
    this.state = {
      isBankImportPopupShow: false,
      isNewQuestionModalShow: false,
      questionType: "multichoice",
    };
  }

  handleQuestionTypeChange = (e) =>
    this.setState({ questionType: e.target.value });

  initQuestion = () => {
    const sectionId = this.props.sections[this.props.sectionId]
      ? this.props.sectionId
      : this.props.currentSectionId; // untuk handle ketika sectionId telah terhapus
    const uuid = uuidv4();
    this.props.initQuestion({
      generatedUUID: uuid,
      sectionId,
      type: this.state.questionType,
    });

    let totalInitialAnswer =
      this.state.questionType === "multichoice" ||
      this.state.questionType === "multianswer"
        ? 2
        : 1;

    for (; totalInitialAnswer > 0; totalInitialAnswer--) {
      this.props.initAnswer({
        questionUUID: uuid,
        questionType: this.state.questionType,
      });
    }
    this.context.hideNewQuestionAsker();
  };

  showBankImportPopup = () => this.setState({ isBankImportPopupShow: true });
  hideBankImportPopup = () => this.setState({ isBankImportPopupShow: false });
  showNewQuestionModal = () => this.setState({ isNewQuestionModalShow: true });
  hideNewQuestionModal = () => this.setState({ isNewQuestionModalShow: false });

  render() {
    const sectionId = this.props.sections[this.props.sectionId]
      ? this.props.sectionId
      : this.props.currentSectionId; // untuk handle ketika sectionId telah terhapus
    const background = ["primary", "success", "warning", "danger", "secondary"][
      Math.floor(Math.random() * 5)
    ];
    return (
      <>
        <Container>
          <p className={`text-center fs-12px p-2 bg-${background}-dim`}>
            Anda akan menambah ke bagian "
            {this.props.sections[sectionId]
              ? this.props.sections[sectionId].title
              : ""}
            "
          </p>
          <h5 className="text-center mt-5">Cara Menambah Soal</h5>
          <p className="text-soft text-center">
            Berikut adalah dua cara yang bisa dilakukan:
          </p>

          <div className="row">
            <div className="col-md-5 offset-md-1">
              <div className="nk-content ">
                <div className="nk-block nk-block-middle wide-xs mx-auto">
                  <div className="price-item card card-bordered text-center bg-primary-dim">
                    <div className="card-inner p-2">
                      <h1>
                        <em className="ni ni-package text-primary"></em>
                      </h1>
                      <div className="price-plan-info">
                        <h5 className="title">Impor dari Bank</h5>
                        <span>
                          Ambil soal yang sudah dipersiapkan sebelumnya.
                        </span>
                      </div>
                      <div className="price-plan-action">
                        <button
                          className="btn btn-primary"
                          onClick={this.showBankImportPopup}
                        >
                          Impor
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="nk-content ">
                <div className="nk-block nk-block-middle wide-xs mx-auto">
                  <div className="price-item card card-bordered text-center bg-primary-dim">
                    <div className="card-inner p-2">
                      <h1>
                        <em className="ni ni-pen text-primary"></em>
                      </h1>
                      <div className="price-plan-info">
                        <h5 className="title">Buat Baru</h5>
                        <span>
                          Tidak ada di bank? Buat soal baru dengan mudah.
                        </span>
                      </div>
                      <div className="price-plan-action">
                        <button
                          onClick={this.showNewQuestionModal}
                          className="btn btn-primary"
                          ref={this.createButtonRef}
                        >
                          Buat
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        {this.state.isBankImportPopupShow && (
          <QuestionImporter
            sectionId={sectionId}
            onClose={this.hideBankImportPopup}
          />
        )}

        {this.state.isNewQuestionModalShow && (
          <FloatModal
            title="Pilih Jenis Soal"
            position="left"
            parentRef={this.createButtonRef}
            onClose={this.hideNewQuestionModal}
          >
            <div className="row gy-4">
              <div className="col-sm-12">
                <div className="form-group">
                  <Label required>Jenis Soal</Label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        className="form-control form-control-sm"
                        defaultValue={this.state.questionType}
                        onChange={this.handleQuestionTypeChange}
                      >
                        <option value="multichoice">Pilihan Ganda</option>
                        <option value="multianswer">
                          Pilihan Ganda Kompleks
                        </option>
                        <option value="truefalse">Benar/Salah</option>
                        <option value="essay">Esai</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <button
                  type="button"
                  className="btn btn-sm btn-light btn-block"
                  onClick={this.hideNewQuestionModal}
                >
                  <span>Batal</span>
                </button>
              </div>
              <div className="col-sm-6">
                <button
                  type="button"
                  className="btn btn-sm btn-primary btn-block"
                  onClick={this.initQuestion}
                >
                  <span>Buat Soal</span>
                </button>
              </div>
            </div>
          </FloatModal>
        )}
      </>
    );
  }
}

NewQuestionAsker.propTypes = {};

const mapStateToProps = (state) => ({
  academicId: state.exam.schoolAcademicId,
  examId: state.exam.data.exam.id,
  currentSectionId: state.exam.currentQuestion.sectionId,
  sections: state.exam.data.sections,
});

const NewQuestionAskerRedux = connect(mapStateToProps, {
  initQuestion,
  initAnswer,
})(NewQuestionAsker);

export default (props) => (
  <ErrorBoundary fallback={<span>Oops, terjadi kesalahan.</span>}>
    <NewQuestionAskerRedux {...props} />
  </ErrorBoundary>
);
