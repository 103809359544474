import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ScrollInstruction = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: initial;
  }
  position: absolute;
  top: -44px;
  left: 0;
  animation-name: table-scroll-instruction-move;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: 3;

  @keyframes table-scroll-instruction-move {
    0% {
      transform: translateX(0vw);
    }
    30% {
      transform: translateX(100vw);
    }
    50% {
      transform: translateX(0vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }
`;

const TableResponsive = (props) => {
  const [isScrollInstructionShow, setIsScrollInstructionShow] = React.useState(
    true
  );
  setTimeout(() => setIsScrollInstructionShow(false), 10000);
  return (
    <>
      {isScrollInstructionShow && (
        <div style={{ position: "relative" }}>
          <ScrollInstruction>
            <div className="btn btn-round btn-outline-primary btn-dim">
              <span>Scroll ke kanan</span>
              <em className="icon ni ni-chevron-right-circle-fill" />
            </div>
          </ScrollInstruction>
        </div>
      )}

      <div className="table-responsive">{props.children}</div>
    </>
  );
};

TableResponsive.propTypes = {};

export default TableResponsive;
