import axios from "configuredAxios";
import loginAxios from "loginAxios";
import qs from "qs";
import lessonAPI from "api/school/lesson";

// TODO: rename get() -> getAll()
// TODO: rename to findOne()
// TODO: buat api per file, contoh: api/User.js, api/Accomplish.js, ...dsb

const api = {
  user: {
    login: (credentials) =>
      loginAxios
        .post("v1/user/auth/login", { ...credentials })
        .then((res) => res.data.data.auth),
    logout: (access_token) =>
      axios.post("/v1/user/auth/logout", { access_token }),
  },
  teacher: {
    getTaughtLessons: () => lessonAPI.get(true),
    getTaughtClasses: (lesson_id) =>
      axios
        .get(`/v1/school/academic`, {
          params: { lesson_id },
        })
        .then((res) => res.data.data),
  },
  academicClass: {
    findOne: (id) =>
      axios
        .get(`/v1/school/academic/${id}`, {}, { timeout: 2000 })
        .then((res) => res.data.data),
  },
  exam: {
    getLessons: () =>
      axios.get("v1/school/exam/filter/lesson").then((res) => res.data.data),
    getExams: (school_academic_id) =>
      axios
        .get("/v1/school/academic/exam", {
          params: { school_academic_id },
        })
        .then((res) => res.data.data),
    getExam: (schoolAcademicId, examId) =>
      axios
        .get(`/v1/school/academic/exam/${examId}`, {
          params: { school_academic_id: schoolAcademicId },
        })
        .then((res) => res.data.data),
    changeStatus: ({ exam_id, status }) =>
      axios.patch(
        `v1/school/academic/exam/${exam_id}/status`,
        { status },
        { timeout: 4000 }
      ),
    create: (schoolAcademicId, data) =>
      axios
        .post("/v1/school/academic/exam", {
          school_academic_id: schoolAcademicId,
          ...data,
        })
        .then((res) => res.data.data),
    update: (examId, data) =>
      axios.put(`/v1/school/academic/exam/${examId}`, data),
    delete: (examId) =>
      axios.delete(`/v1/school/academic/exam/${examId}`, { timeout: 4000 }),
    createSection: ({ exam_id, title, description }) =>
      axios
        .post(
          "/v1/school/academic/exam/section",
          { exam_id, title, description },
          { timeout: 2000 }
        )
        .then((res) => res.data.data),
    updateSection: (id, title, description) =>
      axios.put(
        `/v1/school/academic/exam/section/${id}`,
        {
          title,
          description,
        },
        { timeout: 2000 }
      ),
    deleteSection: (sectionId) =>
      axios.delete(`/v1/school/academic/exam/section/${sectionId}`, {
        timeout: 2000,
      }),
    question: {
      import: (questions) =>
        axios
          .post(`/v1/school/academic/exam/question/import`, {
            imports: questions,
          })
          .then((res) => res.data.data),
      // import: () => Promise.resolve(importMock),
      create: ({ section_id, question }) =>
        axios
          .post(
            "/v1/school/academic/exam/question",
            {
              section_id,
              ...question,
            },
            { timeout: 2000 }
          )
          .then((res) => res.data.data),
      update: ({ questionId, point, content }) =>
        axios
          .put(
            `/v1/school/academic/exam/question/${questionId}`,
            {
              point,
              content,
            },
            { timeout: 2000 }
          )
          .then((res) => res.data.data),
      delete: (questionId) =>
        axios.delete(`/v1/school/academic/exam/question/${questionId}`, {
          timeout: 2000,
        }),
    },
    answer: {
      create: ({ questionId, payload }) =>
        axios
          .post(
            `/v1/school/academic/exam/question/answer`,
            {
              ...payload,
              question_id: questionId,
            },
            { timeout: 2000 }
          )
          .then((res) => res.data.data),
      update: ({ questionId, answerId, payload }) =>
        axios
          .put(
            `/v1/school/academic/exam/question/${questionId}/answer/${answerId}`,
            { ...payload },
            { timeout: 2000 }
          )
          .then((res) => res.data.data),
      delete: ({ questionId, answerId }) =>
        axios.delete(
          `/v1/school/academic/exam/question/${questionId}/answer/${answerId}`,
          { timeout: 2000 }
        ),
    },
    attachment: {
      create: (formData) =>
        axios
          .post(`/v1/school/academic/exam/question/attachment`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => res.data.data),
      delete: (attachmentId) =>
        axios.delete(
          `/v1/school/academic/exam/question/attachment/${attachmentId}`,
          { timeout: 2000 }
        ),
    },
  },
  accomplish: {
    resume: (examId, accomplishId) => {
      return axios
        .get(`/v1/school/exam/${examId}/submission/${accomplishId}`)
        .then((res) => res.data.data);
    },
    start: (examId, token) => {
      const payload = token !== null ? { token } : {};
      return axios
        .post(`/v1/school/exam/${examId}/accomplish`, payload)
        .then((res) => res.data.data);
    },
    saveAnswer: ({ examId, accomplishId, answer }) => {
      return axios.post(
        `/v1/school/exam/${examId}/accomplish/${accomplishId}/update`,
        { ...answer },
        { timeout: 4000 }
      );
    },
    submit: (examId, accomplishId, answers) => {
      return axios.post(
        `/v1/school/exam/${examId}/accomplish/${accomplishId}/submit`,
        { answers }
      );
    },
  },
  submission: {
    get: (examId, params) =>
      axios
        .get(`/v1/school/exam/${examId}/submission`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        })
        .then((res) => res.data.data),
    getDetail: (examId, submissionId) =>
      axios
        .get(`/v1/school/exam/${examId}/submission/${submissionId}`)
        .then((res) => res.data.data),
  },
};

export default api;
