import React from "react";
import PropTypes from "prop-types";
import TableResponsive from "components/Table/TableResponsive";
import Tablee from "components/Table/Table";

const Table = ({ children }) => (
  <div className="card card-bordered mb-3">
    <TableResponsive>
      <Tablee compact className="text-nowrap">
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Urutan</th>
            <th>Waktu Mulai</th>
            <th>Waktu Selesai</th>
            <th>Nilai</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </Tablee>
    </TableResponsive>
  </div>
);

Table.propTypes = {};

export default Table;
