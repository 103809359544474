import React from "react";
import PropTypes from "prop-types";
import GenerateRandomString from "domain/String/GenerateRandomString";
import cx from "classnames";

class Switch extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange = (e) => {
    this.props.onChange &&
      this.props.onChange({
        type: "switch",
        name: this.props.name,
        value: e.target.checked ? this.props.onValue : this.props.offValue,
      });
  };

  render() {
    const { children, size = "md", onValue, offValue, ...props } = this.props;
    const id = GenerateRandomString();
    return (
      <div
        className={cx({
          "custom-control": true,
          "custom-switch": true,
          [`custom-control-${size}`]: size === "md" ? false : true, // size md tidak pakai class
        })}
      >
        <input
          {...props}
          type="checkbox"
          className="custom-control-input"
          id={"switch-" + id}
          onChange={this.handleChange}
        />
        <label className="custom-control-label" htmlFor={"switch-" + id}>
          {children || ""}
        </label>
      </div>
    );
  }
}

Switch.propTypes = {};

export default Switch;
