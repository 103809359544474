import flatten from "lodash/flatten";
import last from "lodash/last";
import forOwn from "lodash/forOwn";

export default class QuestionEntity {
  constructor(question) {
    forOwn(question, (value, key) => {
      this[key] = value;
    });
  }

  setSectionOrder(data) {
    this._sectionOrder = data;
    return this;
  }

  setQuestionOrder(data) {
    this._questionOrder = data;
    return this;
  }

  setQuestions(data) {
    this._questions = data;
    return this;
  }

  /**
   * return index of the question based on question order in all section.
   */
  getAbsoluteIndex() {
    return flatten(
      this._sectionOrder.map((sectionId) => this._questionOrder[sectionId])
    ).indexOf(this.uuid);
  }

  /**
   * return location of previous question.
   */
  prev() {
    const previousQuestionUUIDInCurrentSection = this._questionOrder[
      this.sectionId
    ][this._questionOrder[this.sectionId].indexOf(this.uuid) - 1];
    if (previousQuestionUUIDInCurrentSection !== undefined) {
      return {
        sectionId: this.sectionId,
        uuid: previousQuestionUUIDInCurrentSection,
      };
    }

    const previousSectionId = this._sectionOrder[
      this._sectionOrder.indexOf(this.sectionId) - 1
    ];
    const previousQuestionUUIDInPreviousSection = last(
      this._questionOrder[previousSectionId]
    );
    if (
      previousSectionId !== undefined &&
      previousQuestionUUIDInPreviousSection !== undefined
    ) {
      return {
        sectionId: previousSectionId,
        uuid: previousQuestionUUIDInPreviousSection,
      };
    }

    return null;
  }

  /**
   * return uuid of next question.
   */
  next() {
    const nextQuestionUUIDInCurrentSection = this._questionOrder[
      this.sectionId
    ][this._questionOrder[this.sectionId].indexOf(this.uuid) + 1];
    if (nextQuestionUUIDInCurrentSection !== undefined) {
      return {
        sectionId: this.sectionId,
        uuid: nextQuestionUUIDInCurrentSection,
      };
    }

    const nextSectionId = this._sectionOrder[
      this._sectionOrder.indexOf(this.sectionId) + 1
    ];
    const nextQuestionUUIDInNextSection = nextSectionId
      ? this._questionOrder[nextSectionId][0]
      : null;
    if (
      nextSectionId !== undefined &&
      nextQuestionUUIDInNextSection !== undefined
    ) {
      return {
        sectionId: nextSectionId,
        uuid: nextQuestionUUIDInNextSection,
      };
    }

    return null;
  }
}
