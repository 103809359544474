import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { createExam, updateExam } from "redux/exam/action";
import ModalPortal from "components/ModalPortal";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import BankQuestionTable from "./QuestionBankTable";
import SelectedQuestionTable from "./SelectedQuestionTable";
import { v4 as uuidv4 } from "uuid";
import { showLoader, hideLoader } from "redux/page/action";
import { importQuestions } from "redux/exam/action";
import IconButton from "components/Button/IconButton";
import Block from "components/Block/Block";
import ValidationError from "components/Error/ValidationError";
import Shower from "components/Shower";
import ErrorBoundary from "components/Error/ErrorBoundary";

const Container = styled.div`
  display: block;
  & > div {
    max-width: 1140px;
  }
`;

class QuestionImporter extends React.Component {
  constructor(props) {
    super(props);
    this.schoolAcademicId = this.props.match.params.schoolAcademicId;
    this.state = {
      selectedQuestions: [],
      errors: [],
    };
  }

  componentDidMount() {
    document.body.classList.add("modal-open");
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  addSelectedQuestion = (question) => {
    this.setState((state) => ({
      ...state,
      selectedQuestions: [
        ...state.selectedQuestions,
        {
          uuid: uuidv4(),
          id: question.id,
          formated_type: question.formated_type,
          point: question.point,
          text_content: question.text_content,
          section_id: this.props.sectionId,
        },
      ],
    }));
  };

  removeSelectedQuestion = (questionUUID) => {
    this.setState((state) => ({
      ...state,
      selectedQuestions: state.selectedQuestions.filter(
        (question) => question.uuid !== questionUUID
      ),
    }));
  };

  changeSelectedQuestion = (questionWithChanges) => {
    this.setState((state) => ({
      ...state,
      selectedQuestions: state.selectedQuestions.map((question) => {
        if (question.uuid !== questionWithChanges.uuid) return question;
        return {
          ...question,
          point: questionWithChanges.point,
          section_id: questionWithChanges.section_id,
        };
      }),
    }));
  };

  import = () => {
    this.props.showLoader();
    this.props
      .importQuestions(
        this.state.selectedQuestions.map((question) => ({
          question_id: question.id,
          point: question.point,
          section_id: question.section_id,
        }))
      )
      .then(() =>
        setTimeout(() => {
          this.props.hideLoader();
          this.props.onClose();
        }, 500)
      )
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            this.setState({
              errors: err.response.data.error,
            });
            this.props.hideLoader();
          }
        }
      });
  };

  render() {
    return (
      <ModalPortal>
        <div className="modal-backdrop fade show"></div>
        <Container className="modal fade show">
          <div className="modal-dialog modal-dialog-top">
            <div className="modal-content">
              <div className="card card-bordered bg-lighter">
                <div className="card-inner">
                  <Block>
                    <div className="row">
                      <div className="col-12">
                        <IconButton
                          icon="cross"
                          variant="light"
                          onClick={this.props.onClose}
                        >
                          Batal Impor
                        </IconButton>
                      </div>
                    </div>
                  </Block>

                  {this.state.errors.length > 0 && (
                    <Block>
                      {/* TODO: refactor, keliatannya pattern render props ini jadi bikin complex */}
                      <Shower isShow={true}>
                        {({ isShow, hide }) => (
                          <div>
                            {isShow && (
                              <Block className="mb-2">
                                <ValidationError
                                  errors={this.state.errors}
                                  onClose={() => hide()}
                                />
                              </Block>
                            )}
                          </div>
                        )}
                      </Shower>
                    </Block>
                  )}

                  <Block>
                    <div className="row">
                      <div className="col-12 col-md-5">
                        <BankQuestionTable
                          selectedQuestions={this.state.selectedQuestions}
                          addSelectedQuestion={this.addSelectedQuestion}
                        />
                      </div>
                      <div className="col-12 col-md-7">
                        <SelectedQuestionTable
                          selectedQuestions={this.state.selectedQuestions}
                          removeSelectedQuestion={this.removeSelectedQuestion}
                          changeSelectedQuestion={this.changeSelectedQuestion}
                          import={this.import}
                        />
                      </div>
                    </div>
                  </Block>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </ModalPortal>
    );
  }
}

QuestionImporter.propTypes = {};

const QuestionImporterRedux = connect(null, {
  importQuestions,
  showLoader,
  hideLoader,
})(withRouter(QuestionImporter));

export default (props) => (
  <ErrorBoundary
    fallback={<span className="text-dark">Ups... terjadi kesalahan</span>}
  >
    <QuestionImporterRedux {...props} />
  </ErrorBoundary>
);
