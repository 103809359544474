import axios from "configuredAxios";

export default {
  get: ({
    lesson_id,
    question_type,
    grade_id,
    category_id,
    search = "",
    page,
    limit,
    cancelCallback = () => {},
  }) => {
    const source = axios.CancelToken.source();
    cancelCallback(source.cancel);
    return axios
      .get(`/v1/bank/question`, {
        params: {
          lesson_id,
          question_type: question_type || null,
          grade_id: grade_id || null,
          category_id: category_id || null,
          search: search || null,
          page,
          limit,
        },
        cancelToken: source.token,
      })
      .then((res) => res.data.data);
  },
};

// const data = (id) => ({
//   id: id,
//   formated_type: "Pilihan Ganda",
//   point: "5",
//   text_content:
//     "Pilih dari kota berikut yang merupakan ibukota negara Republik Indonesia!",
// });

// export default {
//   get: () =>
//     Promise.resolve({
//       total_pages: 2,
//       current_page: 1,
//       questions: [
//         data(1),
//         data(2),
//         data(3),
//         data(4),
//         data(5),
//         data(6),
//         data(7),
//         data(8),
//         data(9),
//         data(10),
//         data(11),
//         data(12),
//         data(13),
//         data(14),
//         data(15),
//         data(16),
//       ],
//     }),
// };
