import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container } from "./InputFile.styled";

export default function InputFile({ onChange, ...restProps }) {
  const [filename, setFilename] = useState(null);
  return (
    <Container className="custom-file">
      <input
        type="file"
        className="custom-file-input"
        {...restProps}
        style={{ cursor: "pointer" }}
        onChange={(e) => {
          setFilename(e.target.files ? e.target.files[0].name : null);
          onChange(e);
        }}
      />
      <label className="custom-file-label">{filename || "Pilih berkas"}</label>
    </Container>
  );
}

InputFile.propTypes = {};
