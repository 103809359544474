import omit from "lodash/omit";
import { v4 as uuidv4 } from "uuid";

export default function fromMultianswerReducer(state, action, question) {
  switch (action.questionTypeTarget) {
    case "multichoice": {
      question.type = "multichoice";
      delete question.scoring_mode;
      break;
    }
    case "multianswer": {
      break;
    }
    case "truefalse": {
      state.answers = omit(state.answers, state.answerOrder[question.uuid]); // remove previous answer
      const answer = {
        uuid: uuidv4(),
        answer: null,
        questionUUID: question.uuid,
      };
      state.answerOrder[question.uuid] = [answer.uuid];
      state.answers[answer.uuid] = answer;
      question.type = "truefalse";
      break;
    }
    case "essay": {
      const content = state.answerOrder[question.uuid]
        .map((answerUUID) => state.answers[answerUUID].content || "")
        .join("<br/>")
        .replace(/<br\/>$/, ""); // get choices's content as essay content
      state.answers = omit(state.answers, state.answerOrder[question.uuid]); // remove previous answer
      const answer = { uuid: uuidv4(), content, questionUUID: question.uuid };
      state.answerOrder[question.uuid] = [answer.uuid];
      state.answers[answer.uuid] = answer;
      question.type = "essay";
      break;
    }
    default:
      throw new Error("invalid type target");
  }
}
