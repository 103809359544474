import React, { useContext } from "react";
import PropTypes from "prop-types";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
// TODO: pindahkan ke /components
import styled from "styled-components";
import { DataContext } from "./CardContext";
import Multichoice from "./Multichoice";
import Multianswer from "./Multianswer";
import Truefalse from "./Truefalse";
import Essay from "./Essay";
import SettingForm from "./SettingForm";

// NOTE: ini karena <SaveLoading /> absolute dan ketahan oleh relative .col
const ColumnWithoutRelative = styled.div`
  & > .row > div {
    position: static;
  }
`;

const CardBody = (props) => {
  const { question } = useContext(DataContext);

  return (
    <div className="nk-wgw-inner">
      <ColumnWithoutRelative>
        <Row>
          <Col size="8">
            <div className="nk-block pt-2">
              {question.type === "multichoice" && (
                <Multichoice
                  key={question.uuid}
                  question={question}
                  submitUUID={props.submitUUID}
                />
              )}
              {question.type === "multianswer" && (
                <Multianswer
                  key={question.uuid}
                  question={question}
                  submitUUID={props.submitUUID}
                />
              )}
              {question.type === "truefalse" && (
                <Truefalse
                  key={question.uuid}
                  question={question}
                  submitUUID={props.submitUUID}
                />
              )}
              {question.type === "essay" && (
                <Essay
                  key={question.uuid}
                  question={question}
                  submitUUID={props.submitUUID}
                />
              )}
            </div>
          </Col>
          <Col size="4">
            <SettingForm />
          </Col>
        </Row>
      </ColumnWithoutRelative>
    </div>
  );
};

export default CardBody;
