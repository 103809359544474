import styled from '@emotion/styled/macro'
import Background from "assets/images/background-book.jpg";

export const Container = styled.div`
  overflow: hidden;
  background-image: url(${() => Background});
  background-size: cover;
  background-position: center;
`;

export const Inner = styled.div`
  min-height: 150px;
  position: relative;
  border-bottom: none !important;
`;

export const Overlay = styled.div`
  background-color: rgba(49, 89, 253, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const Card = styled.div`
  position: relative;
  z-index: 1;
`;

export const Tab = styled.ul`
  & {
    margin: 0;
    padding: 0 10px 0 10px;
    li {
      margin: 0;
      border-bottom: 3px solid transparent;
      &.active {
        border-bottom-color: #6576ff;
      }
      a {
        padding: 1.3rem 1rem 1.2rem 0.7rem;
      }
    }
  }
`;
