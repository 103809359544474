import React, { useContext } from "react";
import PropTypes from "prop-types";
import Col from "components/Grid/Col";
import Row from "components/Grid/Row";
import Select from "components/Form/Select";
import InputDecimal from "components/Form/InputDecimal/InputDecimalViewModel";
import { StaticData } from "./StaticDataContext";
import { useSelector } from "react-redux";

export default function SettingForm(props) {
  const { gradesFetching, lessonsFetching } = useContext(StaticData);

  const categories = useSelector(
    (state) => state.createQuestionBank.categories
  );

  return (
    <Row className="gy-1">
      <Col size="12">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-text-hint">
              <span className="overline-title">Poin</span>
            </div>
            <InputDecimal
              min={1}
              max={100}
              maxDecimal={2}
              defaultValue={props.point}
              onChange={({ name, value }) => props.onChange("point", value)}
              className="form-control"
              placeholder="Masukan poin..."
            />
          </div>
        </div>
      </Col>

      <Col size="12">
        <div className="form-group">
          <Select
            className={!props.lesson_id && "border-warning"}
            data-tip="Pelajaran"
            defaultValue={props.lesson_id}
            onChange={(e) => props.onChange("lesson_id", e.target.value)}
          >
            <option value="">
              {lessonsFetching.isFetched ? "Pilih Pelajaran" : "Memuat..."}
            </option>
            {lessonsFetching.data.map((row) => (
              <option value={row.id} key={row.id}>
                {row.name}
              </option>
            ))}
          </Select>
        </div>
      </Col>
      <Col size="12">
        <div className="form-group">
          <Select
            className={!props.grade_id && "border-warning"}
            data-tip="Tingkat"
            defaultValue={props.grade_id}
            onChange={(e) => props.onChange("grade_id", e.target.value)}
          >
            <option value="">
              {gradesFetching.isFetched ? "Pilih Tingkat" : "Memuat..."}
            </option>
            {gradesFetching.data.map((grade) => (
              <option value={grade.id} key={grade.id}>
                {grade.name}
              </option>
            ))}
          </Select>
        </div>
      </Col>
      <Col size="12">
        <Select
          className={!props.category_id && "border-warning"}
          data-tip="Kategori"
          defaultValue={props.category_id}
          onChange={(e) =>
            props.onChange("category_id", parseInt(e.target.value))
          }
        >
          <option value="">Pilih Kategori</option>
          {categories.map((category) => (
            <option value={category.id} key={category.id}>
              {category.name}
            </option>
          ))}
        </Select>
      </Col>
    </Row>
  );
}

SettingForm.propTypes = {};
