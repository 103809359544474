import React from "react";
import PropTypes from "prop-types";
import ModalPortal from "components/ModalPortal";
import styled from "styled-components";
import { connect } from "react-redux";
import { hideForbiddenErrorPopup } from "redux/page/action";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #d9e1ef;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

class ForbiddenError extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.body.classList.add("modal-open");
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  render() {
    return (
      <ModalPortal>
        <Container>
          <div className="nk-content ">
            <div className="nk-block nk-block-middle wide-xs mx-auto">
              <div className="nk-block-content nk-error-ld text-center">
                <h1 className="nk-error-head">
                  <em className="ni ni-lock-alt-fill"></em>
                </h1>
                <h3 className="nk-error-title">Tindakan Tak Diizinkan :)</h3>
                <p className="nk-error-text">
                  Mungkin data yang Anda akses bukan milik Anda. Atau data
                  tersebut sedang dilindungi dari tindakan yang merusak.
                </p>
                {this.props.message && (
                  <mark className="mb-2 h5 d-block">
                    {this.props.message}
                  </mark>
                )}
                <button
                  type="button"
                  onClick={(e) => this.props.hideForbiddenErrorPopup()}
                  className="btn btn-lg btn-primary mt-2"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </Container>
      </ModalPortal>
    );
  }
}

ForbiddenError.propTypes = {};

const mapStateToProps = (state) => ({
  message: state.page.forbiddenErrorPopup.message,
});

export default connect(mapStateToProps, { hideForbiddenErrorPopup })(
  ForbiddenError
);
