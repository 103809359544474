import React from "react";
import PropTypes from "prop-types";

class InputDecimal extends React.Component {
  handleChange = (e) => {
    this.props.viewModel.setValue(e.target.value);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.props.onChange &&
        this.props.onChange({ name: this.props.name, value: this.props.value });
    }
  }

  render() {
    const { wrappedComponentRef, value, onChange, viewModel, ...props } = this.props;
    return (
      <input
      ref={wrappedComponentRef}
        type="text"
        {...props}
        onChange={this.handleChange}
        value={value}
      />
    );
  }
}

InputDecimal.propTypes = {};

export default InputDecimal;
