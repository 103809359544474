import React from "react";
import Notif from "./Notif";
import UserInfo from "./UserInfo";
import { MobileMenuContext } from "components/Layouts/Subscription";
import { Link } from "react-router-dom";
import ErrorBoundary from "components/Error/ErrorBoundary";
import env from "domain/Environment/Env";

const BurgerMenu = (props) => {
  const mobileMenu = React.useContext(MobileMenuContext);
  return (
    <li className="d-lg-none">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          mobileMenu.toggle();
        }}
        className="toggle nk-quick-nav-icon mr-n1"
        data-target="sideNav"
      >
        <em className="icon ni ni-menu"></em>
      </a>
    </li>
  );
};

const QuickNav = (props) => (
  <div className="nk-header-tools">
    <ErrorBoundary
      fallback={
        <Link to="/logout" className="btn btn-primary ml-2">
          <em className="icon ni ni-signout"></em>
          <span>Sign out</span>
        </Link>
      }
    >
      <ul className="nk-quick-nav">
        {/* <Notif /> */}
        <UserInfo />
        <li>
          <a
            href={
              env.isProd()
                ? "https://guru.edulogy.id"
                : "https://guru.dev.edulogy.id"
            }
            className="btn btn-sm btn-outline-primary"
          >
            <span>Buka web lama</span> <em className="icon ni ni-arrow-right" />
          </a>
        </li>
        <BurgerMenu />
      </ul>
    </ErrorBoundary>
  </div>
);

export default QuickNav;
