import React from "react";
import PropTypes from "prop-types";
import truncate from "domain/String/Truncate";
import SimpleModal from "components/Modal/SimpleModal";
import { connect } from "react-redux";
import { deleteAttachment } from "redux/exam/action";

class AttachmentButton extends React.Component {
  static Modal = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = { isModalShow: false };
  }

  showModal = () => this.setState({ isModalShow: true });
  closeModal = () => this.setState({ isModalShow: false });

  delete = () =>
    this.props
      .deleteAttachment({
        questionUUID: this.props.question.uuid,
        id: this.props.attachmentId,
      })
      .catch(() => {});

  render() {
    const { text, icon, children, useModal = true, modalSize } = this.props;
    const MAX_BUTTON_TEXT_LENGTH = 25;
    const MAX_TITLE_LENGTH = 50;
    return (
      <>
        {useModal && this.state.isModalShow && (
          <SimpleModal
            size={modalSize}
            title={
              <>
                <span className="text-soft">Lampiran:</span>{" "}
                {truncate(text, MAX_TITLE_LENGTH)}
              </>
            }
            onClose={this.closeModal}
          >
            {children}
          </SimpleModal>
        )}

        <div className="btn-group mr-1 mb-1">
          <button
            type="button"
            onClick={this.showModal}
            className="btn btn-sm btn-dim btn-outline-info"
          >
            <em className={"icon ni ni-" + icon}></em>
            <span>{truncate(text, MAX_BUTTON_TEXT_LENGTH)}</span>
          </button>
          <button
            className="btn btn-sm btn-dim btn-outline-danger"
            onClick={this.delete}
          >
            <em className="ni ni-trash" />
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  question: state.exam.data.questions[state.exam.currentQuestion.uuid],
});

export default connect(mapStateToProps, { deleteAttachment })(AttachmentButton);
