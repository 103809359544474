import React from "react";
import PropTypes from "prop-types";
import * as portals from "react-reverse-portal";
import SkeletonTextfield from "components/Form/SkeletonTextfield";
import { Html5Entities } from "html-entities";
import styled from "styled-components";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import { EditorContext } from "pages/TeacherExamPage/SharedEditor";
import ClickOutsideAlerter from "components/Event/ClickOutsideAlerter";
import debounce from "lodash/debounce";

const decode = new Html5Entities().decode;

const Container = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 100%;
  all: inherit;
  background: transparent;
  border: 1px dashed #a4a2a2;
  border-radius: 4px;
  padding: 0 2px 0 2px;
  word-break: break-word;
  cursor: text;
  position: relative;

  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    content: "";
  }

  &.placeholder {
    color: #7777ff;
  }

  &.filled:not(:hover) {
    background: inherit;
    border-color: transparent;
  }
  & ol, & ul { all: revert }
`;

const EditorContainer = styled.div`
  & > div {
    max-width: 100%;
    cursor: text;
    min-width: 100%;
  }
  & ol, & ul { all: revert }
`;

class SkeletonEditor extends React.Component {
  static contextType = EditorContext;

  constructor(props) {
    super(props);
    this.debounceFn = null;
    this.state = {
      isEditorReady: false,
      isShowEditor: false,
      content: this.props.defaultValue,
    };
  }

  componentDidMount() {
    const timer = setInterval(() => {
      if (this.state.isEditorReady) {
        clearInterval(timer);
        return;
      }

      if (this.context.editor) {
        this.setState({ isEditorReady: true });
      }
    }, 300);
  }

  openEditor = () => {
    this.context.editor.setData(this.state.content || "");
    this.setState((state) => ({ isShowEditor: state.isEditorReady })); // hanya open editor jika editor "ready"
  };
  closeEditor = () => {
    this.handleEditorChange();
    this.context.editor.setData("");
    this.setState({ isShowEditor: false });
  };

  handleEditorChange = () => {
    const content = this.context.editor.getData().trim();
    this.setState({ content });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isShowEditor === true && this.state.isShowEditor === false) {
      this.props.onChange(this.state.content);
    }
  }

  render() {
    const { placeholder } = this.props;
    const { isShowEditor, content } = this.state;

    return (
      <>
        {!isShowEditor && (
          <Container
            className={cx({
              placeholder: isEmpty(content),
              filled: !isEmpty(content),
            })}
            onClick={this.openEditor}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: decode(content || placeholder || ""),
              }}
            />
          </Container>
        )}
        {isShowEditor && (
          <EditorContainer>
            <ClickOutsideAlerter
              when={isShowEditor}
              onClickOutside={this.closeEditor}
            >
              <portals.OutPortal
                node={this.context.portalNode}
                // onBlur={this.handleEditorChange}
              />
            </ClickOutsideAlerter>
          </EditorContainer>
        )}
      </>
    );
  }
}

SkeletonEditor.propTypes = {};

export default SkeletonEditor;
