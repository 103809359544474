import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import keys from "lodash/keys";
import { v4 as uuidv4 } from "uuid";
import without from "lodash/without";
import omit from "lodash/omit";
import Alert from "components/Alert/Alert";
import SimpleModal from "components/Modal/SimpleModal";
import { showLoader, hideLoader } from "redux/page/action";
import ReactTooltip from "react-tooltip";
import fileAPI from "api/file";
import questionkBankAPI from "api/bank/question";
import Select from "components/Form/Select";
import InputFile from "components/Form/InputFile";

const initialFileType = "image";

class AttachmentUploader extends React.Component {
  constructor(props) {
    super(props);
    const initialFileUUID = uuidv4();
    this.state = {
      filesOrder: [initialFileUUID],
      files: { [initialFileUUID]: null },
      fileTypes: { [initialFileUUID]: initialFileType },
      pendingUploadAttachments: [],
      errorsByFileUUID: {}, // validation error
      pendingsByFileUUID: {}, // success upload to file service but failed to target service
    };
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  componentDidUpdate() {
    if (this.state.filesOrder.length === 0) {
      this.props.onClose();
    }
  }

  handleChange = (e) => {
    e.persist();
    this.setState((state) => ({
      files: { ...state.files, [e.target.name]: e.target.files[0] },
    }));
  };

  handleTypeChange = (fileUUID, type) => {
    this.setState((state) => ({
      fileTypes: { ...state.fileTypes, [fileUUID]: type },
    }));
  };

  addRow = () => {
    const newFileUUID = uuidv4();
    this.setState((state) => ({
      filesOrder: [...state.filesOrder, newFileUUID],
      files: { ...state.files, [newFileUUID]: null },
      fileTypes: { ...state.fileTypes, [newFileUUID]: initialFileType },
    }));
  };

  deleteRow = (fileUUID) => {
    this.setState((state) => ({
      filesOrder: without(state.filesOrder, fileUUID),
      files: omit(state.files, fileUUID),
      fileTypes: omit(state.fileTypes, fileUUID),
      errorsByFileUUID: omit(state.errorsByFileUUID, fileUUID),
      pendingsByFileUUID: omit(state.pendingsByFileUUID, fileUUID),
    }));
  };

  upload = () => {
    const newAttachments = [];
    const promises = [];
    this.props.showLoader();
    this.state.filesOrder.forEach((fileUUID) => {
      const formData = new FormData();
      formData.append("file", this.state.files[fileUUID]);
      formData.append("type", this.state.fileTypes[fileUUID]);
      promises.push(
        fileAPI
          .upload(formData)
          .then((data) => {
            newAttachments.push({
              ...data,
              id: null,
              file_id: data.id,
              uuid: fileUUID,
            });
          })
          .catch((err) => {
            if (err.response && err.response.status === 422) {
              this.setState((state) => ({
                errorsByFileUUID: {
                  ...state.errorsByFileUUID,
                  [fileUUID]: err.response.data.error[0].message,
                },
              }));
              return;
            }
            this.setState((state) => ({
              errorsByFileUUID: {
                ...state.errorsByFileUUID,
                [fileUUID]:
                  "Gagal upload mohon pastikan jaringan internet stabil",
              },
            }));
          })
      );
    });
    Promise.all(promises).then(() => {
      this.props.hideLoader();
      if (newAttachments.length === 0) return;
      newAttachments.forEach((newAttachment) => {
        questionkBankAPI
          .addAttachment({
            question_id: this.props.question.id,
            file_id: newAttachment.file_id.toString(),
          })
          .then((data) => {
            this.props.onChange("attachments", [
              ...this.props.question.attachments,
              { ...newAttachment, id: data.id },
            ]);
            this.deleteRow(newAttachment.uuid);
          })
          .catch((err) => {
            console.log(err);
            this.setState((state) => ({
              pendingsByFileUUID: {
                ...state.pendingsByFileUUID,
                [newAttachment.uuid]: newAttachment,
              },
            }));
            if (err.response && err.response.status === 422) {
              this.setState((state) => ({
                errorsByFileUUID: {
                  ...state.errorsByFileUUID,
                  [newAttachment.uuid]: err.response.data.error[0].message,
                },
              }));
            }
          });
      });
    });
  };

  render() {
    const { filesOrder, errorsByFileUUID, pendingsByFileUUID } = this.state;
    return (
      <SimpleModal
        size="md"
        zIndex="1051"
        title={
          <>
            <span className="text-soft">Lampiran:</span> Tambah
          </>
        }
        onClose={this.props.onClose}
      >
        {keys(errorsByFileUUID).length > 0 && (
          <div className="mb-2">
            <Alert icon="info" variant="warning">
              <strong>Gagal menyimpan karena hal berikut:</strong>
              {filesOrder
                .filter((fileUUID) => errorsByFileUUID[fileUUID] !== undefined)
                .map((fileUUID) => {
                  return (
                    <div key={fileUUID}>
                      <em className="icon ni ni-bullet-fill" />{" "}
                      <span>{errorsByFileUUID[fileUUID]}</span>
                    </div>
                  );
                })}
            </Alert>
          </div>
        )}

        {keys(pendingsByFileUUID).length > 0 && (
          <div className="mb-2">
            <Alert icon="info" variant="warning">
              Gagal unggah. 99.9% sudah terunggah. Mohon cek jaringan internet.
              Sistem akan melanjutkan unggah.
            </Alert>
          </div>
        )}

        <div className="row gx-1 gy-2">
          {filesOrder.map((fileUUID) => {
            return (
              <>
                <div className="col-3">
                  <Select
                    name="type"
                    onChange={(e) =>
                      this.handleTypeChange(fileUUID, e.target.value)
                    }
                  >
                    <option value="image">Gambar</option>
                    <option value="audio">Audio</option>
                    <option value="video">Video</option>
                  </Select>
                </div>
                <div className="col-7 text-truncate text-nowrap">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <InputFile
                        name={fileUUID}
                        onChange={this.handleChange}
                        accept={(() => {
                          switch (this.state.fileTypes[fileUUID]) {
                            case "image":
                              return "image/*";
                            case "audio":
                              return "audio/*";
                            case "video":
                              return "video/*";
                          }
                        })()}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-dim btn-danger"
                    onClick={() => this.deleteRow(fileUUID)}
                  >
                    Batal
                  </button>
                </div>
              </>
            );
          })}
        </div>

        <div className="row mt-1">
          <div className="col-6">
            <button
              className="btn btn-sm btn-block btn-light mt-2"
              onClick={this.addRow}
              disabled={
                this.props.question.attachments.length +
                  this.state.filesOrder.length ===
                5
              }
            >
              Tambah lampiran
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-sm btn-block btn-primary mt-2"
              onClick={this.upload}
            >
              Unggah Semua
            </button>
          </div>
        </div>
      </SimpleModal>
    );
  }
}

AttachmentUploader.propTypes = {};

export default connect(null, {
  showLoader,
  hideLoader,
})(AttachmentUploader);
