import React, { useState } from "react";
import {
  addCategory,
  updateCategory,
  deleteCategory,
} from "redux/createQuestionBank/action";
import Block from "components/Block/Block";
import Alert from "components/Alert/Alert";
import ValidationError from "components/Error/ValidationError";
import { v4 as uuidv4 } from "uuid";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "./ManageCategoryModal.styled";
import SimpleModal from "components/Modal/SimpleModal";
import IconButton from "components/Button/IconButton";
import Button from "components/Button/Button";
import SpinnerGrow from "components/Spinner/SpinnerGrow";

function addHandlerCreator(dispatch) {
  return function addHandler(category) {
    return dispatch(addCategory(category));
  };
}

function updateHandlerCreator(dispatch) {
  return function updateHandler(category) {
    return dispatch(updateCategory(category));
  };
}

function deleteHandlerCreator(dispatch) {
  return function deleteHandler(category) {
    if (category.id === null) return;
    return dispatch(deleteCategory(category.id));
  };
}

function NoHandlerCreator(dispatch) {
  return function NoHandler(category) {
    return Promise.resolve({});
  };
}

export default function ManageCategoryModal(props) {
  const dispatch = useDispatch();

  const [validationErrors, setValidationErrors] = useState([]);
  const addHandler = addHandlerCreator(dispatch, setValidationErrors);
  const updateHandler = updateHandlerCreator(dispatch, setValidationErrors);
  const deleteHandler = deleteHandlerCreator(dispatch, setValidationErrors);
  const noHandler = NoHandlerCreator(dispatch, setValidationErrors);

  const categories = useSelector(
    (state) => state.createQuestionBank.categories
  ).map((category) => ({ uuid: uuidv4(), ...category, handle: noHandler }));

  const [localCategories, setLocalCategories] = useState(categories);
  const [isLoading, setIsLoading] = useState(false);

  function addLocalCategory() {
    setLocalCategories((state) => [
      ...state,
      {
        uuid: uuidv4(),
        id: null,
        name: null,
        handle: addHandler,
      },
    ]);
  }

  function updateLocalCategory(uuid, payload) {
    setLocalCategories((state) =>
      state.map((category) => {
        if (category.uuid !== uuid) return category;
        return {
          ...category,
          ...payload,
          handle: category.id ? updateHandler : addHandler,
        };
      })
    );
  }

  function deleteLocalCategory(uuid) {
    setLocalCategories((state) =>
      state.map((category) => {
        if (category.uuid !== uuid) return category;
        return { ...category, handle: deleteHandler };
      })
    );
  }

  function submit() {
    setIsLoading(true);
    Promise.all(localCategories.map((category) => category.handle(category)))
      .then(() => setTimeout(() => props.onClose(), 400))
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setValidationErrors(err.response.data.error);
        }
        setIsLoading(false);
      });
  }

  return (
    <SimpleModal
      size="sm"
      title={<span className="text-soft">Kelola Kategori</span>}
      onClose={() => props.onClose()}
    >
      <Block>
        {localCategories.length === 0 && (
          <Alert variant="info">Belum memiliki kategori.</Alert>
        )}
        <Grid>
          {localCategories.map((category, index) => {
            return (
              <>
                <div>{index + 1}.</div>
                <div>
                  <input
                    type="text"
                    className={cx({
                      "form-control": true,
                      "bg-danger-dim": category.handle.name === "deleteHandler",
                      "border-white": category.handle.name === "deleteHandler",
                    })}
                    defaultValue={category.name}
                    onBlur={(e) =>
                      updateLocalCategory(category.uuid, {
                        name: e.target.value,
                      })
                    }
                    disabled={category.handle.name === "deleteHandler"}
                  />
                </div>
                <div>
                  <Button
                    variant="danger"
                    dim
                    size="sm"
                    onClick={() => deleteLocalCategory(category.uuid)}
                    disabled={category.handle.name === "deleteHandler"}
                  >
                    Hapus
                  </Button>
                </div>
              </>
            );
          })}
        </Grid>

        <IconButton
          className="mt-2"
          size="sm"
          variant="light"
          icon="plus"
          block
          onClick={addLocalCategory}
        >
          Tambah Baris
        </IconButton>
      </Block>

      {validationErrors.length > 0 && (
        <Block>
          <ValidationError errors={validationErrors} />
        </Block>
      )}

      <Block>
        {!isLoading ? (
          <Button block onClick={submit}>
            Simpan Perubahan
          </Button>
        ) : (
          <div className="text-center">
            <SpinnerGrow size="1.5rem" />
          </div>
        )}
      </Block>
    </SimpleModal>
  );
}
