import axios from "configuredAxios";

export default {
  upload: (formData) =>
    axios
      .post("v1/file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data.data),
};
