import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";

const Investment = (props) => (
  <div className="nk-app-root nk-body npc-invest bg-lighter">
    <div className="nk-wrap">
      <div className="nk-content nk-content-lg nk-content-fluid">
        <div className="container-xl wide-lg">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {props.children} {/* CONTENT WILL BE PLACED HERE  */}
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  </div>
);

Investment.propTypes = {

};

export default Investment;
