import React from "react";
import PropTypes from "prop-types";
import GenerateRandomString from "domain/String/GenerateRandomString";
import styled from "styled-components";

const Label = styled.label`
  & {
    display: inline-block;
  }
  &::before {
    box-shadow: 0 0 2px #021df0 !important;
    border-color: white !important;
  }
  &::after {
    opacity: 0 !important;
  }
`;

const LabelContent = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.125rem;
  padding-top: 0;
  min-width: 100%;
  max-width: 100%;
`;

class Radio extends React.Component {
  constructor(props) {
    super(props);
  }

  preventPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { children, ...rest } = this.props;
    const id = GenerateRandomString();

    return (
      <div
        className="custom-control custom-control-sm custom-radio"
        style={{ minWidth: "100%", maxWidth: "100%" }}
      >
        <input
          type="radio"
          className="custom-control-input"
          id={"choice" + id}
          {...rest}
        />
        <Label
          className="custom-control-label"
          htmlFor={"choice" + id}
          style={{ maxWidth: 0 }}
        >
          &nbsp;
        </Label>
        <LabelContent>{children}</LabelContent>
      </div>
    );
  }
}

Radio.propTypes = {};

export default Radio;
