import styled from "styled-components";

const QuestionContentContainer = styled.div`
  /* margin-top: 30px; */

  display: inline-block;
  width: 100%;

  & textarea {
    &::placeholder {
      color: blue;
    }

    display: inline-block;
    all: inherit;
    background: white;
    border: 1px dashed #a4a2a2;
    border-radius: 4px;
    padding: 0 2px 0 2px;
    width: 100%;
    height: ${(props) => props.height};
    overflow: hidden;

    &.show-off {
      background: #f4bd0e;
      color: white;
    }

    &.filled:not(:hover) {
      background: inherit;
      border-color: transparent;
    }
  }

  & ol, & ul { all: revert }
`;

export default QuestionContentContainer;
