import axios from "axios";
import store from "redux/store";
import {
  showConnectionErrorPopup,
  showErrorPopup,
  show404Popup,
  showForbiddenErrorPopup,
} from "redux/page/action";
import { ErrorReport } from "domain/Error/ErrorReport";

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
});

instance.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response) {
      if (err.response.status === 404) {
        // let client handle this
      } else if (err.response.status === 403) {
        store.dispatch(showForbiddenErrorPopup(err.response.data.message));
      } else if (err.response.status >= 500 && err.response.status <= 599) {
        store.dispatch(showErrorPopup());
        ErrorReport(err);
      } else {
        throw err;
      }
    } else if (!err.status) {
      store.dispatch(showConnectionErrorPopup());
      ErrorReport(err);
    } else if (err.request) {
      store.dispatch(showErrorPopup()); // The request was made but no response was received
      ErrorReport(err);
    } else {
      store.dispatch(showErrorPopup());
      ErrorReport(err);
    }
    return Promise.reject(err);
  }
);

export default instance;
