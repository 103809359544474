import React, { useContext } from "react";
import PropTypes from "prop-types";
import NioIcon from "components/Icon/NioIcon";
import { useDispatch } from "react-redux";
import { duplicateQuestion } from "redux/createQuestionBank/action";
import { DataContext } from "./CardContext";
import Button from "components/Button/Button";
import { StaticData } from "../StaticDataContext";

const DuplicateButton = (props) => {
  const dispatch = useDispatch();
  const { question } = useContext(DataContext);
  const { onCardAdd } = useContext(StaticData);
  return (
    <Button
      size="sm"
      variant="lighter"
      className="text-base"
      onClick={(e) => {
        e.preventDefault();
        dispatch(duplicateQuestion(question.uuid));
        onCardAdd();
      }}
    >
      <NioIcon name="copy" />
      <span>Duplikasikan</span>
    </Button>
  );
};

export default DuplicateButton;
