import React from "react";
import Alert from "components/Alert/Alert";
import PageHeaderWithTools from "components/PageHeader/PageHeaderWithTools";
import api from "api";
import ExamSection from "./ExamSection";
import Tab from "components/Tab/SimpleTab";
import TeacherExamListContext from "./TeacherExamListContext";
import { connect } from "react-redux";
import { showErrorPopup } from "redux/page/action";
import Toastr from "components/Notification/Toastr";
import EmptyImage from "assets/images/empty-box.png";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import styled from "styled-components";
import qs from "qs";
import ReactTooltip from "react-tooltip";
import env from "domain/Environment/Env";

const FilterContainer = styled.div`
  width: 100%;
  @media only screen and (min-width: 800px) {
    width: 240px;
  }
  & > select {
    padding: 0 20px 0 15px;
    width: 100%;
  }
`;

class ExamListPage extends React.Component {
  static contextType = Toastr.Context;

  constructor(props) {
    super(props);
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.state = {
      filter: {
        lessons: [],
        classes: [],
      },
      selectedFilter: {
        lesson: query.lesson ? query.lesson : null,
        class: query.class ? query.class : null,
      },
      academicClass: null,
      inactiveOnGoingExams: [],
      onGoingExams: [],
      passedExams: [],
      soonExams: [],
      allExams: [],
      isLessonFilterFetched: false,
      isClassFilterFetched: true,
      isAcademicClassFetched: false,
      isExamListFetched: false,
    };
  }

  componentDidMount() {
    document.title = "Ulangan";
    this.fetchLesson();

    // kalau ada query params ?className=XXX kita ambil daftar ulangan
    // jika tidak, daftar ulangan otomatis kosong dan harus memilih
    // filter kelas dan pelajaran manual.
    if (this.state.selectedFilter.class) {
      api.teacher
        .getTaughtClasses(this.state.selectedFilter.lesson)
        .then((classes) => {
          this.setState((state) => ({
            filter: { ...state.filter, classes },
            isClassFilterFetched: true,
          }));
        });
      this.fetchExams(this.state.selectedFilter.class);
      api.academicClass
        .findOne(this.state.selectedFilter.class)
        .then((data) => {
          this.setState({ academicClass: data, isAcademicClassFetched: true });
        });
    }
    ReactTooltip.rebuild();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  fetchLesson = () => {
    api.teacher.getTaughtLessons().then((data) => {
      this.setState((state) => ({
        filter: { ...state.filter, lessons: data },
        isLessonFilterFetched: true,
      }));
    });
  };

  fetchExams = (academicClassId) => {
    api.exam.getExams(academicClassId).then((exams) => {
      const inactiveOnGoingExams = exams.filter(
        (exam) =>
          exam.publish_status === "on_going" && exam.status === "inactive"
      );
      const onGoingExams = exams.filter(
        (exam) => exam.publish_status === "on_going" && exam.status === "active"
      );
      const passedExams = exams.filter(
        (exam) => exam.publish_status === "passed"
      );
      const soonExams = exams.filter((exam) => exam.publish_status === "soon");
      const allExams = exams;
      setTimeout(
        () =>
          this.setState({
            inactiveOnGoingExams,
            onGoingExams,
            passedExams,
            soonExams,
            allExams,
            isExamListFetched: true,
          }),
        900
      );
    });
  };

  deleteExam = (id) => {
    return api.exam
      .delete(id)
      .then(() => {
        this.setState((state) => ({
          onGoingExams: state.onGoingExams.filter((exam) => exam.id !== id),
          passedExams: state.passedExams.filter((exam) => exam.id !== id),
          soonExams: state.soonExams.filter((exam) => exam.id !== id),
          allExams: state.allExams.filter((exam) => exam.id !== id),
        }));
        this.context.toastrAddItem(
          <Toastr.Item
            variant="success"
            icon="check-circle-fill"
            className="bg-success-dim p-2"
          >
            Berhasil menghapus ulangan.
          </Toastr.Item>
        );
      })
      .catch(() => {
        this.context.toastrAddItem(
          <Toastr.Item
            variant="danger"
            icon="info-fill"
            className="bg-danger-dim p-2"
          >
            Gagal menghapus ulangan.
          </Toastr.Item>
        );
      });
  };

  handleLessonFilterChange = (e) => {
    e.persist();
    this.setState((state) => ({
      selectedFilter: { class: null, lesson: e.target.value },
      isClassFilterFetched: false,
      isExamListFetched: false,
      academicClass: null,
      inactiveOnGoingExams: [],
      onGoingExams: [],
      passedExams: [],
      soonExams: [],
      allExams: [],
      isAcademicClassFetched: false,
    }));
    setTimeout(() => {
      api.teacher.getTaughtClasses(e.target.value).then((classes) => {
        this.setState((state) => ({
          filter: { ...state.filter, classes },
          isClassFilterFetched: true,
        }));
      });
    }, 300);
  };

  handleClassFilterChange = (e) => {
    e.persist();
    this.setState((state) => ({
      selectedFilter: { ...state.selectedFilter, class: e.target.value },
      isExamListFetched: false,
      academicClass: null,
      inactiveOnGoingExams: [],
      onGoingExams: [],
      passedExams: [],
      soonExams: [],
      allExams: [],
      isAcademicClassFetched: false,
    }));
    this.fetchExams(e.target.value);
    api.academicClass.findOne(e.target.value).then((data) => {
      this.setState({ academicClass: data, isAcademicClassFetched: true });
    });
  };

  render() {
    const {
      academicClass,
      inactiveOnGoingExams,
      onGoingExams,
      passedExams,
      soonExams,
      allExams,
      filter,
      selectedFilter,
      isLessonFilterFetched,
      isClassFilterFetched,
      isAcademicClassFetched,
      isExamListFetched,
    } = this.state;

    return (
      <>
        <PageHeaderWithTools
          heading="Ulangan"
          subheading={
            <span />
            // <div className="mb-4" style={{ opacity: academicClass ? 1 : 0.7 }}>
            //   <span className="btn-group">
            //     <span className="btn btn-sm btn-secondary btn-dim">Kelas</span>
            //     <span className="btn btn-sm btn-danger">
            //       {academicClass ? academicClass.school_class.name : "..."}
            //     </span>
            //   </span>
            //   <span className="btn-group ml-2">
            //     <span className="btn btn-sm btn-secondary btn-dim">
            //       Pelajaran
            //     </span>
            //     <span className="btn btn-sm btn-warning">
            //       {academicClass ? academicClass.school_lesson.name : "..."}
            //     </span>
            //   </span>
            // </div>
          }
          message="Berikut adalah daftar ulangan yang telah dibuat."
        >
          <li>
            <div className="form-control-wrap">
              <FilterContainer>
                {isLessonFilterFetched ? (
                  <div className="form-group">
                    {!selectedFilter.lesson && (
                      <span className="form-note">
                        &nbsp;{" "}
                        {/* ini hanya agar sejajar dengan filter kelas */}
                      </span>
                    )}
                    <div className="form-control-select">
                      <select
                        className="form-control form-control-lg"
                        onChange={this.handleLessonFilterChange}
                        value={selectedFilter.lesson}
                      >
                        <option value="">- Pilih Pelajaran -</option>
                        {filter.lessons.map((lesson) => (
                          <option key={lesson.id} value={lesson.id}>
                            {lesson.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    <SpinnerGrow variant="warning" />
                  </div>
                )}
              </FilterContainer>
            </div>
          </li>
          <li>
            <FilterContainer>
              <div className="form-group">
                <div className="form-control-wrap">
                  {isLessonFilterFetched && isClassFilterFetched ? (
                    <>
                      {!selectedFilter.lesson && (
                        <span className="form-note">
                          Mohon pilih <code>pelajaran</code> dahulu
                        </span>
                      )}
                      <div className="form-control-select">
                        <select
                          className="form-control form-control-lg"
                          onChange={this.handleClassFilterChange}
                          value={selectedFilter.class}
                        >
                          <option value="">- Pilih Kelas -</option>
                          {filter.classes.map((klass) => (
                            <option key={klass.id} value={klass.id}>
                              {klass.class_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <SpinnerGrow variant="warning" />
                    </div>
                  )}
                </div>
              </div>
            </FilterContainer>
          </li>
        </PageHeaderWithTools>

        {isExamListFetched && inactiveOnGoingExams.length > 0 && (
          <Alert variant="danger" icon="info" className="mt-1 mb-0">
            Ada {inactiveOnGoingExams.length} ulangan{" "}
            <strong>sedang berlangsung</strong> namun statusnya{" "}
            <code>tidak aktif</code>.<br />
            Ulangan tersebut tidak akan dibagikan ke siswa hingga diaktifkan.
          </Alert>
        )}

        {!selectedFilter.class ? (
          <div className="text-center mt-2 mb-2 ml-n5">
            <img src={EmptyImage} style={{ width: "150px" }} />
            <h5 className="text-azure mt-4">
              <button className="btn btn-dim btn-outline-warning btn-dim">
                Kelas dan Pelajaran Belum Dipilih
              </button>
            </h5>
          </div>
        ) : (
          <TeacherExamListContext.Provider
            value={{
              academicClassId: selectedFilter.class,
              deleteExam: this.deleteExam,
            }}
          >
            <Tab.Container defaultActive={0}>
              <Tab title={`Semua (${allExams.length})`}>
                <ExamSection isLoading={!isExamListFetched} exams={allExams} />
              </Tab>
              <Tab title={`Sedang Berlangsung (${onGoingExams.length})`}>
                <ExamSection
                  isLoading={!isExamListFetched}
                  exams={onGoingExams}
                />
              </Tab>
              <Tab title={`Mendatang (${soonExams.length})`}>
                <ExamSection isLoading={!isExamListFetched} exams={soonExams} />
              </Tab>
              <Tab title={`Sudah Lewat (${passedExams.length})`}>
                <ExamSection
                  isLoading={!isExamListFetched}
                  exams={passedExams}
                />
              </Tab>
            </Tab.Container>
          </TeacherExamListContext.Provider>
        )}
      </>
    );
  }
}

ExamListPage.propTypes = {};

export default connect(null, { showErrorPopup })((props) => (
  <Toastr position="top-center" maxItem={3}>
    <ExamListPage {...props} />
  </Toastr>
));
