import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Archieve from "./Types/Archieve";
import Document from "./Types/Document";
import Video from "./Types/Video";
import Audio from "./Types/Audio";
import Image from "./Types/Image";
import Url from "./Types/Url";
import Youtube from "./Types/Youtube";
import ErrorBoundary from "components/Error/ErrorBoundary";

const Container = styled.div`
  padding: 0 0 0 10px;
  border-left: 3px solid rgb(76, 221, 244);
`;

const Content = styled.div`
  margin-top: 4px;
  margin-bottom: 25px;
  width: 100%;
`;

const Attachments = ({ data }) => {
  const componentDict = {
    archieve: Archieve,
    document: Document,
    video: Video,
    audio: Audio,
    image: Image,
    url: Url,
    youtube: Youtube,
  };
  const attachments = data.map((attachment, index) => {
    const Component = componentDict[attachment.type];
    if (!Component) {
      throw new Error("Jenis lampiran tidak valid");
    }
    return <Component key={index} data={attachment} />;
  });

  return (
    <Container>
      <span className="text-base">Lampiran ({data.length})</span>
      <Content>{attachments}</Content>
    </Container>
  );
};

export default (props) => (
  <ErrorBoundary
    fallback={
      <div className="p-2 text-warning">
        Oops terjadi kesalahan: tak bisa menampilkan lampiran.
      </div>
    }
  >
    <Attachments {...props} />
  </ErrorBoundary>
);
