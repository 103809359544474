import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import NioIcon from "components/Icon/NioIcon";
import AttachmentUploader from "./AttachmentUploader";
import Button from "components/Button/Button";
import { DataContext } from "./CardContext";

export default function AttachmentButton(props) {
  const [isModalShow, setIsModalShow] = useState(false);
  const { question } = useContext(DataContext);
  return (
    <>
      <Button
        size="sm"
        variant="lighter"
        className="text-base"
        disabled={question.attachments.length === 5}
        onClick={(e) => {
          /* TODO: disable ketika attachment > 5 */
          e.preventDefault();
          setIsModalShow(true);
        }}
      >
        <NioIcon name="clip" />
        <span>Unggah lampiran</span>
      </Button>

      {isModalShow && (
        <AttachmentUploader onClose={() => setIsModalShow(false)} />
      )}
    </>
  );
}
