import React from "react";
import PropTypes from "prop-types";
import SpinnerEllipsis from "components/Spinner/SpinnerEllipsis";

const LoadingRow = (props) => (
  <tr>
    <td colSpan={7} className="text-center">
      <SpinnerEllipsis size="1.3rem" />
    </td>
  </tr>
);

LoadingRow.propTypes = {};

export default LoadingRow;
