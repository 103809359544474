import { FETCHED } from "./type";
import produce from "immer";

const initialState = null;

const questionTypes = produce((state, action = {}) => {
  if (state === undefined) return initialState;

  switch (action.type) {
    case FETCHED:
      return action.types.map((row) => ({
        type: row.type,
        formated_type: row.formated_type,
      }));
  }
});

export default questionTypes;
