import React, { useContext } from "react";
import PropTypes from "prop-types";
import ManageCategoryButton from "./ManageCategoryButton";
import Col from "components/Grid/Col";
import Row from "components/Grid/Row";
import Select from "components/Form/Select";
import InputDecimal from "components/Form/InputDecimal/InputDecimalViewModel";
import { StaticData } from "../StaticDataContext";
import { DataContext } from "./CardContext";
import { useSelector, useDispatch } from "react-redux";
import {
  saveQuestion,
  changeQuestionType,
} from "redux/createQuestionBank/action";

export default function SettingForm(props) {
  const { question } = useContext(DataContext);
  const { gradesFetching, lessonsFetching, questionTypesFetching } = useContext(
    StaticData
  );

  const dispatch = useDispatch();
  function handleChangeType(e) {
    dispatch(
      changeQuestionType({
        questionUUID: question.uuid,
        questionTypeTarget: e.target.value,
      })
    );
  }

  const categories = useSelector((state) => state.createQuestionBank.categories);
  return (
    <Row className="gy-1">
      <Col size="12">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-text-hint">
              <span className="overline-title">Poin</span>
            </div>
            <InputDecimal
              min={1}
              max={100}
              maxDecimal={2}
              defaultValue={question.point}
              onChange={({ name, value }) =>
                dispatch(
                  saveQuestion({
                    uuid: question.uuid,
                    attribute: "point",
                    value,
                  })
                )
              }
              className="form-control"
              placeholder="Masukan poin..."
            />
          </div>
        </div>
      </Col>
      {question.type === "multianswer" && (
        <Col size="12">
          <div className="form-group">
            <Select
              className={!question.scoring_mode && "border-warning"}
              data-tip="Mode Penilaian"
              defaultValue={question.scoring_mode}
              onChange={(e) =>
                dispatch(
                  saveQuestion({
                    uuid: question.uuid,
                    attribute: "scoring_mode",
                    value: e.target.value,
                  })
                )
              }
            >
              <option value="">Pilih Mode Penilaian</option>
              <option value="allornothing">Wajib Benar Semua</option>
            </Select>
          </div>
        </Col>
      )}

      <Col size="12">
        <div className="form-group">
          <Select
            className={!question.lesson_id && "border-warning"}
            data-tip="Pelajaran"
            defaultValue={question.lesson_id}
            onChange={(e) =>
              dispatch(
                saveQuestion({
                  uuid: question.uuid,
                  attribute: "lesson_id",
                  value: parseInt(e.target.value),
                })
              )
            }
          >
            <option value="">
              {lessonsFetching.isFetched ? "Pilih Pelajaran" : "Memuat..."}
            </option>
            {lessonsFetching.data.map((row) => (
              <option value={row.id} key={row.id}>
                {row.name}
              </option>
            ))}
          </Select>
        </div>
      </Col>
      <Col size="12">
        <div className="form-group">
          <Select
            className={!question.grade_id && "border-warning"}
            data-tip="Tingkat"
            defaultValue={question.grade_id}
            onChange={(e) =>
              dispatch(
                saveQuestion({
                  uuid: question.uuid,
                  attribute: "grade_id",
                  value: e.target.value,
                })
              )
            }
          >
            <option value="">
              {gradesFetching.isFetched ? "Pilih Tingkat" : "Memuat..."}
            </option>
            {gradesFetching.data.map((grade) => (
              <option value={grade.id} key={grade.id}>
                {grade.name}
              </option>
            ))}
          </Select>
        </div>
      </Col>
      <Col size="12">
        <div className="form-group">
          <Select
            className={!question.type && "border-warning"}
            data-tip="Jenis Soal"
            defaultValue={question.type}
            onChange={handleChangeType}
          >
            {questionTypesFetching.data.map((row) => (
              <option value={row.type} key={row.type}>
                {row.formated_type}
              </option>
            ))}
          </Select>
        </div>
      </Col>
      <Col size="12">
        <Row className="gx-1">
          <Col size="8">
            <Select
              className={!question.category_id && "border-warning"}
              data-tip="Kategori"
              defaultValue={question.category_id}
              onChange={(e) =>
                dispatch(
                  saveQuestion({
                    uuid: question.uuid,
                    attribute: "category_id",
                    value: parseInt(e.target.value),
                  })
                )
              }
            >
              <option value="">Pilih Kategori</option>
              {categories.map((category) => (
                <option value={category.id} key={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>
          </Col>
          <Col size="4">
            <ManageCategoryButton />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

SettingForm.propTypes = {};
