import React from "react";
import PropTypes from "prop-types";
import LeftSidebar from "./LeftSidebar";
import RightSidebar from "./RightSidebar";
import QuestionBox from "./QuestionBox";
import QuestionNavigator from "./QuestionNavigator";
import { connect } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
  & {
    position: relative;
  }
  & .disabler {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1040;
    background-color: #364a63;
    opacity: 0.2;
  }
  & .disabler-message {
    display: inline-block;
    background: #fef6e0;
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1041;
    opacity: 1;
    border-radius: 4px;
    padding: 1.2rem;
    box-shadow: 0 1px 3px 1px #ccc;
  }
`;

class AccomplishSection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="nk-block">
        <Container className={"card card-custom-s1 card-bordered"}>
          <div className="row no-gutters">
            <div
              className="col-lg-2"
              style={{ borderRight: "1px solid #dbdfea" }}
            >
              <LeftSidebar />
            </div>
            <div className="col-md-9 col-lg-7" style={{ minHeight: "500px" }}>
              <QuestionNavigator />
              <QuestionBox key={this.props.currentQuestionUUID} />
            </div>
            <div className="col-md-3 col-lg-3">
              <RightSidebar />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

AccomplishSection.propTypes = {};

const mapStateToProps = (state) => ({
  currentQuestionUUID: state.examSubmissionReview.currentQuestion.uuid,
});

export default connect(mapStateToProps)(AccomplishSection);
