import React from "react";
import PropTypes from "prop-types";
import SpinnerGrow from "components/Spinner/SpinnerGrow";

const SummaryCard = ({ renderTitle, renderContent, isLoading }) => (
  <div className="nk-wg-card is-s1 card card-bordered">
    <div className="card-inner">
      <div className="nk-iv-wg2">
        <div className="nk-iv-wg2-title">
          <h6 className="title">{renderTitle}</h6>
        </div>
        <div className="nk-iv-wg2-text">
          <div className="nk-iv-wg2-amount">
            {isLoading ? renderContent : <SpinnerGrow variant="warning" size="2.4rem" />}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SummaryCard;
