import omit from "lodash/omit";
import { v4 as uuidv4 } from "uuid";

export default function fromMultichoiceReducer(state, action, question) {
  switch (action.questionTypeTarget) {
    case "multichoice": {
      break;
    }
    case "multianswer": {
      question.type = "multianswer";
      question.scoring_mode = null;
      break;
    }
    case "truefalse": {
      state.answers = omit(state.answers, state.answerOrder[question.uuid]); // remove previous answer
      const answer = { uuid: uuidv4(), answer: null };
      state.answerOrder[question.uuid] = [answer.uuid];
      state.answers[answer.uuid] = answer;
      question.type = "truefalse";
      break;
    }
    case "essay": {
      const content = state.answerOrder[question.uuid]
        .map((answerUUID) => state.answers[answerUUID].content || "")
        .join("<br/>")
        .replace(/<br\/>$/, ""); // get choices's content as essay content
      state.answers = omit(state.answers, state.answerOrder[question.uuid]); // remove previous answer
      const answer = { uuid: uuidv4(), content };
      state.answerOrder[question.uuid] = [answer.uuid];
      state.answers[answer.uuid] = answer;
      question.type = "essay";
      break;
    }
    default:
      throw new Error("invalid type target");
  }
}
