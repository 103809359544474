import React from "react";
import { Switch, withRouter } from "react-router-dom";
import AnyRoute from "./components/Routes/AnyRoute";
import UserRoute from "./components/Routes/UserRoute";
import GuestRoute from "./components/Routes/GuestRoute";
import ReactGA from "react-ga";
import env from "domain/Environment/Env";
import lazyDelay from "domain/React/LazyDelay";
import PageFallback from "components/Loading/PageFallback";
import ErrorBoundary from "components/Error/ErrorBoundary";

import Subscription from "./components/Layouts/Subscription";
// import Investment from "./components/Layouts/Investment";
import Blank from "./components/Layouts/Blank";

import TeacherExamListPage from "./pages/TeacherExamListPage";
import TeacherExamPage from "./pages/TeacherExamPage";
import TeacherExamResultPage from "./pages/TeacherExamResultPage";
import TeacherExamSubmissionListPage from "./pages/TeacherExamSubmissionListPage";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import NoInternet from "components/Error/NoInternet";
import TeacherExamSubmissonReviewPage from "pages/TeacherExamSubmissonReviewPage";
import QuestionBankListPage from "pages/QuestionBankListPage";
import CreateQuestionBankPage from "pages/CreateQuestionBankPage";
const E_404 = lazyDelay(() => import("./pages/E_404"));
const ExamSubmissionListPage = lazyDelay(() =>
  import("./pages/ExamSubmissionListPage")
);

const App = ({ history }) => {
  history.listen((location) => {
    if (!env.isProd()) return;
    ReactGA.pageview(location.pathname + location.search);
  });

  return (
    <>
      <React.Suspense fallback={<PageFallback />}>
        <Switch>
          <UserRoute
            path="/"
            exact
            layout={Subscription}
            component={TeacherExamListPage}
          />
          <UserRoute
            path="/academic/exam"
            exact
            layout={Subscription}
            component={TeacherExamListPage}
          />
          <UserRoute
            path="/class/:schoolAcademicId/academic/exam/create"
            exact
            layout={Blank}
            component={TeacherExamPage}
          />
          <UserRoute
            path="/class/:schoolAcademicId/academic/exam/:examId/edit"
            exact
            layout={Blank}
            component={TeacherExamPage}
          />
          <UserRoute
            path="/class/:schoolAcademicId/academic/exam/:examId/result"
            exact
            layout={Subscription}
            component={TeacherExamResultPage}
          />
          <UserRoute
            path="/class/:schoolAcademicId/academic/exam/:examId/student/:studentId/submission"
            exact
            layout={Subscription}
            component={TeacherExamSubmissionListPage}
          />
          <UserRoute
            path="/class/:schoolAcademicId/academic/exam/:examId/student/:studentId/submission/:submissionId"
            exact
            layout={Blank}
            component={TeacherExamSubmissonReviewPage}
          />
          <UserRoute
            path="/bank/question"
            exact
            layout={Subscription}
            component={QuestionBankListPage}
          />
          <UserRoute
            path="/bank/question/create"
            exact
            layout={Blank}
            component={CreateQuestionBankPage}
          />
          <GuestRoute path="/login" exact component={LoginPage} />
          <UserRoute path="/logout" exact component={LogoutPage} />

          <AnyRoute component={E_404} />
        </Switch>
      </React.Suspense>
    </>
  );
};

const AppWithRouter = withRouter(App);

export default (props) => (
  <ErrorBoundary fallback={<NoInternet />}>
    <AppWithRouter {...props} />
  </ErrorBoundary>
);
