import React from "react";
import PropTypes from "prop-types";
import Select from "components/Form/Select";
import Label from "components/Form/Label";

class ScoreFilter extends React.Component {
  render() {
    return (
      <div className="form-group">
        <Label>Status Penilaian</Label>
        <Select {...this.props}>
          <option value="">Semua</option>
          <option value="true">Sudah Diberi Nilai</option>
          <option value="false">Belum Diberi Nilai</option>
        </Select>
      </div>
    );
  }
}

export default ScoreFilter;
