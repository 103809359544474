import { FETCHED } from "./type";
import api from "api/master/questionType";

export const fetched = (types) => ({
  type: FETCHED,
  types,
});

export const fetch = () => {
  return (dispatch) =>
    api.get().then((types) => {
      dispatch(fetched(types));
    });
};
