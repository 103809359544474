import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "components/Icon/Icon";
import prettyFloat from "domain/Float/PrettyFloat";
import ErrorBoundary from "components/Error/ErrorBoundary";
import QuestionEntity from "domain/Exam/QuestionEntity";
import { connect } from "react-redux";
import { switchQuestion } from "redux/exam/action";
import QuestionDeleteButton from "./QuestionDeleteButton";
import QuestionAttachmentUploader from "./QuestionAttachmentUploader";

const formatedQuestionType = {
  multichoice: "Pilihan Ganda",
  multianswer: "PG Kompleks",
  truefalse: "Benar/Salah",
  essay: "Esai",
};

const formatedScoringMode = {
  allornothing: "Wajib Benar Semua",
  // rightminuswrong: "Benar dikurangi Salah",
  // correctanswer: "Hitung Benar saja",
};

const Container = styled.div`
  background: #f8f8f8;
  border: none;
  border-radius: initial;
  border-bottom: 1px solid #dbdfea;
  /* height: 67.48px; */
`;

class QuestionNavigator extends React.Component {
  constructor(props) {
    super(props);
  }

  switchQuestion = ({ sectionId, uuid }) =>
    this.props.switchQuestion({ sectionId, uuid });

  render() {
    const {
      currentQuestion,
      questions,
      sectionOrder,
      questionOrder,
    } = this.props;

    const question = new QuestionEntity(currentQuestion)
      .setSectionOrder(sectionOrder)
      .setQuestionOrder(questionOrder)
      .setQuestions(questions);

    return (
      <Container className="card card-bordered bg-white">
        <div className="card-inner" style={{ padding: ".75rem" }}>
          <div className="align-center gx-3">
            <div className="flex-item">
              <h6 style={{ marginBottom: 0 }}>
                Soal {question.getAbsoluteIndex() + 1}
              </h6>
              <div className="sub-text-sm mt-1">
                <span className="text-base mr-1">
                  {question.point ? (
                    prettyFloat(question.point)
                  ) : (
                    <em>kosong</em>
                  )}{" "}
                  poin
                </span>
                <span className="text-base mr-1">&middot;</span>
                <span className="text-base mr-1">
                  {formatedQuestionType[question.type]}
                </span>
                {question.type === "multianswer" && (
                  <>
                    <span className="text-base mr-1">&middot;</span>
                    <span className="text-base mr-1">
                      {formatedScoringMode[question.scoring_mode]}
                    </span>
                  </>
                )}
                <span className="text-base mr-1">&middot;</span>
                <span className="text-base mr-1"><QuestionAttachmentUploader /></span>
                <span className="text-base mr-1">&middot;</span>
                <span className="text-base mr-1">
                  <QuestionDeleteButton question={question} />
                </span>
              </div>
            </div>
            <div className="flex-item" style={{ marginLeft: "auto" }}>
              <button
                className="btn btn-dim btn-gray"
                disabled={question.prev() === null}
                onClick={() => this.switchQuestion(question.prev())}
              >
                <Icon nio="arrow-left" />
              </button>
            </div>
            <div className="flex-item">
              <button
                className="btn btn-dim btn-gray"
                disabled={question.next() === null}
                onClick={() => this.switchQuestion(question.next())}
              >
                <Icon nio="arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

QuestionNavigator.propTypes = {};

const mapStateToProps = (state) => ({
  currentQuestion: state.exam.data.questions[state.exam.currentQuestion.uuid],
  questions: state.exam.data.questions,
  sectionOrder: state.exam.sectionOrder,
  questionOrder: state.exam.questionOrder,
});

const QuestionNavigatorRedux = connect(mapStateToProps, { switchQuestion })(
  QuestionNavigator
);

export default (props) => (
  <ErrorBoundary fallback={<span></span>}>
    <QuestionNavigatorRedux {...props} />
  </ErrorBoundary>
);
