import React from "react";
import PropTypes from "prop-types";
import QuestionContentContainer from "./shared/QuestionContentContainer";
import AnswerContainer from "./shared/AnswerContainer";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Radio from "components/Form/Radio";
import { connect } from "react-redux";
import {
  saveQuestionLocally,
  saveAnswerLocally,
  markAnswerSavedInServer,
} from "redux/exam/action";
import ErrorBoundary from "components/Error/ErrorBoundary";
import QuestionForm from "./QuestionForm";
import api from "api";
import omit from "lodash/omit";

class Truefalse extends React.Component {
  saveQuestion = () => {
    if (this.props.question.id) {
      const promises = [
        new Promise((resolve, reject) =>
          resolve("just make sure has 1 promise")
        ),
      ];
      if (this.props.isQuestionChanged) {
        promises.push(
          api.exam.question.update({
            questionId: this.props.question.id,
            point: this.props.question.point,
            content: this.props.question.content,
          })
        );
      }
      // is answer changed
      if (!this.props.isAnswersSavedInServer[this.props.answer.uuid]) {
        promises.push(
          api.exam.answer
            .update({
              questionId: this.props.question.id,
              answerId: this.props.answer.id,
              payload: { answer: this.props.answer.answer },
            })
            .then(() =>
              this.props.markAnswerSavedInServer({
                uuid: this.props.answer.uuid,
              })
            )
        );
      }
      return Promise.all(promises);
    } else {
      return api.exam.question
        .create({
          section_id: this.props.question.sectionId,
          question: {
            ...this.props.question,
            answer: this.props.answer.answer,
          },
        })
        .then((data) => {
          this.props.saveQuestionLocally({
            uuid: this.props.question.uuid,
            question: { ...this.props.question, ...omit(data, "answer") },
          });
          // NOTE: pastikan backend mengembalikan data choice sesuai urutan.
          this.props.saveAnswerLocally({
            uuid: this.props.answer.uuid,
            answer: { ...this.props.answer, id: data.answer.id },
          });
          this.props.markAnswerSavedInServer({ uuid: this.props.answer.uuid });
        });
    }
  };

  onChoose = (e) => {
    this.props.saveAnswerLocally({
      uuid: this.props.answer.uuid,
      answer: { ...this.props.answer, answer: e.target.value === "true" },
    });
  };

  render() {
    return (
      <>
        <QuestionForm onSave={this.saveQuestion} />

        <AnswerContainer>
          <AnswerItemBordered style={{ flexGrow: 1, minWidth: 0 }}>
            <Radio
              name="choice"
              value={true}
              checked={this.props.answer.answer === true}
              onChange={this.onChoose}
            >
              Benar
            </Radio>
          </AnswerItemBordered>
          <AnswerItemBordered style={{ flexGrow: 1, minWidth: 0 }}>
            <Radio
              name="choice"
              value={false}
              checked={this.props.answer.answer === false}
              onChange={this.onChoose}
            >
              Salah
            </Radio>
          </AnswerItemBordered>
        </AnswerContainer>
      </>
    );
  }
}

Truefalse.propTypes = {};

const mapStateToProps = (state) => ({
  answer:
    state.exam.data.answers[
      state.exam.answerOrder[state.exam.currentQuestion.uuid][0]
    ],
  question: state.exam.data.questions[state.exam.currentQuestion.uuid],
  isQuestionChanged: !state.exam.isQuestionsSavedInServer[
    state.exam.currentQuestion.uuid
  ],
  isAnswersSavedInServer: state.exam.isAnswersSavedInServer,
});

const TruefalseRedux = connect(mapStateToProps, {
  saveQuestionLocally,
  saveAnswerLocally,
  markAnswerSavedInServer,
})(Truefalse);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <TruefalseRedux {...props} />
  </ErrorBoundary>
);
