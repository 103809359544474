import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: props.defaultActive };
  }

  switchTab = (newIndex) => this.setState({ activeIndex: newIndex });

  render() {
    const activeTab = this.props.children[this.state.activeIndex];
    const titles = React.Children.map(
      this.props.children,
      (child) => child.props.title
    );
    return (
      <>
        <ul className="nav nav-tabs">
          {titles.map((title, tabIndex) => {
            return (
              <li className="nav-item" key={tabIndex}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => this.switchTab(tabIndex)}
                  className={cx({
                    "nav-link": true,
                    active: this.state.activeIndex === tabIndex,
                  })}
                >
                  {title}
                </span>
              </li>
            );
          })}
        </ul>
        <div className="tab-content">
          <div className="tab-pane active">{activeTab}</div>
        </div>
      </>
    );
  }
}

class Tab extends React.Component {
  static Container = Container;
  render() {
    return this.props.children;
  }
}

Tab.propTypes = {};

export default Tab;
