import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NioIcon from "components/Icon/NioIcon";
import AttachmentButton from "./AttachmentButton";
import DuplicateButton from "./DuplicateButton";
import DeleteButton from "./DeleteButton";

const CardFooter = (props) => {
  return (
    <div className="nk-wgw-actions bg-lighter d-flex justify-content-between">
      <ul>
        <li>
          <AttachmentButton />
        </li>
      </ul>

      <ul>
        <li>
          <DuplicateButton />
        </li>
        <li>
          <DeleteButton question={props.question} />
        </li>
      </ul>
    </div>
  );
};

CardFooter.propTypes = {};

export default CardFooter;
