import React from "react";
import PropTypes from "prop-types";
import questionAPI from "api/master/question";
import Axios from "axios";
import BankQuestionTable from "./BankQuestionTable";
import api from "api";
import { withRouter } from "react-router-dom";

class BankQuestionTableViewModel extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.schoolAcademicId = this.props.match.params.schoolAcademicId;
    this.axiosCancel = null;
    this.state = {
      isFetchLoading: true,
      isLessonFetched: false,
      lesson: null,
      totalPage: 1,
      questions: [],
    };
  }

  componentDidMount() {
    api.academicClass
      .findOne(this.schoolAcademicId)
      .then((data) => {
        this.setState({ lesson: data.school_lesson });
      })
      .then(() => this.setState({ isLessonFetched: true }));
  }

  fetch = (filter, pageNumber, pageLimit) => {
    if (this.state.lesson === null) return;
    this.setState({ isFetchLoading: true });
    if (typeof this.axiosCancel === "function") {
      this.axiosCancel();
    }
    questionAPI
      .get({
        ...filter,
        lesson_id: this.state.lesson.id,
        page: pageNumber,
        limit: pageLimit,
        cancel: (cancel) => (this.axiosCancel = cancel),
      })
      .then((data) => {
        setTimeout(() => {
          this.setState({
            isFetchLoading: false,
            totalPage: data.total_pages,
            questions: data.questions.map((question) => ({
              id: question.id,
              formated_type: question.formated_type,
              point: question.point,
              text_content: question.text_content,
            })),
          });
        }, 500);
      });
  };

  addSelectedQuestion = (question) => {
    this.props.addSelectedQuestion(question);
  };

  render() {
    return (
      <BankQuestionTable
        {...this.props}
        viewModel={{
          fetch: this.fetch,
          isFetchLoading: this.state.isFetchLoading,
          isLessonFetched: this.state.isLessonFetched,
          lessonName: this.state.isLessonFetched
            ? this.state.lesson.name
            : null,
          totalPage: this.state.totalPage,
          questions: this.state.questions,
          addSelectedQuestion: this.addSelectedQuestion,
          selectedQuestions: this.props.selectedQuestions,
        }}
      />
    );
  }
}

export default withRouter(BankQuestionTableViewModel);
