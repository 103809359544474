import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Spinner = styled.div`
  & {
    display: inline-block;
    text-align: center;
  }

  & > div {
    width: ${(props) => props.size};
    height: ${(props) => props.size};

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  & .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  & .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const SpinnerEllipsis = ({ size = "2rem", variant = "warning"}) => (
  <Spinner size={size}>
    <div className={"bounce1 bg-" + variant}></div>
    <div className={"bounce2 bg-" + variant}></div>
    <div className={"bounce3 bg-" + variant}></div>
  </Spinner>
);

SpinnerEllipsis.propTypes = {};

export default SpinnerEllipsis;
