import React from "react";
import PropTypes from "prop-types";
import LinkButton from "components/Button/LinkButton";

class ExportButtonLink extends React.Component {
  render() {
    return (
      <LinkButton
        variant="primary"
        to={`${process.env.REACT_APP_ENDPOINT}/v1/report/school/academic/exam/${this.props.examId}/score?file_type=excel`}
      >
        Ekspor
      </LinkButton>
    );
  }
}

export default ExportButtonLink;
