import React from "react";
import PropTypes from "prop-types";
import truncate from "domain/String/Truncate";
import SimpleModal from "components/Modal/SimpleModal";
import { EditModalContext } from "../../../EditModalContext";
import questionAPI from "api/bank/question";

class AttachmentButton extends React.Component {
  static contextType = EditModalContext;

  static Modal = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = { isModalShow: false };
  }

  showModal = () => this.setState({ isModalShow: true });
  closeModal = () => this.setState({ isModalShow: false });

  delete = () => {
    questionAPI.deleteAttachment(this.props.attachment.id).then(() => {
      this.context.onChange(
        "attachments",
        this.context.question.attachments.filter(
          (attachment) => attachment.uuid !== this.props.attachment.uuid
        )
      );
    });
  };

  render() {
    const { text, icon, children, useModal = true, modalSize } = this.props;
    const MAX_BUTTON_TEXT_LENGTH = 25;
    const MAX_TITLE_LENGTH = 50;
    return (
      <>
        {useModal && this.state.isModalShow && (
          <SimpleModal
            size={modalSize}
            title={
              <>
                <span className="text-soft">Lampiran:</span>{" "}
                {truncate(text, MAX_TITLE_LENGTH)}
              </>
            }
            onClose={this.closeModal}
          >
            {children}
          </SimpleModal>
        )}

        <div className="btn-group mr-1 mb-1">
          <button
            type="button"
            onClick={this.showModal}
            className="btn btn-sm btn-dim btn-outline-info"
          >
            <em className={"icon ni ni-" + icon}></em>
            <span>{truncate(text, MAX_BUTTON_TEXT_LENGTH)}</span>
          </button>
          <button
            className="btn btn-sm btn-dim btn-outline-danger"
            onClick={this.delete}
          >
            <em className="ni ni-trash" />
          </button>
        </div>
      </>
    );
  }
}

export default AttachmentButton;
