import { v4 as uuidv4 } from "uuid";

// TODO: banyak kode duplikasi di bawah, coba cari pattern untuk mengatasi
//       ini seperti template method pattern in OOP language.
export default function duplicateQuestionReducer(
  state,
  action,
  copiedQuestion
) {
  switch (copiedQuestion.type) {
    case "multichoice": {
      const question = {
        uuid: uuidv4(),
        type: "multichoice",
        point: copiedQuestion.point,
        category_id: copiedQuestion.category_id,
        lesson_id: copiedQuestion.lesson_id,
        grade_id: copiedQuestion.grade_id,
        content: copiedQuestion.content,
        attachments: copiedQuestion.attachments.map((attachment) => ({
          ...attachment,
        })),
      };
      state.questionOrder.push(question.uuid);
      state.questions[question.uuid] = question;

      const answers = state.answerOrder[copiedQuestion.uuid].map(
        (answerUUID) => ({
          uuid: uuidv4(),
          content: state.answers[answerUUID].content,
          key: state.answers[answerUUID].key,
          questionUUID: question.uuid,
        })
      );
      state.answerOrder[question.uuid] = answers.map((answer) => answer.uuid);
      answers.forEach((answer) => {
        state.answers[answer.uuid] = answer;
      });
      break;
    }
    case "multianswer": {
      const question = {
        uuid: uuidv4(),
        type: "multianswer",
        point: copiedQuestion.point,
        category_id: copiedQuestion.category_id,
        lesson_id: copiedQuestion.lesson_id,
        grade_id: copiedQuestion.grade_id,
        content: copiedQuestion.content,
        scoring_mode: copiedQuestion.scoring_mode,
        attachments: copiedQuestion.attachments.map((attachment) => ({
          ...attachment,
        })),
      };
      state.questionOrder.push(question.uuid);
      state.questions[question.uuid] = question;

      const answers = state.answerOrder[copiedQuestion.uuid].map(
        (answerUUID) => ({
          uuid: uuidv4(),
          content: state.answers[answerUUID].content,
          key: state.answers[answerUUID].key,
          questionUUID: question.uuid,
        })
      );
      state.answerOrder[question.uuid] = answers.map((answer) => answer.uuid);
      answers.forEach((answer) => {
        state.answers[answer.uuid] = answer;
      });
      break;
    }
    case "truefalse": {
      const question = {
        uuid: uuidv4(),
        type: "truefalse",
        point: copiedQuestion.point,
        category_id: copiedQuestion.category_id,
        lesson_id: copiedQuestion.lesson_id,
        grade_id: copiedQuestion.grade_id,
        content: copiedQuestion.content,
        attachments: copiedQuestion.attachments.map((attachment) => ({
          ...attachment,
        })),
      };
      state.questionOrder.push(question.uuid);
      state.questions[question.uuid] = question;

      const answers = state.answerOrder[copiedQuestion.uuid].map(
        (answerUUID) => ({
          uuid: uuidv4(),
          answer: state.answers[answerUUID].answer,
          questionUUID: question.uuid,
        })
      );
      state.answerOrder[question.uuid] = answers.map((answer) => answer.uuid);
      answers.forEach((answer) => {
        state.answers[answer.uuid] = answer;
      });
      break;
    }
    case "essay": {
      const question = {
        uuid: uuidv4(),
        type: "essay",
        point: copiedQuestion.point,
        category_id: copiedQuestion.category_id,
        lesson_id: copiedQuestion.lesson_id,
        grade_id: copiedQuestion.grade_id,
        content: copiedQuestion.content,
        attachments: copiedQuestion.attachments.map((attachment) => ({
          ...attachment,
        })),
      };
      state.questionOrder.push(question.uuid);
      state.questions[question.uuid] = question;

      const answers = state.answerOrder[copiedQuestion.uuid].map(
        (answerUUID) => ({
          uuid: uuidv4(),
          content: state.answers[answerUUID].answer,
          questionUUID: question.uuid,
        })
      );
      state.answerOrder[question.uuid] = answers.map((answer) => answer.uuid);
      answers.forEach((answer) => {
        state.answers[answer.uuid] = answer;
      });
      break;
    }
    default:
      throw new Error("Invalid question type: " + copiedQuestion.type);
  }
}
