import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Block from "components/Block/Block";
import TableResponsive from "components/Table/TableResponsive";
import Table from "components/Table/Table";
import InputDecimal from "components/Form/InputDecimal";
import Select from "components/Form/Select";
import Button from "components/Button/Button";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import Link from "components/Link/Link";
import env from "domain/Environment/Env";

const Row = styled.tr`
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#ffebf3" : "inherit")};
`;

class SelectedQuestionTable extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = { selectedQuestionUUIDs: [] };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedQuestions.length !== this.props.selectedQuestions.length
    ) {
      ReactTooltip.rebuild();
    }
  }

  removeSelectedQuestions = () => {
    this.state.selectedQuestionUUIDs.forEach((questionID) =>
      this.props.removeSelectedQuestion(questionID)
    );
    this.setState({ selectedQuestionUUIDs: [] });
  };

  handleQuestionClick = (uuid) => {
    this.setState((state) => {
      // unselect
      if (state.selectedQuestionUUIDs.includes(uuid)) {
        return {
          ...state,
          selectedQuestionUUIDs: state.selectedQuestionUUIDs.filter(
            (questionId) => questionId !== uuid
          ),
        };
      }

      return {
        ...state,
        selectedQuestionUUIDs: [...state.selectedQuestionUUIDs, uuid],
      };
    });
  };

  selectAll = () => {
    if (
      this.props.selectedQuestions.length ===
      this.state.selectedQuestionUUIDs.length
    ) {
      this.setState({
        selectedQuestionUUIDs: [],
      });
    } else {
      this.setState({
        selectedQuestionUUIDs: this.props.selectedQuestions.map(
          ({ uuid }) => uuid
        ),
      });
    }
  };
  render() {
    const { selectedQuestions } = this.props;
    return (
      <>
        <Block>
          <h5>Soal Terpilih</h5>
        </Block>

        <Block>
          <div className="row mb-2">
            <div className="col-12 col-md-4">
              <Button variant="light" size="sm" onClick={this.selectAll} block>
                {this.props.selectedQuestions.length ===
                this.state.selectedQuestionUUIDs.length
                  ? "Batal Tandai"
                  : "Tandai Semua"}
              </Button>
            </div>
            <div className="col-12 col-md-4">
              <Button
                variant="danger"
                size="sm"
                onClick={this.removeSelectedQuestions}
                outline
                dim
                block
                disabled={this.state.selectedQuestionUUIDs.length === 0}
              >
                Batal Pilih ({this.state.selectedQuestionUUIDs.length || 0})
                Soal
              </Button>
            </div>
          </div>

          <div
            className="card card-bordered mb-3"
            style={{ minHeight: "300px" }}
          >
            <TableResponsive>
              <Table
                compact
                style={{
                  tableLayout: "fixed",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "100px" }}>Poin</th>
                    <th style={{ width: "100px" }}>Bagian</th>
                    <th>Pertanyaan</th>
                    <th style={{ width: "150px" }}>Jenis</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedQuestions.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center text-soft">
                        Soal yang dipilih akan muncul disini.
                      </td>
                    </tr>
                  )}

                  {selectedQuestions.map((question) => (
                    <Row
                      key={question.uuid}
                      selected={this.state.selectedQuestionUUIDs.includes(
                        question.uuid
                      )}
                      onClick={(e) => this.handleQuestionClick(question.uuid)}
                    >
                      <td>
                        <InputDecimal
                          min={1}
                          max={100}
                          maxDecimal={2}
                          onChange={({ name, value }) =>
                            this.props.changeSelectedQuestion({
                              ...question,
                              point: value,
                            })
                          }
                          className="form-control form-control-sm"
                          defaultValue={question.point}
                        />
                      </td>
                      <td>
                        <Select
                          defaultValue={question.section_id}
                          onChange={(e) => {
                            this.props.changeSelectedQuestion({
                              ...question,
                              section_id: parseInt(e.target.value),
                            });
                          }}
                          className="form-control-sm"
                        >
                          {this.props.sections.map((section) => (
                            <option key={section.id} value={section.id}>
                              {section.title}
                            </option>
                          ))}
                        </Select>
                      </td>
                      <td className="text-nowrap text-truncate">
                        <span data-tip={question.text_content}>
                          <Link
                            target="_blank"
                            to={
                              env.isProd()
                                ? `https://guru.edulogy.id/teacher/administration/question/${question.id}`
                                : `https://guru.dev.edulogy.id/teacher/administration/question/${question.id}`
                            }
                          >
                            {question.text_content}
                          </Link>
                        </span>
                      </td>
                      <td className="text-nowrap text-truncate">
                        {question.formated_type}
                      </td>
                    </Row>
                  ))}
                </tbody>
              </Table>
            </TableResponsive>
          </div>

          <Button
            variant="primary"
            block
            onClick={this.props.import}
            disabled={selectedQuestions.length === 0}
          >
            Impor{" "}
            {selectedQuestions.length > 0 &&
              "(" + selectedQuestions.length + ")"}
          </Button>
        </Block>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sections: state.exam.sectionOrder.map(
    (sectionId) => state.exam.data.sections[sectionId]
  ),
});

export default connect(mapStateToProps)(SelectedQuestionTable);
