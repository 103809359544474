import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SummaryHeader from "./SummaryHeader";
import { withRouter } from "react-router-dom";
import PageHeader from "components/PageHeader/PageHeader";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import Filter from "./Filter";
import ExportButtonLink from "./ExportButtonLink";
import Block from "components/Block/Block";
import Badge from "components/Badge/Badge";
import ResultTable from "./ResultTable";
import examResultAPI from "api/academic/examResult";
import api from "api";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import Link from "components/Link/Link";
import NioIcon from "components/Icon/NioIcon";

class TeacherExamResultPage extends React.Component {
  constructor(props) {
    super(props);
    this.schoolAcademicId = this.props.match.params.schoolAcademicId;
    this.examId = this.props.match.params.examId;
    this.axiosCancel = null;
    this.state = {
      isAcademicClassFetched: false,
      isExamFetched: false,
      academicClass: null,
      exam: null,
      isDataFetched: false,
      data: [],
      filters: {
        has_submission: null,
        has_score: null,
        is_minimum_completion_passed: null,
      },
    };
  }

  componentDidMount() {
    this.fetch();
    api.academicClass.findOne(this.schoolAcademicId).then((data) => {
      this.setState({ academicClass: data, isAcademicClassFetched: true });
    });
    api.exam.getExam(this.schoolAcademicId, this.examId).then((data) => {
      this.setState({ exam: data, isExamFetched: true });
    });
  }

  fetch = () => {
    this.setState({ isDataFetched: false, data: [] });
    if (typeof this.axiosCancel === "function") {
      this.axiosCancel();
    }
    examResultAPI
      .get({
        ...this.state.filters,
        examId: this.examId,
        cancelCallback: (c) => (this.axiosCancel = c),
      })
      .then((data) => {
        if (this.state.filters.is_minimum_completion_passed !== null) {
          data = data.filter(
            (examResult) =>
              examResult.is_minimum_completion_passed.toString() ===
              this.state.filters.is_minimum_completion_passed
          );
        }
        setTimeout(() => this.setState({ data, isDataFetched: true }), 500);
      });
  };

  handleFilterChange = ({
    has_submission,
    has_score,
    is_minimum_completion_passed,
  }) => {
    this.setState(
      {
        filters: {
          has_submission,
          has_score,
          is_minimum_completion_passed,
        },
      },
      () => this.fetch()
    );
  };

  render() {
    return (
      <>
        <div className="mb-2">
          <Link to={`/academic/exam`} className="text-soft fs-18px">
            <NioIcon name="arrow-left" /> <span>Kembali</span>
          </Link>
        </div>

        <PageHeader
          heading="Penilaian"
          subheading={
            this.state.isAcademicClassFetched ? (
              <>
                <Badge>{this.state.academicClass.school_lesson.name}</Badge>{" "}
                <Badge variant="danger">
                  {this.state.academicClass.school_class.name}
                </Badge>
              </>
            ) : (
              <SpinnerGrow size="1.3rem" />
            )
          }
          message={"Berikut adalah nilai akhir masing-masing siswa."}
        />

        <Block>
          <SummaryHeader examId={this.examId} />
        </Block>

        <Block>
          <Row className="justify-content-between align-items-end">
            <Col size="9">
              {this.state.isExamFetched ? (
                <Filter
                  onChange={this.handleFilterChange}
                  examType={this.state.exam.type}
                />
              ) : (
                <SpinnerGrow size="2.3rem" />
              )}
            </Col>
            {/* <Col size="3" className="text-right">
              <ExportButtonLink examId={this.examId} />
            </Col> */}
          </Row>
        </Block>

        <Block>
          <ResultTable
            isLoading={!this.state.isDataFetched}
            data={this.state.data}
            schoolAcademicId={this.schoolAcademicId}
            examId={this.examId}
          />
        </Block>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(withRouter(TeacherExamResultPage));
