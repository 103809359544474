import React from "react";
import PropTypes from "prop-types";
import AnswerContainer from "./shared/AnswerContainer";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Radio from "components/Form/Radio";
import { connect } from "react-redux";
import {
  saveQuestionLocally,
  saveAnswerLocally,
  markAnswerSavedInServer,
} from "redux/exam/action";
import ErrorBoundary from "components/Error/ErrorBoundary";
import QuestionForm from "./QuestionForm";
import api from "api";
import SkeletonEditor from "./shared/SkeletonEditor";
import omit from "lodash/omit";

class Essay extends React.Component {
  saveQuestion = () => {
    if (this.props.question.id) {
      const promises = [
        new Promise((resolve, reject) =>
          resolve("just make sure has 1 promise")
        ),
      ];
      if (this.props.isQuestionChanged) {
        promises.push(
          api.exam.question.update({
            questionId: this.props.question.id,
            point: this.props.question.point,
            content: this.props.question.content,
          })
        );
      }
      // is answer changed
      if (!this.props.isAnswersSavedInServer[this.props.answer.uuid]) {
        promises.push(
          api.exam.answer
            .update({
              questionId: this.props.question.id,
              answerId: this.props.answer.id,
              payload: { answer: this.props.answer.content },
            })
            .then(() =>
              this.props.markAnswerSavedInServer({ uuid: this.props.answer.uuid })
            )
        );
      }
      return Promise.all(promises);
    } else {
      return api.exam.question
        .create({
          section_id: this.props.question.sectionId,
          question: {
            ...this.props.question,
            answer: this.props.answer.content,
          },
        })
        .then((data) => {
          this.props.saveQuestionLocally({
            uuid: this.props.question.uuid,
            question: { ...this.props.question, ...omit(data, "answer") },
          });
          // NOTE: pastikan backend mengembalikan data choice sesuai urutan.
          this.props.saveAnswerLocally({
            uuid: this.props.answer.uuid,
            answer: { ...this.props.answer, id: data.answer.id },
          });
          this.props.markAnswerSavedInServer({ uuid: this.props.answer.uuid });
        });
    }
  };

  handleAnswerChange = (content) =>
    this.props.saveAnswerLocally({
      uuid: this.props.answer.uuid,
      answer: { ...this.props.answer, content },
    });

  render() {
    const { question, answer } = this.props;
    return (
      <>
        <QuestionForm onSave={this.saveQuestion} />

        <div className="nk-block">
          <div
            className="p-1"
            style={{
              borderRadius: "4px",
              border: "1px solid #dbdfea",
              minHeight: "100px",
            }}
          >
            <SkeletonEditor
              placeholder="Ketikan jawaban disini untuk perbandingan nanti (tidak wajib)"
              defaultValue={answer.content}
              key={question.uuid}
              onChange={this.handleAnswerChange}
            />
          </div>
        </div>
      </>
    );
  }
}

Essay.propTypes = {};

const mapStateToProps = (state) => ({
  answer:
    state.exam.data.answers[
      state.exam.answerOrder[state.exam.currentQuestion.uuid][0]
    ],
  question: state.exam.data.questions[state.exam.currentQuestion.uuid],
  isQuestionChanged: !state.exam.isQuestionsSavedInServer[
    state.exam.currentQuestion.uuid
  ],
  isAnswersSavedInServer: state.exam.isAnswersSavedInServer,
});

const EssayRedux = connect(mapStateToProps, {
  saveQuestionLocally,
  saveAnswerLocally,
  markAnswerSavedInServer,
})(Essay);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <EssayRedux {...props} />
  </ErrorBoundary>
);
