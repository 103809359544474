import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { show404Popup } from "redux/page/action";
import { initExam, fetchExam, clearExam } from "redux/exam/action";
import Accomplish from "./Accomplish";
import PageLoading from "components/Loading/PageLoading";
import SharedEditor from "./SharedEditor";
import Toastr from "components/Notification/Toastr";
import ExamForm from "./ExamForm";
import Headline from "./Headline";

class ExamAccomplishPage extends React.Component {
  constructor(props) {
    super(props);
    this.examId = this.props.match.params.examId;
    this.schoolAcademicId = this.props.match.params.schoolAcademicId;
    this.isEdit = !!this.examId;
    this.state = {
      isLoading: true,
      isShowExamForm: !this.isEdit,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.isEdit) {
        this.props
          .fetchExam({
            schoolAcademicId: this.schoolAcademicId,
            examId: this.examId,
          })
          .then(() => this.setState({ isLoading: false }))
          .catch((err) => {
            if (err.response && err.response.status === 404) {
              this.props.show404Popup();
            }
          });
      } else {
        this.props.initExam(this.schoolAcademicId);
        this.setState({ isLoading: false });
      }
    }, 100);
  }

  exit = () => {
    this.props.clearExam();
    this.props.history.push(`/academic/exam`);
  };

  showExamForm = () => this.setState({ isShowExamForm: true });
  hideExamForm = () => this.setState({ isShowExamForm: false });

  render() {
    const { isShowExamForm } = this.state;

    if (this.state.isLoading) {
      return <PageLoading isLoading={true} variant="ellipsis" />;
    }

    return (
      <Toastr position="top-center" maxItem={3}>
        {isShowExamForm && <ExamForm onClose={this.hideExamForm} />}

        <Headline
          exam={this.props.exam}
          onEditClick={this.showExamForm}
          onExit={this.exit}
        />

        <SharedEditor>
          <Accomplish />
        </SharedEditor>
      </Toastr>
    );
  }
}

ExamAccomplishPage.propTypes = {};

const mapStateToProps = (state) => ({
  exam: state.exam.data.exam,
});

export default connect(mapStateToProps, {
  initExam,
  fetchExam,
  clearExam,
  show404Popup,
})(ExamAccomplishPage);
