import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import questionCategoryAPI from "api/master/questionCategory";

export default function useFetchQuestionCategories() {
  const [isFetched, setIsFetched] = useState(false);
  const [data, setData] = useState([]);
  const [reloadUUID, setReloadUUID] = useState("");

  useEffect(() => {
    setIsFetched(false);
    questionCategoryAPI.get().then((data) => {
      setIsFetched(true);
      setData(data);
    });
  }, [reloadUUID]);

  return {
    isFetched,
    data,
    reload: () => setReloadUUID(uuidv4()),
  };
}

useFetchQuestionCategories.propTypes = {};
