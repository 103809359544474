import React from "react";
import PropTypes from "prop-types";
import Link from "components/Link/Link";
import PrettyFloat from "domain/Float/PrettyFloat";
import Badge from "components/Badge/Badge";

const DataRow = ({ data, schoolAcademicId, examId }) =>
  data.map((row) => (
    <tr>
      <td>
        <img
          src={row.student.photo}
          style={{ width: "32px", height: "32px" }}
        />
      </td>
      <td>{row.student.code}</td>
      <td>{row.student.nis}</td>
      <td>{row.student.name}</td>
      <td className="text-danger">
        {row.final_score !== null &&
          row.is_minimum_completion_passed !== null && (
            <>
              {row.is_minimum_completion_passed ? (
                <Badge variant="success" dim>Tuntas</Badge>
              ) : (
                <Badge variant="danger" dim>Belum tuntas</Badge>
              )}
            </>
          )}{" "}
        {row.final_score ? PrettyFloat(row.final_score) : "-"}
      </td>
      <td>{row.total_submissions} kali</td>
      <td>
        <Link
          size="sm"
          className="btn btn-sm btn-primary"
          to={`/class/${schoolAcademicId}/academic/exam/${examId}/student/${row.student.id}/submission`}
        >
          Lihat Pengerjaan
        </Link>
      </td>
    </tr>
  ));

DataRow.propTypes = {};

export default DataRow;
