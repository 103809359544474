import axios from "configuredAxios";

export default {
  getSubmission: (submissionId) =>
    axios
      .get(`/v1/school/academic/exam/submission/${submissionId}`)
      .then((res) => res.data.data),
  question: {
    score: {
      update: ({ question_id, score }) =>
        axios.patch("/v1/school/academic/exam/submission/question/score/", {
          question_id,
          score,
        }),
    },
  },
};

// export default {
//   getSubmission: (submissionId) =>
//     Promise.resolve({
//       id: "1",
//       total_questions: 3,
//       total_filleds: 2,
//       score: "100",
//       student: {
//         id: "1",
//         photo:
//           "https://hatta.storage.edulogy.id/file/teacher/photo/2020/07/16/sample-640426.JPEG",
//         code: "10101010",
//         nis: "10101111",
//         name: "Yudi Supriyadi",
//       },
//       exam: {
//         id: "239",
//         title: "hehe",
//         description: "hehe",
//         instruction: null,
//       },
//       sections: [
//         {
//           id: 191,
//           title: "Esai",
//           description: null,
//           total_points: "10.00",
//           total_questions: 2,
//           questions: [
//             {
//               id: 687,
//               formated_type: "Esai",
//               type: "essay",
//               point: "5.00",
//               total_gained_points: "0.00",
//               content:
//                 "<p>Sebutkan minimal lima nama provinsi di Indonesia!</p>",
//               answer: {
//                 id: 105,
//                 answer:
//                   "<p>Jika siswa menjawab minimal 5 dari nama provinsi di Indonesia berikut maka jawaban dianggap benar, contoh: Jawa Barat, Jawa Tengah, Jawa Timur, Bali, dsb.</p>",
//               },
//               mark: {
//                 id: "1",
//                 answer: "Ini adalah jawaban siswa untuk soal esai sekarang",
//                 status: null,
//               },
//               attachments: [
//                 {
//                   id: 388,
//                   formated_type: "Gambar",
//                   type: "image",
//                   image: {
//                     id: 348,
//                     name: "admin-module.png",
//                     extension: "png",
//                     size: "111790",
//                     url:
//                       "https://soedirman.storage.dev.edulogy.id/file/teacher/learning/classes/exam/v2/section/question/image/2020/10/01/admin-module.png",
//                   },
//                 },
//               ],
//             },
//             {
//               id: 62083,
//               formated_type: "Pilihan Ganda",
//               type: "multichoice",
//               point: "5.00",
//               total_gained_points: "5.00",
//               content:
//                 "<p>Pilih kota berikut yang merupakan Ibukota Indonesia!</p>",
//               choices: [
//                 {
//                   id: "248159",
//                   content: "<p>Bandung</p>",
//                   key: false,
//                   order: 1,
//                 },
//                 {
//                   id: "248160",
//                   content: "<p>Jakarta</p>",
//                   key: true,
//                   order: 2,
//                 },
//                 {
//                   id: "248161",
//                   content: "<p>Garut</p>",
//                   key: false,
//                   order: 3,
//                 },
//               ],
//               mark: {
//                 id: "1975772",
//                 answer_id: "248159",
//               },
//               attachments: [],
//             },
//           ],
//         },
//         {
//           id: 3778,
//           title: "Pilihan Ganda",
//           description: null,
//           total_points: "10.00",
//           total_questions: 2,
//           questions: [
//             {
//               id: 62084,
//               formated_type: "Pilihan Ganda Kompleks",
//               type: "multianswer",
//               point: "1.00",
//               total_gained_points: "1.00",
//               content: "<p>Pilih dari berikut yang merupakan buah!</p>",
//               scoring_mode: "allornothing",
//               choices: [
//                 {
//                   id: "289",
//                   content: "<p>Pisang</p>",
//                   key: true,
//                   order: 1,
//                 },
//                 {
//                   id: "290",
//                   content: "<p>Motor</p>",
//                   key: false,
//                   order: 2,
//                 },
//                 {
//                   id: "291",
//                   content: "<p>Mangga</p>",
//                   key: true,
//                   order: 3,
//                 },
//                 {
//                   id: "292",
//                   content: "<p>Ponsel</p>",
//                   key: false,
//                   order: 4,
//                 },
//               ],
//               marks: [
//                 {
//                   id: "1975772",
//                   answer_id: "290",
//                 },
//                 {
//                   id: "1975774",
//                   answer_id: "291",
//                 },
//               ],
//               attachments: [],
//             },
//             {
//               id: 62085,
//               formated_type: "Benar Salah",
//               type: "truefalse",
//               point: "1.00",
//               total_gained_points: "1.00",
//               content: "<p>Apakah edulogy lahir di Bandung?</p>",
//               answer: { id: 325, answer: true },
//               mark: {
//                 id: "1",
//                 answer: true,
//               },
//               attachments: [],
//             },
//           ],
//         },
//       ],
//     }),
//   question: {
//     score: {
//       update: ({ question_id, score }) => Promise.resolve(true),
//     },
//   },
// };
