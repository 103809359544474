import React from "react";
import PropTypes from "prop-types";
import * as portals from "react-reverse-portal";
import { Html5Entities } from "html-entities";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import { SharedEditorContext } from "./SharedEditorContext";
import ClickOutsideAlerter from "components/Event/ClickOutsideAlerter";
import { Container, EditorContainer } from "./SkeletonEditor.styled";

const decode = new Html5Entities().decode;

class SkeletonEditor extends React.Component {
  static contextType = SharedEditorContext;

  constructor(props) {
    super(props);
    this.debounceFn = null;
    this.state = {
      isShowEditor: false,
      content: this.props.defaultValue,
    };
  }

  openEditor = () => {
    this.context.editor.setData(this.state.content || "");
    this.setState({ isShowEditor: this.context.isEditorReady }); // hanya open editor jika editor "ready"
  };
  closeEditor = () => {
    this.handleEditorChange();
    this.context.editor.setData("");
    this.setState({ isShowEditor: false });
  };

  handleEditorChange = () => {
    const content = this.context.editor.getData().trim();
    this.setState({ content });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isShowEditor === true && this.state.isShowEditor === false) {
      this.props.onChange(this.state.content);
    }
  }

  render() {
    const { placeholder } = this.props;
    const { isShowEditor, content } = this.state;

    return (
      <>
        {!isShowEditor && (
          <Container
            className={cx({
              placeholder: isEmpty(content),
              filled: !isEmpty(content),
            })}
            onClick={this.openEditor}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: decode(content || placeholder || ""),
              }}
            />
          </Container>
        )}
        {isShowEditor && (
          <EditorContainer>
            <ClickOutsideAlerter
              when={isShowEditor}
              onClickOutside={this.closeEditor}
            >
              <portals.OutPortal node={this.context.portalNode} />
            </ClickOutsideAlerter>
          </EditorContainer>
        )}
      </>
    );
  }
}

SkeletonEditor.propTypes = {};

export default SkeletonEditor;
