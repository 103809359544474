import axios from "configuredAxios";

export default {
  get: (school_academic_id) => {
    return axios
      .get(`/v1/school/academic/material`, {
        params: {
          school_academic_id,
        },
      })
      .then((res) => res.data.data);
  },
};
