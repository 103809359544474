import axios from "configuredAxios";

export default {
  get: ({
    lesson_id,
    question_type,
    grade_id,
    category_id,
    search = "",
    page,
    limit,
  }) =>
    axios
      .get(`v1/bank/question`, {
        params: {
          lesson_id,
          question_type,
          grade_id,
          category_id,
          search,
          page,
          limit,
        },
      })
      .then((res) => res.data.data),
  getById: (id) =>
    axios.get(`v1/bank/question/${id}`).then((res) => res.data.data),
  createMany: (questions) =>
    axios.post(`v1/bank/question`, { questions }).then((res) => res.data.data),
  update: ({ id, question }) =>
    axios
      .put(`v1/bank/question/${id}`, { ...question })
      .then((res) => res.data.data),
  addAnswer: ({ question_id, choice }) =>
    axios
      .post(`v1/bank/question/answer`, { question_id, choice })
      .then((res) => res.data.data), // for edit question (multichoice and multianswer)
  deleteAnswer: ({ question_id, answer_id }) =>
    axios.delete(`v1/bank/question/${question_id}/answer/${answer_id}`), // for edit question (multichoice and multianswer)
  deleteMany: (question_ids) =>
    axios.delete(`v1/bank/question`, { data: { question_ids } }),
  exportToCuricculum: (questions) =>
    axios
      .post(`v1/bank/question/export/school`, { questions })
      .then((res) => res.data.data),
  addAttachment: ({ question_id, file_id }) =>
    axios
      .post(`v1/bank/question/attachment`, { question_id, file_id })
      .then((res) => res.data.data),
  deleteAttachment: (id) => axios.delete(`v1/bank/question/attachment/${id}`),
};
