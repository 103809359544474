import React from "react";
import PropTypes from "prop-types";
import Truncate from "domain/String/Truncate";
import Link from "components/Link/Link";
import LinkButton from "components/Button/LinkButton";
import { withRouter } from "react-router-dom";

const Headline = ({ exam, onExit, match, ...props }) => {
  const schoolAcademicId = match.params.schoolAcademicId;
  const examId = match.params.examId;
  const studentId = match.params.studentId;
  return (
    <>
      <div className="nk-block">
        <div className="alert alert-info alert-pro mt-n5 mb-4 border-white">
          <div className="alert-cta flex-wrap flex-md-nowrap">
            <div className="alert-text text-dark">
              <span className="h6 ff-base mb-0 p-0 fw-medium">
                {Truncate(exam.title, 50)}
              </span>
              <small className="ml-1 text-soft">
                {exam.description ? (
                  Truncate(exam.description, 50)
                ) : (
                  <em>Tidak ada deskripsi.</em>
                )}
              </small>
            </div>
            <ul className="alert-actions gx-3 mt-3 mb-1 my-md-0">
              <li>
                <LinkButton
                  size="sm"
                  variant="light"
                  to={`/class/${schoolAcademicId}/academic/exam/${examId}/student/${studentId}/submission`}
                >
                  Kembali
                </LinkButton>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

Headline.propTypes = {};

export default withRouter(Headline);
