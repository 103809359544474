import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "components/Button/IconButton";
import ConfirmModal from "components/Modal/ConfirmModal";
import questionBankAPI from "api/bank/question";

// TODO: jadikan component karena akan ada banyak tempat yang serupa
// dimana ada tombol hapus dan modal konfirmasi.
export default function Delete(props) {
  const [isModalShow, setIsModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function _delete() {
    setIsLoading(true);
    questionBankAPI
      .deleteMany([props.question.id])
      .then(() => props.onDelete())
      .catch(() => setIsModalShow(false))
      .then(() => setIsLoading(false));
  }

  return (
    <>
      <IconButton
        variant="danger"
        size="sm"
        icon="trash"
        onClick={() => setIsModalShow(true)}
        outline
        dim
      />

      {isModalShow && (
        <ConfirmModal
          isLoading={isLoading}
          variant="danger"
          acceptLabel="Hapus"
          onAccept={_delete}
          onCancel={() => setIsModalShow(false)}
          message={<span>Apakah yakin akan dihapus?</span>}
        />
      )}
    </>
  );
}
