import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import SpinnerEllipsis from "components/Spinner/SpinnerEllipsis";

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
  opacity: 0.8;
  background: #d9e1ef;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
`;

class SaveLoading extends React.Component {
  render() {
    return (
      <>
        <Backdrop />
        <Container>
          <div className="nk-content ">
            <div className="nk-block nk-block-middle wide-xs mx-auto">
              <div className="nk-block-content nk-error-ld text-center">
                <SpinnerEllipsis size="2rem" />
                <h5 className="nk-error-title mt-3">Sedang Menyimpan...</h5>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

SaveLoading.propTypes = {};

export default SaveLoading;
