import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import IconButton from "components/Button/IconButton";
import questionBankAPI from "api/bank/question";
import ConfirmModal from "components/Modal/ConfirmModal";
import { TableContext } from "./TableContext";

export default function DeleteManyButton(props) {
  const { tableReload } = useContext(TableContext);

  const [isModalShow, setIsModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleDelete() {
    setIsLoading(true);
    const questionIds = props.selectedQuestions.map((question) => question.id);
    questionBankAPI.deleteMany(questionIds).then(() => {
      tableReload();
      setIsModalShow(false);
      setIsLoading(false);
      props.selectedQuestions.forEach((question) =>
        props.removeSelectedQuestion(question)
      );
    }).catch((err) => {
      if (err.response && err.response.status === 403) {
        setIsLoading(false);
        setIsModalShow(false);
      }
    });
  }

  return (
    <>
      <IconButton
        round
        icon="cross"
        variant="danger"
        disabled={props.selectedQuestions.length === 0}
        onClick={() => setIsModalShow(true)}
      >
        <span>Hapus Semua ({props.selectedQuestions.length})</span>
      </IconButton>

      {isModalShow && (
        <ConfirmModal
          isLoading={isLoading}
          variant="danger"
          acceptLabel="Hapus"
          onAccept={handleDelete}
          onCancel={() => setIsModalShow(false)}
          message={<span>Apakah yakin akan dihapus?</span>}
        />
      )}
    </>
  );
}

DeleteManyButton.propTypes = {};
