export const EXAM_FETCHED = "EXAM_FETCHED";

export const EXAM_INITIALIZED = "EXAM_INITIALIZED";

export const EXAM_CREATED = "EXAM_CREATED";

export const EXAM_UPDATED = "EXAM_UPDATED";

export const EXAM_STATUS_CHANGED = "EXAM_STATUS_CHANGED";

export const EXAM_CLEARED = "EXAM_CLEARED";

export const SECTION_CREATED = "SECTION_CREATED";

export const SECTION_UPDATED = "SECTION_UPDATED";

export const SECTION_DELETED = "SECTION_DELETED";

export const QUESTION_IMPORTED = "QUESTION_IMPORTED";

export const QUESTION_INITIALIZED = "QUESTION_INITIALIZED";

export const QUESTION_SAVED = "QUESTION_SAVED";

export const QUESTION_SAVED_IN_SERVER = "QUESTION_SAVED_IN_SERVER";

export const QUESTION_SAVED_IN_SERVER_FAILED =
  "QUESTION_SAVED_IN_SERVER_FAILED";

export const QUESTION_DELETED = "QUESTION_DELETED";

export const QUESTION_SWITCHED = "QUESTION_SWITCHED";

export const ANSWER_INITIALIZED = "ANSWER_INITIALIZED";

export const ANSWER_SAVED = "ANSWER_SAVED";

export const ANSWER_SAVED_IN_SERVER = "ANSWER_SAVED_IN_SERVER";

export const ANSWER_SAVED_IN_SERVER_FAILED = "ANSWER_SAVED_IN_SERVER_FAILED";

export const ANSWER_DELETED = "ANSWER_DELETED";

export const ATTACHMENT_SAVED = "ATTACHMENT_SAVED";

export const ATTACHMENT_DELETED = "ATTACHMENT_DELETED";
