import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { hideConnectionErrorPopup } from "redux/page/action";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #d9e1ef;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

class NoInternet extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.body.classList.add("modal-open");
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  render() {
    return (
      <Container>
        {/* <em className="ni ni-wifi-off" style={{ fontSize: "6rem" }}></em>
          <h3>Masalah Koneksi</h3>
          <p>Pastikan kamu terhubung ke internet.</p> */}

        <div className="nk-content ">
          <div className="nk-block nk-block-middle wide-xs mx-auto">
            <div className="nk-block-content nk-error-ld text-center">
              <h1 className="nk-error-head">
                <em className="ni ni-wifi-off"></em>
              </h1>
              <h3 className="nk-error-title">Masalah Terhubung ke Server</h3>
              <p className="nk-error-text">
                Mohon pastikan jaringan internet lancar.
              </p>
              <button
                type="button"
                onClick={(e) => this.props.hideConnectionErrorPopup()}
                className="btn btn-lg btn-primary mt-2"
              >
                Tutup Pesan
              </button>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

NoInternet.propTypes = {};

export default connect(null, { hideConnectionErrorPopup })(NoInternet)
