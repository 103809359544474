import {
  CLEARED,
  QUESTION_INITIALIZED,
  QUESTION_INITIALIZE_FROM_PREVIOUS_SETTING,
  QUESTION_INITIALIZE_FROM_PASTE,
  QUESTION_TYPE_CHANGED,
  QUESTION_UPDATED,
  QUESTION_DELETED,
  QUESTION_SAVED,
  QUESTION_DUPLICATED,
  QUESTION_ANSWER_INITIALIZED,
  QUESTION_ANSWER_UPDATED,
  QUESTION_ANSWER_DELETED,
  QUESTION_ATTACHMENT_ADDED,
  QUESTION_ATTACHMENT_DELETED,
  QUESTION_CATEGORY_FETCHED,
  QUESTION_CATEGORY_ADDED,
  QUESTION_CATEGORY_UPDATED,
  QUESTION_CATEGORY_DELETED,
  QUESTION_TYPES_FETCHED,
} from "./type";
import questionCategoryAPI from "api/master/questionCategory";
import questionTypeAPI from "api/master/questionType";

export const cleared = () => ({
  type: CLEARED,
});

export const questionInitialized = () => ({
  type: QUESTION_INITIALIZED,
});

export const questionInitializeFromPreviousSetting = () => ({
  type: QUESTION_INITIALIZE_FROM_PREVIOUS_SETTING,
});

export const questionInitializeFromPaste = ({
  questionContent,
  choices,
  point,
  category_id,
  lesson_id,
  grade_id,
}) => ({
  type: QUESTION_INITIALIZE_FROM_PASTE,
  questionContent,
  choices,
  point,
  category_id,
  lesson_id,
  grade_id,
});

export const questionTypeChanged = ({ questionUUID, questionTypeTarget }) => ({
  type: QUESTION_TYPE_CHANGED,
  questionUUID,
  questionTypeTarget,
});

// TODO: ubah jadi questionUpdated
export const questionSaved = ({ uuid, attribute, value }) => ({
  type: QUESTION_UPDATED,
  uuid,
  attribute,
  value,
});

export const questionDeleted = (uuid) => ({
  type: QUESTION_DELETED,
  uuid,
});

export const questionSavedInServer = (uuid) => ({
  type: QUESTION_SAVED,
  uuid,
});

export const questionDuplicated = (uuid) => ({
  type: QUESTION_DUPLICATED,
  uuid,
});

export const questionAnswerInitialized = ({ questionUUID, questionType }) => ({
  type: QUESTION_ANSWER_INITIALIZED,
  questionUUID,
  questionType,
});

export const questionAnswerUpdated = ({ uuid, answer }) => ({
  type: QUESTION_ANSWER_UPDATED,
  uuid,
  answer,
});

export const questionAnswerDeleted = ({ questionUUID, uuid }) => ({
  type: QUESTION_ANSWER_DELETED,
  questionUUID,
  uuid,
});

export const questionAttachmentAdded = ({ questionUUID, attachment }) => ({
  type: QUESTION_ATTACHMENT_ADDED,
  questionUUID,
  attachment,
});

export const questionAttachmentDeleted = ({
  questionUUID,
  attachmentUUID,
}) => ({
  type: QUESTION_ATTACHMENT_DELETED,
  questionUUID,
  attachmentUUID,
});

export const questionCategoryFetched = (data) => ({
  type: QUESTION_CATEGORY_FETCHED,
  data,
});

export const questionCategoryAdded = (category) => ({
  type: QUESTION_CATEGORY_ADDED,
  category,
});

export const questionCategoryUpdated = ({ id, name }) => ({
  type: QUESTION_CATEGORY_UPDATED,
  id,
  name,
});

export const questionCategoryDeleted = (id) => ({
  type: QUESTION_CATEGORY_DELETED,
  id,
});

export const questionTypesFetched = (data) => ({
  type: QUESTION_TYPES_FETCHED,
  data,
});

export const clear = () => {
  return (dispatch) => dispatch(cleared());
};

export const initQuestion = () => {
  return (dispatch) => dispatch(questionInitialized());
};

export const initQuestionFromPreviousSetting = () => {
  return (dispatch) => {
    dispatch(questionInitializeFromPreviousSetting());
  };
};

export const initQuestionFromPaste = ({
  questionContent,
  choices,
  point,
  category_id,
  lesson_id,
  grade_id,
}) => {
  return (dispatch) => {
    dispatch(
      questionInitializeFromPaste({
        questionContent,
        choices,
        point,
        category_id,
        lesson_id,
        grade_id,
      })
    );
  };
};

export const changeQuestionType = ({ questionUUID, questionTypeTarget }) => {
  return (dispatch) =>
    dispatch(
      questionTypeChanged({
        questionUUID,
        questionTypeTarget,
      })
    );
};

export const saveQuestion = ({ uuid, attribute, value }) => {
  return (dispatch) =>
    dispatch(
      questionSaved({
        uuid,
        attribute,
        value,
      })
    );
};

export const deleteQuestion = (uuid) => {
  return (dispatch) => dispatch(questionDeleted(uuid));
};

export const markSavedInServer = (uuid) => {
  return (dispatch) => dispatch(questionSavedInServer(uuid));
};

export const duplicateQuestion = (uuid) => {
  return (dispatch) => dispatch(questionDuplicated(uuid));
};

export const initAnswer = ({ questionUUID, questionType }) => {
  return (dispatch) =>
    dispatch(
      questionAnswerInitialized({
        questionUUID,
        questionType,
      })
    );
};

export const updateAnswer = ({ uuid, answer }) => {
  return (dispatch) =>
    dispatch(
      questionAnswerUpdated({
        uuid,
        answer,
      })
    );
};

export const deleteAnswer = ({ questionUUID, uuid }) => {
  return (dispatch) =>
    dispatch(
      questionAnswerDeleted({
        questionUUID,
        uuid,
      })
    );
};

export const addAttachment = ({ questionUUID, attachment }) => {
  return (dispatch) =>
    dispatch(
      questionAttachmentAdded({
        questionUUID,
        attachment,
      })
    );
};

export const deleteAttachment = ({ questionUUID, attachmentUUID }) => {
  return (dispatch) =>
    dispatch(
      questionAttachmentDeleted({
        questionUUID,
        attachmentUUID,
      })
    );
};

export const fetchCategory = () => {
  return (dispatch) =>
    questionCategoryAPI.get().then((data) => {
      dispatch(questionCategoryFetched(data));
      return data;
    });
};

export const addCategory = (category) => {
  return (dispatch) =>
    questionCategoryAPI
      .create(category)
      .then((categoryFromServer) =>
        dispatch(questionCategoryAdded(categoryFromServer))
      );
};

export const updateCategory = ({ id, name }) => {
  return (dispatch) =>
    questionCategoryAPI
      .update({ id, name })
      .then(() => dispatch(questionCategoryUpdated({ id, name })));
};

export const deleteCategory = (id) => {
  return (dispatch) =>
    questionCategoryAPI
      .delete(id)
      .then(() => dispatch(questionCategoryDeleted(id)));
};

export const fetchQuestionTypes = () => {
  return (dispatch) =>
    questionTypeAPI.get().then((data) => dispatch(questionTypesFetched(data)));
};
