import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import SimpleModal from "components/Modal/SimpleModal";
import Button from "components/Button/Button";
import Row from "./Row";
import TableResponsive from "components/Table/TableResponsive";
import Table from "components/Table/Table";
import Block from "components/Block/Block";
import questionBankAPI from "api/bank/question";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "redux/page/action";
import { TableContext } from "../TableContext";

export default function SendCuricculumModal(props) {
  const { tableReload } = useContext(TableContext);
  const dispatch = useDispatch();

  useEffect(() => {
    props.selectedQuestions.length === 0 && props.onClose();
  }, [props]);

  function handleSubmit() {
    dispatch(showLoader());
    questionBankAPI
      .exportToCuricculum(
        props.selectedQuestions.map((question) => ({
          id: question.id,
          point: question.point,
        }))
      )
      .then(() => {
        props.selectedQuestions.forEach((question) =>
          props.removeSelectedQuestion(question)
        );
        tableReload();
        dispatch(hideLoader());
      });
  }

  return (
    <SimpleModal size="xl" title={"Kirim ke Kurikulum"} onClose={props.onClose}>
      <Block>
        <div className="card card-bordered">
          <TableResponsive>
            <Table compact className="table">
              <thead>
                <tr>
                  <th style={{ width: "150px" }}>Jenis</th>
                  <th>Pertanyaan</th>
                  <th style={{ width: "250px" }}>Total Dibagikan</th>
                  <th style={{ width: "250px" }}>Poin</th>
                  <th style={{ width: "150px" }} className="text-center">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.selectedQuestions.map((question) => (
                  <Row
                    key={question.id}
                    question={question}
                    changeSelectedQuestion={props.changeSelectedQuestion}
                    removeSelectedQuestion={props.removeSelectedQuestion}
                  />
                ))}
              </tbody>
            </Table>
          </TableResponsive>
        </div>
      </Block>
      <Block>
        <Button block onClick={handleSubmit}>
          Kirim Sekarang
        </Button>
      </Block>
    </SimpleModal>
  );
}

SendCuricculumModal.propTypes = {};
