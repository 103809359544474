import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 25px;
  max-width: 100%;
  padding-left: 10px;
`;

const AnswerContainer = ({ children, ...rest }) => (
  <Container {...rest}>{children}</Container>
);

export default AnswerContainer;
