import React from "react";
import PropTypes from "prop-types";
import AnswerContainer from "./shared/AnswerContainer";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Radio from "components/Form/Radio";
import { connect } from "react-redux";
import ErrorBoundary from "components/Error/ErrorBoundary";
import QuestionForm from "./QuestionForm";
import Key from "./shared/Key";

const AnswerField = ({ children, value, isMark, isKey }) => {
  let style = {};
  if (isKey && isMark) {
    style = { background: "#e2f8f1", color: "#20c997" }; // "correct" color
  } else if (isKey && !isMark) {
    style = { background: "inherit", color: "inherit" }; // "normal" color
  } else if (!isKey && isMark) {
    style = { background: "#fce9e7", color: "#e85347" }; // "incorrect" color
  } else if (!isKey && !isMark) {
    style = { background: "inherit", color: "inherit" }; // "normal" color
  }
  return (
    <AnswerItemBordered style={{ flexGrow: 1, minWidth: 0, ...style }}>
      {isKey && <Key>kunci</Key>}
      <Radio name="choice" value={value} checked={isMark}>
        {children}
      </Radio>
    </AnswerItemBordered>
  );
};

class Truefalse extends React.Component {
  render() {
    const { answer, mark } = this.props;

    return (
      <>
        <QuestionForm />

        <AnswerContainer>
          <AnswerField
            value={true}
            isKey={answer.answer === true}
            isMark={mark.answer === true}
          >
            Benar
          </AnswerField>
          <AnswerField
            value={false}
            isKey={answer.answer === false}
            isMark={mark.answer === false}
          >
            Salah
          </AnswerField>
        </AnswerContainer>
      </>
    );
  }
}

Truefalse.propTypes = {};

const mapStateToProps = (state) => ({
  answer:
    state.examSubmissionReview.data.answers[
      state.examSubmissionReview.answerOrder[
        state.examSubmissionReview.currentQuestion.uuid
      ][0]
    ],
  question:
    state.examSubmissionReview.data.questions[
      state.examSubmissionReview.currentQuestion.uuid
    ],
  mark:
    state.examSubmissionReview.data.questions[
      state.examSubmissionReview.currentQuestion.uuid
    ].mark || {},
});

const TruefalseRedux = connect(mapStateToProps)(Truefalse);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <TruefalseRedux {...props} />
  </ErrorBoundary>
);
