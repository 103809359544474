import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import truncate from "domain/String/Truncate";
import styled from "styled-components";
import ClickOutsideAlerter from "components/Event/ClickOutsideAlerter";
import env from "domain/Environment/Env";

const Dropdown = styled.div`
  @media only screen and (max-width: 270px) {
    right: 20px;
  }
  @media only screen and (min-width: 271px) and (max-width: 460px) {
    right: -20px;
  }
`;

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);

    this.dropdownRef = React.createRef();
    this.state = { isShow: false };
  }

  componentDidUpdate() {
    if (this.state.isShow) {
      this.dropdownRef.current.focus(); // so we can hide dropdown after onBlur occurs
    }
  }

  toggleDropdown(e) {
    e.preventDefault();
    this.setState((state) => ({ isShow: !state.isShow }));
  }

  hideDropdown() {
    this.setState({ isShow: false });
  }

  render() {
    const { isShow } = this.state;
    const { teacher } = this.props;

    return (
      <li className="dropdown user-dropdown order-sm-first">
        <ClickOutsideAlerter when={isShow} onClickOutside={this.hideDropdown}>
          <a
            href="#"
            onClick={(e) => e.preventDefault()}
            className="dropdown-toggle"
            data-toggle="dropdown"
            onMouseDown={this.toggleDropdown}
          >
            <div className="user-toggle">
              <div className="user-avatar sm">
                {teacher.photo ? (
                  <img src={teacher.photo} />
                ) : (
                  <em className="icon ni ni-user-alt"></em>
                )}
              </div>
              <div className="user-name dropdown-indicator d-none d-sm-block">
                <div className="text-info">Guru</div>
                {truncate(teacher.name, 14)}
              </div>
            </div>
          </a>
          <Dropdown
            tabIndex="0"
            ref={this.dropdownRef}
            className={
              "dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1" +
              (isShow ? " show" : "")
            }
          >
            <div className="dropdown-inner user-card-wrap bg-lighter d-md-block">
              <div className="user-card">
                <div className="user-avatar">
                  <span>
                    {teacher.name
                      .match(/\b(\w)/g)
                      .join("")
                      .substr(0, 2)
                      .toUpperCase()}
                  </span>
                </div>
                <div className="user-info">
                  <span className="lead-text">
                    {truncate(teacher.name, 14)}
                  </span>
                  <span className="sub-text text-truncate">
                    {teacher.email ? (
                      truncate(teacher.email, 22)
                    ) : (
                      <em>Email belum diatur</em>
                    )}
                  </span>
                </div>
                <div className="user-action">
                  <a
                    className="btn btn-icon mr-n2"
                    href="#"
                    style={{ cursor: "not-allowed" }}
                  >
                    <em className="icon ni ni-setting"></em>
                  </a>
                </div>
              </div>
            </div>
            <div className="dropdown-inner">
              <ul className="link-list">
                <li>
                  <a
                    target="_blank"
                    href={
                      env.isProd()
                        ? "https://guru.edulogy.id/setting/profile"
                        : "https://guru.dev.edulogy.id/setting/profile"
                    }
                  >
                    <em className="icon ni ni-user-alt"></em>
                    <span>Lihat Profil</span>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCdOsw3TfLpCEr6xkP6Ngpjw"
                  >
                    <em className="icon ni ni-youtube-fill text-danger"></em>
                    <span>Tonton Tutorial</span>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://go.edulogy.id/customer-care"
                  >
                    <em className="icon ni ni-whatsapp-round text-teal"></em>
                    <span>Hubungi Customer Care</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="dropdown-inner">
              <ul className="link-list">
                <li>
                  <Link to="/logout">
                    <em className="icon ni ni-signout"></em>
                    <span>Keluar</span>
                  </Link>
                </li>
              </ul>
            </div>
          </Dropdown>
        </ClickOutsideAlerter>
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  teacher: state.auth.teacher,
});

export default connect(mapStateToProps)(UserInfo);
