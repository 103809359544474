import React from "react";
import PropTypes from "prop-types";
import GenerateRandomString from "domain/String/GenerateRandomString";
import styled from "styled-components";

const Label = styled.label`
  & {
    display: inline-block;
  }
  &::before {
    box-shadow: 0 0 2px #021df0 !important;
    border-color: white !important;
  }
  &::after {
    opacity: 0 !important;
  }
`;

class Radio extends React.Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
  }

  componentDidMount() {
    this.props.labelRef && this.props.labelRef(this.labelRef);
  }

  render() {
    const { children, labelRef, ...rest } = this.props;
    const id = GenerateRandomString();

    return (
      <div className="custom-control custom-control-sm custom-radio">
        <input
          type="radio"
          className="custom-control-input"
          id={"choice" + id}
          {...rest}
        />
        <Label
          ref={this.labelRef}
          className="custom-control-label"
          htmlFor={"choice" + id}
        >
          {children}
        </Label>
      </div>
    );
  }
}

Radio.propTypes = {};

export default Radio;
