import React from "react";
import PropTypes from "prop-types";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

const Image = ({ src, ...props }) => <InnerImageZoom src={src} {...props} />;

Image.propTypes = {};

export default Image;
