import React from "react";
import PropTypes from "prop-types";
import TableResponsive from "components/Table/TableResponsive";
import Table from "components/Table/Table";
import DataRow from "./DataRow";
import LoadingRow from "./LoadingRow";
import EmptyRow from "./EmptyRow";

const ResultTable = ({
  isLoading,
  data,
  schoolAcademicId,
  examId,
  ...props
}) => (
  <div className="card card-bordered mb-3">
    <TableResponsive>
      <Table compact>
        <thead>
          <tr>
            <th>Foto</th>
            <th>EduCode</th>
            <th>NIS</th>
            <th>Nama</th>
            <th>Nilai Akhir</th>
            <th>Pengerjaan</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <LoadingRow />}
          {!isLoading && data.length === 0 && <EmptyRow />}
          {!isLoading && data.length > 0 && (
            <DataRow
              data={data}
              schoolAcademicId={schoolAcademicId}
              examId={examId}
            />
          )}
        </tbody>
      </Table>
    </TableResponsive>
  </div>
);

export default ResultTable;
