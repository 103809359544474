import React from "react";
import PropTypes from "prop-types";
import QuestionForm from "./QuestionForm";
import SkeletonEditor from "components/Editor/SharedEditor/SkeletonEditor";

class Essay extends React.Component {
  render() {
    return (
      <>
        <QuestionForm
          question={this.props.question}
          onChange={this.props.onChange}
        />

        <div className="nk-block">
          <div
            className="p-1"
            style={{
              borderRadius: "4px",
              border: "1px solid #dbdfea",
              minHeight: "100px",
            }}
          >
            <SkeletonEditor
              placeholder="Ketikan jawaban disini untuk perbandingan nanti (tidak wajib)"
              defaultValue={this.props.question.answer.answer}
              key={this.props.question.uuid}
              onChange={(content) =>
                this.props.onChange("answer", {
                  ...this.props.answer,
                  answer: content || null,
                })
              }
            />
          </div>
        </div>
      </>
    );
  }
}

Essay.propTypes = {};

export default Essay;
