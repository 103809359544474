import InvalidValueError from "./InvalidValueError";

const lang = "id";

export default class DecimalValue {
  constructor(value) {
    value = this._sanitize(value);
    this._validate(value);
    this._value = value;
  }

  set value(value) { }
  get value() { return undefined; }

  get = () => {
    return this._value;
  }
  getFloat = () => {
    return parseFloat(this._value);
  }

  /**
   * Will transform value by:
   *  - truncate fraction if asked
   *  - remove trailing decimal point
   *  - localization decimal point
   * @param {number} truncateLength
   * @returns {string} transformed value
   */
  getPrettyValue = (truncateLength = 0) => {
    this.removeTrailingZero();
    let value = this.getTruncatedFraction(truncateLength);
    value = value.match(/^\d+\.$/) ? value.replace(".", "") : value;
    if (lang === "id") {
      value = value.replace(".", ",");
    }
    return value;
  };

  isEquals = (otherValue) => this._value === otherValue;

  getFraction = () => {
    const [matched, decimalPoint, fractionPart] = this._value.match(
      /^\d+(\.)?(\d+)?$/
    );
    return fractionPart || null;
    // TODO: return this
  };

  getTruncatedFraction = (length) => {
    const [
      matched,
      wholeNumber,
      decimalPoint,
      fractionalPart,
    ] = this._value.match(/^(\d+)(\.)?(\d+)?$/);
    if (!fractionalPart) return this._value;
    if (length < 1) return this._value;
    return wholeNumber + "." + fractionalPart.substr(0, length);
    // TODO: return this
  };

  removeTrailingZero = () => {
    if (null !== this._value.match(/^(\d+)\.(0+)$/)) {
      const [
        matched,
        wholeNumber,
        fractionalPart,
      ] = this._value.match(/^(\d+)\.(0+)$/);
      this._value = wholeNumber;
    }
    return this
  }

  _sanitize = (value) => {
    if (typeof value === "number") {
      value = value.toString();
    }
    if (value.match(/^\d+,(\d+)?$/)) {
      value = value.replace(",", "."); // standarize decimal point.
    }
    return value;
  };

  _validate = (value) => {
    if (value.match(/^\d+(\.)?(\d+)?$/) === null) {
      throw new InvalidValueError("Value is not decimal in correct form");
    }
  };
}
