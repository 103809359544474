import React from "react";
import PropTypes from "prop-types";
import GenerateRandomString from "domain/String/GenerateRandomString";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
  }

  componentDidMount() {
    this.props.labelRef && this.props.labelRef(this.labelRef);
  }

  handleChange = (e) => {
    e.target.value = e.target.checked
      ? this.props.onValue
      : this.props.offValue;
    this.props.onChange && this.props.onChange(e);
  };

  render() {
    const id = GenerateRandomString();

    const { children, labelRef, onValue, offValue, ...rest } = this.props;

    return (
      <div className="custom-control custom-control-sm custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={"choice" + id}
          {...rest}
          onChange={this.handleChange}
        />
        <label
          ref={this.labelRef}
          className="custom-control-label"
          htmlFor={"choice" + id}
        >
          {children}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {};

export default Checkbox;
