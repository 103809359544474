import omit from "lodash/omit";
import { v4 as uuidv4 } from "uuid";

function choiceAnswerCreator(questionUUID, content = null) {
  return {
    uuid: uuidv4(),
    key: false,
    content,
    questionUUID,
  };
}

export default function fromEssayReducer(state, action, question) {
  const content = state.answers[state.answerOrder[question.uuid][0]].content;

  switch (action.questionTypeTarget) {
    case "multichoice": {
      state.answers = omit(state.answers, state.answerOrder[question.uuid]); // remove previous answer
      const answer1 = choiceAnswerCreator(question.uuid, content);
      const answer2 = choiceAnswerCreator(question.uuid);
      state.answerOrder[question.uuid] = [answer1.uuid, answer2.uuid];
      state.answers[answer1.uuid] = answer1;
      state.answers[answer2.uuid] = answer2;
      question.type = "multichoice";
      break;
    }
    case "multianswer": {
      state.answers = omit(state.answers, state.answerOrder[question.uuid]); // remove previous answer
      const answer1 = choiceAnswerCreator(question.uuid, content);
      const answer2 = choiceAnswerCreator(question.uuid);
      state.answerOrder[question.uuid] = [answer1.uuid, answer2.uuid];
      state.answers[answer1.uuid] = answer1;
      state.answers[answer2.uuid] = answer2;
      question.type = "multianswer";
      break;
    }
    case "truefalse": {
      state.answers = omit(state.answers, state.answerOrder[question.uuid]); // remove previous answer
      const answer = { uuid: uuidv4(), answer: null, questionUUID: question.uuid };
      state.answerOrder[question.uuid] = [answer.uuid];
      state.answers[answer.uuid] = answer;
      question.type = "truefalse";
      break;
    }
    case "essay": {
      break;
    }
    default:
      throw new Error("invalid type target");
  }
}
