import React from "react";
import PropTypes from "prop-types";
import GenerateRandomString from "domain/String/GenerateRandomString";
import ModalPortal from "components/ModalPortal";
import Item from "./Item";

const Context = React.createContext({});

class Toastr extends React.Component {
  static Context = Context;
  static Item = Item;

  constructor(props) {
    super(props);
    this.state = { items: [] };
  }

  addItem = (component) => {
    setTimeout(() => {
      const id = GenerateRandomString();
      this.setState(
        (state) => ({
          items: state.items.concat([{ id, component }]),
        }),
        () => {
          setTimeout(() => {
            this.setState((state) => ({
              items: state.items.filter((item) => item.id !== id),
            }));
          }, 3000);
        }
      );
    }, 500);
  };

  render() {
    const { position, maxItem } = this.props;

    return (
      <>
        <ModalPortal>
          <div id="toast-container" className={"toast-" + position}>
            {this.state.items
              .reverse()
              .slice(0, maxItem)
              .map((item) =>
                React.cloneElement(item.component, {
                  key: item.id,
                })
              )}
          </div>
        </ModalPortal>

        <Context.Provider
          value={{
            toastrAddItem: this.addItem,
          }}
        >
          {this.props.children}
        </Context.Provider>
      </>
    );
  }
}

Toastr.propTypes = {};

export default Toastr;
