import React from "react";
import PropTypes from "prop-types";

const root = document.body;

/**
 * <OutsideAlerter when={boolean} onClickOutside={your_handler} />
 *
 * @param when            aktifkan click-outside listener hanya ketika when = true
 * @param onClickOutside
 */
class ClickOutsideAlerter extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    root.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    root.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate() {
    if (this.props.when) {
      root.addEventListener("mousedown", this.handleClickOutside);
    } else {
      root.removeEventListener("mousedown", this.handleClickOutside);
    }
  }

  handleClickOutside = (e) => {
    if (checkOnBlacklist(e.target)) return;
    if (!this.wrapperRef.current.contains(e.target)) {
      this.props.onClickOutside();
    }
  };

  render() {
    return (
      <div
        ref={this.wrapperRef}
        style={{ display: "inline-block" }}
        className={this.props.when ? "active" : "" /* cuma agar re-render */}
      >
        {this.props.children}
      </div>
    );
  }
}

ClickOutsideAlerter.propTypes = {};

export default ClickOutsideAlerter;

// Abaikan jika user melakukan klik di element berikut
// meskipun element letaknya di luar.
const BLACKLIST_ELEMENTS = [
  '.wrs_modal_dialogContainer', // modal rumus matematika di CKEditor
  '.ck-balloon-rotator__content', // fitur CKEditor untuk masukan link
]
function checkOnBlacklist(clickedElement) {
  let isOnBlacklist = false;
  BLACKLIST_ELEMENTS.forEach((blacklistElement) => {
    if (document.querySelector(blacklistElement)) {
      if (document.querySelector(blacklistElement).contains(clickedElement)) {
        isOnBlacklist = true;
      }
    }
  })
  return isOnBlacklist;
}
