import React from "react";
import PropTypes from "prop-types";
import Button from "../shared/Button";

class Youtube extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <Button
        attachmentId={data.id}
        text={"https://www.youtube.com/watch?v=" + data.video.video_id}
        icon="youtube"
      >
        <Button.Modal>
          <iframe
            style={{ width: "100%" }}
            src={"https://www.youtube.com/embed/" + data.video.video_id}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Button.Modal>
      </Button>
    );
  }
}

Youtube.propTypes = {};

export default Youtube;
