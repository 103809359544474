import React from "react";
import PropTypes from "prop-types";
import ModalPortal from "components/ModalPortal";
import styled from "styled-components";
import { connect } from "react-redux";
import { hide404Popup } from "redux/page/action";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #d9e1ef;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

class NotFoundError extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.body.classList.add("modal-open");
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  handleClick = () => {
    this.props.hide404Popup();
    this.props.history.goBack();
  };

  render() {
    return (
      <ModalPortal>
        <Container>
          <div className="nk-content ">
            <div className="nk-block nk-block-middle wide-xs mx-auto">
              <div className="nk-block-content nk-error-ld text-center">
                <h1 className="nk-error-head">404</h1>
                <h3 className="nk-error-title">Laman tidak ditemukan</h3>
                <p className="nk-error-text">
                  Tampaknya kamu mengakses laman yang sudah dihapus atau tidak
                  pernah ada sebelumnya.
                </p>
                <Link to="/" className="btn btn-lg btn-primary mt-2">
                  Kembali ke Beranda
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </ModalPortal>
    );
  }
}

NotFoundError.propTypes = {};

export default connect(null, { hide404Popup })(NotFoundError);
