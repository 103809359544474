import React from "react";

class WelcomePopup extends React.Component {
  render() {
    return (
      <div
        className="alert-alt alert-info alert-icon"
        style={{ borderRadius: 0 }}
      >
        <em className="icon ni ni-help-alt"></em>{" "}
        <strong>
          EduTeacher sedang dipindahkan dalam rangka peningkatan kenyamanan.
          Sayangnya, Anda harus login kembali karena terdapat perbedaan :)
        </strong>
      </div>
    );
  }
}

export default WelcomePopup;
