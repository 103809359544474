import React from "react";
import PropTypes from "prop-types";
import AnswerContainer from "./shared/AnswerContainer";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Radio from "components/Form/Radio";
import ErrorBoundary from "components/Error/ErrorBoundary";
import QuestionForm from "./QuestionForm";

class Truefalse extends React.Component {
  onChoose = (e) => {
    this.props.onChange("answer", {
      ...this.props.question.answer,
      answer: e.target.value === "true",
    });
  };

  render() {
    return (
      <>
        <QuestionForm
          question={this.props.question}
          onChange={this.props.onChange}
        />

        <AnswerContainer>
          <AnswerItemBordered style={{ flexGrow: 1, minWidth: 0 }}>
            <Radio
              name="choice"
              value={true}
              checked={this.props.question.answer.answer === true}
              onChange={() =>
                this.props.onChange("answer", {
                  ...this.props.question.answer,
                  answer: true,
                })
              }
            >
              Benar
            </Radio>
          </AnswerItemBordered>
          <AnswerItemBordered style={{ flexGrow: 1, minWidth: 0 }}>
            <Radio
              name="choice"
              value={false}
              checked={this.props.question.answer.answer === false}
              onChange={() =>
                this.props.onChange("answer", {
                  ...this.props.question.answer,
                  answer: false,
                })
              }
            >
              Salah
            </Radio>
          </AnswerItemBordered>
        </AnswerContainer>
      </>
    );
  }
}

Truefalse.propTypes = {};

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <Truefalse {...props} />
  </ErrorBoundary>
);
