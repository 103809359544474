import React from "react";
import PropTypes from "prop-types";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Radio from "./shared/Radio";
import { connect } from "react-redux";
import ErrorBoundary from "components/Error/ErrorBoundary";
import QuestionForm from "./QuestionForm";
import AnswerContainer from "./shared/AnswerContainer";
import Key from "./shared/Key";
import AnswerContentContainer from "./shared/AnswerContentContainer";

const AnswerField = ({ uuid, content, isKey, isMark }) => {
  let style = {};
  if (isKey && isMark) {
    style = { background: "#e2f8f1", color: "#20c997" }; // "correct" color
  } else if (isKey && !isMark) {
    style = { background: "inherit", color: "inherit" }; // "normal" color
  } else if (!isKey && isMark) {
    style = { background: "#fce9e7", color: "#e85347" }; // "incorrect" color
  } else if (!isKey && !isMark) {
    style = { background: "inherit", color: "inherit" }; // "normal" color
  }

  return (
    <div className="d-flex" style={{ alignItems: "center" }}>
      <AnswerItemBordered
        style={{ flexGrow: 1, minWidth: 0, maxWidth: "100%", ...style }}
      >
        {isKey && <Key>kunci</Key>}
        <Radio name="choice" value={uuid} checked={isMark}>
          <AnswerContentContainer dangerouslySetInnerHTML={{ __html: content }} />
        </Radio>
      </AnswerItemBordered>
    </div>
  );
};

class Multichoice extends React.Component {
  render() {
    return (
      <>
        <QuestionForm />

        <AnswerContainer>
          {this.props.choices.map((choice) => (
            <AnswerField
              key={choice.uuid}
              uuid={choice.uuid}
              content={choice.content}
              isKey={choice.key}
              isMark={this.props.mark.answer_id === choice.id}
            />
          ))}
        </AnswerContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  choices: state.examSubmissionReview.answerOrder[
    state.examSubmissionReview.currentQuestion.uuid
  ].map((answerUUID) => state.examSubmissionReview.data.answers[answerUUID]),
  question:
    state.examSubmissionReview.data.questions[
      state.examSubmissionReview.currentQuestion.uuid
    ],
  mark:
    state.examSubmissionReview.data.questions[
      state.examSubmissionReview.currentQuestion.uuid
    ].mark || {},
});
const MultichoiceRedux = connect(mapStateToProps)(Multichoice);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <MultichoiceRedux {...props} />
  </ErrorBoundary>
);
