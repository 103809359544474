import React from "react";
import PropTypes from "prop-types";
import { InlineList, InlineListItem } from "components/InlineList/InlineList";
import styled from "styled-components";
import Icon from "components/Icon/Icon";
import cx from "classnames";
import { connect } from "react-redux";
import { switchQuestion } from "redux/examSubmissionReview/action";
import QuestionEntity from "domain/Exam/QuestionEntity";

const QuestionNumberContainer = styled.div`
  border-radius: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  &:hover {
    border: 1px solid #798bff;
  }

  &:active {
    border-color: white;
  }
`;
const QuestionNumber = styled.div`
  width: 25px;
  height: 25px;
  font-size: 10px;
  /* centering text */
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
`;
const CheckIconContainer = styled.span`
  border: none;
  font-size: 12px;
  position: absolute;
  bottom: -4px;
  right: -1px;
`;

class QuestionIndex extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children: label, isEssay, isCheck, isActive, ...props } = this.props;

    return (
      <QuestionNumberContainer
        {...props}
        className={cx("user-avatar", {
          "bg-light": !isActive && !isEssay,
          "text-gray": !isActive && !isEssay,
          "bg-primary": isActive,
          "text-white": isActive,
          "bg-orange": !isActive && isEssay,
          "text-white": !isActive && isEssay,
        })}
      >
        <QuestionNumber>{label}</QuestionNumber>
        {isCheck && (
          <CheckIconContainer className="text-teal">
            <Icon nio="check-fill-c" />
          </CheckIconContainer>
        )}
      </QuestionNumberContainer>
    );
  }
}

class SectionQuestionList extends React.Component {
  switchQuestion = (uuid) => {
    this.props.switchQuestion({
      sectionId: this.props.sectionId,
      uuid,
    });
  };

  render() {
    const {
      questions,
      sectionOrder,
      questionOrder,
      curentQuestionUUID,
    } = this.props;

    return (
      <div className="card-inner">
        <InlineList>
          {questions.map((question) => {
            question = new QuestionEntity(question)
              .setSectionOrder(sectionOrder)
              .setQuestionOrder(questionOrder);
            return (
              <InlineListItem key={question.uuid}>
                <QuestionIndex
                  // isCheck={answeredQuestionIds.includes(question.id)}
                  isActive={curentQuestionUUID === question.uuid}
                  isEssay={question.type === "essay"}
                  onClick={() => this.switchQuestion(question.uuid)}
                >
                  {question.getAbsoluteIndex() + 1}
                </QuestionIndex>
              </InlineListItem>
            );
          })}
        </InlineList>
      </div>
    );
  }
}

SectionQuestionList.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  questions: state.examSubmissionReview.questionOrder[ownProps.sectionId].map(
    (uuid) => state.examSubmissionReview.data.questions[uuid]
  ),
  curentQuestionUUID: state.examSubmissionReview.currentQuestion.uuid,
  sectionOrder: state.examSubmissionReview.sectionOrder,
  questionOrder: state.examSubmissionReview.questionOrder,
});

export default connect(mapStateToProps, { switchQuestion })(
  SectionQuestionList
);
