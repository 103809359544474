import React from "react";
import PropTypes from "prop-types";

export const ToolItem = (props) => (
  <li>
    {props.children}
  </li>
);

export const PageHeaderWithTools = ({ heading, subheading, message, children }) => {
  return (
    <div className="nk-block-head nk-block-head-lg">
      <div className="nk-block-head-sub">
        <span>{subheading}</span>
      </div>
      <div className="nk-block-between-md g-4">
        <div className="nk-block-head-content">
          <h2 className="nk-block-title fw-normal">{heading}</h2>
          <div className="nk-block-des">
            <p>
              {message}
              <span className="text-primary">
                <em className="icon ni ni-info"></em>
              </span>
            </p>
          </div>
        </div>
        <div className="nk-block-head-content">
          <ul className="nk-block-tools gx-3">
            {children}
          </ul>
        </div>
      </div>
    </div>
  );
};

PageHeaderWithTools.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ]).isRequired
};

export default PageHeaderWithTools;
