import React from "react";
// import BlueLogo from "assets/images/logo-blue.png";
import BlueLogo from "assets/images/edu-blue-logo-new.png";
import WhiteLogo from "assets/images/logo-white.png";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SchoolLogoContainer = styled.div`
  display: initial;
  @media only screen and (max-width: 316px) {
    display: none;
  }
`;

const Logo = (props) => (
  <>
    <div className="nk-header-brand mr-2">
      <Link to="/" className="logo-link">
        <img
          className="logo-light logo-img"
          src={BlueLogo}
          alt="logo"
          style={{ opacity: 1 } /* hapus ini kalau ingin support dark theme */}
        />
        {/* <img
        className="logo-dark logo-img"
        src={WhiteLogo}
        alt="logo-dark"
      /> */}
        {/* <span className="nio-version">Ulangan</span> */}
      </Link>
    </div>
    {props.school && props.school.logo && (
      <SchoolLogoContainer className="nk-header-brand">
        <Link to="/" className="logo-link">
          <img
            className="logo-light logo-img"
            src={props.school.logo}
            alt="logo"
            style={{ opacity: 1, objectFit: "cover" }}
          />
          {/* <span className="nio-version">Ulangan</span> */}
        </Link>
      </SchoolLogoContainer>
    )}
  </>
);

const mapStateToProps = (state) => ({
  school: state.auth.teacher.school,
});

export default connect(mapStateToProps)(Logo);
