import styled from "styled-components";

const Key = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 100;
  background: #e2f8f1;
  color: #20c997;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  border-left: 1px solid #dbdfea;
`;

export default Key;
