import React from "react";
import PropTypes from "prop-types";
import CardTab from "components/Tab/CardTab/CardTab";
import Link from "components/Link/Link";
import NioIcon from "components/Icon/NioIcon";
import Block from "components/Block/Block";
// TODO: ini dipake di bank soal sama persis oleh karena itu pindahkan ke shared component
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import Table from "./Table";
import DeleteManyButton from "./DeleteManyButton";
import SendCuricculumButton from "./SendCuricculumButton";
import { TableContext } from "./TableContext";
import env from "domain/Environment/Env";
import Alert from "components/Alert/Alert";

class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedQuestions: [], tableReloader: null };
  }

  addSelectedQuestion = (question) => {
    this.setState((state) => ({
      selectedQuestions: [...state.selectedQuestions, { ...question }],
    }));
  };

  changeSelectedQuestion = ({ id, payload }) => {
    this.setState((state) => ({
      selectedQuestions: state.selectedQuestions.map((question) => {
        if (question.id !== id) return question;
        return {
          ...question,
          ...payload,
          id,
        };
      }),
    }));
  };

  removeSelectedQuestion = (question) => {
    this.setState((state) => ({
      selectedQuestions: state.selectedQuestions.filter(
        (selectedQuestion) => selectedQuestion.id !== question.id
      ),
    }));
  };

  setTableReloader = (tableReloader) => this.setState({ tableReloader });

  render() {
    return (
      <TableContext.Provider
        value={{
          // TODO: addSelectedQuestion, changeSelectedQuestion, removeSelectedQuestion
          // tambahkan ke context karena dipassing ke deep child
          tableReload: this.state
            .tableReloader /* TODO: mungkin pakai redux agar tidak seperti ini. masalahnya tabel ingin bisa direload tapi oleh component yg bukan anak tabel */,
          setTableReloader: this.setTableReloader,
        }}
      >
        <Block>
          <CardTab
            renderInner={() => <h3 className="text-white">Bank</h3>}
            renderTab={() => (
              // TODO: refactor CardTab agar tidak perlu begini
              <>
                <li>
                  <a
                    href={
                      env.isProd()
                        ? "https://guru.edulogy.id/teacher/administration/material"
                        : "https://guru.dev.edulogy.id/teacher/administration/material"
                    }
                    className="text-base"
                  >
                    <em className="icon ni ni-book-read"></em>{" "}
                    <span>Bank Materi</span>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      env.isProd()
                        ? "https://guru.edulogy.id/teacher/administration/assignment"
                        : "https://guru.dev.edulogy.id/teacher/administration/assignment"
                    }
                    className="text-base"
                  >
                    <em className="icon ni ni-notes-alt"></em>{" "}
                    <span>Bank Tugas</span>
                  </a>
                </li>
                <li className="active">
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="text-azure"
                  >
                    <em className="icon ni ni-reports-alt"></em>{" "}
                    <span>Bank Soal</span>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      env.isProd()
                        ? "https://guru.edulogy.id/teacher/administration/RPP"
                        : "https://guru.dev.edulogy.id/teacher/administration/RPP"
                    }
                    className="text-base"
                  >
                    <em className="icon ni ni-file-text"></em>{" "}
                    <span>Bank RPP</span>
                  </a>
                </li>
              </>
            )}
          />
        </Block>

        <Block>
          <Alert variant="info" icon="info" className="mt-1 mb-0">
            Masih ingin membuka fitur bank soal di web lama? Buka{" "}
            <a
              href={
                env.isProd()
                  ? `https://guru.edulogy.id/teacher/administration/question`
                  : `https://guru.dev.edulogy.id/teacher/administration/question`
              }
            >
              <u>disini</u>
            </a>{" "}
            untuk pindah.
          </Alert>
        </Block>

        <Block>
          <Row className="justify-content-between">
            <Col size="3">
              <Link
                to="/bank/question/create"
                className="btn btn-primary btn-round"
              >
                <NioIcon name="plus" /> <span>Tambah</span>
              </Link>
            </Col>
            <Col className="text-right" size="9">
              <DeleteManyButton
                selectedQuestions={this.state.selectedQuestions}
                removeSelectedQuestion={this.removeSelectedQuestion}
              />
              <span className="mr-2" />
              <SendCuricculumButton
                selectedQuestions={this.state.selectedQuestions}
                changeSelectedQuestion={this.changeSelectedQuestion}
                removeSelectedQuestion={this.removeSelectedQuestion}
              />
            </Col>
          </Row>
        </Block>

        <Block>
          <Table
            addSelectedQuestion={this.addSelectedQuestion}
            removeSelectedQuestion={this.removeSelectedQuestion}
            selectedQuestions={this.state.selectedQuestions}
          />
        </Block>
      </TableContext.Provider>
    );
  }
}

export default index;
