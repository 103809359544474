import { FETCHED } from "./type";
import produce from "immer";

const initialState = null;

const questionCategories = produce((state, action = {}) => {
  if (state === undefined) return initialState;

  switch (action.type) {
    case FETCHED:
      return action.categories.map((category) => ({
        id: category.id,
        name: category.name,
      }));
  }
});

export default questionCategories;
