import React from "react";
import PropTypes from "prop-types";

const LoadingRow = (props) => (
  <tr>
    <td colSpan={7} className="text-center">
      Tidak ada data untuk ditampilkan.
    </td>
  </tr>
);

LoadingRow.propTypes = {};

export default LoadingRow;
