import React from "react";
import { CKEditor as OriginalCKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import CustomUploadAdapterPlugin from "./CustomUploadAdapterPlugin";

export default function CKEditor({
  EditorBuild = ClassicEditor,
  UploadAdapterPlugin = CustomUploadAdapterPlugin,
  ...restProps
}) {
  return (
    <OriginalCKEditor
      {...restProps}
      editor={EditorBuild}
      config={{
        ...config,
        extraPlugins: [UploadAdapterPlugin],
      }}
    />
  );
}

const config = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "imageUpload",
      "bulletedList",
      "numberedList",
      "|",
      "indent",
      "outdent",
      "|",
      "link",
      "mediaEmbed",
      // "MathType",
      // "ChemType",
      "undo",
      "redo",
    ],
  },
  language: "id",
  image: {
    toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
  },
  extraPlugins: [],
};
