import axios from "configuredAxios";
import env from "domain/Environment/Env";

const Log = (message) => {
  if (env.isLocal() || env.isDev()) {
    console.log(message);
  }

  if (env.isDev() || env.isProd()) {
    axios
      .post("v1/log", { message })
      .then(() => {})
      .catch(() => {});
  }
};

export default Log;
