import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "redux/auth/action";
import { PageContext } from "components/Page";

const Logout = ({ logout, accessToken, history }) => {
  const page = React.useContext(PageContext)

  document.title = "Logout...";

  setTimeout(() => {
    logout(accessToken)
      .then(() => history.push("/login"))
      .catch((err) => {
        if (err.response) {
        } else if (err.request) {
          page.noInternet();
        } else {
        }
      });
  }, 1000);

  return <p className="p-3 ff-mono text-dark">Sedang memproses logout...</p>;
};

Logout.propTypes = {};

const mapStateToProps = (state) => ({
  accessToken: state.auth.access_token,
});

export default connect(mapStateToProps, { logout })(Logout);
