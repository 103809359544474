import React from "react";
import PropTypes from "prop-types";
import Button from "../shared/Button";
import VideoViewer from "components/Viewer/Video";

export default class Video extends React.Component {
  static propTypes = {};
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <Button
        attachment={data}
        text={data.name || data.url}
        icon="video"
      >
        <Button.Modal>
          <VideoViewer url={data.url} extension={data.extension} />
        </Button.Modal>
      </Button>
    );
  }
}
