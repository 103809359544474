import React from "react";
import PropTypes from "prop-types";
import Button from "../shared/Button";
import AudioViewer from "components/Viewer/Audio";

class Audio extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <Button
        attachmentId={data.id}
        text={data.audio.name || data.audio.url}
        icon="vol"
      >
        <Button.Modal>
          <AudioViewer url={data.audio.url} extension={data.audio.extension} />
        </Button.Modal>
      </Button>
    );
  }
}

Audio.propTypes = {};

export default Audio;
