import { produce } from "immer";
import { v4 as uuidv4 } from "uuid";
import omit from "lodash/omit";
import last from "lodash/last";
import fromMultichoiceReducer from "./fromMultichoiceReducer";
import fromMultianswerReducer from "./fromMultianswerReducer";
import fromTruefalseReducer from "./fromTruefalseReducer";
import fromEssayReducer from "./fromEssayReducer";
import duplicateQuestionReducer from "./duplicateQuestionReducer";
import {
  CLEARED,
  QUESTION_INITIALIZED,
  QUESTION_INITIALIZE_FROM_PREVIOUS_SETTING,
  QUESTION_INITIALIZE_FROM_PASTE,
  QUESTION_TYPE_CHANGED,
  QUESTION_UPDATED,
  QUESTION_DELETED,
  QUESTION_DUPLICATED,
  QUESTION_ANSWER_INITIALIZED,
  QUESTION_ANSWER_UPDATED,
  QUESTION_ANSWER_DELETED,
  QUESTION_SAVED,
  QUESTION_ATTACHMENT_ADDED,
  QUESTION_ATTACHMENT_DELETED,
  QUESTION_CATEGORY_FETCHED,
  QUESTION_CATEGORY_ADDED,
  QUESTION_CATEGORY_UPDATED,
  QUESTION_CATEGORY_DELETED,
  QUESTION_TYPES_FETCHED,
} from "./type";

const initialQuestion = {
  isSaved: false,
  uuid: null,
  type: "multichoice",
  point: null,
  category_id: null,
  lesson_id: null,
  grade_id: null,
  content: null,
  // scoring_mode: null,
  attachments: [],
};

const initialAnswer = {
  multichoice: {
    uuid: null,
    id: null,
    questionUUID: null,
    key: false,
    content: null,
  },
  multianswer: {
    uuid: null,
    id: null,
    questionUUID: null,
    key: false,
    content: null,
  },
  truefalse: {
    uuid: null,
    questionUUID: null,
    answer: null,
  },
  essay: {
    uuid: null,
    questionUUID: null,
    content: null,
  },
};

const initialState = {
  isCategoriesFetched: false,
  categories: [],
  isTypesFetched: false,
  types: [],
  questionOrder: [],
  questions: {},
  answerOrder: [],
  answers: {},
};

const createQuestionBank = produce((state, action = {}) => {
  switch (action.type) {
    case CLEARED: {
      return { ...initialState };
    }
    case QUESTION_INITIALIZED: {
      const uuid = uuidv4();
      state.questions[uuid] = { ...initialQuestion, uuid };
      state.questionOrder.push(uuid);

      const answer1 = {
        uuid: uuidv4(),
        key: false,
        content: null,
        questionUUID: uuid,
      };
      const answer2 = {
        uuid: uuidv4(),
        key: false,
        content: null,
        questionUUID: uuid,
      };
      state.answerOrder = {
        ...state.answerOrder,
        [uuid]: [answer1.uuid, answer2.uuid],
      };
      state.answers = {
        ...state.answers,
        [answer1.uuid]: answer1,
        [answer2.uuid]: answer2,
      };
      return;
    }
    case QUESTION_INITIALIZE_FROM_PREVIOUS_SETTING: {
      const lastQuestion = state.questions[last(state.questionOrder)];

      const uuid = uuidv4();
      state.questions[uuid] = {
        ...initialQuestion,
        uuid,
        point: lastQuestion.point,
        category_id: lastQuestion.category_id,
        lesson_id: lastQuestion.lesson_id,
        grade_id: lastQuestion.grade_id,
      };
      state.questionOrder.push(uuid);

      const answer1 = {
        uuid: uuidv4(),
        key: false,
        content: null,
        questionUUID: uuid,
      };
      const answer2 = {
        uuid: uuidv4(),
        key: false,
        content: null,
        questionUUID: uuid,
      };
      state.answerOrder = {
        ...state.answerOrder,
        [uuid]: [answer1.uuid, answer2.uuid],
      };
      state.answers = {
        ...state.answers,
        [answer1.uuid]: answer1,
        [answer2.uuid]: answer2,
      };
      return;
    }
    case QUESTION_INITIALIZE_FROM_PASTE: {
      const uuid = uuidv4();
      state.questions[uuid] = {
        ...initialQuestion,
        uuid,
        content: action.questionContent,
        point: action.point,
        category_id: action.category_id,
        lesson_id: action.lesson_id,
        grade_id: action.grade_id,
      };
      state.questionOrder.push(uuid);

      const choices = action.choices.map((choice) => ({
        uuid: uuidv4(),
        key: false,
        content: choice,
        questionUUID: uuid,
      }));

      state.answerOrder = {
        ...state.answerOrder,
        [uuid]: choices.map((choice) => choice.uuid),
      };
      state.answers = {
        ...state.answers,
        ...choices.reduce(
          (accumulator, choice) => ({
            ...accumulator,
            [choice.uuid]: choice,
          }),
          {}
        ),
      };
      return;
    }
    case QUESTION_TYPE_CHANGED: {
      const question = state.questions[action.questionUUID];
      switch (question.type) {
        case "multichoice":
          // return fromMultichoiceReducer(state, action, question);
          fromMultichoiceReducer(state, action, question);
          break;
        case "multianswer":
          fromMultianswerReducer(state, action, question);
          break;
        case "truefalse":
          fromTruefalseReducer(state, action, question);
          break;
        case "essay":
          fromEssayReducer(state, action, question);
          break;
        default:
          throw new Error("Invalid question type");
      }
      break;
    }
    case QUESTION_UPDATED: {
      state.questions[action.uuid][action.attribute] = action.value;
      return;
    }
    case QUESTION_DELETED: {
      state.questionOrder = state.questionOrder.filter(
        (uuid) => uuid !== action.uuid
      );
      // NOTE: kode berikut tidak dihapus karena ada bug akibat re-render question
      // yang lebih cepat dibanding re-render question list
      // state.questions = omit(state.questions, action.uuid);
      // state.answers = omit(state.answers, state.answerOrder[action.uuid]);
      // state.answerOrder = omit(state.answerOrder, action.uuid);
      return;
    }
    case QUESTION_SAVED: {
      state.questions[action.uuid].isSaved = true;
      return;
    }
    case QUESTION_DUPLICATED: {
      duplicateQuestionReducer(state, action, state.questions[action.uuid]);
      break;
    }
    case QUESTION_ANSWER_INITIALIZED: {
      const uuid = uuidv4();
      state.answerOrder[action.questionUUID].push(uuid);
      state.answers[uuid] = {
        ...initialAnswer[action.questionType],
        uuid,
        questionUUID: action.questionUUID,
      };
      return;
    }
    case QUESTION_ANSWER_UPDATED: {
      state.answers[action.uuid] = {
        ...state.answers[action.uuid],
        ...action.answer,
      };
      return;
    }
    case QUESTION_ANSWER_DELETED: {
      state.answerOrder[action.questionUUID] = state.answerOrder[
        action.questionUUID
      ].filter((answerUUID) => answerUUID !== action.uuid);
      state.answers = omit(state.answers, action.uuid);
      return;
    }
    case QUESTION_ATTACHMENT_ADDED: {
      state.questions[action.questionUUID].attachments.push({
        ...action.attachment,
        uuid: uuidv4(), // NOTE: uuid diperlukan kalau ada jenis attachment seperti URL
      });
      return;
    }
    case QUESTION_ATTACHMENT_DELETED: {
      state.questions[action.questionUUID].attachments = state.questions[
        action.questionUUID
      ].attachments.filter(
        (attachment) => attachment.uuid !== action.attachmentUUID
      );
      return;
    }
    case QUESTION_CATEGORY_FETCHED: {
      state.categories = action.data;
      state.isCategoriesFetched = true;
      return;
    }
    case QUESTION_CATEGORY_ADDED: {
      state.categories.push({ ...action.category });
      return;
    }
    case QUESTION_CATEGORY_UPDATED: {
      state.categories = state.categories.map((category) => {
        if (category.id !== action.id) return category;
        return {
          ...category,
          name: action.name,
        };
      });
      return;
    }
    case QUESTION_CATEGORY_DELETED: {
      state.categories = state.categories.filter(
        (category) => category.id !== action.id
      );
      return;
    }
    case QUESTION_TYPES_FETCHED: {
      state.types = action.data;
      state.isTypesFetched = true;
      break;
    }
    default:
      return state;
  }
}, initialState);

export default createQuestionBank;
