import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "components/Button/Button";
import SimpleModal from "components/Modal/SimpleModal";
import Block from "components/Block/Block";
import { useDispatch } from "react-redux";
import { initQuestionFromPaste } from "redux/createQuestionBank/action";
import Alert from "components/Alert/Alert";
import IconButton from "components/Button/IconButton";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import SettingForm from "./SettingForm";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import { v4 as uuidv4 } from "uuid";

function removeChoiceNumber(choiceString) {
  return choiceString.replace(
    /^([abcdefghijABCDEFGHJI]\.|\(?[abcdefghijABCDEFGHJI]\))[\s]*/,
    ""
  );
}

function parseQuestion(rawString) {
  let lines = rawString
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line !== "");
  let questionContent = "";
  let choices = [];

  lines.forEach((line, index) => {
    const match = line.match(
      /^([abcdefghijABCDEFGHJI]\.|\(?[abcdefghijABCDEFGHJI]\)).*/
    );
    if (match) {
      choices.push(removeChoiceNumber(line));
    } else if (match === null && choices.length === 0) {
      questionContent += line + "\n";
    } else if (match === null && choices.length > 0) {
      choices[choices.length - 1] += line + "\n";
    }
  });

  // multichoice at least should have 2 choices
  while (choices.length < 2) {
    choices.push("");
  }

  // ckeditor cannot accept new line
  questionContent = questionContent.replace(/\n/g, "<br/>");
  choices = choices.map((choice) => choice.replace(/\n/g, "<br/>"));

  return {
    questionContent: questionContent,
    choices,
  };
}

function removeQuestionNumber(questionContent) {
  return questionContent.replace(/^\s*([1-9]+\.|\(?[1-9]+\))\s*/, "");
}

export default function PasteButton(props) {
  const [isShow, setIsShow] = useState(false);
  return (
    <>
      {isShow && <Paste onClose={() => setIsShow(false)} />}

      <IconButton
        size="sm"
        icon="align-left"
        variant="warning"
        onClick={() => setIsShow(true)}
      >
        Paste Soal
      </IconButton>
    </>
  );
}

function Paste(props) {
  const [rows, setRows] = useState([
    {
      id: uuidv4(),
      content: "",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState({
    point: null,
    lesson_id: null,
    grade_id: null,
    category_id: null,
  });

  const dispatch = useDispatch();
  function submit() {
    setIsLoading(true);
    rows.forEach(({ content }) => {
      if (!content.trim()) return;
      const { questionContent, choices } = parseQuestion(content);
      dispatch(
        initQuestionFromPaste({ questionContent, choices, ...settings })
      );
    });
    setTimeout(() => props.onClose(), 500);
  }

  return (
    <SimpleModal size="lg" title="Paste Soal" onClose={props.onClose}>
      <PasteAlert />
      <Block>
        <SettingForm
          point={settings.point}
          lesson_id={settings.lesson_id}
          grade_id={settings.grade_id}
          category_id={settings.category_id}
          onChange={(name, value) => {
            setSettings((state) => ({ ...state, [name]: value }));
          }}
        />
      </Block>

      {rows.map((row) => (
        <Block key={row.uuid}>
          <Row className="gx-1">
            <Col size="9">
              <textarea
                value={row.content}
                onChange={(e) => {
                  const content = e.target.value;
                  setRows((rows) =>
                    rows.map((row_) => {
                      if (row_.uuid !== row.uuid) return row_;
                      return {
                        ...row_,
                        content,
                      };
                    })
                  );
                }}
                className="d-block form-control form-control-sm bg-lighter"
                style={{ width: "100%" }}
                rows="5"
              />
            </Col>
            <Col size="3">
              <Button
                size="sm"
                block
                dim
                variant="danger"
                onClick={() => {
                  setRows((rows) =>
                    rows.filter((row_) => row_.uuid !== row.uuid)
                  );
                }}
                disabled={rows.length === 1}
              >
                Hapus
              </Button>
              <Button
                size="sm"
                block
                variant="light"
                onClick={() => {
                  setRows((rows) => [
                    ...rows.map((row_) => {
                      if (row_.uuid !== row.uuid) return row_;
                      return {
                        ...row_,
                        content: removeQuestionNumber(row_.content),
                      };
                    }),
                  ]);
                }}
              >
                Hapus Nomor Soal
              </Button>
            </Col>
          </Row>
        </Block>
      ))}

      <Block>
        <Row>
          <Col size="6">
            <Button
              size="sm"
              variant="light"
              block
              onClick={() => {
                setRows((rows) => [...rows, { uuid: uuidv4(), content: "" }]);
              }}
            >
              Tambah Soal
            </Button>
          </Col>
          <Col size="6">
            <Button
              size="sm"
              block
              type="submit"
              onClick={submit}
              className="mb-2"
            >
              {!isLoading ? (
                "Ekstrak"
              ) : (
                <SpinnerGrow size="1rem" variant="white" />
              )}
            </Button>
          </Col>
        </Row>
      </Block>
    </SimpleModal>
  );
}

function PasteAlert(props) {
  return (
    <Alert icon="info" className="fs-12px">
      <p>
        - Pastikan setiap pilihan diawali <code>a.</code>, <code>a)</code> atau{" "}
        <code>(a)</code>.<br />- Setelah paste, spasi soal mungkin berantakan
        tapi sistem akan berusaha merapikannya.
      </p>

      <div className="text-dark pl-2">
        <br />
        1. Pilihlah kota yang berada di Indonesia.
        <br />
        <span className="text-danger">a.</span> Jakarta
        <br />
        <span className="text-danger">b)</span> New York
        <br />
        <span className="text-danger">(c)</span> Manhattan
        <br />
      </div>
    </Alert>
  );
}

Paste.propTypes = {};
