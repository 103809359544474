import React from "react";
import PropTypes from "prop-types";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Checkbox from "./shared/Checkbox";
import SkeletonEditor from "components/Editor/SharedEditor/SkeletonEditor";
import styled from "styled-components";
import QuestionForm from "./QuestionForm";
import AnswerContainer from "./shared/AnswerContainer";
import { v4 as uuidv4 } from "uuid";
import questionBankAPI from "api/bank/question";

const AnswerFieldDelete = styled.em`
  cursor: pointer;
  &:hover {
    color: #e85347;
  }
`;

const AnswerField = ({
  uuid,
  isChecked,
  content,
  onCheck,
  onChange,
  onRemove,
  showRemoveButton = false,
}) => (
  <div className="d-flex" style={{ alignItems: "center" }}>
    <AnswerItemBordered style={{ flexGrow: 1, minWidth: 0 }}>
      <Checkbox
        name="choice"
        value={uuid}
        onValue={"true"}
        offValue={"false"}
        onChange={(e) => onCheck(e.target.value === "true")}
        checked={isChecked}
      >
        <SkeletonEditor
          placeholder="Ketikan pilihan jawaban disini..."
          defaultValue={content}
          onChange={(content) => onChange(content)}
        />
      </Checkbox>
    </AnswerItemBordered>
    {showRemoveButton && (
      <AnswerFieldDelete
        className="icon ni ni-cross ml-2 mr-n4"
        onClick={() => onRemove(uuid)}
      />
    )}
  </div>
);

const AnswerFieldAdd = (props) => (
  <AnswerItemBordered
    {...props}
    className="bg-lighter text-center border-0"
    style={{ fontSize: "12px" }}
  >
    Tambah pilihan jawaban
  </AnswerItemBordered>
);

class Multianswer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount() {
    this.props.onChange(
      "choices",
      this.props.question.choices.map((choice) => ({
        ...choice,
        uuid: uuidv4(),
      }))
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.question.choices[0].uuid === undefined &&
      this.props.question.choices[0].uuid !== undefined
    ) {
      this.setState({ isLoading: false });
    }
  }

  add = () => {
    this.props.onChange("choices", [
      ...this.props.question.choices,
      {
        id: null,
        uuid: uuidv4(),
        key: false,
        content: null,
      },
    ]);
  };

  onDelete = (choiceTarget) => {
    if (choiceTarget.id) {
      questionBankAPI.deleteAnswer({ question_id: this.props.question.id, answer_id: choiceTarget.id }).then(() => {
        this.props.onChange(
          "choices",
          this.props.question.choices.filter((choice) => choice.uuid !== choiceTarget.uuid)
        );
      });
    } else {
      this.props.onChange(
        "choices",
        this.props.question.choices.filter((choice) => choice.uuid !== choiceTarget.uuid)
      );
    }
  };

  handleCheck = (uuid, isChecked) => {
    this.props.onChange(
      "choices",
      this.props.question.choices.map((choice) => {
        if (choice.uuid !== uuid) return choice;
        return {
          ...choice,
          key: isChecked,
        };
      })
    );
  };

  onChange = (uuid, content) => {
    this.props.onChange(
      "choices",
      this.props.question.choices.map((choice) => {
        if (choice.uuid !== uuid) return choice;
        return {
          ...choice,
          content: content,
        };
      })
    );
  };

  render() {
    if (this.state.isLoading) {
      return "Memuat...";
    }

    return (
      <div className="pr-2">
        <QuestionForm
          question={this.props.question}
          onChange={this.props.onChange}
        />

        <AnswerContainer>
          {this.props.question.choices.map((choice) => (
            <AnswerField
              key={choice.uuid}
              uuid={choice.uuid}
              isChecked={choice.key}
              content={choice.content}
              onChange={(content) => this.onChange(choice.uuid, content)}
              onCheck={(isChecked) => this.handleCheck(choice.uuid, isChecked)}
              onRemove={() => this.onDelete(choice)}
              showRemoveButton={
                this.props.question.choices.length !== 2 &&
                !(
                  this.props.question.choices.filter((choice) =>
                    Number.isInteger(choice.id)
                  ).length === 2 && Number.isInteger(choice.id)
                ) &&
                !(
                  this.props.question.choices.filter((choice) => choice.key === true)
                    .length === 1 && choice.key === true
                )
              }
            />
          ))}
          {this.props.question.choices.length < 10 && (
            <AnswerFieldAdd onClick={this.add} />
          )}
        </AnswerContainer>
      </div>
    );
  }
}

export default Multianswer;
