import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Badge = ({
  variant = "primary",
  size = "xs",
  dim = false,
  outline = false,
  className,
  children,
  ...props
}) => (
  <span
    className={cx(
      className,
      "badge",
      outline ? `badge-outline-${variant}` : `badge-${variant}`,
      size !== "xs" && `badge-${size}`,
      dim && "badge-dim"
    )}
    {...props}
  >
    {children}
  </span>
);

Badge.propTypes = {

  className: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  dim: PropTypes.bool,
  outline: PropTypes.bool,
};

export default Badge;
