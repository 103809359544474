import axios from "configuredAxios";

export default {
  getByStudent: ({ examId, studentId, cancelCallback = () => {} }) => {
    const source = axios.CancelToken.source();
    cancelCallback(source.cancel);
    return axios
      .get(`/v1/school/academic/exam/${examId}/submission`, {
        params: {
          student_id: studentId,
        },
        cancelToken: source.token,
      })
      .then((res) => res.data.data);
  },
  score: {
    markAsFinalScore: ({ examId, student_id, submission_id }) =>
      axios.patch(`/v1/school/academic/exam/${examId}/score`, {
        student_id,
        submission_id,
      }),
  },
};

// const data = (id) => ({
//   id: id,
//   started_at: "raw date nya",
//   finished_at: "",
//   score: Math.floor(Math.random() * 10),
//   total_questions: 10,
//   total_scored_questions: 10,
// });

// export default {
//   getByStudent: ({ examId, student_id, cancelCallback = () => {} }) => {
//     return Promise.resolve({
//       student: {
//         id: "10",
//         photo:
//           "https://hatta.storage.edulogy.id/file/teacher/photo/2020/07/16/sample-640426.JPEG",
//         code: "10101010",
//         nis: "10101111",
//         name: "Yudi Supriyadi",
//       },
//       final_score: 100,
//       submissions: [data(1), data(2), data(3)],
//     });
//   },
//   score: {
//     markAsFinalScore: () => Promise.resolve(true),
//   },
// };
