import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Select = ({ className = "", children, ...props }) => (
  <div className="form-control-select">
    <select
      className={cx(className, "form-control")}
      {...props}
    >
      {children}
    </select>
  </div>
);

Select.propTypes = {};

export default Select;
