import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ErrorBoundary from "components/Error/ErrorBoundary";
import moment from "moment-timezone";
import "moment-duration-format";
import keys from "lodash/keys";

class LeftSidebar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      score,
      totalFilleds,
      totalQuestions,
      instruction,
    } = this.props;

    return (
      <div className="card-inner-group" style={{ borderRight: "none" }}>
        <div className="card-inner">
          <span className="sub-text sub-text-sm text-dark">Waktu tersiswa:</span>
          <span className="sub-text sub-text-sm text-soft mb-2">
            -- : -- : --
          </span>
          <br />
          <span className="sub-text sub-text-sm text-dark">
            Siswa menjawab:
          </span>
          <span className="sub-text sub-text-sm text-soft mb-2">
            {totalFilleds} dari {totalQuestions}
          </span>
        </div>
        <div className="card-inner">
          <h6>Instruksi</h6>
          <span className="sub-text sub-text-sm text-soft">
            {instruction || <em>Tidak ada instruksi.</em>}
          </span>
        </div>
      </div>
    );
  }
}

LeftSidebar.propTypes = {};

const mapStateToProps = (state) => ({
  score: state.examSubmissionReview.data.submission.score,
  totalFilleds: state.examSubmissionReview.data.submission.total_filleds,
  totalQuestions: state.examSubmissionReview.data.submission.total_questions,
  instruction: state.examSubmissionReview.data.exam.instruction,
});

const LeftSidebarRedux = connect(mapStateToProps)(LeftSidebar);

export default (props) => (
  <ErrorBoundary fallback={<span>Oops, terjadi kesalahan.</span>}>
    <LeftSidebarRedux {...props} />
  </ErrorBoundary>
);
