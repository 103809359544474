import React from "react";
import PropTypes from "prop-types";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Checkbox from "./shared/Checkbox";
import { connect } from "react-redux";
import ErrorBoundary from "components/Error/ErrorBoundary";
import QuestionForm from "./QuestionForm";
import AnswerContainer from "./shared/AnswerContainer";
import Key from "./shared/Key";
import AnswerContentContainer from "./shared/AnswerContentContainer";

const AnswerField = ({ uuid, content, isKey, isMark }) => {
  let style = {};
  if (isKey && isMark) {
    style = { background: "#e2f8f1", color: "#20c997" }; // "correct" color
  } else if (isKey && !isMark) {
    style = { background: "inherit", color: "inherit" }; // "normal" color
  } else if (!isKey && isMark) {
    style = { background: "#fce9e7", color: "#e85347" }; // "incorrect" color
  } else if (!isKey && !isMark) {
    style = { background: "inherit", color: "inherit" }; // "normal" color
  }

  return (
    <div className="d-flex" style={{ alignItems: "center" }}>
      <AnswerItemBordered style={{ flexGrow: 1, minWidth: 0, ...style }}>
        {isKey && <Key>kunci</Key>}
        <Checkbox
          name="choice"
          value={uuid}
          onValue={"true"}
          offValue={"false"}
          checked={isMark}
        >
          <AnswerContentContainer dangerouslySetInnerHTML={{ __html: content }} />
        </Checkbox>
      </AnswerItemBordered>
    </div>
  );
};

class Multianswer extends React.Component {
  render() {
    return (
      <>
        <QuestionForm />

        <AnswerContainer>
          {this.props.choices.map((choice) => (
            <AnswerField
              key={choice.uuid}
              uuid={choice.uuid}
              isKey={choice.key}
              isMark={this.props.marks.includes(choice.id)}
              content={choice.content}
            />
          ))}
        </AnswerContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  choices: state.examSubmissionReview.answerOrder[
    state.examSubmissionReview.currentQuestion.uuid
  ].map((answerUUID) => state.examSubmissionReview.data.answers[answerUUID]),
  marks: state.examSubmissionReview.data.questions[
    state.examSubmissionReview.currentQuestion.uuid
  ].marks.map(({ answer_id }) => answer_id),
  question:
    state.examSubmissionReview.data.questions[
      state.examSubmissionReview.currentQuestion.uuid
    ],
});
const MultianswerRedux = connect(mapStateToProps)(Multianswer);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <MultianswerRedux {...props} />
  </ErrorBoundary>
);
