import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Block from "components/Block/Block";
import Datatable from "components/Table/Datatable";
import Checkbox from "components/Form/Checkbox";
import EmptyRow from "components/Table/EmptyRow";
import LoadingRow from "components/Table/LoadingRow";
import Row from "./Row";
import TypeFilter from "./Filter/TypeFilter";
import GradeFilter from "./Filter/GradeFilter";
import CategoryFilter from "./Filter/CategoryFilter";
import LessonFilter from "./Filter/LessonFilter";
import ReactTooltip from "react-tooltip";
import difference from "lodash/difference";
import useFetchQuestions from "hooks/api/bank/useFetchQuestions";
import { TableContext } from "../TableContext";
import SharedEditor from "components/Editor/SharedEditor";
import CKEditor from "components/Editor/CKEditor";

const PAGE_LIMIT = 10;
const MAX_VISIBLE_PAGE_NUMBER = 3;

export default function Table(props) {
  const { setTableReloader } = useContext(TableContext);

  const [filters, setFilters] = useState({
    lesson_id: null,
    category_id: null,
    question_type: null,
    grade_id: null,
    search: null,
    currentPage: 1,
  });

  const { isFetched, data, reload: tableReloader } = useFetchQuestions({
    filters,
    pageLimit: PAGE_LIMIT,
  });

  useEffect(() => {
    setTableReloader(tableReloader); // TODO: pakai redux biar gak perlu kaya gini.
  }, []);

  function handleSwitchPage(pageNumber) {
    setFilters((state) => ({ ...state, currentPage: pageNumber }));
  }

  function handleFilterChange(key, value) {
    setFilters((state) => ({ ...state, [key]: value || null, currentPage: 1 }));
  }

  function toggleSelect(question) {
    if (
      props.selectedQuestions.find(
        (selectedQuestion) => selectedQuestion.id === question.id
      ) === undefined
    ) {
      props.addSelectedQuestion(question);
    } else {
      props.removeSelectedQuestion(question);
    }
  }

  function toggleSelectAll() {
    const questionIds = data.questions.map(({ id }) => id);
    const selectedQuestionIds = props.selectedQuestions.map(({ id }) => id);
    if (difference(questionIds, selectedQuestionIds).length === 0) {
      data.questions.forEach((question) =>
        props.removeSelectedQuestion(question)
      );
    } else {
      const selectedQuestionIds = props.selectedQuestions.map(({ id }) => id);
      data.questions
        .filter((question) => !selectedQuestionIds.includes(question.id))
        .forEach((question) => props.addSelectedQuestion(question));
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  function refresh() {
    setFilters((state) => ({ ...state })); // hack for trigger re-fetch questions
  }

  const questionIds = data.questions.map(({ id }) => id); // TODO: fix ini duplicate code
  const selectedQuestionIds = props.selectedQuestions.map(({ id }) => id);
  const isAllSelected =
    difference(questionIds, selectedQuestionIds).length === 0 &&
    questionIds.length > 0; // kondisi di atas akan false positive ketika questionIds === 0
  return (
    <SharedEditor
      editor={(setEditor) => (
        <CKEditor onReady={(editor) => setEditor(editor)} />
      )}
    >
      <Block className="mt-3">
        <div className="row gy-2">
          <div className="col-3">
            <LessonFilter
              onChange={(e) => handleFilterChange("lesson_id", e.target.value)}
            />
          </div>
          <div className="col-3">
            <TypeFilter
              onChange={(e) =>
                handleFilterChange("question_type", e.target.value)
              }
            />
          </div>
          <div className="col-3">
            <GradeFilter
              onChange={(e) => handleFilterChange("grade_id", e.target.value)}
            />
          </div>
          <div className="col-3">
            <CategoryFilter
              onChange={(e) =>
                handleFilterChange("category_id", e.target.value)
              }
            />
          </div>
        </div>
      </Block>
      <Block className="pt-2">
        <Datatable
          compact
          onSearch={(keyword) => handleFilterChange("search", keyword)}
          onSwitchPage={handleSwitchPage}
          pagination={{
            // TODO: this is maybe a typo, try to change "currentPage: filters.currentPage"
            filters: filters.currentPage,
            totalPage: data.totalPage,
            maxVisiblePage: MAX_VISIBLE_PAGE_NUMBER,
            position: "bottom",
          }}
          style={{
            tableLayout: "fixed",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "60px" }} data-tip="Pilih semua">
                <Checkbox checked={isAllSelected} onChange={toggleSelectAll} />
              </th>
              <th style={{ width: "150px" }}>Jenis</th>
              <th>Pertanyaan</th>
              <th style={{ width: "250px" }}>Total Dibagikan</th>
              <th style={{ width: "150px" }} className="text-center">
                Aksi
              </th>
            </tr>
          </thead>
          <tbody>
            {!isFetched && <LoadingRow colSpan={5} />}
            {isFetched && data.questions.length === 0 && (
              <EmptyRow colSpan={5} />
            )}
            {isFetched &&
              data.questions.length > 0 &&
              data.questions.map((question) => (
                <Row
                  key={question.id}
                  question={question}
                  toggleSelect={toggleSelect}
                  isSelected={
                    props.selectedQuestions.find(
                      (selectedQuestion) => selectedQuestion.id === question.id
                    ) !== undefined
                  }
                  onRefresh={refresh}
                />
              ))}
          </tbody>
        </Datatable>
      </Block>
    </SharedEditor>
  );
}
