import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { saveAttachment } from "redux/exam/action";
import keys from "lodash/keys";
import { v4 as uuidv4 } from "uuid";
import without from "lodash/without";
import omit from "lodash/omit";
import api from "api";
import Alert from "components/Alert/Alert";
import SimpleModal from "components/Modal/SimpleModal";
import { showLoader, hideLoader } from "redux/page/action";
import ReactTooltip from "react-tooltip";

class QuestionAttachmentUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filesOrder: [],
      files: {},
      errorByFileUUID: {},
      isModalShow: false,
    };
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  componentDidUpdate() {
    if (this.state.filesOrder.length === 0 && this.state.isModalShow) {
      this.closeModal();
    }
  }

  handleChange = (e) => {
    e.persist();
    this.setState((state) => ({
      files: { ...state.files, [e.target.name]: e.target.files[0] },
    }));
  };

  addRow = () => {
    const newFileUUID = uuidv4();
    this.setState((state) => ({
      filesOrder: [...state.filesOrder, newFileUUID],
      files: { ...state.files, [newFileUUID]: null },
    }));
  };

  deleteRow = (fileUUID) => {
    this.setState((state) => ({
      filesOrder: without(state.filesOrder, fileUUID),
      files: omit(state.files, fileUUID),
      errorByFileUUID: omit(state.errorByFileUUID, fileUUID),
    }));
  };

  upload = () => {
    const promises = [];
    this.props.showLoader();
    this.state.filesOrder.forEach((fileUUID) => {
      const formData = new FormData();
      formData.append("file", this.state.files[fileUUID]);
      formData.append("question_id", this.props.question.id);
      promises.push(
        api.exam.attachment
          .create(formData)
          .then((data) => {
            this.props.saveAttachment({
              questionUUID: this.props.question.uuid,
              attachment: data,
            });
            this.deleteRow(fileUUID);
          })
          .catch((err) => {
            this.setState((state) => ({
              errorByFileUUID: {
                ...state.errorByFileUUID,
                [fileUUID]: err.response.data.error[0].message,
              },
            }));
          })
      );
    });
    Promise.all(promises)
      .then(() => this.props.hideLoader())
      .catch(() => this.props.hideLoader());
  };

  openModal = (e) => {
    e.preventDefault();
    if (!this.props.question.id) return;
    const initialFileUUID = uuidv4();
    this.setState({
      isModalShow: true,
      filesOrder: [initialFileUUID],
      files: { [initialFileUUID]: null },
    });
  };
  closeModal = () => this.setState({ isModalShow: false });

  render() {
    const { filesOrder, errorByFileUUID, isModalShow } = this.state;
    return (
      <>
        {isModalShow && (
          <SimpleModal
            size="sm"
            title={
              <>
                <span className="text-soft">Lampiran:</span> Tambah
              </>
            }
            onClose={this.closeModal}
          >
            {keys(errorByFileUUID).length > 0 && (
              <div className="nk-block mb-2">
                <Alert icon="info" variant="warning">
                  <strong>Gagal menyimpan karena hal berikut:</strong>
                  {filesOrder
                    .filter(
                      (fileUUID) => errorByFileUUID[fileUUID] !== undefined
                    )
                    .map((fileUUID) => {
                      return (
                        <div key={fileUUID}>
                          <em className="icon ni ni-bullet-fill" />{" "}
                          <span>{errorByFileUUID[fileUUID]}</span>
                        </div>
                      );
                    })}
                </Alert>
              </div>
            )}

            <div className="row gy-2">
              {filesOrder.map((fileUUID) => {
                return (
                  <>
                    <div className="col-9 text-truncate text-nowrap">
                      <input
                        type="file"
                        name={fileUUID}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-3">
                      <button
                        className="btn btn-sm btn-dim btn-danger"
                        onClick={() => this.deleteRow(fileUUID)}
                      >
                        Batal
                      </button>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="row mt-1">
              <div className="col-6">
                <button
                  className="btn btn-sm btn-block btn-light mt-2"
                  onClick={this.addRow}
                >
                  Tambah lampiran
                </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-sm btn-block btn-primary mt-2"
                  onClick={this.upload}
                >
                  Unggah Semua
                </button>
              </div>
            </div>
          </SimpleModal>
        )}

        <a
          href="#"
          className="text-azure"
          onClick={this.openModal}
          style={{
            cursor: !this.props.question.id ? "not-allowed" : "pointer",
          }}
          data-tip={!this.props.question.id ? "Mohon simpan soal dahulu" : ""}
        >
          <span>Tambah Lampiran</span>
        </a>
      </>
    );
  }
}

QuestionAttachmentUploader.propTypes = {};

const mapStateToProps = (state) => ({
  question: state.exam.data.questions[state.exam.currentQuestion.uuid],
});

export default connect(mapStateToProps, {
  saveAttachment,
  showLoader,
  hideLoader,
})(QuestionAttachmentUploader);
