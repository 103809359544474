import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Button from "./Button";
import NioIcon from "components/Icon/NioIcon";

const IconButton = ({
  icon,
  position = "left",
  wider = false,
  className = null,
  children = null,
  ...props
}) => (
  <Button
    className={cx(className, wider && "btn-wider")}
    {...props}
  >
    {position === "left" && <NioIcon name={icon} />}
    {children && <span>{children}</span>}
    {position === "right" && <NioIcon name={icon} />}
  </Button>
);

IconButton.propTypes = {

  icon: PropTypes.string.isRequired,
  position: PropTypes.oneOf(["left", "right"]),
  wider: PropTypes.bool,
  className: PropTypes.string,
};

export default IconButton;
