import {
  CONNECTION_ERROR_POPUP_SHOWED,
  CONNECTION_ERROR_POPUP_HID,
  ERROR_POPUP_SHOWED,
  ERROR_POPUP_HID,
  ERROR_404_POPUP_SHOWED,
  ERROR_404_POPUP_HID,
  FORBIDDEN_ERROR_POPUP_SHOWED,
  FORBIDDEN_ERROR_POPUP_HID,
  LOADER_SHOWED,
  LOADER_HID,
} from "./type";

const initialState = {
  loader: { isShow: false, content: null },
  isConnectionErrorPopupShow: false,
  isErrorPopupShow: false,
  isError404PopupShow: false,
  forbiddenErrorPopup: {
    isShow: false,
    message: null,
  },
};

function page(state = initialState, action = {}) {
  switch (action.type) {
    case CONNECTION_ERROR_POPUP_SHOWED:
      return { ...state, isConnectionErrorPopupShow: true };
    case CONNECTION_ERROR_POPUP_HID:
      return { ...state, isConnectionErrorPopupShow: false };
    case ERROR_POPUP_SHOWED:
      return { ...state, isErrorPopupShow: true };
    case ERROR_POPUP_HID:
      return { ...state, isErrorPopupShow: false };
    case ERROR_404_POPUP_SHOWED:
      return { ...state, isError404PopupShow: true };
    case ERROR_404_POPUP_HID:
      return { ...state, isError404PopupShow: false };
    case FORBIDDEN_ERROR_POPUP_SHOWED:
      return {
        ...state,
        forbiddenErrorPopup: { isShow: true, message: action.message },
      };
    case FORBIDDEN_ERROR_POPUP_HID:
      return { ...state, forbiddenErrorPopup: { isShow: false, message: null } };
    case LOADER_SHOWED:
      return { ...state, loader: { isShow: true, content: action.content } };
    case LOADER_HID:
      return { ...state, loader: { isShow: false, content: null } };
    default:
      return state;
  }
}

export default page;
