import React from "react";
import PropTypes from "prop-types";
import ModalPortal from "components/ModalPortal";
import styled from "styled-components";
import cx from "classnames";

const Modal = styled.div`
  ${(props) => props.customWidth && "max-width: " + props.customWidth + ";"}
`;

class SimpleModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.body.classList.add("modal-open");
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  render() {
    const { size = "md", customWidth = null, zIndex = 1050 } = this.props;
    return (
      <ModalPortal>
        <div className="modal-backdrop fade show"></div>

        <div
          className="modal fade show"
          tabIndex="-1"
          style={{ paddingRight: "12px", display: "block", zIndex }}
          aria-modal="true"
        >
          <Modal
            className={cx(
              "modal-dialog modal-dialog-top",
              customWidth === null && "modal-" + size
            )}
            customWidth={customWidth}
            role="document"
          >
            <div className="modal-content">
              {this.props.title && (
                <div className="modal-header bg-light">
                  <h5 className="modal-title">{this.props.title}</h5>
                  <button
                    data-testid="SimpleModal__closeButton"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.props.onClose}
                  >
                    <em className="icon ni ni-cross-fill-c text-danger"></em>
                  </button>
                </div>
              )}
              <div className="modal-body">{this.props.children}</div>
            </div>
          </Modal>
        </div>
      </ModalPortal>
    );
  }
}

SimpleModal.propTypes = {};

export default SimpleModal;
