import React from "react";
import PropTypes from "prop-types";
import Select from "components/Form/Select";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import lessonAPI from "api/school/lesson";

class LessonFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      lessons: [],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      lessonAPI.get(false).then((data) =>
        this.setState({
          isLoading: false,
          lessons: data.map((lesson) => ({
            id: lesson.id,
            name: lesson.name,
          })),
        })
      );
    }, 300);
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <SpinnerGrow variant="warning" />
        </div>
      );
    }
    return (
      <div className="form-group">
        <Select {...this.props}>
          <option value="">Semua Pelajaran</option>
          {this.state.lessons.map((lesson) => (
            <option key={lesson.id} value={lesson.id}>
              {lesson.name}
            </option>
          ))}
        </Select>
      </div>
    );
  }
}

export default LessonFilter;
