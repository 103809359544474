import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const FormGroup = ({ children, className = null, ...props }) => (
  <div className={cx("form-group", className)} {...props}>
    {children}
  </div>
);

FormGroup.propTypes = {};

export default FormGroup;
