import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const NioIcon = ({ name, className = null, ...props }) => (
  <em className={cx(className, "icon", "ni", "ni-" + name)} {...props} />
);

NioIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default NioIcon;
