import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { userLoggedIn } from "redux/auth/action";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import moment from "moment-timezone";
import "moment/locale/id";
import ReactGA from "react-ga";
import env from "domain/Environment/Env";
import { ErrorReport, ErrorMessageReport } from "domain/Error/ErrorReport";
import PageError from "components/Error/PageError";
import ErrorBoundary from "components/Error/ErrorBoundary";

env.check();

// Handle global error
if (env.isDev() || env.isProd()) {
  window.onerror = function (message, url, lineNo, columnNo, error) {
    if (error instanceof Error) {
      ErrorReport(error);
    } else {
      ErrorMessageReport(`${url} - on ${lineNo},${columnNo}: ${message}`);
    }
    return false;
  };
  window.onunhandledrejection = function(e) {
    ErrorMessageReport(`Unhandled rejection (promise: ${e.promise}, reason: ${e.reason}).`);
  }
}

// set timezone and moment lang
moment.locale("id");
const localTimezone = moment.tz.guess();
const indonesianTimezone = ["Asia/Jakarta", "Asia/Makassar", "Asia/Jayapura"];
if (indonesianTimezone.includes(localTimezone)) {
  moment.tz.setDefault(localTimezone);
} else {
  moment.tz.setDefault(process.env.REACT_APP_DEFAULT_TIMEZONE);
}

// relogin user
const auth = localStorage.getItem("auth");
if (auth) {
  store.dispatch(userLoggedIn(JSON.parse(auth)));
}

// Google Analytic
if (env.isProd()) {
  ReactGA.initialize("UA-158686248-11");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

document.getElementById("app-loader").remove();

ReactDOM.render(
  <ErrorBoundary fallback={<PageError />}>
    <BrowserRouter>
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
