import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import { useSelector } from "react-redux";
import ManageCategoryModal from "./ManageCategoryModal";
import IconButton from "components/Button/IconButton";
import ReactTooltip from "react-tooltip";

export default function ManageCategoryButton(props) {
  const isCategoriesFetched = useSelector(
    (state) => state.createQuestionBank.isCategoriesFetched
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const [isModalShow, setModalShow] = useState(false);
  return (
    <>
      <IconButton
        icon="setting"
        block
        onClick={() => setModalShow(true)}
        disabled={!isCategoriesFetched}
        data-tip="Kelola kategori"
      >
        {!isCategoriesFetched && <SpinnerGrow size="1rem" variant="white" />}
      </IconButton>

      {isModalShow && (
        <ManageCategoryModal onClose={() => setModalShow(false)} />
      )}
    </>
  );
}
