import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Datatable from "components/Table/Datatable";
import env from "domain/Environment/Env";
import SpinnerEllipsis from "components/Spinner/SpinnerEllipsis";
import Block from "components/Block/Block";
import TypeFilter from "./Filter/TypeFilter";
import GradeFilter from "./Filter/GradeFilter";
import CategoryFilter from "./Filter/CategoryFilter";
import NioIcon from "components/Icon/NioIcon";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import Badge from "components/Badge/Badge";
import ClickOutsideAlerter from "components/Event/ClickOutsideAlerter";
import Link from "components/Link/Link";
import Button from "components/Button/Button";
import IconButton from "components/Button/IconButton";
import Alert from "components/Alert/Alert";

const Row = styled.tr`
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#ffebf3" : "inherit")};
`;

const ArrowIconContainer = styled.div`
  & > .ni:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const PAGE_LIMIT = 10;
const MAX_VISIBLE_PAGE_NUMBER = 3;

class BankQuestionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      filter: {
        search: null,
        question_type: null,
        grade_id: null,
        category_id: null,
      },
      selectedQuestions: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.viewModel.isLessonFetched === false &&
      this.props.viewModel.isLessonFetched === true
    ) {
      this.props.viewModel.fetch(
        this.state.filter,
        this.state.currentPage,
        PAGE_LIMIT
      );
    }

    if (
      prevProps.viewModel.isFetchLoading === true &&
      this.props.viewModel.isFetchLoading === false
    ) {
      ReactTooltip.rebuild();
    }
  }

  handleFilterChange = (key, value) => {
    this.setState(
      (state) => ({ filter: { ...state.filter, [key]: value } }),
      () => {
        this.props.viewModel.fetch(this.state.filter, 1, PAGE_LIMIT);
      }
    );
  };

  handleSwitchPage = (pageNumber) => {
    this.setState({ currentPage: pageNumber, selectedQuestions: [] }, () => {
      this.props.viewModel.fetch(
        this.state.filter,
        this.state.currentPage,
        PAGE_LIMIT
      );
    });
  };

  handleQuestionClick = (question) => {
    this.setState((state) => {
      // unselect
      if (
        state.selectedQuestions.find(
          (selectedQuestion) => selectedQuestion.id === question.id
        ) !== undefined
      ) {
        return {
          ...state,
          selectedQuestions: state.selectedQuestions.filter(
            (selectedQuestion) => selectedQuestion.id !== question.id
          ),
        };
      }

      return {
        ...state,
        selectedQuestions: [...state.selectedQuestions, { ...question }],
      };
    });
  };

  selectAll = () => {
    if (this.state.selectedQuestions.length > 0) {
      this.setState({
        selectedQuestions: [],
      });
    } else {
      this.setState((state) => ({
        ...state,
        selectedQuestions: [
          ...state.selectedQuestions,
          ...this.props.viewModel.questions.filter(
            (question) =>
              this.state.selectedQuestions.find(
                (selectedQuestion) => selectedQuestion.id === question.id
              ) === undefined // yang sudah terpilih jangan dipilih lagi
          ),
        ],
      }));
    }
  };

  handleAdd = () => {
    this.state.selectedQuestions.forEach((question) =>
      this.props.viewModel.addSelectedQuestion(question)
    );
    this.setState({ selectedQuestions: [] });
  };

  render() {
    const { currentPage } = this.state;
    const { viewModel } = this.props;
    return (
      <>
        <Block>
          <h5>Bank Soal</h5>
          {viewModel.isLessonFetched && (
            <span className="text-soft">
              Berikut adalah bank soal di pelajaran {viewModel.lessonName}
            </span>
          )}
        </Block>

        <Block className="d-flex align-items-center justify-content-center">
          <ClickOutsideAlerter
            when={true}
            onClickOutside={() => {
              // TODO: fitur deselect ketika onBlur ini tidak jalan entah kenapa
              this.setState({ selectedQuestions: [] });
            }}
          >
            <Block>
              <div className="row gy-2 gx-1">
                <div className="col-4">
                  <TypeFilter
                    onChange={(e) =>
                      this.handleFilterChange("question_type", e.target.value)
                    }
                  />
                </div>
                <div className="col-4">
                  <GradeFilter
                    onChange={(e) =>
                      this.handleFilterChange("grade_id", e.target.value)
                    }
                  />
                </div>
                <div className="col-4">
                  <CategoryFilter
                    onChange={(e) =>
                      this.handleFilterChange("category_id", e.target.value)
                    }
                  />
                </div>
                <div className="col-6">
                  <Button
                    variant="light"
                    size="sm"
                    onClick={this.selectAll}
                    block
                  >
                    {this.state.selectedQuestions.length > 0
                      ? `Batal Tandai (${this.state.selectedQuestions.length})`
                      : "Tandai Semua"}
                  </Button>
                </div>
                <div className="col-6">
                  {/* <ArrowIconContainer className="ml-2">
                    <NioIcon
                      name="forward-arrow-fill text-primary ml-2 mr-2"
                      style={{ fontSize: "50px" }}
                      onClick={this.handleAdd}
                    />
                  </ArrowIconContainer> */}
                  <IconButton
                    icon="arrow-right"
                    position="right"
                    variant="primary"
                    size="sm"
                    onClick={this.handleAdd}
                    block
                  >
                    Pilih
                  </IconButton>
                </div>
              </div>
            </Block>
            <Block className="pt-2">
              <Datatable
                compact
                onSearch={this.handleSearch}
                onSwitchPage={this.handleSwitchPage}
                minHeight="300px"
                pagination={{
                  currentPage,
                  totalPage: viewModel.totalPage,
                  maxVisiblePage: MAX_VISIBLE_PAGE_NUMBER,
                }}
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Pertanyaan</th>
                    <th style={{ width: "150px" }}>Jenis</th>
                  </tr>
                </thead>
                <tbody>
                  {!viewModel.isFetchLoading ? (
                    viewModel.questions.length > 0 ? (
                      viewModel.questions.map((question) => (
                        <Row
                          key={question.id}
                          selected={
                            this.state.selectedQuestions.find(
                              (selectedQuestion) =>
                                selectedQuestion.id === question.id
                            ) !== undefined
                          }
                          onClick={(e) => this.handleQuestionClick(question)}
                        >
                          <td className="text-nowrap text-truncate">
                            <span data-tip={question.text_content}>
                              {viewModel.selectedQuestions.filter(
                                ({ id }) => id === question.id
                              ).length > 0 && (
                                <Badge variant="info" className="mr-1">
                                  {
                                    viewModel.selectedQuestions.filter(
                                      ({ id }) => id === question.id
                                    ).length
                                  }
                                </Badge>
                              )}
                              {/* TODO: kalau halaman ini sudah dibuat, arahkan ke yang baru */}
                              <Link
                                target="_blank"
                                to={
                                  env.isProd()
                                    ? `https://guru.edulogy.id/teacher/administration/question/${question.id}`
                                    : `https://guru.dev.edulogy.id/teacher/administration/question/${question.id}`
                                }
                              >
                                {question.text_content}
                              </Link>
                            </span>
                          </td>
                          <td className="text-nowrap text-truncate">
                            {question.formated_type}
                          </td>
                        </Row>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          Tidak ada data untuk ditampilkan.
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        <SpinnerEllipsis variant="warning" size="1.5rem" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Datatable>
            </Block>
          </ClickOutsideAlerter>
        </Block>
      </>
    );
  }
}

BankQuestionTable.propTypes = {};

export default BankQuestionTable;
