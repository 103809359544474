/**
 * Re-render math equation on dynamic component.
 *
 * USAGE:
 *  componentDidUpdate() {
 *    // re-render math equation using mathjax
 *    typeset(() => this.node.current);
 *  }
 *
 * @see http://docs.mathjax.org/en/latest/web/typeset.html
 */
export default function typeset(code) {
  if (!window.MathJax) {
    // MathJax not loaded. Probably user connection is unstable
    return;
  }

  window.MathJax.startup.promise = window.MathJax.startup.promise
    .then(() => {
      code();
      return window.MathJax.typesetPromise();
    })
    .catch((err) => console.log("Typeset failed: " + err.message));
  return window.MathJax.startup.promise;
}
