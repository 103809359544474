import React from "react";
import PropTypes from "prop-types";
import examResultAPI from "api/academic/examResult";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import FormGroup from "components/Form/FormGroup";
import Label from "components/Form/Label";
import Select from "components/Form/Select";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import Block from "components/Block/Block";
import { withRouter } from "react-router-dom";
import PrettyFloat from "domain/Float/PrettyFloat";

class StudentSelector extends React.Component {
  constructor(props) {
    super(props);
    this.schoolAcademicId = this.props.match.params.schoolAcademicId;
    this.examId = this.props.match.params.examId;
    this.currentStudentId = this.props.match.params.studentId;
    this.state = {
      data: [],
      isDataFetched: false,
    };
  }

  componentDidMount() {
    this.setState({ isDataFetched: false });
    examResultAPI
      .get({
        examId: this.examId,
        has_submission: null,
        has_score: null,
      })
      .then((data) => {
        setTimeout(() => this.setState({ data, isDataFetched: true }), 500);
      });
  }

  handleChange = (e) => {
    this.props.history.push(
      `/class/${this.schoolAcademicId}/academic/exam/${this.examId}/student/${e.target.value}/submission`
    );
  };

  render() {
    if (!this.state.isDataFetched) {
      return (
        <>
          <Block className="p-3">
            <SpinnerGrow />
          </Block>
          <Block className="p-5">
            <SpinnerGrow />
          </Block>
        </>
      );
    }
    return (
      <>
        <Block>
          <Row>
            <Col size="4">
              <FormGroup>
                <Label>Pindah siswa</Label>
                <Select
                  onChange={this.handleChange}
                  defaultValue={this.currentStudentId}
                >
                  {this.state.data.map((row) => (
                    <option key={row.student.id} value={row.student.id}>
                      {row.student.name} - (
                      {row.final_score ? PrettyFloat(row.final_score) : "-"}) -{" "}
                      {row.total_submissions}x
                    </option>
                  ))}
                </Select>
              </FormGroup>
            </Col>
          </Row>
        </Block>

        {this.props.render(
          this.state.data.find((row) => row.student.id == this.currentStudentId)
        )}
      </>
    );
  }
}

export default withRouter(StudentSelector);
