import React, { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import NioIcon from "components/Icon/NioIcon";
import FloatModal from "components/Modal/FloatModal";
import { connect, useDispatch, useSelector } from "react-redux";
import { deleteQuestion } from "redux/createQuestionBank/action";
import { DataContext } from "./CardContext";
import Button from "components/Button/Button";

const DeleteButton = (props) => {
  const dispatch = useDispatch();

  const { question } = useContext(DataContext);

  const [isModalShow, setModalShow] = useState(false);
  const buttonRef = useRef();

  return (
    <>
      <Button
        variant="lighter"
        className="text-base"
        size="sm"
        onClick={(e) => {
          e.preventDefault();
          setModalShow(true);
        }}
        forwardedRef={buttonRef}
      >
        <NioIcon name="trash" />
        <span>Hapus</span>
      </Button>

      {isModalShow && (
        <FloatModal
          title="Konfirmasi Hapus"
          position="left"
          parentRef={buttonRef}
          onClose={() => setModalShow(false)}
        >
          <div className="row gy-4">
            <div className="col-sm-12">Anda akan menghapus soal.</div>

            <div className="col-sm-6">
              <button
                type="button"
                className="btn btn-sm btn-light btn-block"
                onClick={() => setModalShow(false)}
              >
                <span>Batal</span>
              </button>
            </div>
            <div className="col-sm-6">
              <button
                type="button"
                className="btn btn-sm btn-danger btn-block"
                onClick={() => dispatch(deleteQuestion(question.uuid))}
              >
                <span>Hapus Soal</span>
              </button>
            </div>
          </div>
        </FloatModal>
      )}
    </>
  );
};

export default connect(null, {})(DeleteButton);
