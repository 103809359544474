import React from "react";
import PropTypes from "prop-types";
import ModalPortal from "components/ModalPortal";
import styled from "styled-components";

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0.8;
  background: #d9e1ef;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

class Disabler extends React.Component {
  render() {
    return (
      <>
        <Backdrop />
      <Container>
        <div className="nk-content ">
          <div className="nk-block nk-block-middle wide-xs mx-auto">
            <div className="nk-block-content nk-error-ld text-center">
              <h1 className="nk-error-head">
                <em className="ni ni-lock-alt-fill"></em>
              </h1>
              <h3 className="nk-error-title">Ulangan Dikunci</h3>
              <p className="nk-error-text">
                Selama status <code>aktif</code> ulangan tidak bisa diubah.
                <br />
                Ubah status bila ingin membuka kunci.
              </p>
            </div>
          </div>
        </div>
      </Container>
      </>
    );
  }
}

Disabler.propTypes = {};

export default Disabler;
