import { FETCHED } from "./type";
import produce from "immer";

const initialState = null;

const grades = produce((state, action = {}) => {
  if (state === undefined) return initialState;

  switch (action.type) {
    case FETCHED:
      return action.grades;
  }
});

export default grades;
