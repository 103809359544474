import React from "react";
import PropTypes from "prop-types";
import Alert from "./Alert";

const AlertPro = ({ children, className = null, ...props }) => (
  <Alert className="alert-pro" {...props}>
    <div className="alert-text">{children}</div>
  </Alert>
);

AlertPro.propTypes = {

  className: PropTypes.string,
};

export default AlertPro;
