import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FloatModal from "components/Modal/FloatModal";
import Label from "components/Form/Label";
import { updateSection } from "redux/exam/action";
import Alert from "components/Alert/Alert";
import SpinnerGrow from "components/Spinner/SpinnerGrow";

class ExamSectionEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      isEditModalShow: false,
      isLoading: false,
      errors: [],
      data: {
        id: this.props.section.id,
        title: this.props.section.title,
        description: this.props.section.description,
      },
    };
  }

  handleChange = (e) => {
    e.persist();
    this.setState((state) => ({
      data: { ...state.data, [e.target.name]: e.target.value },
    }));
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    this.props
      .updateSection(this.state.data)
      .then(() => {
        this.closeEditModal();
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            this.setState({
              errors: err.response.data.error,
              isLoading: false,
            });
          }
        }
      });
  };

  openEditModal = (e) => {
    e.stopPropagation();
    this.setState({ isEditModalShow: true });
  };
  closeEditModal = () => {
    this.setState({ isEditModalShow: false });
  };

  render() {
    return (
      <>
        <span className="mr-1" onClick={this.openEditModal} ref={this.ref}>
          <em className="ni ni-edit text-primary" />
        </span>

        {this.state.isEditModalShow && (
          <FloatModal
            title="Ubah Bagian"
            position="left"
            parentRef={this.ref}
            onClose={this.closeEditModal}
          >
            {this.state.errors.length > 0 && (
              <div className="mb-2">
                <Alert icon="info" variant="warning">
                  <strong>Gagal menyimpan karena hal berikut:</strong>
                  {this.state.errors.map((err, index) => {
                    return (
                      <div key={index}>
                        <em className="icon ni ni-bullet-fill" />{" "}
                        <span>{err.message}</span>
                      </div>
                    );
                  })}
                </Alert>
              </div>
            )}
            <div className="row gy-4" onClick={(e) => e.stopPropagation()}>
              <div className="col-sm-12">
                <div className="form-group">
                  <Label>Judul</Label>
                  <div className="form-control-wrap">
                    <input
                      name="title"
                      type="text"
                      className="form-control"
                      defaultValue={this.props.section.title}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <button
                  type="button"
                  className="btn btn-sm btn-light btn-block"
                  onClick={this.closeEditModal}
                >
                  <span>Batal</span>
                </button>
              </div>
              <div className="col-sm-6">
                {this.state.isLoading ? (
                  <div className="text-center">
                    <SpinnerGrow size="1.2rem" />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm btn-primary btn-block"
                    onClick={this.handleSubmit}
                  >
                    <span>Ubah</span>
                  </button>
                )}
              </div>
            </div>
          </FloatModal>
        )}
      </>
    );
  }
}

ExamSectionEditForm.propTypes = {};

export default connect(null, { updateSection })(ExamSectionEditForm);
