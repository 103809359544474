import React from 'react';
import PropTypes from 'prop-types';

const EmptyRow = (props) => (
  <tr>
    <td colSpan={5} className="text-center">Tidak ada data untuk ditampilkan.</td>
  </tr>
);

export default EmptyRow;
