const prefix = 'Bank/Question/Create/';

export const CLEARED = prefix + 'cleared';

export const QUESTION_INITIALIZED = prefix + 'questionInitialized';

export const QUESTION_INITIALIZE_FROM_PREVIOUS_SETTING = prefix + 'questionInitializedFromPreviousSetting';

export const QUESTION_INITIALIZE_FROM_PASTE = prefix + 'questionInitializeFromPaste';

export const QUESTION_TYPE_CHANGED = prefix + 'questionTypeChanged';

export const QUESTION_UPDATED = prefix + 'questionUpdated';

export const QUESTION_DELETED = prefix + 'questionDeleted';

export const QUESTION_SAVED = prefix + 'questionSaved';

export const QUESTION_DUPLICATED = prefix + 'questionDuplicated';

export const QUESTION_ANSWER_INITIALIZED = prefix + 'questionAnswerInitialized';

export const QUESTION_ANSWER_UPDATED = prefix + 'questionAnswerUpdated';

export const QUESTION_ANSWER_DELETED = prefix + 'questionAnswerDeleted';

export const QUESTION_ATTACHMENT_ADDED = prefix + 'questionAttachmentAdded';

export const QUESTION_ATTACHMENT_DELETED = prefix + 'questionAttachmentDeleted';

export const QUESTION_CATEGORY_FETCHED = prefix + 'questionCategoryFetched';

export const QUESTION_CATEGORY_ADDED = prefix + 'questionCategoryAdded';

export const QUESTION_CATEGORY_UPDATED = prefix + 'questionCategoryUpdated';

export const QUESTION_CATEGORY_DELETED = prefix + 'questionCategoryDeleted';

export const QUESTION_TYPES_FETCHED = prefix + 'questionTypesFetched';
