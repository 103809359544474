import {
  EXAM_FETCHED,
  EXAM_INITIALIZED,
  EXAM_CREATED,
  EXAM_UPDATED,
  EXAM_STATUS_CHANGED,
  EXAM_CLEARED,
  SECTION_CREATED,
  SECTION_UPDATED,
  SECTION_DELETED,
  QUESTION_IMPORTED,
  QUESTION_INITIALIZED,
  QUESTION_SAVED,
  QUESTION_SAVED_IN_SERVER,
  QUESTION_SAVED_IN_SERVER_FAILED,
  QUESTION_DELETED,
  QUESTION_SWITCHED,
  ANSWER_INITIALIZED,
  ANSWER_SAVED,
  ANSWER_SAVED_IN_SERVER,
  ANSWER_SAVED_IN_SERVER_FAILED,
  ANSWER_DELETED,
  ATTACHMENT_SAVED,
  ATTACHMENT_DELETED,
} from "./type";
import { v4 as uuidv4 } from "uuid";
import flatten from "lodash/flatten";
import omit from "lodash/omit";
import without from "lodash/without";
import values from "lodash/values";
import mapValues from "lodash/mapValues";

const initialExam = {
  id: null,
  title: "",
  description: "",
  instruction: "",
  type: null,
  setting: {
    token: "",
    devices: [],
    start_at: null,
    finish_at: null,
    total_durations: null,
    total_attempts: null,
    status: "inactive",
    security_mode: "open",
    random_question: false,
    random_answer: false,
    show_score: false,
    show_submission: false,
  },
};

const initialSection = {
  id: null,
  title: null,
  description: null,
  questions: [],
};

const initialQuestionPoint = "1.00";
const initialQuestion = {
  multichoice: {
    uuid: null,
    id: null,
    sectionId: null,
    type: "multichoice",
    point: initialQuestionPoint,
    content: null,
    attachments: [],
  },
  multianswer: {
    uuid: null,
    id: null,
    sectionId: null,
    type: "multianswer",
    point: initialQuestionPoint,
    content: null,
    scoring_mode: "allornothing",
    attachments: [],
  },
  truefalse: {
    uuid: null,
    id: null,
    sectionId: null,
    type: "truefalse",
    point: initialQuestionPoint,
    content: null,
    attachments: [],
  },
  essay: {
    uuid: null,
    id: null,
    sectionId: null,
    type: "essay",
    point: initialQuestionPoint,
    content: null,
    attachments: [],
  },
};

const initialAnswer = {
  multichoice: {
    uuid: null,
    id: null,
    questionUUID: null,
    key: false,
    content: null,
  },
  multianswer: {
    uuid: null,
    id: null,
    questionUUID: null,
    key: false,
    content: null,
  },
  truefalse: {
    uuid: null,
    questionUUID: null,
    answer: null,
  },
  essay: {
    uuid: null,
    questionUUID: null,
    content: null,
  },
};

const initialState = {
  schoolAcademicId: null,
  currentQuestion: { sectionId: null, uuid: null },
  sectionOrder: [],
  questionOrder: {},
  answerOrder: {},
  isQuestionsSavedInServer: {},
  isAnswersSavedInServer: {},
  data: {
    exam: initialExam,
    sections: {},
    questions: {},
    answers: {},
  },
};

function exam(state = initialState, action) {
  switch (action.type) {
    case EXAM_FETCHED:
      const questionsFlatten = flatten(
        action.exam.sections.map((section) =>
          section.questions.map((question) =>
            omit({
              ...question,
              uuid: uuidv4(),
              sectionId: section.id,
            })
          )
        )
      );
      const questions = questionsFlatten.reduce(
        (accumulator, question) => ({
          ...accumulator,
          [question.uuid]: question,
        }),
        {}
      );

      let answersGroupedByQuestion = questionsFlatten
        .filter(({ type }) => type === "multichoice" || type === "multianswer")
        .reduce(
          (accumulator, question) => ({
            ...accumulator,
            [question.uuid]: question.choices.map((choice) => ({
              ...initialAnswer[question.type],
              ...choice,
              uuid: uuidv4(),
              questionUUID: question.uuid,
            })),
          }),
          {}
        );
      answersGroupedByQuestion = {
        ...answersGroupedByQuestion,
        ...questionsFlatten
          .filter(({ type }) => type === "truefalse")
          .reduce(
            (accumulator, question) => ({
              ...accumulator,
              [question.uuid]: [
                {
                  ...initialAnswer[question.type],
                  uuid: uuidv4(),
                  questionUUID: question.uuid,
                  id: question.answer.id,
                  answer: question.answer.answer,
                },
              ],
            }),
            {}
          ),
      };
      answersGroupedByQuestion = {
        ...answersGroupedByQuestion,
        ...questionsFlatten
          .filter(({ type }) => type === "essay")
          .reduce(
            (accumulator, question) => ({
              ...accumulator,
              [question.uuid]: [
                {
                  ...initialAnswer[question.type],
                  uuid: uuidv4(),
                  questionUUID: question.uuid,
                  id: question.answer.id,
                  content: question.answer.answer,
                },
              ],
            }),
            {}
          ),
      };

      const sectionOrder = action.exam.sections.map(({ id }) => id);
      const questionOrder = action.exam.sections.reduce(
        (accumulator, section) => ({
          ...accumulator,
          [section.id]: questionsFlatten
            .filter(({ sectionId }) => sectionId === section.id)
            .map(({ uuid }) => uuid),
        }),
        {}
      );

      const firstQuestionUUID = sectionOrder.reduce(
        (accumulator, sectionId) => [
          ...accumulator,
          ...questionOrder[sectionId],
        ],
        []
      )[0];

      return {
        ...initialState,
        schoolAcademicId: parseInt(action.schoolAcademicId),
        currentQuestion: {
          sectionId: firstQuestionUUID
            ? questions[firstQuestionUUID].sectionId
            : sectionOrder[0],
          uuid: firstQuestionUUID || null,
        },
        sectionOrder,
        questionOrder,
        answerOrder: mapValues(answersGroupedByQuestion, (answers) =>
          answers.map((answer) => answer.uuid)
        ),
        isQuestionsSavedInServer: questionsFlatten.reduce(
          (accumulator, question) => ({
            ...accumulator,
            [question.uuid]: true,
          }),
          {}
        ),
        isAnswersSavedInServer: flatten(
          values(answersGroupedByQuestion)
        ).reduce(
          (accumulator, answer) => ({
            ...accumulator,
            [answer.uuid]: true,
          }),
          {}
        ),
        data: {
          exam: omit(action.exam, "sections"),
          sections: action.exam.sections.reduce(
            (accumulator, section) => ({
              ...accumulator,
              [section.id]: omit(section, "questions"),
            }),
            {}
          ),
          questions,
          answers: flatten(values(answersGroupedByQuestion)).reduce(
            (accumulator, answer) => ({
              ...accumulator,
              [answer.uuid]: { ...answer },
            }),
            {}
          ),
        },
      };
    case EXAM_INITIALIZED:
      return {
        schoolAcademicId: action.schoolAcademicId,
        ...initialState,
      };
    case EXAM_CREATED:
      return {
        ...initialState,
        schoolAcademicId: parseInt(action.schoolAcademicId),
        currentQuestion: { sectionId: action.section.id, uuid: null },
        sectionOrder: [action.section.id],
        questionOrder: { [action.section.id]: [] },
        data: {
          exam: { ...initialExam, ...action.exam },
          sections: {
            [action.section.id]: { ...initialSection, ...action.section },
          },
        },
      };
    case EXAM_UPDATED:
      return { ...state, data: { ...state.data, exam: { ...action.exam } } };
    case EXAM_STATUS_CHANGED:
      return {
        ...state,
        data: {
          ...state.data,
          exam: {
            ...state.data.exam,
            setting: { ...state.data.exam.setting, status: action.status },
          },
        },
      };
    case EXAM_CLEARED:
      return { ...initialState };
    case SECTION_CREATED:
      return {
        ...state,
        sectionOrder: [...state.sectionOrder, action.section.id],
        questionOrder: { ...state.questionOrder, [action.section.id]: [] },
        data: {
          ...state.data,
          sections: {
            ...state.data.sections,
            [action.section.id]: { ...initialSection, ...action.section },
          },
        },
      };
    case SECTION_UPDATED:
      return {
        ...state,
        data: {
          ...state.data,
          sections: {
            ...state.data.sections,
            [action.section.id]: {
              ...state.data.sections[action.section.id],
              ...action.section,
            },
          },
        },
      };
    case SECTION_DELETED:
      const questionUUIDWillDeleted = state.questionOrder[action.id];
      return {
        ...state,
        currentQuestion: action.currentQuestion
          ? action.currentQuestion
          : state.currentQuestion,
        sectionOrder: without(state.sectionOrder, action.id),
        questionOrder: omit(state.questionOrder, action.id),
        isQuestionsSavedInServer: omit(
          state.isQuestionsSavedInServer,
          questionUUIDWillDeleted
        ),
        data: {
          ...state.data,
          sections: omit(state.data.sections, action.id),
          questions: omit(state.data.questions, questionUUIDWillDeleted),
        },
      };
    case QUESTION_IMPORTED: {
      const question = {
        ...action.question,
        uuid: uuidv4(),
        sectionId: action.sectionId,
      };
      let answers = null;
      if (question.type === "multichoice" || question.type === "multianswer") {
        answers = question.choices.map((choice) => ({
          ...initialAnswer[question.type],
          ...choice,
          uuid: uuidv4(),
          questionUUID: question.uuid,
        }));
      } else if (question.type === "truefalse") {
        answers = [
          {
            ...initialAnswer[question.type],
            uuid: uuidv4(),
            questionUUID: question.uuid,
            id: question.answer.id,
            answer: question.answer.answer,
          },
        ];
      } else if (question.type === "essay") {
        answers = [
          {
            ...initialAnswer[question.type],
            uuid: uuidv4(),
            questionUUID: question.uuid,
            id: question.answer.id,
            content: question.answer.answer,
          },
        ];
      }

      return {
        ...state,
        questionOrder: {
          ...state.questionOrder,
          [question.sectionId]: [
            ...state.questionOrder[question.sectionId],
            question.uuid,
          ],
        },
        answerOrder: {
          ...state.answerOrder,
          [question.uuid]: answers.map((answer) => answer.uuid),
        },
        isQuestionsSavedInServer: {
          ...state.isQuestionsSavedInServer,
          [question.uuid]: true,
        },
        isAnswersSavedInServer: {
          ...state.isAnswersSavedInServer,
          ...answers.reduce(
            (accumulator, answer) => ({
              ...accumulator,
              [answer.uuid]: true,
            }),
            {}
          ),
        },
        data: {
          ...state.data,
          questions: {
            ...state.data.questions,
            [question.uuid]: { ...question },
          },
          answers: {
            ...state.data.answers,
            ...flatten(answers).reduce(
              (accumulator, answer) => ({
                ...accumulator,
                [answer.uuid]: { ...answer },
              }),
              {}
            ),
          },
        },
      };
    }
    case QUESTION_INITIALIZED:
      const questionUUID = action.generatedUUID;
      return {
        ...state,
        currentQuestion: { sectionId: action.sectionId, uuid: questionUUID },
        questionOrder: {
          ...state.questionOrder,
          [action.sectionId]: [
            ...state.questionOrder[action.sectionId],
            questionUUID,
          ],
        },
        isQuestionsSavedInServer: {
          ...state.isQuestionsSavedInServer,
          [questionUUID]: false,
        },
        answerOrder: {
          ...state.answerOrder,
          [questionUUID]: [],
        },
        data: {
          ...state.data,
          questions: {
            ...state.data.questions,
            [questionUUID]: {
              ...initialQuestion[action.questionType],
              uuid: questionUUID,
              sectionId: action.sectionId,
            },
          },
        },
      };
    case QUESTION_SAVED:
      return {
        ...state,
        isQuestionsSavedInServer: {
          ...state.isQuestionsSavedInServer,
          [action.uuid]: false,
        },
        data: {
          ...state.data,
          questions: {
            ...state.data.questions,
            [action.uuid]: {
              ...state.data.questions[action.uuid],
              ...action.question,
            },
          },
        },
      };
    case QUESTION_SAVED_IN_SERVER:
      return {
        ...state,
        isQuestionsSavedInServer: {
          ...state.isQuestionsSavedInServer,
          [action.uuid]: true,
        },
      };
    case QUESTION_SAVED_IN_SERVER_FAILED:
      return {
        ...state,
        isQuestionsSavedInServer: {
          ...state.isQuestionsSavedInServer,
          [action.uuid]: false,
        },
      };
    case QUESTION_DELETED:
      return {
        ...state,
        currentQuestion: action.questionSwitchedTo,
        questionOrder: {
          ...state.questionOrder,
          [action.sectionId]: without(
            state.questionOrder[action.sectionId],
            action.uuid
          ),
        },
        isQuestionsSavedInServer: omit(
          state.isQuestionsSavedInServer,
          action.uuid
        ),
        data: {
          ...state.data,
          questions: omit(state.data.questions, action.uuid),
        },
      };
    case QUESTION_SWITCHED:
      return {
        ...state,
        currentQuestion: {
          sectionId: action.sectionId,
          uuid: action.uuid,
        },
      };
    case ANSWER_INITIALIZED:
      const uuid = uuidv4();
      return {
        ...state,
        answerOrder: {
          ...state.answerOrder,
          [action.questionUUID]: [
            ...state.answerOrder[action.questionUUID],
            uuid,
          ],
        },
        isAnswersSavedInServer: {
          ...state.isAnswersSavedInServer,
          [uuid]: true,
        },
        data: {
          ...state.data,
          answers: {
            ...state.data.answers,
            [uuid]: {
              ...initialAnswer[action.questionType],
              uuid,
              questionUUID: action.questionUUID,
            },
          },
        },
      };
    case ANSWER_SAVED:
      return {
        ...state,
        isQuestionsSavedInServer: {
          ...state.isQuestionsSavedInServer,
          [state.data.answers[action.uuid].questionUUID]: false,
        },
        isAnswersSavedInServer: {
          ...state.isAnswersSavedInServer,
          [action.uuid]: false,
        },
        data: {
          ...state.data,
          answers: {
            ...state.data.answers,
            [action.uuid]: {
              ...state.data.answers[action.uuid],
              ...action.answer,
            },
          },
        },
      };
    case ANSWER_SAVED_IN_SERVER:
      return {
        ...state,
        isAnswersSavedInServer: {
          ...state.isAnswersSavedInServer,
          [action.uuid]: true,
        },
        data: {
          ...state.data,
          answers: {
            ...state.data.answers,
            [action.uuid]: {
              ...state.data.answers[action.uuid],
              id: action.id ? action.id : state.data.answers[action.uuid].id,
            },
          },
        },
      };
    case ANSWER_SAVED_IN_SERVER_FAILED:
      return {
        ...state,
        isAnswersSavedInServer: {
          ...state.isAnswersSavedInServer,
          [action.uuid]: false,
        },
      };
    case ANSWER_DELETED:
      return {
        ...state,
        answerOrder: {
          ...state.answerOrder,
          [action.questionUUID]: without(
            state.answerOrder[action.questionUUID],
            action.uuid
          ),
        },
        data: {
          ...state.data,
          answers: omit(state.data.answers, action.uuid),
        },
      };
    case ATTACHMENT_SAVED:
      return {
        ...state,
        data: {
          ...state.data,
          questions: {
            ...state.data.questions,
            [action.questionUUID]: {
              ...state.data.questions[action.questionUUID],
              attachments: [
                ...state.data.questions[action.questionUUID].attachments,
                action.attachment,
              ],
            },
          },
        },
      };
    case ATTACHMENT_DELETED:
      return {
        ...state,
        data: {
          ...state.data,
          questions: {
            ...state.data.questions,
            [action.questionUUID]: {
              ...state.data.questions[action.questionUUID],
              attachments: state.data.questions[
                action.questionUUID
              ].attachments.filter(({ id }) => id !== action.id),
            },
          },
        },
      };
    default:
      return state;
  }
}

export default exam;
