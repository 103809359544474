import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import IconButton from "components/Button/IconButton";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { clear } from "redux/createQuestionBank/action";
import {
  initQuestion,
  initQuestionFromPreviousSetting,
} from "redux/createQuestionBank/action";
import styled from "styled-components";
import PasteButton from "./Paste";
import ConfirmModal from "components/Modal/ConfirmModal";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  & > div {
    width: 800px;
    margin: 0 auto;
    margin-top: -0.5rem;
  }
`;

class Headline extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isConfirmExitShow: false, isLoading: false };
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  onExit = () => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.props.clear();
      this.props.history.push("/bank/question");
    }, 300);
  };

  render() {
    return (
      <>
        <Container className="nk-block">
          <div className="alert alert-info alert-pro border-white">
            <div className="alert-cta flex-wrap flex-md-nowrap">
              <div className="alert-text text-dark">
                <span className="h6 ff-base mb-0 p-0 fw-medium">Bank Soal</span>
              </div>
              <ul className="alert-actions gx-3 mt-3 mb-1 my-md-0">
                <li>
                  <IconButton
                    size="sm"
                    icon="plus"
                    variant="primary"
                    onClick={() => {
                      if (this.props.totalQuestion === 0) {
                        this.props.initQuestion();
                      } else {
                        this.props.initQuestionFromPreviousSetting();
                      }
                      this.props.onCardAdd();
                    }}
                  >
                    Tambah Soal
                  </IconButton>
                </li>
                <li>
                  <PasteButton />
                </li>
                <li>
                  <button
                    onClick={() => this.setState({ isConfirmExitShow: true })}
                    className="btn btn-sm btn-light"
                  >
                    Kembali
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </Container>

        {this.state.isConfirmExitShow && (
          <ConfirmModal
            isLoading={this.state.isLoading}
            variant="primary"
            acceptLabel="Keluar"
            onAccept={this.onExit}
            onCancel={() => this.setState({ isConfirmExitShow: false })}
            message={<span>Apakah yakin akan keluar?</span>}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  totalQuestion: state.createQuestionBank.questionOrder.length,
});

export default connect(mapStateToProps, {
  clear,
  initQuestion,
  initQuestionFromPreviousSetting,
})(withRouter(Headline));
