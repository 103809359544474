const fixedFloat = (_float, fixedCount) => {
  let floatAsString = _float;
  if (typeof _float !== "string") {
    floatAsString = _float.toString();
  }
  const [wholeNumber, decimalNumber] = floatAsString.split(".");

  if (!decimalNumber) return _float;

  const decimalNumberWithFixed = decimalNumber.substr(0, fixedCount);

  return parseFloat([wholeNumber, decimalNumberWithFixed].join("."));
};

export default function (number) {
  if (!number) return "";

  // remove unsignificant number, for example: 65.20 -> 65.2
  if (typeof number === "string" || number instanceof String) {
    number = parseFloat(number);
  }

  number = fixedFloat(number, 2);

  // TODO: change this if edulogy is available on another country.
  // change javascript decimal point (dot) to indonesian (comma), for example: 2.5 -> 2,5
  return number.toString().replace(".", ",");
}
