import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import ErrorBoundary from "components/Error/ErrorBoundary";
import PageError from "./Error/PageError";

let modalRoot = document.getElementById("modal-root");
if (!modalRoot) { // actually this is for testing only
  modalRoot = document.createElement("div");
  modalRoot.setAttribute("id", "modalRoot");
  document.body.appendChild(modalRoot);
}

class ModalPortal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      <ErrorBoundary fallback={PageError}>{this.props.children}</ErrorBoundary>,
      this.el
    );
  }
}

ModalPortal.propTypes = {};

export default ModalPortal;
