import axios from "axios";
import store from "redux/store";
import {
  showConnectionErrorPopup,
  showErrorPopup,
  show404Popup,
  showForbiddenErrorPopup,
} from "redux/page/action";
import { ErrorReport } from "domain/Error/ErrorReport";

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

// Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common['Authorization'] = `Bearer ${auth.access_token}`;

instance.interceptors.request.use(function (config) {
  const access_token = store.getState().auth.access_token;
  config.headers.Authorization = access_token ? `Bearer ${access_token}` : "";
  return config;
});
instance.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response) {
      if (err.response.status === 404) {
        // let client handle this
      } else if (err.response.status === 403) {
        store.dispatch(showForbiddenErrorPopup(err.response.data.message));
      } else if (err.response.status === 400) {
        store.dispatch(showErrorPopup());
        ErrorReport(err);
      } else if (err.response.status >= 500 && err.response.status <= 599) {
        store.dispatch(showErrorPopup());
        ErrorReport(err);
      } else {
        throw err;
      }
    } else if (!err.status) {
      store.dispatch(showConnectionErrorPopup());
      ErrorReport(err);
    } else if (err.request) {
      store.dispatch(showErrorPopup()); // The request was made but no response was received
      ErrorReport(err);
    } else {
      store.dispatch(showErrorPopup());
      ErrorReport(err);
    }
    return Promise.reject(err); // other code has dependency to this to know error happen.
  }
);

export default instance;
