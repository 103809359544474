import React from "react";
import PropTypes from "prop-types";
import FloatModal from "components/Modal/FloatModal";
import Label from "components/Form/Label";
import { createSection } from "redux/exam/action";
import { connect } from "react-redux";
import { showErrorPopup } from "redux/page/action";
import { PageContext } from "components/Page";
import Alert from "components/Alert/Alert";
import SpinnerGrow from "components/Spinner/SpinnerGrow";

class ExamSectionAddForm extends React.Component {
  static contextType = PageContext;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.inputRef = React.createRef();
    this.state = {
      isModalShow: false,
      data: { title: "Pilihan Ganda", description: "" },
      errors: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    // this.ref.current && this.ref.current.select();
  }

  showModal = () => this.setState({ isModalShow: true });
  closeModal = () => this.setState({ isModalShow: false });

  handleChange = (e) => {
    e.persist();
    this.setState((state) => ({
      data: { ...state.data, [e.target.name]: e.target.value },
    }));
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    this.props
      .createSection({
        exam_id: this.props.examId,
        title: this.state.data.title,
        description: this.state.data.description,
      })
      .then(() => this.setState({ isModalShow: false, isLoading: false }))
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            this.setState({
              errors: err.response.data.error,
              isLoading: false,
            });
          }
        }
      });
  };

  render() {
    const { errors } = this.state;
    return (
      <>
        {this.state.isModalShow && (
          <FloatModal
            title="Tambah Bagian"
            position="left"
            parentRef={this.inputRef}
            onClose={this.closeModal}
          >
            {errors.length > 0 && (
              <div className="nk-block nk-block-lg">
                <Alert icon="info" variant="warning">
                  <strong>Gagal menyimpan karena hal berikut:</strong>
                  {errors.map((err, index) => {
                    return (
                      <div key={index}>
                        <em className="icon ni ni-bullet-fill" />{" "}
                        <span>{err.message}</span>
                      </div>
                    );
                  })}
                </Alert>
              </div>
            )}
            <div className="row gy-4">
              <div className="col-sm-12">
                <div className="form-group">
                  <Label required>Judul</Label>
                  <div className="form-control-wrap">
                    <input
                      ref={this.ref}
                      type="text"
                      name="title"
                      className="form-control"
                      defaultValue={this.state.data.title}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <button
                  type="button"
                  className="btn btn-sm btn-light btn-block"
                  onClick={this.closeModal}
                >
                  <span>Batal</span>
                </button>
              </div>
              <div className="col-sm-6">
                {this.state.isLoading ? (
                  <div className="text-center">
                    <SpinnerGrow size="1.2rem" />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm btn-primary btn-block"
                    onClick={this.handleSubmit}
                  >
                    <span>Tambah Bagian</span>
                  </button>
                )}
              </div>
            </div>
          </FloatModal>
        )}

        <div className="text-center mt-3 mb-3">
          <button
            className="btn btn-primary btn-dim"
            onClick={this.showModal}
            ref={this.inputRef}
          >
            <em className="icon ni ni-plus-circle-fill"></em>{" "}
            <span>Tambah Bagian</span>
          </button>
        </div>
      </>
    );
  }
}

ExamSectionAddForm.propTypes = {};

const mapStateToProps = (state) => ({
  schoolAcademicId: state.exam.schoolAcademicId,
  examId: state.exam.data.exam.id,
});

export default connect(mapStateToProps, { createSection, showErrorPopup })(
  ExamSectionAddForm
);
