import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createExam, updateExam } from "redux/exam/action";
import ModalPortal from "components/ModalPortal";
import Label from "components/Form/Label";
import Select from "components/Form/ReactSelectWrapper";
import Switch from "components/Form/Switch";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import Alert from "components/Alert/Alert";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import { PageContext } from "components/Page";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import "react-datetime/css/react-datetime.css"; // TODO: pindahkanke index.js karena ini harus 1 kali
import keys from "lodash/keys";
import api from "api";
import materialAPI from "api/academic/material";
import ReactSelectWrapper from "components/Form/ReactSelectWrapper";

const Container = styled.div`
  & .form-note {
    font-size: 11px;
    font-style: normal;
    line-height: 1.2;
  }

  & .form-label {
    font-weight: normal;
  }

  & textarea {
    resize: none;
  }

  & .modal-content {
    border: none;
    /* box-shadow: none; */
  }
`;

export const EXAM_TYPE_PRETEST = "pretest";
export const EXAM_TYPE_POSTTEST = "posttest";

class ExamDetailForm extends React.Component {
  static contextType = PageContext;

  constructor(props) {
    super(props);
    this.schoolAcademicId = this.props.match.params.schoolAcademicId;
    const { sections, setting, ...exam } = this.props.exam;
    this.state = {
      exam: { ...exam, minimum_completion: undefined },
      minimum_completion_in_decimal:
        exam.minimum_completion !== null
          ? (exam.minimum_completion * 100).toString()
          : null,
      setting,
      academicClass: null,
      errors: [],
      isLoading: false,
      availablePrerequisiteExams: null,
      availablePrerequisiteMaterials: null,
    };
  }

  startLoading = () => this.setState({ isLoading: true });
  endLoading = () => this.setState({ isLoading: false });

  componentDidMount() {
    document.body.classList.add("modal-open");
    api.academicClass
      .findOne(this.schoolAcademicId)
      .then((data) => {
        this.setState({ academicClass: data });
      })
      .catch(() => {});
    api.exam
      .getExams(this.schoolAcademicId)
      .then((data) => {
        let availablePrerequisiteExams = data;
        const isEdit = Boolean(this.props.exam.id);
        if (isEdit) {
          availablePrerequisiteExams = availablePrerequisiteExams.filter(
            ({ id }) => id !== this.props.exam.id
          );
        }
        this.setState({ availablePrerequisiteExams });
      })
      .catch(() => {});
    materialAPI
      .get(this.schoolAcademicId)
      .then((data) => {
        this.setState({ availablePrerequisiteMaterials: data });
      })
      .catch(() => {});
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  handleDetailChange = (e) => {
    e.persist();
    this.setState((state) => ({
      exam: {
        ...state.exam,
        [e.target.name]: e.target.value.trim(),
      },
    }));
  };

  handleSettingChange = (e) => {
    if (e.target && e.target.name === "token") {
      const value = e.target.value;
      this.setState((state) => ({
        setting: {
          ...state.setting,
          token: value.trim() === "" ? null : value.trim(),
        },
      }));
    } else {
      const key = e.type === "switch" ? e.name : e.target.name;
      const value = e.type === "switch" ? e.value : e.target.value;
      this.setState((state) => ({
        setting: {
          ...state.setting,
          [key]: value === "" ? null : value,
        },
      }));
    }
  };

  handleDeviceSettingChange = (selecteds) => {
    const devices = Array.isArray(selecteds)
      ? selecteds.map((option) => option.value)
      : [];
    this.setState((state) => ({
      setting: { ...state.setting, devices },
    }));
    // aturan bisnis: security_mode boleh "closed" hanya jika perangkat
    // adalah hanya Android.
    if (devices.length > 1 && devices.includes("android")) {
      this.setState((state) => ({
        setting: { ...state.setting, security_mode: "open" },
      }));
    }
  };

  handleStartAtChange = (moment) => {
    if (typeof moment === "string") return;
    this.setState((state) => ({
      setting: {
        ...state.setting,
        start_at: moment.format("YYYY-MM-DD HH:mm:ss"),
      },
    }));
  };

  handleFinishAtChange = (moment) => {
    if (typeof moment === "string") return;
    this.setState((state) => ({
      setting: {
        ...state.setting,
        finish_at: moment.format("YYYY-MM-DD HH:mm:ss"),
      },
    }));
  };

  handleFailedSave = (err) => {
    if (err.response) {
      if (err.response.status === 422) {
        this.setState({ errors: err.response.data.error });
      }
      this.endLoading();
    }
  };

  submit = () => {
    this.startLoading();
    setTimeout(() => {
      const data = {
        ...this.state.exam,
        setting: this.state.setting,
        minimum_completion: this.state.minimum_completion_in_decimal
          ? (this.state.minimum_completion_in_decimal / 100).toFixed(2)
          : null,
      };
      if (this.props.exam.id) {
        this.props
          .updateExam({
            schoolAcademicId: this.schoolAcademicId,
            examId: data.id,
            data,
          })
          .then(() => this.props.onClose())
          .catch(this.handleFailedSave);
      } else {
        this.props
          .createExam({
            schoolAcademicId: this.schoolAcademicId,
            data,
          })
          .then((createdExam) => {
            this.props.history.push(
              `/class/${this.schoolAcademicId}/academic/exam/${createdExam.id}/edit`
            );
            this.props.onClose();
          })
          .catch(this.handleFailedSave);
      }
    }, 500);
  };

  render() {
    const { onClose } = this.props;
    const {
      exam,
      setting,
      errors,
      availablePrerequisiteExams,
      availablePrerequisiteMaterials,
    } = this.state;

    const isEdit = Boolean(this.props.exam.id);
    return (
      <ModalPortal>
        <div className="modal-backdrop fade show" />
        <Container className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-top modal-xl">
            <div className="modal-content">
              <div className="card card-bordered bg-lighter">
                <div className="card-inner">
                  {errors.length > 0 && (
                    <div className="nk-block nk-block-lg">
                      <Alert icon="info" variant="warning">
                        <strong>Gagal menyimpan karena hal berikut:</strong>
                        {errors.map((err, index) => {
                          return (
                            <div key={index}>
                              <em className="icon ni ni-bullet-fill" />{" "}
                              <span>{err.message}</span>
                            </div>
                          );
                        })}
                      </Alert>
                    </div>
                  )}
                  <div className="nk-block nk-block-lg">
                    <div className="nk-block-between-md g-4">
                      <div className="nk-block-head-content">
                        {!exam.id ? (
                          <Link to={`/academic/exam`} className="btn btn-light">
                            <em className="icon ni ni-arrow-left" />{" "}
                            <span>Kembali ke laman utama</span>
                          </Link>
                        ) : (
                          <button
                            className="btn btn-auto btn-light"
                            onClick={onClose}
                          >
                            <em className="icon ni ni-cross" />{" "}
                            <span>Batal Ubah</span>
                          </button>
                        )}
                      </div>
                      <div className="nk-block-head-content">
                        <div>
                          <span className="btn-group mr-2">
                            <span className="btn btn-sm btn-outline-secondary btn-dim">
                              {this.state.academicClass
                                ? this.state.academicClass.school_lesson.name
                                : "..."}
                            </span>
                          </span>
                          <span className="btn-group">
                            <span className="btn btn-sm btn-danger">
                              {this.state.academicClass
                                ? this.state.academicClass.school_class.name
                                : "..."}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="nk-block-head-content">
                        {!this.state.isLoading ? (
                          <button
                            className="btn btn-block btn-primary"
                            onClick={this.submit}
                          >
                            {exam.id ? "Simpan" : "Buat Ulangan"}
                          </button>
                        ) : (
                          <div className="text-center">
                            <SpinnerGrow size="2rem" variant="warning" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="nk-block nk-block-lg">
                    <div className="nk-block-between-md g-4">
                      <div className="nk-block-head-content">
                        <div className="card-head">
                          <h5 className="card-title">Detail Ulangan</h5>
                        </div>
                      </div>
                      <div className="nk-block-head-content"></div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label required>Judul Ulangan</Label>
                              <span className="form-note">
                                Ketikan judul ulangan Anda.
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="title"
                                  onChange={this.handleDetailChange}
                                  className="form-control"
                                  defaultValue={exam.title}
                                  maxLength="200"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label>Deskripsi</Label>
                              <span className="form-note">
                                Penjelasan singkat tentang ulangan.
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <textarea
                                  type="text"
                                  name="description"
                                  onChange={this.handleDetailChange}
                                  className="form-control"
                                  defaultValue={exam.description}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label required>Jenis</Label>
                              <span className="form-note">
                                Tentukan jenis ulangan.
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <select
                                    name="type"
                                    className="form-control"
                                    onChange={(e) => {
                                      const type = e.target.value;
                                      let default_minimum_completion = null;
                                      if (type === EXAM_TYPE_PRETEST) {
                                        default_minimum_completion = "80";
                                      }
                                      if (type === EXAM_TYPE_POSTTEST) {
                                        default_minimum_completion = "60";
                                      }
                                      this.setState((state) => ({
                                        ...state,
                                        exam: {
                                          ...state.exam,
                                          type,
                                        },
                                        minimum_completion_in_decimal:
                                          default_minimum_completion,
                                      }));
                                    }}
                                    defaultValue={exam.type}
                                    disabled={isEdit}
                                  >
                                    <option value="">- Pilih -</option>
                                    <option value="daily">
                                      Ulangan Harian
                                    </option>
                                    <option value={EXAM_TYPE_PRETEST}>
                                      Quiz Prakerja
                                    </option>
                                    <option value={EXAM_TYPE_POSTTEST}>
                                      Post Test Prakerja
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label>Instruksi</Label>
                              <span className="form-note">
                                Bagaimana cara mengerjakan?
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <textarea
                                  type="text"
                                  name="instruction"
                                  onChange={this.handleDetailChange}
                                  className="form-control"
                                  defaultValue={exam.instruction}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block nk-block-lg">
                    <div className="card-head">
                      <h5 className="card-title">Pengaturan</h5>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label required>Waktu Mulai</Label>
                              <span className="form-note">
                                Kapan siswa bisa mengerjakan?
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <Datetime
                                  dateFormat="DD MMM YYYY"
                                  timeFormat="\J\a\m HH:mm"
                                  inputProps={{ readOnly: true }}
                                  initialValue={this.state.setting.start_at}
                                  onChange={this.handleStartAtChange}
                                  isValidDate={(current) =>
                                    current.isAfter(moment().subtract(1, "day"))
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label required>Waktu Berakhir</Label>
                              <span className="form-note">
                                Batas pengerjaan ulangan.
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <Datetime
                                  dateFormat="DD MMM YYYY"
                                  timeFormat="\J\a\m HH:mm"
                                  inputProps={{ readOnly: true }}
                                  initialValue={this.state.setting.finish_at}
                                  onChange={this.handleFinishAtChange}
                                  isValidDate={(current) =>
                                    current.isAfter(moment().subtract(1, "day"))
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label>Token</Label>
                              <span className="form-note">
                                Untuk keamanan ulangan.
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="token"
                                  className="form-control"
                                  onChange={this.handleSettingChange}
                                  defaultValue={setting.token}
                                  maxLength="30"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label required>Total Durasi</Label>
                              <span className="form-note">
                                Berapa lama ulangan dikerjakan?
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <div className="form-text-hint">
                                  <span className="overline-title">Menit</span>
                                </div>
                                <input
                                  min="1"
                                  type="number"
                                  className="form-control"
                                  name="total_durations"
                                  onChange={this.handleSettingChange}
                                  defaultValue={setting.total_durations}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label required>Total Kesempatan</Label>
                              <span className="form-note">
                                Kesempatan siswa mengulang.
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <input
                                  min="1"
                                  max="255"
                                  type="number"
                                  className="form-control"
                                  name="total_attempts"
                                  onChange={this.handleSettingChange}
                                  defaultValue={setting.total_attempts}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label required>Perangkat</Label>
                              <span className="form-note">
                                Di perangkat apa saja siswa boleh mengerjakan?
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div
                              className="form-control-wrap"
                              style={{ marginTop: "-3px" }}
                            >
                              <Select
                                placeholder="Pilih Perangkat"
                                isMulti
                                options={[
                                  { value: "web", label: "Web" },
                                  { value: "android", label: "Android" },
                                ]}
                                defaultValue={setting.devices}
                                onChange={this.handleDeviceSettingChange}
                              />
                            </div>
                            <span className="form-note">
                              Untuk perangkat lain (iphone, window phone, dll)
                              silakan gunakan Web.
                            </span>
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label
                                required={
                                  exam.type === EXAM_TYPE_PRETEST ||
                                  exam.type === EXAM_TYPE_POSTTEST
                                }
                              >
                                Ketuntasan (%)
                              </Label>
                              <span className="form-note">
                                Hanya untuk jenis ulangan quiz dan post
                                test prakerja.
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <input
                                  min="1"
                                  max="100"
                                  type="number"
                                  step="1"
                                  className="form-control"
                                  name="minimum_completion_in_decimal"
                                  required={
                                    exam.type === EXAM_TYPE_PRETEST ||
                                    exam.type === EXAM_TYPE_POSTTEST
                                  }
                                  disabled={
                                    !(
                                      exam.type === EXAM_TYPE_PRETEST ||
                                      exam.type === EXAM_TYPE_POSTTEST
                                    )
                                  }
                                  value={
                                    this.state.minimum_completion_in_decimal !==
                                    null
                                      ? this.state.minimum_completion_in_decimal
                                      : ""
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value;

                                    const isFloat =
                                      value.includes(".") ||
                                      value.includes(",");
                                    const isEmpty = value === "";
                                    if (isFloat || isEmpty) {
                                      this.setState((state) => ({
                                        ...state,
                                        minimum_completion_in_decimal: null,
                                      }));
                                      return;
                                    }

                                    this.setState((state) => ({
                                      ...state,
                                      minimum_completion_in_decimal: value,
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="row g-3 align-top">
                          <div className="col-lg-8">
                            <div className="form-group">
                              <Label>Acak soal</Label>
                              <span className="form-note">
                                Lakukan acak soal oleh sistem?
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <Switch
                              name="random_question"
                              size="md"
                              offValue={false}
                              onValue={true}
                              onChange={this.handleSettingChange}
                              checked={setting.random_question === true}
                            />
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-8">
                            <div className="form-group">
                              <Label>Acak jawaban</Label>
                              <span className="form-note">
                                Lakukan acak jawaban oleh sistem?
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <Switch
                              name="random_answer"
                              offValue={false}
                              onValue={true}
                              onChange={this.handleSettingChange}
                              checked={setting.random_answer === true}
                            />
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-8">
                            <div className="form-group">
                              <Label>Tampilkan skor</Label>
                              <span className="form-note">
                                Apakah siswa boleh melihat nilai setelah
                                mengerjakan?
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <Switch
                              name="show_score"
                              offValue={false}
                              onValue={true}
                              onChange={this.handleSettingChange}
                              checked={setting.show_score === true}
                            />
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-8">
                            <div className="form-group">
                              <Label>Tampilkan pengerjaan</Label>
                              <span className="form-note">
                                Siswa bisa melihat pengerjaannya setelah selesai
                                ulangan?
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <Switch
                              size="md"
                              name="show_submission"
                              offValue={false}
                              onValue={true}
                              onChange={this.handleSettingChange}
                              checked={setting.show_submission === true}
                            />
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-8">
                            <div className="form-group">
                              <Label>
                                Kunci perangkat <small>(tutup buku)</small>
                              </Label>
                              <span className="form-note">
                                <code>
                                  Fitur ini hanya tersedia di Android.
                                </code>{" "}
                                <br />
                                <code>
                                  Mohon atur "perangkat" menjadi Android saja.
                                </code>
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <Switch
                                  name="security_mode"
                                  size="md"
                                  offValue={"open"}
                                  onValue={"close"}
                                  onChange={this.handleSettingChange}
                                  checked={setting.security_mode === "close"}
                                  disabled={
                                    !(
                                      setting.devices.length === 1 &&
                                      setting.devices[0] === "android"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nk-block nk-block-lg">
                    <div className="card-head">
                      <h5 className="card-title">Prasyarat Prakerja</h5>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label required={exam.type === EXAM_TYPE_PRETEST}>
                                Materi
                              </Label>
                              <span className="form-note">
                                Siswa wajib menyelesaikan materi tersebut.
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              {availablePrerequisiteMaterials !== null ? (
                                <Select
                                  isMulti
                                  name="prerequisite_material_ids"
                                  isDisabled={exam.type !== EXAM_TYPE_PRETEST}
                                  defaultValue={
                                    Array.isArray(
                                      exam.prerequisite_material_ids
                                    )
                                      ? exam.prerequisite_material_ids
                                      : []
                                  }
                                  onChange={(data) => {
                                    const prerequisite_material_ids =
                                      data === null
                                        ? []
                                        : data.map(({ value }) => value);
                                    this.setState((state) => ({
                                      exam: {
                                        ...state.exam,
                                        prerequisite_material_ids,
                                      },
                                    }));
                                  }}
                                  options={availablePrerequisiteMaterials.map(
                                    (material) => ({
                                      label: material.title,
                                      value: material.id,
                                    })
                                  )}
                                />
                              ) : (
                                <div className="text-center">
                                  <SpinnerGrow size="2rem" variant="warning" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row g-3 align-top">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <Label
                                required={exam.type === EXAM_TYPE_POSTTEST}
                              >
                                Ulangan
                              </Label>
                              <span className="form-note">
                                Siswa wajib lulus ulangan tersebut.
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              {availablePrerequisiteExams !== null ? (
                                <ReactSelectWrapper
                                  isMulti
                                  name="prerequisite_exam_ids"
                                  isDisabled={exam.type !== EXAM_TYPE_POSTTEST}
                                  defaultValue={
                                    Array.isArray(exam.prerequisite_exam_ids)
                                      ? exam.prerequisite_exam_ids
                                      : []
                                  }
                                  onChange={(data) => {
                                    const prerequisite_exam_ids =
                                      data === null
                                        ? []
                                        : data.map(({ value }) => value);
                                    this.setState((state) => ({
                                      exam: {
                                        ...state.exam,
                                        prerequisite_exam_ids,
                                      },
                                    }));
                                  }}
                                  options={availablePrerequisiteExams.map(
                                    (exam) => ({
                                      label: exam.title,
                                      value: exam.id,
                                    })
                                  )}
                                />
                              ) : (
                                <div className="text-center">
                                  <SpinnerGrow size="2rem" variant="warning" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </ModalPortal>
    );
  }
}

ExamDetailForm.propTypes = {};

const mapStateToProps = (state) => ({
  exam: state.exam.data.exam,
});

export default connect(mapStateToProps, { createExam, updateExam })(
  withRouter(ExamDetailForm)
);
