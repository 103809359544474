import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import questionBankAPI from "api/bank/question";
import { v4 as uuidv4 } from "uuid";

export default function useFetchQuestions({ filters, pageLimit }) {
  const [isFetched, setIsFetched] = useState(false);
  const [reloadUUID, setReloadUUID] = useState("");
  const [data, setData] = useState({
    totalPage: 1,
    questions: [],
  });

  const [axiosCancel, setAxiosCancel] = useState(null);
  useEffect(() => {
    if (axiosCancel === "function") {
      axiosCancel();
    }
    setIsFetched(false);
    questionBankAPI
      .get({
        ...filters,
        page: filters.currentPage,
        limit: pageLimit,
        cancel: (cancel) => setAxiosCancel(cancel),
      })
      .then((data) => {
        setIsFetched(true);
        setData({
          totalPage: data.total_pages,
          questions: data.questions.map((question) => ({
            id: question.id,
            type: question.type,
            formated_type: question.formated_type,
            point: question.point,
            text_content: question.text_content,
            total_shared: question.total_shared,
            last_shared_date: question.last_shared_date,
          })),
        });
      });
  }, [filters, pageLimit, reloadUUID]);

  return {
    isFetched,
    data,
    reload: () => setReloadUUID(uuidv4()),
  };
}

useFetchQuestions.propTypes = {};
