import React from "react";
import Card from "./Card";
import Block from "components/Block/Block";
import BankSVG from "./bank.svg";
import { useSelector } from "react-redux";

export default function CardList(props) {
  const questions = useSelector((state) =>
    state.createQuestionBank.questionOrder.map(
      (uuid) => state.createQuestionBank.questions[uuid]
    )
  );

  if (questions.length === 0) {
    return <EmptyCardList />;
  }
  return questions.map((question, index) => (
    <Block key={question.uuid}>
      <div className="position-relative">
        <span
          className="h5 ff-base rounded text-base bg-white p-1"
          style={{
            position: "absolute",
            left: "-50px",
            boxShadow: "0px 1px 3px #dbdfea",
            border: "1px solid #dbdfea",
          }}
        >
          {index + 1}.
        </span>
        <Card
          key={question.uuid}
          question={question}
          submitUUID={props.submitUUID}
        />
      </div>
    </Block>
  ));
}

function EmptyCardList(props) {
  return (
    <div className="card card-bordered">
      <div className="card-inner">
        <div className="nk-help">
          <div className="nk-help-img">
            <img src={BankSVG} />
          </div>
          <div className="nk-help-text">
            <h5>Belum ada soal ditambahkan</h5>
            <p className="text-soft">
              Tekan tombol <code>tambah</code> untuk menambahkan soal.
              <br />
              Atau, <code>paste</code> bila ingin mudah memindahkan soal dari
              file lain.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
