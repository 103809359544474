import React from "react";
import PropTypes from "prop-types";
import TableResponsive from "components/Table/TableResponsive";
import Table from "./Table";
import moment from "moment-timezone";
import LinkButton from "components/Button/LinkButton";
import ButtonGroup from "components/ButtonGroup/ButtonGroup";
import Block from "components/Block/Block";
import { withRouter } from "react-router-dom";
import submissionAPI from "api/academic/examSubmission";
import SpinnerEllipsis from "components/Spinner/SpinnerEllipsis";
import Button from "components/Button/Button";
import Shower from "components/Shower";
import ConfirmModal from "components/Modal/ConfirmModal";
import Row from "./Row";
import EmptyRow from "./EmptyRow";

class SubmissionTable extends React.Component {
  constructor(props) {
    super(props);
    this.examId = this.props.match.params.examId;
    this.studentId = this.props.match.params.studentId;
    this.state = {
      data: [],
      isDataFetched: false,
    };
  }

  componentDidMount() {
    submissionAPI
      .getByStudent({ examId: this.examId, studentId: this.studentId })
      .then((data) => {
        setTimeout(
          () =>
            this.setState({
              data: data.submissions,
              isDataFetched: true,
            }),
          500
        );
      });
  }

  render() {
    const { data, isDataFetched } = this.state;
    const { onSubmissionScoreSelected } = this.props;
    return (
      <Block>
        <Table>
          {isDataFetched ? (
            data.length === 0 ? (
              <EmptyRow />
            ) : (
              data.map((row, index) => (
                <Row
                  key={row.id}
                  data={data}
                  row={row}
                  index={index}
                  onSubmissionScoreSelected={onSubmissionScoreSelected}
                />
              ))
            )
          ) : (
            <tr>
              <td colSpan={5} className="text-center">
                <SpinnerEllipsis size="1.3rem" />
              </td>
            </tr>
          )}
        </Table>
      </Block>
    );
  }
}

export default withRouter(SubmissionTable);
