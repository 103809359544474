import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ExamSectionHeader from "./ExamSectionHeader";
import ExamSectionQuestionList from "./ExamSectionQuestionList";
import { connect } from "react-redux";
import ErrorBoundary from "components/Error/ErrorBoundary";

const Container = styled.div`
  & {
    border-left: 1px solid #dbdfea;
    border-right: none !important;
    height: 100%;
  }

  @media only screen and (max-width: 600px) {
    & {
      border-left: none;
      border-top: 1px solid #dbdfea;
    }
  }
`;

class RightSidebar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { sectionOrder } = this.props;
    return (
      <Container className="card-inner-group">
        {sectionOrder.map((sectionId) => (
          <div key={sectionId}>
            <ExamSectionHeader sectionId={sectionId} onClick={() => {}} />
            <ExamSectionQuestionList sectionId={sectionId} />
          </div>
        ))}
      </Container>
    );
  }
}

RightSidebar.propTypes = {};

const mapStateToProps = (state) => ({
  sectionOrder: state.examSubmissionReview.sectionOrder,
});

const RightSidebarRedux = connect(mapStateToProps)(RightSidebar);

export default (props) => (
  <ErrorBoundary fallback={<span></span>}>
    <RightSidebarRedux {...props} />
  </ErrorBoundary>
);
