import React, { useState } from "react";
import { SharedEditorContext as Context } from "./SharedEditorContext";
import * as portals from "react-reverse-portal";
import ErrorBoundary from "components/Error/ErrorBoundary";

const portalNode = portals.createHtmlPortalNode();

function SharedEditor(props) {
  const [editor, setEditor] = useState(null);
  const [isEditorReady, setIsEditorReady] = useState(false);

  function setupEditor(editor) {
    setEditor(editor);
    setIsEditorReady(true);
  }

  return (
    <>
      <Context.Provider value={{ portalNode, editor, isEditorReady }}>
        {props.children}
      </Context.Provider>

      {/* Editor for sent to portal */}
      <portals.InPortal node={portalNode}>
        {props.editor(setupEditor)} {/* Client can use any editor and give to us */}
      </portals.InPortal>
    </>
  );
}

export default (props) => (
  <ErrorBoundary>
    <SharedEditor {...props} />
  </ErrorBoundary>
);
