import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import ModalPortal from "components/ModalPortal";
import SpinnerEllipsis from "components/Spinner/SpinnerEllipsis";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const Backdrop = styled.div`
  opacity: 0.8;
  transition: opacity 0.15s linear;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #364a63;
`;
const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

class PageLoading extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isLoading, variant = "grow", children } = this.props;

    if (!isLoading) return null;
    return (
      <ModalPortal>
        <Container onClick={this.handleClick}>
          <Backdrop>
            <SpinnerContainer>
              {variant === "grow" && <SpinnerGrow variant="warning" size="5rem" />}
              {variant === "ellipsis" && <SpinnerEllipsis variant="warning" size="2rem" />}
            </SpinnerContainer>
          </Backdrop>
        </Container>
      </ModalPortal>
    );
  }
};

PageLoading.propTypes = {};

export default PageLoading;
