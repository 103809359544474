import submissionReviewAPI from "api/academic/examSubmissionReview";
import {
  SUBMISSION_FETCHED,
  SUBMISSION_QUESTION_SWITCHED,
  SUBMISSION_SCORE_GIVEN,
} from "./type";

export const fetched = (data) => ({
  type: SUBMISSION_FETCHED,
  data,
});

export const questionSwitched = ({ sectionId, uuid }) => ({
  type: SUBMISSION_QUESTION_SWITCHED,
  sectionId,
  uuid,
});

export const scoreGiven = ({ question_uuid, score, status }) => ({
  type: SUBMISSION_SCORE_GIVEN,
  question_uuid,
  score,
  status,
});

export const fetch = (id) => {
  return (dispatch) =>
    submissionReviewAPI
      .getSubmission(id)
      .then((data) => dispatch(fetched(data)));
};

export const switchQuestion = ({ sectionId, uuid }) => {
  return (dispatch) => dispatch(questionSwitched({ sectionId, uuid }));
};

export const giveScore = ({ question_id, question_uuid, score, status }) => {
  return (dispatch) =>
    submissionReviewAPI.question.score
      .update({
        question_id,
        score,
      })
      .then(() => {
        dispatch(scoreGiven({ question_uuid, score, status: "" }));
      });
};
