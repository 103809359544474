import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Truncate from "domain/String/Truncate";
import Button from "components/Button/Button";
import InputDecimal from "components/Form/InputDecimal/InputDecimalViewModel";
import DecimalValue from "components/Form/InputDecimal/DecimalValue";

class Row extends React.Component {
  render() {
    const {
      question,
      changeSelectedQuestion,
      removeSelectedQuestion,
    } = this.props;
    return (
      <tr>
        <td className="text-nowrap text-truncate">{question.formated_type}</td>
        <td className="text-nowrap text-truncate">
          <span data-tip={question.text_content}>
            {Truncate(question.text_content, 40)}
          </span>
        </td>
        <td className="text-nowrap text-truncate">
          {question.total_shared > 0 ? (
            <>
              <span className="text-danger">{question.total_shared}x</span> ke
              kurikulum
              <small className="d-block">
                Terakhir:{" "}
                {moment(question.last_shared_date).format("D MMMM HH:mm z")}
              </small>
            </>
          ) : (
            <small>Belum pernah dikirim.</small>
          )}
        </td>
        <td>
          <InputDecimal
            min={1}
            max={100}
            maxDecimal={2}
            defaultValue={(() => {
              if (!question.point) return "";
              return new DecimalValue(question.point)
                .removeTrailingZero()
                .get();
            })()}
            onChange={({ name, value }) =>
              changeSelectedQuestion({
                id: question.id,
                payload: { ...question, point: value },
              })
            }
            className="form-control"
            placeholder="Masukan poin..."
          />
        </td>
        <td className="text-center">
          <Button
            variant="light"
            size="sm"
            onClick={() => removeSelectedQuestion(question)}
          >
            Batal
          </Button>
        </td>
      </tr>
    );
  }
}

export default Row;
