import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetch } from "redux/master/questionTypes/action";
import Select from "components/Form/Select";
import SpinnerGrow from "components/Spinner/SpinnerGrow";

class TypeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.questionTypes === null,
    };
  }

  componentDidMount() {
    this.props.questionTypes === null && this.props.fetch();
  }

  componentDidUpdate() {
    if (this.state.isLoading && this.props.questionTypes !== null) {
      setTimeout(() => this.setState({ isLoading: false }), 300);
    }
  }

  render() {
    const { questionTypes, fetch, ...props } = this.props;
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <SpinnerGrow variant="warning" />
        </div>
      );
    }
    return (
      <div className="form-group">
        <Select {...props}>
          <option value="">Semua Jenis</option>
          {questionTypes.map((row) => (
            <option key={row.type} value={row.type}>
              {row.formated_type}
            </option>
          ))}
        </Select>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questionTypes: state.masterQuestionTypes,
});

export default connect(mapStateToProps, { fetch })(TypeFilter);
