import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import api from "api";
import questionTypeAPI from "api/master/questionType";

export default function useFetchQuestionTypes() {
  const [isFetched, setIsFetched] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    questionTypeAPI.get().then((data) => {
      setData(data);
      setIsFetched(true);
    });
  }, []);

  return {
    isFetched,
    data,
  };
}

useFetchQuestionTypes.propTypes = {};
