import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ErrorBoundary from "components/Error/ErrorBoundary";
import moment from "moment-timezone";
import "moment-duration-format";
import keys from "lodash/keys";

class LeftSidebar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      totalDurations,
      totalQuestions,
      instruction,
    } = this.props;

    return (
      <div className="card-inner-group" style={{ borderRight: "none" }}>
        <div className="card-inner">
          <span className="sub-text sub-text-sm text-dark">Waktu tersisa:</span>
          <span className="sub-text sub-text-sm text-soft mb-2">
            {moment
              .duration(totalDurations, "minutes")
              .format("H:mm:ss", { trim: false })}
          </span>
          <br />
          <span className="sub-text sub-text-sm text-dark">
            Kamu sudah menjawab:
          </span>
          <span className="sub-text sub-text-sm text-soft mb-2">
            0 dari {totalQuestions}
          </span>
        </div>
        <div className="card-inner">
          <h6>Instruksi</h6>
          <span className="sub-text sub-text-sm text-soft">
            {instruction || <em>Tidak ada instruksi.</em>}
          </span>
        </div>
      </div>
    );
  }
}

LeftSidebar.propTypes = {};

const mapStateToProps = (state) => ({
  totalDurations: state.exam.data.exam.setting.total_durations,
  totalQuestions: keys(state.exam.data.questions).length,
  instruction: state.exam.data.instruction,
});

const LeftSidebarRedux = connect(mapStateToProps)(LeftSidebar);

export default (props) => (
  <ErrorBoundary fallback={<span>Oops, terjadi kesalahan.</span>}>
    <LeftSidebarRedux {...props} />
  </ErrorBoundary>
);
