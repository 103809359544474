import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ErrorBoundary from "components/Error/ErrorBoundary";
import QuestionForm from "./QuestionForm";
import AnswerContentContainer from "./shared/AnswerContentContainer";

class Essay extends React.Component {
  render() {
    const { answer, mark } = this.props;
    return (
      <>
        <QuestionForm />

        <div className="nk-block">
          <strong className="pl-1">Jawaban siswa:</strong>
          <div
            className="p-1"
            style={{
              borderRadius: "4px",
              border: "1px solid #dbdfea",
              minHeight: "100px",
            }}
          >
            <AnswerContentContainer
              dangerouslySetInnerHTML={{
                __html: mark.answer || "",
              }}
            />
          </div>
        </div>

        <div className="nk-block">
          <strong className="pl-1">Kunci jawaban:</strong>
          <div
            className="p-1 border border-primary"
            style={{
              borderRadius: "4px",
              minHeight: "100px",
            }}
          >
            <AnswerContentContainer dangerouslySetInnerHTML={{ __html: answer.content }} />
          </div>
        </div>
      </>
    );
  }
}

Essay.propTypes = {};

const mapStateToProps = (state) => ({
  answer:
    state.examSubmissionReview.data.answers[
      state.examSubmissionReview.answerOrder[
        state.examSubmissionReview.currentQuestion.uuid
      ][0]
    ],
  mark:
    state.examSubmissionReview.data.questions[
      state.examSubmissionReview.currentQuestion.uuid
    ].mark || {},
});

const EssayRedux = connect(mapStateToProps)(Essay);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <EssayRedux {...props} />
  </ErrorBoundary>
);
