import React from "react";
import PropTypes from "prop-types";
import Multichoice from "./Multichoice";
import { connect } from "react-redux";
import Truefalse from "./Truefalse";
import Essay from "./Essay";
import Multianswer from "./Multianswer";
import renderMath from "domain/Math/MathJax";

class QuestionBox extends React.Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  componentDidMount() {
    renderMath(() => this.node.current);
  }

  render() {
    const { question } = this.props;
    const questionComponent = {
      multichoice: <Multichoice />,
      multianswer: <Multianswer />,
      truefalse: <Truefalse />,
      essay: <Essay />,
    };

    return (
      <div
        className="card-inner card-inner-lg pt-3"
        style={{ maxWidth: "90%" }}
        ref={this.node}
      >
        {questionComponent[question.type]}
      </div>
    );
  }
}

QuestionBox.propTypes = {};

const mapStateToProps = (state) => {
  return {
    question: state.exam.data.questions[state.exam.currentQuestion.uuid],
  };
};

export default connect(mapStateToProps)(QuestionBox);
