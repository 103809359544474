import React from "react";
import PropTypes from "prop-types";
import Select from "components/Form/Select";
import Label from "components/Form/Label";

class StatusFilter extends React.Component {
  render() {
    return (
      <div className="form-group">
        <Label>Status Mengerjakan</Label>
        <Select {...this.props}>
          <option value="">Semua</option>
          <option value="true">Sudah Mengerjakan</option>
          <option value="false">Belum Mengerjakan</option>
        </Select>
      </div>
    );
  }
}

export default StatusFilter;
