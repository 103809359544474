import React from "react";
import PropTypes from "prop-types";

const InputSearch = ({
  onChange,
  placeholder = "Ketikan sesuatu untuk dicari",
}) => (
  <div className="nk-search-box" style={{margin: 0}}>
    <div className="form-group">
      <div className="form-control-wrap">
        <input
          type="text"
          className="form-control form-control-lg"
          onChange={onChange}
          placeholder={placeholder}
          style={{ borderRadius: "4px" }}
        />
        <button className="form-icon form-icon-right">
          <em className="icon ni ni-search" />
        </button>
      </div>
    </div>
  </div>
);

InputSearch.propTypes = {};

export default InputSearch;
