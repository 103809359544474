import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "components/Button/IconButton";
import EditModal from "./EditModal";

export default function EditModalButton(props) {
  const [isModalShow, setIsModalShow] = useState(false);

  return (
    <>
      <IconButton
        outline
        dim
        size="sm"
        icon="pen"
        data-tip="Ubah soal"
        onClick={() => setIsModalShow(true)}
      />

      {isModalShow && (
        <EditModal
          questionId={props.question.id}
          onClose={() => setIsModalShow(false)}
        />
      )}
    </>
  );
}

EditModalButton.propTypes = {};
