import React from "react";
import PropTypes from "prop-types";
import { Container, Inner, Overlay, Card, Tab } from "./CardTab.styled";
import NioIcon from "components/Icon/NioIcon";

const CardTab = ({ renderTab, renderInner }) => (
  <Container className="card card-bordered border-white">
    <Overlay />
    <Card className="card-inner-group">
      <Inner className="card-inner">{renderInner()}</Inner>
      <div className="card-inner bg-primary-dim" style={{ padding: 0 }}>
        <Tab className="nk-iv-wg3-nav">{renderTab()}</Tab>
      </div>
    </Card>
  </Container>
);

CardTab.propTypes = {};

export default CardTab;
