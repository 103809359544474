import React from "react";
import PropTypes from "prop-types";
import GenerateRandomString from "domain/String/GenerateRandomString";
import styled from "styled-components";

const LabelContent = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.125rem;
  padding-top: 0;
  min-width: 100%;
  max-width: 100%;
`;

class Checkbox extends React.Component {
  handleChange = (e) => {
    e.target.value = e.target.checked
      ? this.props.onValue
      : this.props.offValue;
    this.props.onChange && this.props.onChange(e);
  };

  render() {
    const id = GenerateRandomString();

    const { children, ...rest } = this.props;

    return (
      <div
        className="custom-control custom-control-sm custom-checkbox"
        style={{ minWidth: "100%", maxWidth: "100%" }}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={"choice" + id}
          {...rest}
          onChange={this.handleChange}
        />
        <label
          className="custom-control-label"
          htmlFor={"choice" + id}
          style={{ maxWidth: 0 }}
        >
          &nbsp;
        </label>
        <LabelContent>{children}</LabelContent>
      </div>
    );
  }
}

Checkbox.propTypes = {};

export default Checkbox;
