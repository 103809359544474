import { FETCHED } from "./type";
import api from "api/school/grade";

export const fetched = (grades) => ({
  type: FETCHED,
  grades,
});

export const fetch = () => {
  return (dispatch) =>
    api.get().then((grades) => {
      dispatch(fetched(grades));
    });
};
