import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import SimpleModal from "components/Modal/SimpleModal";
import Multichoice from "./Multichoice";
import Multianswer from "./Multianswer";
import Truefalse from "./Truefalse";
import Essay from "./Essay";
import useFetchQuestion from "hooks/api/bank/useFetchQuestion";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import Row from "components/Grid/Row";
import ErrorBoundary from "components/Error/ErrorBoundary";
import SettingForm from "./SettingForm";
import Col from "components/Grid/Col";
import Button from "components/Button/Button";
import ValidationError from "components/Error/ValidationError";
import questionBankAPI from "api/bank/question";
import { TableContext } from "../../TableContext";
import { EditModalContext } from "./EditModalContext";
import Attachments from "./shared/Attachments";
import { v4 as uuidv4 } from "uuid";
import Block from "components/Block/Block";

function addUUIDToAttachments(data) {
  return {
    ...data,
    attachments: data.attachments.map((attachment) => ({
      ...attachment,
      uuid: uuidv4(),
    })),
  };
}

function update(question) {
  if (question.type === "multichoice" || question.type === "multianswer") {
    const promises = [];
    question.choices
      .filter((choice) => choice.id === null)
      .forEach((choice) => {
        promises.push(
          questionBankAPI
            .addAnswer({ question_id: question.id, choice })
            .then((data) => {
              choice.id = data.id;
            })
        );
      });

    return Promise.all(promises).then(() => {
      return questionBankAPI.update({
        id: question.id,
        question: {
          ...question,
          choices: question.choices.filter((choice) => choice.id !== null),
        },
      });
    });
  }
  if (question.type === "truefalse") {
    return questionBankAPI.update({
      id: question.id,
      question: {
        ...question,
        answer: question.answer.answer,
      },
    });
  }
  if (question.type === "essay") {
    return questionBankAPI.update({
      id: question.id,
      question: {
        ...question,
        answer: question.answer.answer || null,
      },
    });
  }
  throw new Error("Invalid question type");
}

function EditModal({ questionId, onClose }) {
  const [question, setQuestion] = useState(null);
  const questionFetching = useFetchQuestion(questionId, (data) =>
    setQuestion(addUUIDToAttachments(data))
  );
  const [validationErrors, setValidationErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function onChange(key, value) {
    setQuestion((state) => ({ ...state, [key]: value }));
  }

  const { tableReload } = useContext(TableContext);
  function submit() {
    setIsLoading(true);
    update(question)
      .then(() => {
        tableReload();
        onClose();
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setValidationErrors(err.response.data.error);
        }
      })
      .then(() => setIsLoading(false));
  }

  return (
    <SimpleModal
      customWidth="808px"
      title={<span className="text-soft">Ubah soal</span>}
      onClose={() => onClose()}
    >
      {!questionFetching.isFetched && (
        <div className="text-center">
          <SpinnerGrow size="1.2rem" />
        </div>
      )}

      {questionFetching.isFetched && question && (
        <EditModalContext.Provider value={{ onChange, question }}>
          {validationErrors.length > 0 && (
            <Block className="mb-2">
              <ValidationError
                errors={validationErrors}
                onClose={() => setValidationErrors([])}
              />
            </Block>
          )}
          <Row className="gy-4">
            <Col size="8">
              {question.attachments.length > 0 && (
                <Attachments data={question.attachments} />
              )}

              {question.type === "multichoice" && (
                <Multichoice question={question} onChange={onChange} />
              )}
              {question.type === "multianswer" && (
                <Multianswer question={question} onChange={onChange} />
              )}
              {question.type === "truefalse" && (
                <Truefalse question={question} onChange={onChange} />
              )}
              {question.type === "essay" && (
                <Essay question={question} onChange={onChange} />
              )}
            </Col>
            <Col size="4">
              <SettingForm question={question} onChange={onChange} />
            </Col>
            <Col size="12">
              {!isLoading ? (
                <Button block onClick={submit}>
                  Simpan Perubahan
                </Button>
              ) : (
                <div className="text-center">
                  <SpinnerGrow size="1.5rem" />
                </div>
              )}
            </Col>
          </Row>
        </EditModalContext.Provider>
      )}
    </SimpleModal>
  );
}

EditModal.propTypes = {};

export default (props) => (
  <ErrorBoundary>
    <EditModal {...props} />
  </ErrorBoundary>
);
