import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import lessonAPI from "api/school/lesson";

export default function useFetchLessons(statusTeaching = true) {
  const [isFetched, setIsFetched] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    lessonAPI.get(statusTeaching).then((data) => {
      setData(data);
      setIsFetched(true);
    });
  }, []);

  return {
    isFetched,
    data,
  };
}

useFetchLessons.propTypes = {};
