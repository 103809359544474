import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Button = ({
  variant = "primary",
  size = "md",
  block = false,
  dim = false,
  outline = false,
  round = false,
  children,
  className = null,
  forwardedRef = null,
  ...props
}) => {
  return (
    <button
      ref={forwardedRef}
      type="button"
      className={cx(
        className,
        "btn",
        outline ? `btn-outline-${variant}` : `btn-${variant}`,
        `btn-${size}`,
        block && "btn-block",
        dim && "btn-dim",
        round && "btn-round"
      )}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  block: PropTypes.bool,
  dim: PropTypes.bool,
  outline: PropTypes.bool,
  round: PropTypes.bool,
};

export default Button;
