import React from "react";
import PropTypes from "prop-types";
import Button from "../shared/Button";

class Archieve extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <a href={data.url} download>
        <Button
          attachment={data}
          useModal={false}
          text={data.name || data.url}
          icon="file-zip"
        />
      </a>
    );
  }
}

Archieve.propTypes = {};

export default Archieve;
