import React from "react";
import PropTypes from "prop-types";
import NoInternet from "components/Error/NoInternet";
import ErrorBoundary from "components/Error/ErrorBoundary";
import PageError from "components/Error/PageError";
import ModalPortal from "components/ModalPortal";
import PageLoading from "./Loading/PageLoading";
import { connect } from "react-redux";
import NotFoundError from "./Error/NotFoundError";
import ForbiddenError from "./Error/ForbiddenError";
import ReactTooltip from "react-tooltip";

export const PageContext = React.createContext({});

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    setTimeout(() => window.scrollTo(0, 0), 1000);
  }

  render() {
    const {
      isErrorPopupShow,
      isError404PopupShow,
      isConnectionErrorPopupShow,
      isForbiddenErrorPopupShow,
      loader,
    } = this.props;

    return (
      <ErrorBoundary fallback={<PageError />}>
        <ReactTooltip className="tooltip" effect="solid" />

        {isConnectionErrorPopupShow && (
          <ModalPortal>
            <NoInternet />
          </ModalPortal>
        )}

        {isErrorPopupShow && <PageError />}
        {isError404PopupShow && <NotFoundError />}
        {isForbiddenErrorPopupShow && <ForbiddenError />}

        {loader.isShow && <PageLoading isLoading={true} variant="ellipsis" />}

        <PageContext.Provider value={{ handleError: this.handleError }}>
          {this.props.children}
        </PageContext.Provider>
      </ErrorBoundary>
    );
  }
}

Page.propTypes = {};

const mapStateToProps = (state) => ({
  loader: state.page.loader,
  isConnectionErrorPopupShow: state.page.isConnectionErrorPopupShow,
  isErrorPopupShow: state.page.isErrorPopupShow,
  isError404PopupShow: state.page.isError404PopupShow,
  isForbiddenErrorPopupShow: state.page.forbiddenErrorPopup.isShow,
});

export default connect(mapStateToProps)(Page);
