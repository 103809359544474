import React from "react";
import * as portals from "react-reverse-portal";
import CustomUploadAdapterPlugin from "./CKEditorUploadAdapterPlugin";
import ErrorBoundary from "components/Error/ErrorBoundary";
import CKEditor from "components/Editor/CKEditor/CKEditor";

export const EditorContext = React.createContext("EditorContext");

const WebEditorPortalNode = portals.createHtmlPortalNode();

const CKEditorPortalWithErrorBoundary = ({ setEditor, ...props }) => {
  return (
    <ErrorBoundary fallback={<em>Editor gagal dimunculkan.</em>}>
      {/* NOTE: fallback di atas tidak akan muncul karena component <Essay />
        tidak akan menampilkan komponen ini termasuk error boundary bila editor not loaded. */}
      <portals.InPortal {...props}>
        <CKEditor
          UploadAdapterPlugin={CustomUploadAdapterPlugin}
          onReady={(editor) => {
            setEditor(editor);
          }}
        />
      </portals.InPortal>
    </ErrorBoundary>
  );
};

/**
 * Berbagi 1 editor untuk semua komponen <Essay />
 *
 * Untuk render-and-mount satu CKEditor itu butuh beberapa detik.
 * Hal ini akan membuat jengkel user ketika berpindah banyak soal
 * dengan cepat. Karena jika user mengunjungi soal esay, ia harus
 * menunggu X detik baru soal esai akan muncul: React tidak akan
 * menampilkan soal esai hingga CKEditor selesai rendering.
 *
 * Dengan package React Reverse Portal, memungkinkan kita untuk
 * render satu CKEditor kemudian menggunakan editor tersebut di semua
 * soal esai.
 *
 * [TODO] Masalah yang belum terselesaikan:
 *  - Riwayat undo/redo sulit dipertahankan jika berpindah soal.
 */
class SharedEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = { editor: null };
  }

  setEditor = (editor) => this.setState({ editor });

  render() {
    const { children: AccomplishComponent } = this.props;

    return (
      <>
        <EditorContext.Provider
          value={{ portalNode: WebEditorPortalNode, editor: this.state.editor }}
        >
          {AccomplishComponent}
        </EditorContext.Provider>

        <CKEditorPortalWithErrorBoundary
          setEditor={this.setEditor}
          node={WebEditorPortalNode}
        />
      </>
    );
  }
}

SharedEditor.propTypes = {};

export default SharedEditor;
