import {
  CONNECTION_ERROR_POPUP_SHOWED,
  CONNECTION_ERROR_POPUP_HID,
  ERROR_POPUP_SHOWED,
  ERROR_POPUP_HID,
  ERROR_404_POPUP_SHOWED, // TODO: rename -> 404_ERROR_POPUP_SHOWED
  ERROR_404_POPUP_HID,
  FORBIDDEN_ERROR_POPUP_SHOWED,
  FORBIDDEN_ERROR_POPUP_HID,
  LOADER_SHOWED,
  LOADER_HID,
} from "./type";

export const connectionErrorPopupShowed = () => ({
  type: CONNECTION_ERROR_POPUP_SHOWED,
});

export const connectionErrorPopupHid = () => ({
  type: CONNECTION_ERROR_POPUP_HID,
});

export const errorPopupShowed = () => ({
  type: ERROR_POPUP_SHOWED,
});

export const errorPopupHid = () => ({
  type: ERROR_POPUP_HID,
});

export const error404PopupShowed = () => ({
  type: ERROR_404_POPUP_SHOWED,
});

export const error404PopupHid = () => ({
  type: ERROR_404_POPUP_HID,
});

export const forbiddenErrorPopupShowed = (message) => ({
  type: FORBIDDEN_ERROR_POPUP_SHOWED,
  message
});

export const forbiddenErrorPopupHid = () => ({
  type: FORBIDDEN_ERROR_POPUP_HID,
});

export const loaderShowed = (content = null) => ({
  type: LOADER_SHOWED,
  content,
});

export const loaderHid = () => ({
  type: LOADER_HID,
});

export const showConnectionErrorPopup = () => {
  return (dispatch) => dispatch(connectionErrorPopupShowed());
};

export const hideConnectionErrorPopup = () => {
  return (dispatch) => dispatch(connectionErrorPopupHid());
};

export const showErrorPopup = () => {
  return (dispatch) => dispatch(errorPopupShowed());
};

export const hideErrorPopup = () => {
  return (dispatch) => dispatch(errorPopupHid());
};

export const show404Popup = () => {
  return (dispatch) => dispatch(error404PopupShowed());
};

export const hide404Popup = () => {
  return (dispatch) => dispatch(error404PopupHid());
};

export const showForbiddenErrorPopup = (message) => {
  return (dispatch) => dispatch(forbiddenErrorPopupShowed(message));
};

export const hideForbiddenErrorPopup = () => {
  return (dispatch) => dispatch(forbiddenErrorPopupHid());
};

export const showLoader = (content = null) => {
  return (dispatch) => dispatch(loaderShowed(content));
};

export const hideLoader = () => {
  return (dispatch) => dispatch(loaderHid());
};
