import Log from "domain/Log/Log";
import env from "domain/Environment/Env";
import store from "redux/store";
import StackTrace from "stacktrace-js";

export const ErrorMessageReport = (message) => {
  const loggedInTeacherId = store.getState().auth.teacher
      ? store.getState().auth.teacher.id
      : "<Guest>";

    const userAgent =
      navigator && navigator.userAgent
        ? navigator.userAgent
        : "<Browser tidak mendukung navigator.userAgent>";

  Log(`
      [Teacher ID]: ${loggedInTeacherId}
      [Accessed URL]: ${window.location.href}
      [UserAgent]: ${userAgent}
      [Error Message]:
      ${message}
  `);
};

export const ErrorReport = (err, reactStack = null) => {
  /**
   * Error berikut sering memenuhi log error tetapi
   * sebenarnya bukan dari aplikasi kita. Sehingga lebih
   * baik diabaikan:
   *    'getReadModeRender' of undefined
   *    'getReadModeConfig' of undefined
   *    'getReadModeExtract' of undefined
   * @see https://twitter.com/ClaudioDekker/status/1301157171850293250
   */
  if (err.name === "TypeError" && err.message.search("getReadMode") !== -1) {
    return;
  }

  StackTrace.fromError(err).then((stackframes) => {
    let stringifiedStack = stackframes.map((sf) => sf.toString()).join("\n");
    stringifiedStack = stringifiedStack || "<Tidak ada>";

    const errorName = err.name || "<Tidak ada>";
    const errorMessage = err.message || "<Tidak ada>";
    const errorStack = err.stack || "<Browser tidak mendukung stack trace>";
    const userAgent =
      navigator && navigator.userAgent
        ? navigator.userAgent
        : "<Browser tidak mendukung navigator.userAgent>";

    const loggedInTeacherId = store.getState().auth.teacher
      ? store.getState().auth.teacher.id
      : "<Guest>";

    Log(`
      [Teacher ID]: ${loggedInTeacherId}
      [Accessed URL]: ${window.location.href}
      [UserAgent]: ${userAgent}
      [Error Name]: ${errorName}
      [Error Message]: ${errorMessage}
      [StackTrace.js Stack Trace]:
      ${stringifiedStack}
      [JS Stack Trace]:
      ${errorStack}
  `);
  });
};
