import React from "react";
import PropTypes from "prop-types";
import ExamCard from "./ExamCard";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import { Link, withRouter } from "react-router-dom";
import SpinnerEllipsis from "components/Spinner/SpinnerEllipsis";
import TeacherExamListContext from "./TeacherExamListContext";

const AddCard = withRouter((props) => {
  const { academicClassId } = React.useContext(TeacherExamListContext);
  return (
    <div className="col-md-4">
      <div className="card card-bordered dashed" style={{ height: "251.9px" }}>
        <div className="nk-wgw-add">
          <div className="nk-wgw-inner">
            <Link to={`/class/${academicClassId}/academic/exam/create`}>
              <div className="add-icon">
                <em className="icon ni ni-plus"></em>
              </div>
              <h6 className="title">Buat Ulangan</h6>
            </Link>
            <span className="sub-text">
              Buat ulangan baru kemudian atur kapan siswa bisa mengerjakan.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

class ExamSection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { exams = [], isLoading } = this.props;
    const examCards = exams.map((exam) => (
      <ExamCard key={exam.id} exam={exam} />
    ));
    return (
      <div className="nk-block nk-block-lg">
        {/* <div className="nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title">
                {title}{" "}
                <span className="count text-base">({exams.length})</span>
              </h5>
            </div>
            <div
              style={{ flex: 1, border: "1px solid #e5e9f2" }}
              className="ml-4"
            />
          </div>
        </div> */}
        {!isLoading ? (
          <div className="row gy-4 nk-iv-scheme-list mt-1">
            <AddCard />
            {examCards.length > 0 ? examCards : null}
          </div>
        ) : (
          <div className="text-center mt-5">
            <SpinnerEllipsis variant="warning" size="1.5rem" />
          </div>
        )}
      </div>
    );
  }
}

ExamSection.propTypes = {};

export default ExamSection;
