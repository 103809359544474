import React from "react";
import PropTypes from "prop-types";
import SkeletonEditor from "./shared/SkeletonEditor";
import Alert from "components/Alert/Alert";
import { connect } from "react-redux";
import prettyFloat from "domain/Float/PrettyFloat";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import pickBy from "lodash/pickBy";
import keys from "lodash/keys";
import {
  saveQuestionLocally,
  markQuestionSavedInServer,
  markQuestionSaveInServerFailed,
} from "redux/exam/action";
import QuestionEntity from "domain/Exam/QuestionEntity";
import Attachments from "./shared/Attachments";
import styled from "styled-components";
import ValidationError from "components/Error/ValidationError";
import Toastr from "components/Notification/Toastr";
import InputDecimal from "components/Form/InputDecimal";
import Block from "components/Block/Block";

const SaveButtonContainer = styled.div`
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  z-index: 200;
`;

class QuestionForm extends React.Component {
  static contextType = Toastr.Context;

  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      errors: [],
      isSaveLoading: false,
    };
  }

  save = () => {
    this.setState({ errors: [], isSaveLoading: true });
    setTimeout(() => {
      this.props
        .onSave()
        .then(() => {
          this.props.markQuestionSavedInServer(this.props.question.uuid);
          this.setState({ isSaveLoading: false });
          this.context.toastrAddItem(
            <Toastr.Item
              variant="success"
              icon="check-circle-fill"
              className="bg-success-dim p-2"
            >
              Berhasil menyimpan soal.
            </Toastr.Item>
          );
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.setState({
                loading: false,
                errors: err.response.data.error,
              });
            }
          }

          this.props.markQuestionSaveInServerFailed(this.props.question.uuid);
          this.setState({ isSaveLoading: false });
        });
    }, 500);
  };

  handleChange = (name, value) =>
    this.props.saveQuestionLocally({
      uuid: this.props.question.uuid,
      question: { ...this.props.question, [name]: value },
    });

  handleValidationClose = () => this.setState({ errors: [] });

  render() {
    const { question, isQuestionChanged, unsavedQuestions } = this.props;
    const { isSaveLoading, errors } = this.state;
    return (
      <>
        {isQuestionChanged && (
          <SaveButtonContainer className="bg-indigo border-top border-dark p-1 text-center text-white">
            Soal belum disimpan di server.
            {!isSaveLoading ? (
              <button
                className="btn btn-sm btn-success bg-teal ml-2"
                onClick={this.save}
              >
                Simpan
              </button>
            ) : (
              <div className="d-inline-block ml-3">
                <SpinnerGrow size="1.4rem" variant="warning" />
              </div>
            )}
          </SaveButtonContainer>
        )}

        <div
          className="nk-block"
          ref={this.node}
          // className="align-center flex-wrap flex-md-nowrap g-3 h-100"
        >
          <div className="nk-block-content">
            {errors.length > 0 && (
              <Block className="mb-2">
                <ValidationError errors={errors} onClose={this.handleValidationClose} />
              </Block>
            )}

            {(unsavedQuestions.length > 1 ||
              (unsavedQuestions.length === 1 &&
                unsavedQuestions[0].uuid !== question.uuid)) && (
              <div className="nk-block mb-2">
                <Alert className="p-1 text-truncate text-nowrap" variant="danger">
                  <span className="ml-2">
                    Soal [
                    {unsavedQuestions
                      .map((question) => question.getAbsoluteIndex() + 1)
                      .join(", ")}
                    ] belum disimpan. Mohon tekan tombol "Simpan" di soal
                    tersebut.
                  </span>
                </Alert>
              </div>
            )}

            {question.attachments.length > 0 && (
              <Attachments data={question.attachments} />
            )}

            <div className="nk-block mt-0 mb-2">
              <div className="row gy-4">
                <div className="col-sm-12 col-md-4">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <div className="form-text-hint">
                        <span className="overline-title">Poin</span>
                      </div>
                      <InputDecimal
                        min={1}
                        max={100}
                        maxDecimal={2}
                        onChange={({ name, value }) =>
                          this.handleChange("point", value)
                        }
                        className="form-control form-control-sm"
                        defaultValue={prettyFloat(question.point).replace(
                          ",",
                          "."
                        )}
                      />
                    </div>
                  </div>
                </div>
                {question.type === "multianswer" && (
                  <div className="col-sm-12 col-md-5">
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <select
                          className="form-control form-control-sm"
                          defaultValue={question.scoring_mode}
                          onChange={(e) =>
                            this.handleChange("scoring_mode", e.target.value)
                          }
                        >
                          <option value="allornothing">
                            Wajib Benar Semua
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="nk-block pt-2">
              <SkeletonEditor
                placeholder="Ketikan pertanyaan disini..."
                defaultValue={question.content}
                key={question.uuid}
                onChange={(content) => this.handleChange("content", content)}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

QuestionForm.propTypes = {};

const mapStateToProps = (state) => {
  return {
    question: state.exam.data.questions[state.exam.currentQuestion.uuid],
    unsavedQuestions: keys(
      pickBy(state.exam.isQuestionsSavedInServer, (isSaved) => !isSaved)
    ).map((questionUUID) =>
      new QuestionEntity(state.exam.data.questions[questionUUID])
        .setSectionOrder(state.exam.sectionOrder)
        .setQuestionOrder(state.exam.questionOrder)
        .setQuestions(state.exam.data.questions)
    ),
    isQuestionChanged: !state.exam.isQuestionsSavedInServer[
      state.exam.currentQuestion.uuid
    ],
  };
};

export default connect(mapStateToProps, {
  saveQuestionLocally,
  markQuestionSavedInServer,
  markQuestionSaveInServerFailed,
})(QuestionForm);
