import axios from "configuredAxios";

export default {
  get: () =>
    axios.get(`/v1/bank/question/category`).then((res) => res.data.data),
  create: (category) =>
    axios
      .post(`/v1/bank/question/category`, category)
      .then((res) => res.data.data),
  update: (category) =>
    axios
      .put(`/v1/bank/question/category/${category.id}`, category)
      .then((res) => res.data.data),
  delete: (id) => axios.delete(`/v1/bank/question/category/${id}`),
};
