import React from "react";
import PropTypes from "prop-types";

const PageHeader = ({ heading, subheading, message }) => (
  <div className="nk-block-head nk-block-head-lg">
    <div className="nk-block-head-content">
      <div className="nk-block-head-sub">
        {subheading}
      </div>
      <h2 className="nk-block-title fw-normal">{heading}</h2>
      <div className="nk-block-des">
        <p>
          {message}
        </p>
      </div>
    </div>
  </div>
);

PageHeader.propTypes = {
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};

export default PageHeader;
