import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const SpinnerGrow = ({ size = "2rem", variant = "warning" }) => (
  <div
    className={cx("spinner-grow", variant && `text-${variant}`)}
    style={{ width: size, height: size }}
    role="status"
  >
    <span className="sr-only">Memuat...</span>
  </div>
);

SpinnerGrow.propTypes = {};

export default SpinnerGrow;
