import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Checkbox from "components/Form/Checkbox";
import styled from "styled-components";
import ButtonGroup from "components/ButtonGroup/ButtonGroup";
import DeleteButton from "./DeleteButton";
import Truncate from "domain/String/Truncate";
import EditModalButton from "./EditModalButton/EditModalButton";

const Container = styled.tr`
  background-color: ${(props) => (props.isSelected ? "#ffebf3" : "inherit")};
`;

class Row extends React.Component {
  constructor(props) {
    super(props);
  }

  onDelete = () => this.props.onRefresh();

  render() {
    const { question, toggleSelect, isSelected } = this.props;
    return (
      <Container isSelected={isSelected}>
        <td>
          <Checkbox
            checked={isSelected}
            onChange={() => toggleSelect(question)}
          />
        </td>
        <td className="text-nowrap text-truncate">{question.formated_type}</td>
        <td className="text-nowrap text-truncate">
          <span data-tip={question.text_content}>
            {Truncate(question.text_content, 40)}
          </span>
        </td>
        <td className="text-nowrap text-truncate">
          {question.total_shared > 0 ? (
            <>
              <span className="text-danger">{question.total_shared}x</span> ke
              kurikulum
              <small className="d-block">
                Terakhir:{" "}
                {moment(question.last_shared_date).format("D MMMM HH:mm z")}
              </small>
            </>
          ) : (
            <small>Belum pernah dikirim.</small>
          )}
        </td>
        <td className="text-center">
          {isSelected ? (
            <span></span>
          ) : (
            <ButtonGroup>
              <EditModalButton question={question} />
              <DeleteButton question={question} onDelete={this.onDelete} />
            </ButtonGroup>
          )}
        </td>
      </Container>
    );
  }
}

export default Row;
