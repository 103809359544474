import React from "react";
import PropTypes from "prop-types";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Radio from "./shared/Radio";
import { connect } from "react-redux";
import {
  initAnswer,
  saveQuestionLocally,
  saveAnswerLocally,
  markAnswerSavedInServer,
  deleteAnswerLocally,
} from "redux/exam/action";
// import AccomplishContext from "pages/ExamAccomplishPage/AccomplishContext";
import ErrorBoundary from "components/Error/ErrorBoundary";
import SkeletonEditor from "./shared/SkeletonEditor";
import styled from "styled-components";
import QuestionForm from "./QuestionForm";
import api from "api";
import AnswerContainer from "./shared/AnswerContainer";
import omit from "lodash/omit";

const AnswerFieldDelete = styled.em`
  cursor: pointer;
  &:hover {
    color: #e85347;
  }
`;

const AnswerField = ({
  uuid,
  isChecked,
  content,
  onChange,
  onRemove,
  onChecked,
  showRemoveButton = false,
}) => (
  <div className="d-flex" style={{ alignItems: "center" }}>
    <AnswerItemBordered style={{ flexGrow: 1, minWidth: 0, maxWidth: "100%" }}>
      <Radio
        name="choice"
        value={uuid}
        checked={isChecked}
        onChange={onChecked}
      >
        <SkeletonEditor
          placeholder="Ketikan pilihan jawaban disini..."
          defaultValue={content}
          onChange={(content) => onChange(content)}
        />
      </Radio>
    </AnswerItemBordered>
    {showRemoveButton && (
      <AnswerFieldDelete
        className="icon ni ni-cross ml-2 mr-n4"
        onClick={() => onRemove()}
      />
    )}
  </div>
);

const AnswerFieldAdd = (props) => (
  <AnswerItemBordered
    {...props}
    className="bg-lighter text-center border-0"
    style={{ fontSize: "12px" }}
  >
    Tambah pilihan jawaban
  </AnswerItemBordered>
);

class Multichoice extends React.Component {
  add = () =>
    this.props.initAnswer({
      questionUUID: this.props.question.uuid,
      questionType: this.props.question.type,
    });

  save = (changedChoice) => {
    this.props.saveAnswerLocally({
      uuid: changedChoice.uuid,
      answer: changedChoice,
    });
  };

  removeLocally = (uuid) => {
    this.props.deleteAnswerLocally({
      questionUUID: this.props.question.uuid,
      uuid,
    });
  };

  remove = ({ id, uuid }) => {
    if (!id) {
      this.removeLocally(uuid);
      return;
    }

    api.exam.answer
      .delete({
        questionId: this.props.question.id,
        answerId: id,
      })
      .then(() =>
        this.props.deleteAnswerLocally({
          questionUUID: this.props.question.uuid,
          uuid,
        })
      );
  };

  saveQuestion = () => {
    if (this.props.question.id) {
      const promises = [
        new Promise((resolve, reject) =>
          resolve("just make sure has 1 promise")
        ),
      ];
      if (this.props.isQuestionChanged) {
        promises.push(
          api.exam.question.update({
            questionId: this.props.question.id,
            point: this.props.question.point,
            content: this.props.question.content,
          })
        );
      }
      // update or create answer
      this.props.choices
        .sort((choice1, choice2) => {
          // NOTE: Urutan sangat penting ketika update kunci jawaban.
          // sorting agar choice.key === true dulu sebelum choice.key === false
          // karena ada masalah dengan validasi backend: multianswer minimal punya 1 kunci.
          if (choice1.key === true) {
            return -1;
          }
          if (choice1.key === false) {
            return 1;
          }
          return 0;
        })
        .forEach((choice) => {
          if (this.props.isAnswersSavedInServer[choice.uuid]) return; // skip unchanged
          if (choice.id) {
            promises.push(
              api.exam.answer
                .update({
                  questionId: this.props.question.id,
                  answerId: choice.id,
                  payload: { choice },
                })
                .then(() => {
                  this.props.markAnswerSavedInServer({ uuid: choice.uuid });
                })
            );
          } else {
            promises.push(
              api.exam.answer
                .create({
                  questionId: this.props.question.id,
                  payload: { choice },
                })
                .then((data) => {
                  this.props.markAnswerSavedInServer({
                    uuid: choice.uuid,
                    id: data.choice.id,
                  });
                })
            );
          }
        });
      return Promise.all(promises);
    } else {
      return api.exam.question
        .create({
          section_id: this.props.question.sectionId,
          question: { ...this.props.question, choices: this.props.choices },
        })
        .then((data) => {
          this.props.saveQuestionLocally({
            uuid: this.props.question.uuid,
            question: { ...this.props.question, ...omit(data, "choices") },
          });
          // NOTE: pastikan backend mengembalikan data choice sesuai urutan.
          this.props.choices.forEach((choice, index) => {
            this.props.saveAnswerLocally({
              uuid: choice.uuid,
              answer: { ...choice, id: data.choices[index].id },
            });
            this.props.markAnswerSavedInServer({ uuid: choice.uuid });
          });
        });
    }
  };

  onChoose = (choice) => {
    this.props.choices.forEach((choice) => {
      this.props.saveAnswerLocally({
        uuid: choice.uuid,
        answer: { ...choice, key: false },
      });
    });
    this.props.saveAnswerLocally({
      uuid: choice.uuid,
      answer: { ...choice, key: true },
    });
  };

  render() {
    return (
      <>
        <QuestionForm onSave={this.saveQuestion} />

        <AnswerContainer>
          {this.props.choices.map((choice) => (
            <AnswerField
              key={choice.uuid}
              uuid={choice.uuid}
              isChecked={choice.key}
              content={choice.content}
              onChange={(content) => this.save({ ...choice, content })}
              onChecked={() => this.onChoose(choice)}
              onRemove={() => this.remove(choice)}
              showRemoveButton={
                this.props.choices.length !== 2 &&
                choice.key === false &&
                !(
                  this.props.choices.filter((choice) =>
                    Number.isInteger(choice.id)
                  ).length === 2 && Number.isInteger(choice.id)
                )
              }
            />
          ))}
          {this.props.choices.length < 10 && (
            <AnswerFieldAdd onClick={this.add} />
          )}
        </AnswerContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  choices: state.exam.answerOrder[state.exam.currentQuestion.uuid].map(
    (answerUUID) => state.exam.data.answers[answerUUID]
  ),
  question: state.exam.data.questions[state.exam.currentQuestion.uuid],
  isQuestionChanged: !state.exam.isQuestionsSavedInServer[
    state.exam.currentQuestion.uuid
  ],
  isAnswersSavedInServer: state.exam.isAnswersSavedInServer,
});
const MultichoiceRedux = connect(mapStateToProps, {
  initAnswer,
  saveQuestionLocally,
  saveAnswerLocally,
  markAnswerSavedInServer,
  deleteAnswerLocally,
})(Multichoice);
export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <MultichoiceRedux {...props} />
  </ErrorBoundary>
);
