import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  initQuestion,
  fetchQuestionTypes,
  fetchCategory,
} from "redux/createQuestionBank/action";
import styled from "styled-components";
import SharedEditor from "components/Editor/SharedEditor";
import CardList from "./Card/CardList";
import Headline from "./Headline";
import { connect } from "react-redux";
import useFetchLessons from "hooks/api/useFetchLessons";
import useFetchQuestionTypes from "hooks/api/useFetchQuestionTypes";
import { StaticData } from "./StaticDataContext";
import useFetchGrades from "hooks/api/useFetchGrades";
import { v4 as uuidv4 } from "uuid";
import Toastr from "components/Notification/Toastr";
import CKEditor from "components/Editor/CKEditor";
import { useSelector, useDispatch } from "react-redux";

const SaveButtonContainer = styled.div`
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  z-index: 200;
`;

function SubmitButton(props) {
  return (
    <SaveButtonContainer className="bg-indigo border-top border-dark p-1 text-center text-white">
      Total {props.totalQuestion} soal telah dibuat
      <button
        className="btn btn-sm btn-success bg-teal ml-2"
        onClick={props.submit}
      >
        Simpan
      </button>
    </SaveButtonContainer>
  );
}

const Container = styled.div`
  width: 800px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 100px;
`;

const Index = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const dummyCardRef = useRef(null);

  const gradesFetching = useFetchGrades();
  const lessonsFetching = useFetchLessons(false);
  const questionTypesFetching = useFetchQuestionTypes();

  useEffect(() => {
    if (
      gradesFetching.isFetched &&
      lessonsFetching.isFetched &&
      questionTypesFetching.isFetched
    ) {
      setIsLoading(false);
    }
  }, [gradesFetching, lessonsFetching, questionTypesFetching]);

  function onCardAdd() {
    setTimeout(() => {
      dummyCardRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 300);
  }

  // TODO: apakah ini cara yg benar?
  const [submitUUID, setSubmitUUID] = useState(null);
  function submit() {
    setSubmitUUID(uuidv4());
  }

  const isCategoriesFetched = useSelector(
    (state) => state.createQuestionBank.isCategoriesFetched
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isCategoriesFetched) return;
    dispatch(fetchCategory());
  }, []);

  return (
    <StaticData.Provider
      value={{
        lessonsFetching,
        questionTypesFetching,
        gradesFetching,
        onCardAdd, // TODO: karena ada ini nama contextnya jangan StaticData
      }}
    >
      <Toastr position="top-center" maxItem={3}>
        <SharedEditor
          editor={(setEditor) => (
            <CKEditor onReady={(editor) => setEditor(editor)} />
          )}
        >
          <Container>
            <Headline onCardAdd={onCardAdd} />

            {isLoading ? (
              <div>Memuat...</div>
            ) : (
              <CardList submitUUID={submitUUID} />
            )}
            <div ref={dummyCardRef}></div>

            {props.totalQuestion > 0 && (
              <SubmitButton
                totalQuestion={props.totalQuestion}
                submit={submit}
              />
            )}
          </Container>
        </SharedEditor>
      </Toastr>
    </StaticData.Provider>
  );
};

const mapStateToProps = (state) => ({
  totalQuestion: state.createQuestionBank.questionOrder.length,
});

export default connect(mapStateToProps, {
  initQuestion,
  fetchQuestionTypes,
})(Index);
