import React from "react";
import PropTypes from "prop-types";
import AnswerContainer from "./shared/AnswerContainer";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Radio from "components/Form/Radio";
import { connect } from "react-redux";
import {
  initAnswer,
  updateAnswer,
  deleteAnswer,
  markSavedInServer,
} from "redux/createQuestionBank/action";
import ErrorBoundary from "components/Error/ErrorBoundary";
import QuestionForm from "./QuestionForm";
import SaveLoading from "./shared/SaveLoading";
import questionAPI from "api/bank/question";
import Toastr from "components/Notification/Toastr";

class Truefalse extends React.Component {
  static contextType = Toastr.Context;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSaved: false,
      errors: [],
    };
  }

  save = () => {
    this.setState({ isLoading: true });
    return questionAPI
      .createMany([
        {
          ...this.props.question,
          answer: this.props.answer.answer,
        },
      ])
      .then(() => {
        this.setState({ isLoading: false });
        this.setState({ isSaved: true });
        this.props.markSavedInServer(this.props.question.uuid);
        this.context.toastrAddItem(
          <Toastr.Item
            variant="success"
            icon="check-circle-fill"
            className="bg-success-dim p-2"
          >
            Berhasil menyimpan soal.
          </Toastr.Item>
        );
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          this.setState({ errors: err.response.data.error });
        }
        this.setState({ isLoading: false });
        this.context.toastrAddItem(
          <Toastr.Item
            variant="danger"
            icon="info-fill"
            className="bg-danger-dim p-2"
          >
            Gagal menyimpan soal
          </Toastr.Item>
        );
      });
  };

  componentDidUpdate(prevProps) {
    if (this.state.isLoading) return;
    if (this.props.submitUUID === null) return;
    if (prevProps.submitUUID !== this.props.submitUUID) {
      this.save();
    }
  }

  render() {
    if (this.state.isSaved) return null;

    return (
      <div>
        {this.state.isLoading && <SaveLoading />}

        <QuestionForm validationErrors={this.state.errors} />

        <AnswerContainer>
          <AnswerItemBordered style={{ flexGrow: 1, minWidth: 0 }}>
            <Radio
              name={"choice" + this.props.question.uuid}
              value={true}
              checked={this.props.answer.answer === true}
              onChange={() =>
                this.props.updateAnswer({
                  uuid: this.props.answer.uuid,
                  answer: { ...this.props.answer, answer: true },
                })
              }
            >
              Benar
            </Radio>
          </AnswerItemBordered>
          <AnswerItemBordered style={{ flexGrow: 1, minWidth: 0 }}>
            <Radio
              name={"choice" + this.props.question.uuid}
              value={false}
              checked={this.props.answer.answer === false}
              onChange={() =>
                this.props.updateAnswer({
                  uuid: this.props.answer.uuid,
                  answer: { ...this.props.answer, answer: false },
                })
              }
            >
              Salah
            </Radio>
          </AnswerItemBordered>
        </AnswerContainer>
      </div>
    );
  }
}

Truefalse.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  answer:
    state.createQuestionBank.answers[
      state.createQuestionBank.answerOrder[ownProps.question.uuid][0]
    ],
});

const TruefalseRedux = connect(mapStateToProps, {
  initAnswer,
  updateAnswer,
  deleteAnswer,
  markSavedInServer,
})(Truefalse);

export default (props) => (
  <ErrorBoundary>
    <TruefalseRedux {...props} />
  </ErrorBoundary>
);
