import React from "react";
import PropTypes from "prop-types";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import StatusFilter from "./StatusFilter";
import ScoreFilter from "./ScoreFilter";
import CompletionStatusFilter from "./CompletionStatusFilter";
import {
  EXAM_TYPE_POSTTEST,
  EXAM_TYPE_PRETEST,
} from "pages/TeacherExamPage/ExamForm";

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      has_submission: null,
      has_score: null,
      is_minimum_completion_passed: null,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value || null });
  };

  componentDidUpdate() {
    this.props.onChange({ ...this.state });
  }

  render() {
    const { examType } = this.props;
    return (
      <Row>
        <Col size="4">
          <StatusFilter name="has_submission" onChange={this.handleChange} />
        </Col>
        <Col size="4">
          <ScoreFilter name="has_score" onChange={this.handleChange} />
        </Col>
        {(examType === EXAM_TYPE_PRETEST ||
          examType === EXAM_TYPE_POSTTEST) && (
          <Col size="4">
            <CompletionStatusFilter
              name="is_minimum_completion_passed"
              onChange={this.handleChange}
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default Filter;
