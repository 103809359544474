import React from "react";
import PropTypes from "prop-types";
import CardFooter from "./CardFooter";
import CardBody from "./CardBody";
import { DataContext } from "./CardContext";
import AlertPro from "components/Alert/AlertPro";
import ErrorBoundary from "components/Error/ErrorBoundary";

const Card = (props) => {
  if (props.question.isSaved) {
    return (
      <AlertPro variant="success">
        <strong>Soal tersimpan</strong>
      </AlertPro>
    );
  }

  return (
    <DataContext.Provider
      value={{
        question: props.question,
        lessons: props.lessons,
      }}
    >
      <div
        className="card card-bordered"
        style={{ boxShadow: "0px 1px 3px #dbdfea", position: "relative" }}
      >
        <div className="nk-wgw">
          <CardBody submitUUID={props.submitUUID} />
          <CardFooter />
        </div>
      </div>
    </DataContext.Provider>
  );
};

export default (props) => (
  <ErrorBoundary>
    <Card {...props} />
  </ErrorBoundary>
);
