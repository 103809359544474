import React from "react";
import PropTypes from "prop-types";

const Label = ({ children, className = "", required = false, }) => (
  <label className={"form-label " + className}>
    {children}{" "}
    {required && (
      <small style={{ color: "red", fontSize: "75%" }}>(wajib)</small>
    )}
  </label>
);

Label.propTypes = {};

export default Label;
