import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetch as fetchSubmission } from "redux/examSubmissionReview/action";
import Accomplish from "./Accomplish";
import PageLoading from "components/Loading/PageLoading";
import SharedEditor from "./SharedEditor";
import Toastr from "components/Notification/Toastr";
import Headline from "./Headline";
import StudentDetail from "pages/TeacherExamSubmissonReviewPage/StudentDetail";

class ExamAccomplishPage extends React.Component {
  constructor(props) {
    super(props);
    this.submissionId = this.props.match.params.submissionId;
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.props
        .fetchSubmission(this.submissionId)
        .then(() => this.setState({ isLoading: false }));
    }, 500);
  }

  exit = () => {
    this.props.history.push(`/class/:schoolAcademicId/academic/exam/:examId/student/:studentId/submission`);
  };

  render() {
    if (this.state.isLoading) {
      return <PageLoading isLoading={true} variant="ellipsis" />;
    }

    return (
      <Toastr position="top-center" maxItem={3}>
        <Headline
          exam={this.props.exam}
          onExit={this.exit}
        />

        <StudentDetail />

        <SharedEditor>
          <Accomplish />
        </SharedEditor>
      </Toastr>
    );
  }
}

ExamAccomplishPage.propTypes = {};

const mapStateToProps = (state) => ({
  submission: state.examSubmissionReview.data.submission,
  exam: state.examSubmissionReview.data.exam,
});

export default connect(mapStateToProps, {
  fetchSubmission,
})(ExamAccomplishPage);
