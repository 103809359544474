import React from "react";
import PropTypes from "prop-types";
import SummaryCard from "components/Statistic/SummaryCard";
import examResultAPI from "api/academic/examResult";
import PrettyFloat from "domain/Float/PrettyFloat";

class Summary extends React.Component {
  state = {
    total_submissions: 0,
    total_students: 0,
    highest_scores: "0.00",
    lowest_scores: "0.00",
    average_scores: "0.00",
    isFetched: false
  };

  componentDidMount() {
    examResultAPI.getSummary(this.props.examId).then((data) => {
      setTimeout(() => this.setState({ ...data, isFetched: true }), 600);
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-md-3">
          <SummaryCard
            renderTitle={"Total Mengerjakan"}
            renderContent={
              this.state.total_submissions + "/" + this.state.total_students
            }
            isLoading={this.state.isFetched}
          />
        </div>
        <div className="col-12 col-md-3">
          <SummaryCard
            renderTitle={"Nilai Tertinggi"}
            renderContent={PrettyFloat(this.state.highest_scores)}
            isLoading={this.state.isFetched}
          />
        </div>
        <div className="col-12 col-md-3">
          <SummaryCard
            renderTitle={"Nilai Terendah"}
            renderContent={PrettyFloat(this.state.lowest_scores)}
            isLoading={this.state.isFetched}
          />
        </div>
        <div className="col-12 col-md-3">
          <SummaryCard
            renderTitle={"Nilai Rata-rata"}
            renderContent={PrettyFloat(this.state.average_scores)}
            isLoading={this.state.isFetched}
          />
        </div>
      </div>
    );
  }
}

export default Summary;
