import React from "react";
import PropTypes from "prop-types";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Radio from "./shared/Radio";
import { connect } from "react-redux";
import {
  initAnswer,
  updateAnswer,
  deleteAnswer,
  markSavedInServer,
} from "redux/createQuestionBank/action";
// import AccomplishContext from "pages/ExamAccomplishPage/AccomplishContext";
import ErrorBoundary from "components/Error/ErrorBoundary";
import SkeletonEditor from "components/Editor/SharedEditor/SkeletonEditor";
import styled from "styled-components";
import QuestionForm from "./QuestionForm";
import AnswerContainer from "./shared/AnswerContainer";
import questionAPI from "api/bank/question";
import SaveLoading from "./shared/SaveLoading";
import Toastr from "components/Notification/Toastr";

const AnswerFieldDelete = styled.em`
  cursor: pointer;
  &:hover {
    color: #e85347;
  }
`;

const AnswerField = ({
  uuid,
  radioName,
  isChecked,
  content,
  onChange,
  onRemove,
  onChecked,
  showRemoveButton = false,
}) => (
  <div className="d-flex" style={{ alignItems: "center" }}>
    <AnswerItemBordered style={{ flexGrow: 1, minWidth: 0, maxWidth: "100%" }}>
      <Radio
        name={radioName}
        value={uuid}
        checked={isChecked}
        onChange={onChecked}
      >
        <SkeletonEditor
          placeholder="Ketikan pilihan jawaban disini..."
          defaultValue={content}
          onChange={(content) => onChange(content)}
        />
      </Radio>
    </AnswerItemBordered>
    {showRemoveButton && (
      <AnswerFieldDelete
        className="icon ni ni-cross ml-2 mr-n4"
        onClick={() => onRemove()}
      />
    )}
  </div>
);

const AnswerFieldAdd = (props) => (
  <AnswerItemBordered
    {...props}
    className="bg-lighter text-center border-0"
    style={{ fontSize: "12px" }}
  >
    Tambah pilihan jawaban
  </AnswerItemBordered>
);

class Multichoice extends React.Component {
  static contextType = Toastr.Context;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSaved: false,
      errors: [],
    };
  }

  save = () => {
    this.setState({ isLoading: true });
    return questionAPI
      .createMany([
        {
          ...this.props.question,
          choices: this.props.choices,
        },
      ])
      .then(() => {
        this.setState({ isLoading: false });
        this.setState({ isSaved: true });
        this.props.markSavedInServer(this.props.question.uuid);
        this.context.toastrAddItem(
          <Toastr.Item
            variant="success"
            icon="check-circle-fill"
            className="bg-success-dim p-2"
          >
            Berhasil menyimpan soal.
          </Toastr.Item>
        );
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          this.setState({ errors: err.response.data.error });
        }
        this.setState({ isLoading: false });
        this.context.toastrAddItem(
          <Toastr.Item
            variant="danger"
            icon="info-fill"
            className="bg-danger-dim p-2"
          >
            Gagal menyimpan soal
          </Toastr.Item>
        );
      });
  };

  componentDidUpdate(prevProps) {
    if (this.state.isLoading) return;
    if (this.props.submitUUID === null) return;
    if (prevProps.submitUUID !== this.props.submitUUID) {
      this.save();
    }
  }

  onChoose = (choice) => {
    this.props.choices.forEach((choice) => {
      this.props.updateAnswer({
        uuid: choice.uuid,
        answer: { ...choice, key: false },
      });
    });
    this.props.updateAnswer({
      uuid: choice.uuid,
      answer: { ...choice, key: true },
    });
  };

  render() {
    if (this.state.isSaved) return null;

    return (
      <div className="pr-2">
        {this.state.isLoading && <SaveLoading />}

        <QuestionForm validationErrors={this.state.errors} />

        <AnswerContainer>
          {this.props.choices.map((choice) => (
            <AnswerField
              key={choice.uuid}
              uuid={choice.uuid}
              radioName={"choice_" + this.props.question.uuid}
              isChecked={choice.key}
              content={choice.content}
              onChange={(content) =>
                this.props.updateAnswer({
                  uuid: choice.uuid,
                  answer: { ...choice, content },
                })
              }
              onChecked={() => this.onChoose(choice)}
              onRemove={() =>
                this.props.deleteAnswer({
                  questionUUID: this.props.question.uuid,
                  uuid: choice.uuid,
                })
              }
              showRemoveButton={
                this.props.choices.length !== 2 &&
                choice.key === false &&
                !(
                  this.props.choices.filter((choice) =>
                    Number.isInteger(choice.id)
                  ).length === 2 && Number.isInteger(choice.id)
                )
              }
            />
          ))}
          {this.props.choices.length < 10 && (
            <AnswerFieldAdd
              onClick={() =>
                this.props.initAnswer({
                  questionUUID: this.props.question.uuid,
                  questionType: this.props.question.type,
                })
              }
            />
          )}
        </AnswerContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    choices: state.createQuestionBank.answerOrder[ownProps.question.uuid].map(
      (answerUUID) => state.createQuestionBank.answers[answerUUID]
    ),
  };
};
const MultichoiceRedux = connect(mapStateToProps, {
  initAnswer,
  updateAnswer,
  deleteAnswer,
  markSavedInServer,
})(Multichoice);
export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <MultichoiceRedux {...props} />
  </ErrorBoundary>
);
