import React from "react";
import PropTypes from "prop-types";
import ClickOutsideAlerter from "./Event/ClickOutsideAlerter";

class Shower extends React.Component {
  static defaultProps = {
    isShow: false,
    hideOnBlur: false,
  };

  constructor(props) {
    super(props);
    this.state = { isShow: this.props.isShow };
  }

  show = () => {
    this.setState({ isShow: true });
  };

  hide = () => {
    this.setState({ isShow: false });
  };

  toggle = () => {
    this.setState((state) => ({ isShow: !state.isShow }));
  };

  renderWithBlur = (children) => {
    return (
      <ClickOutsideAlerter when={true} onClickOutside={this.hide}>
        {children}
      </ClickOutsideAlerter>
    );
  };

  render() {
    const children = this.props.children({
      isShow: this.state.isShow,
      toggle: this.toggle,
      show: this.show,
      hide: this.hide,
    });
    if (this.props.hideOnBlur) {
      return this.renderWithBlur(children);
    }
    return children;
  }
}

export default Shower;
