import { FETCHED } from "./type";
import api from "api/master/questionCategory";

export const fetched = (categories) => ({
  type: FETCHED,
  categories,
});

export const fetch = () => {
  return (dispatch) =>
    api.get().then((categories) => {
      dispatch(fetched(categories));
    });
};
