import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Attachments from "./shared/Attachments";
import Toastr from "components/Notification/Toastr";
import QuestionContentContainer from "./shared/QuestionContentContainer";

class QuestionForm extends React.Component {
  static contextType = Toastr.Context;

  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  render() {
    const { question } = this.props;
    return (
      <>
        <div className="nk-block" ref={this.node}>
          <div className="nk-block-content">
            {Array.isArray(question.attachments) &&
              question.attachments.length > 0 && (
                <Attachments data={question.attachments} />
              )}

            <div className="nk-block pt-2">
              <QuestionContentContainer dangerouslySetInnerHTML={{ __html: question.content }} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

QuestionForm.propTypes = {};

const mapStateToProps = (state) => {
  return {
    question:
      state.examSubmissionReview.data.questions[
        state.examSubmissionReview.currentQuestion.uuid
      ],
  };
};

export default connect(mapStateToProps)(QuestionForm);
