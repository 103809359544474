import React from "react";
import PropTypes from "prop-types";
import Link from "components/Link/Link";
import Block from "components/Block/Block";
import PrettyFloat from "domain/Float/PrettyFloat";

class StudentDetail extends React.Component {
  render() {
    const { data, newFinalScore } = this.props;
    return (
      <Block>
        <div className="tranx-list tranx-list-stretch card card-bordered">
          <div className="tranx-item" style={{ padding: "1rem 1.5rem" }}>
            <div className="tranx-col">
              <div className="tranx-info">
                <div className="tranx-badge">
                  <span className="tranx-icon">
                    <img src={data.student.photo} />
                  </span>
                </div>
                <div className="tranx-data">
                  <div className="tranx-label">
                    <Link to="#">{data.student.name}</Link>
                  </div>
                  <div className="tranx-date">
                    Melakukan {data.total_submissions} kali kesempatan
                    pengerjaan.
                  </div>
                </div>
              </div>
            </div>
            <div className="tranx-col">
              <div className="tranx-amount">
                <div className="number">
                  {!Object.is(parseFloat(newFinalScore), NaN)
                    ? PrettyFloat(newFinalScore)
                    : PrettyFloat(data.final_score)}
                </div>
                <div className="number-sm">Nilai Akhir</div>
              </div>
            </div>
          </div>
        </div>
      </Block>
    );
  }
}

export default StudentDetail;
