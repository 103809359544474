import store from "redux/store";
import Log from "domain/Log/Log";
import { showLoader, hideLoader } from "redux/page/action";

/**
 * Custom upload adapter plugin untuk menangani
 * cara upload file ke server dan menerima gambar dari server.
 *
 * @see https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/upload-adapter.html#implementing-a-custom-upload-adapter
 */

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    store.dispatch(showLoader());
    xhr.open(
      "POST",
      process.env.REACT_APP_ENDPOINT +
        "/v1/school/academic/exam/question/upload",
      true
    );
    xhr.responseType = "json";
  }

  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Gagal mengunggah gambar: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("loadend", () => setTimeout(() => store.dispatch(hideLoader()), 500));
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      if (response && response.data && response.data.url) {
        resolve({
          default: response.data.url,
        });
      } else {
        return reject(genericErrorText);
      }
    });

    // Upload progress when it is supported.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(reject, file) {
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      Log(`User mengunggah gambar tidak valid : ${file.name} (${file.type})`);
      reject(`Gambar ditolak karena bukan JPG atau PNG.`);
      return;
    }

    const data = new FormData();
    data.append("file", file);
    // NOTE: kata dokumentasi method ini adalah tempat yang benar
    // untuk menaruh security seperti authorization header atau CSRF token
    const access_token = store.getState().auth.access_token;
    this.xhr.setRequestHeader("Authorization", "Bearer " + access_token);
    this.xhr.send(data);
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(reject, file);
        })
    );
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }
}

function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

export default CustomUploadAdapterPlugin;
