import React from "react";
import PropTypes from "prop-types";
import { ErrorReport } from "domain/Error/ErrorReport";
import env from "domain/Environment/Env";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (env.isDev() || env.isProd()) {
      ErrorReport(error, info.componentStack);
    } else {
      console.log(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback || (
          <em className="text-warning">Oops terjadi kesalahan...</em>
        )
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  fallback: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.elementType,
  ]),
};

export default ErrorBoundary;
