import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import gradeAPI from "api/school/grade";

export default function useFetchGrades() {
  const [isFetched, setIsFetched] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    gradeAPI.get().then((data) => {
      setData(data);
      setIsFetched(true);
    });
  }, []);

  return {
    isFetched,
    data,
  };
}

useFetchGrades.propTypes = {};
