import React from "react";
import PropTypes from "prop-types";
import SubmissionTable from "./SubmissionTable";
import StudentDetail from "./StudentDetail";
import PageHeader from "components/PageHeader/PageHeader";
import StudentSelector from "./StudentSelector";
import submissionAPI from "api/academic/examSubmission";
import api from "api";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import Badge from "components/Badge/Badge";
import NioIcon from "components/Icon/NioIcon";
import Link from "components/Link/Link";

class TeacherExamSubmissionListPage extends React.Component {
  constructor(props) {
    super(props);
    this.schoolAcademicId = this.props.match.params.schoolAcademicId;
    this.examId = this.props.match.params.examId;
    this.studentId = this.props.match.params.studentId;
    this.state = {
      isAcademicClassFetched: false,
      academicClass: null,
      newFinalScore: null,
    };
  }

  componentDidMount() {
    api.academicClass.findOne(this.schoolAcademicId).then((data) => {
      this.setState({ academicClass: data, isAcademicClassFetched: true });
    });
  }

  handleMakeSubmissionScoreAsFinal = ({ submissionId, submissionScore }) => {
    return submissionAPI.score
      .markAsFinalScore({
        examId: this.examId,
        student_id: this.studentId,
        submission_id: submissionId,
      })
      .then(() => this.setState({ newFinalScore: submissionScore }));
  };

  render() {
    return (
      <>
        <div className="mb-2">
          <Link to={`/class/${this.schoolAcademicId}/academic/exam/${this.examId}/result`} className="text-soft fs-18px">
            <NioIcon name="arrow-left" /> <span>Kembali</span>
          </Link>
        </div>

        <PageHeader
          heading="Riwayat Pengerjaan"
          subheading={
            this.state.isAcademicClassFetched ? (
              <>
                <Badge>{this.state.academicClass.school_lesson.name}</Badge>{" "}
                <Badge variant="danger">
                  {this.state.academicClass.school_class.name}
                </Badge>
              </>
            ) : (
              <SpinnerGrow size="1.3rem" />
            )
          }
          message={"Berikut adalah pengerjaan yang telah dilakukan siswa."}
        />

        <StudentSelector
          render={(studentWithResult) =>
            !Object.is(parseFloat(this.state.newFinalScore), NaN) ? (
              <StudentDetail
                data={studentWithResult}
                newFinalScore={this.state.newFinalScore}
              />
            ) : (
              <StudentDetail data={studentWithResult} />
            )
          }
        />

        <SubmissionTable
          onSubmissionScoreSelected={this.handleMakeSubmissionScoreAsFinal}
        />
      </>
    );
  }
}

export default TeacherExamSubmissionListPage;
