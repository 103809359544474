import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "components/Button/Button";
import InputDecimal from "components/Form/InputDecimal";
import SimpleModal from "components/Modal/SimpleModal";
import Shower from "components/Shower";
import Block from "components/Block/Block";
import DecimalValue from "components/Form/InputDecimal/DecimalValue";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import { giveScore } from "redux/examSubmissionReview/action";
import cx from "classnames";
import ValidationError from "components/Error/ValidationError";
import Alert from "components/Alert/Alert";

class EssayScoreForm extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      point: null,
      errors: [],
      isSaveLoading: false,
    };
  }

  handleChange = (point) => this.setState({ point });

  handleFullPointClick = () =>
    this.setState(
      { point: this.props.question.point },
      () =>
        (this.ref.current.value = new DecimalValue(this.state.point)
          .removeTrailingZero()
          .get())
    );
  handleZeroPointClick = () =>
    this.setState(
      { point: "0" },
      () => (this.ref.current.value = this.state.point)
    );

  handleSubmit = (callback) => {
    this.setState({ errors: [], isSaveLoading: true });
    let status = null;
    switch (parseFloat(this.state.point)) {
      case 0:
        status = "incorrect";
        break;
      case parseFloat(this.props.question.point):
        status = "correct";
        break;
      default:
        status = "partial";
    }
    return this.props
      .giveScore({
        question_id: this.props.question.id,
        question_uuid: this.props.question.uuid,
        score: this.state.point,
        status,
      })
      .then(() =>
        setTimeout(() => {
          this.setState({ isSaveLoading: false });
          callback();
        }, 300)
      )
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            this.setState({ errors: err.response.data.error });
          }
        }
        this.setState({ isSaveLoading: false });
      });
  };

  render() {
    const { question } = this.props;
    const isAlreadyHasScore = question.status !== null;
    return (
      <Shower>
        {(shower) => (
          <>
            <Block className="pt-2 pb-2 pl-3 pr-3">
              <div
                className={cx("card card-bordered", {
                  "bg-primary-dim": isAlreadyHasScore,
                  "text-primary": isAlreadyHasScore,
                  "bg-danger-dim": !isAlreadyHasScore,
                  "text-danger": !isAlreadyHasScore,
                })}
              >
                <div className="card-inner p-1">
                  <div className="nk-cta">
                    <div className="nk-cta-block">
                      <div className="ml-1">
                        {!isAlreadyHasScore ? (
                          <span>Soal ini belum diberi nilai.</span>
                        ) : (
                          <span>Nilai telah diberikan.</span>
                        )}
                      </div>
                    </div>
                    <div className="nk-cta-action">
                      <Button
                        variant={isAlreadyHasScore ? "primary" : "danger"}
                        size="sm"
                        onClick={shower.show}
                      >
                        {!isAlreadyHasScore ? (
                          <span>Beri Nilai</span>
                        ) : (
                          <span>Ubah Nilai</span>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Block>

            {shower.isShow && (
              <SimpleModal
                size={"sm"}
                title={"Beri Nilai"}
                onClose={shower.hide}
              >
                {this.state.errors.length > 0 && (
                  <Block className="mb-2">
                    <ValidationError
                      errors={this.state.errors}
                      onClose={() => {}}
                    />
                  </Block>
                )}
                <div className="nk-block mt-0 mb-2">
                  <Alert variant="warning" icon="info">
                    Pastikan perbarui nilai akhir setelah mengubah nilai
                    pengerjaan.
                  </Alert>
                  <div className="row gx-1 gy-2">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <div className="form-text-hint">
                            <span className="overline-title">Poin</span>
                          </div>
                          <InputDecimal
                            min={0}
                            max={question.point}
                            defaultValue={question.total_gained_points}
                            maxDecimal={2}
                            onChange={({ value }) => this.handleChange(value)}
                            wrappedComponentRef={this.ref}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <Button
                        variant="success"
                        size="sm"
                        outline
                        block
                        onClick={this.handleFullPointClick}
                      >
                        Poin Penuh
                      </Button>
                    </div>
                    <div className="col-sm-4">
                      <Button
                        variant="danger"
                        size="sm"
                        outline
                        block
                        onClick={this.handleZeroPointClick}
                      >
                        Beri Nol
                      </Button>
                    </div>
                    <div className="col-sm-4">
                      {this.state.isSaveLoading ? (
                        <div className="text-center">
                          <SpinnerGrow size="1.3rem" />
                        </div>
                      ) : (
                        <Button
                          variant="primary"
                          size="sm"
                          block
                          onClick={() => this.handleSubmit(() => shower.hide())}
                          disabled={
                            this.state.point
                              ? false
                              : true /* TODO: kalau input kosong jadi "0" dan ini jadi salah logic */
                          }
                        >
                          Simpan
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </SimpleModal>
            )}
          </>
        )}
      </Shower>
    );
  }
}

export default connect(null, { giveScore })(EssayScoreForm);
