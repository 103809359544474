import React from "react";
import PropTypes from "prop-types";
import Truncate from "domain/String/Truncate";
import Switch from "components/Form/Switch";
import { updateStatusExam } from "redux/exam/action";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import pickBy from "lodash/pickBy";
import keys from "lodash/keys";
import QuestionEntity from "domain/Exam/QuestionEntity";

class Headline extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isChangeStatusLoading: false };
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  handleStatusChange = ({ value }) => {
    this.setState({ isChangeStatusLoading: true });
    this.props
      .updateStatusExam({ examId: this.props.exam.id, status: value })
      .then(() => {
        setTimeout(() => this.setState({ isChangeStatusLoading: false }), 300);
      });
  };

  render() {
    const {
      exam,
      sectionsWithNoQuestion,
      unsavedQuestions,
      onEditClick,
      onExit,
      ...props
    } = this.props;
    return (
      <>
        <div className="nk-block">
          <div className="alert alert-info alert-pro mt-n5 mb-4 border-white">
            <div className="alert-cta flex-wrap flex-md-nowrap">
              <div className="alert-text text-dark">
                <span className="h6 ff-base mb-0 p-0 fw-medium">
                  {Truncate(exam.title, 50)}
                </span>
                <small className="ml-1 text-soft">
                  {exam.description ? (
                    Truncate(exam.description, 50)
                  ) : (
                    <em>Tidak ada deskripsi.</em>
                  )}
                </small>
              </div>
              <ul className="alert-actions gx-3 mt-3 mb-1 my-md-0">
                <li className="d-flex justify-content-center align-items-center">
                  {this.state.isChangeStatusLoading && (
                    <div className="mr-1">
                      <SpinnerGrow size="1.2rem" />
                    </div>
                  )}
                  <div
                    className="d-inline-block"
                    data-tip={
                      sectionsWithNoQuestion.length > 0
                        ? "Ada bagian yang tidak memiliki soal."
                        : ""
                    }
                  >
                    <Switch
                      name="status"
                      size="md"
                      offValue={"inactive"}
                      onValue={"active"}
                      onChange={this.handleStatusChange}
                      checked={exam.setting.status === "active"}
                      disabled={
                        (exam.setting.status === "inactive" &&
                          sectionsWithNoQuestion.length > 0) ||
                        this.state.isChangeStatusLoading
                      }
                    >
                      Aktifkan
                    </Switch>
                  </div>
                </li>
                <li>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={onEditClick}
                  >
                    <em className="icon ni ni-pen" />
                    <span>Ubah ulangan dan pengaturan</span>
                  </button>
                </li>
                <li>
                  <button
                    onClick={onExit}
                    className="btn btn-sm btn-light"
                    disabled={unsavedQuestions.length > 0}
                  >
                    Kembali
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  exam: state.exam.data.exam,
  sectionsWithNoQuestion: state.exam.sectionOrder.filter(
    (sectionId) =>
      state.exam.questionOrder[sectionId].filter(
        (questionUUID) =>
          state.exam.isQuestionsSavedInServer[questionUUID] === true // only saved question
      ).length === 0
  ),
  unsavedQuestions: keys(
    pickBy(state.exam.isQuestionsSavedInServer, (isSaved) => !isSaved)
  ).map((questionUUID) =>
    new QuestionEntity(state.exam.data.questions[questionUUID])
      .setSectionOrder(state.exam.sectionOrder)
      .setQuestionOrder(state.exam.questionOrder)
      .setQuestions(state.exam.data.questions)
  ),
});

export default connect(mapStateToProps, { updateStatusExam })(Headline);
