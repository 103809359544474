import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  -webkit-animation: fadeInDown 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeInDown 0.5s; /* Firefox < 16 */
  -ms-animation: fadeInDown 0.5s; /* Internet Explorer */
  -o-animation: fadeInDown 0.5s; /* Opera < 12.1 */
  animation: fadeInDown 1s;

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    50% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Item = ({
  variant, // NOTE: variant="danger" -> variant="error"
  children,
  icon = "info-fill",
  width = "280px",
  className = "",
}) => (
  <Container
    className={"p-1 toastr toast-" + variant + " " + className}
    style={{ width }}
  >
    <div
      className="toast-message"
      style={{ minWidth: "100px", display: "inline-block" }}
    >
      <span className="toastr-icon">
        <em className={"icon ni ni-" + icon}></em>
      </span>
      <div className="toastr-text">{children}</div>
    </div>
  </Container>
);

export default Item;
