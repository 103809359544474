import axios from "configuredAxios";

export default {
  get: (status_teaching) =>
    axios
      .get(`/v1/school/lesson`, {
        params: { status_teaching },
      })
      .then((res) => res.data.data),
};
