import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  border: 1px solid #dbdfea;
  margin-top: 0.75rem !important;
  padding: 10px 10px;
  border-radius: 4px;
  position: relative;
  &:hover {
    cursor: pointer;
    border-color: #6576ff;
  }
`;

class AnswerItemBordered extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, ...props } = this.props;
    return <Container {...props}>{children}</Container>;
  }
}

export default AnswerItemBordered;
