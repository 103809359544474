import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteQuestion } from "redux/exam/action";
import FloatModal from "components/Modal/FloatModal";
import QuestionEntity from "domain/Exam/QuestionEntity";

class QuestionDeleteButton extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = { isConfirmShow: false };
  }

  remove = () => {
    this.props.deleteQuestion({
      question: this.props.question,
      questionSwitchedTo: this.props.question.prev()
        ? this.props.question.prev()
        : { sectionId: this.props.currentSectionId, uuid: null },
    });
    this.setState({ isConfirmShow: false });
  };

  showConfirm = (e) => {
    e.preventDefault();
    this.setState({ isConfirmShow: true });
  };
  closeConfirm = () => {
    this.setState({ isConfirmShow: false });
  };

  render() {
    return (
      <>
        <a
          href="#"
          className="text-danger"
          onClick={this.showConfirm}
          ref={this.ref}
        >
          Hapus
        </a>

        {this.state.isConfirmShow && (
          <FloatModal
            title="Konfirmasi Hapus"
            position="left"
            parentRef={this.ref}
            onClose={this.closeConfirm}
          >
            <div className="row gy-4">
              <div className="col-sm-12">
                Anda akan menghapus soal secara permanen.
              </div>

              <div className="col-sm-6">
                <button
                  type="button"
                  className="btn btn-sm btn-light btn-block"
                  onClick={this.closeConfirm}
                >
                  <span>Batal</span>
                </button>
              </div>
              <div className="col-sm-6">
                <button
                  type="button"
                  className="btn btn-sm btn-danger btn-block"
                  onClick={this.remove}
                >
                  <span>Hapus Soal</span>
                </button>
              </div>
            </div>
          </FloatModal>
        )}
      </>
    );
  }
}

QuestionDeleteButton.propTypes = {};

const mapStateToProps = (state) => ({
  currentSectionId: state.exam.currentQuestion.sectionId,
});

export default connect(mapStateToProps, { deleteQuestion })(
  QuestionDeleteButton
);
