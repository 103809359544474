import axios from "configuredAxios";

export default {
  get: ({ examId, has_submission, has_score, cancelCallback = () => {} }) => {
    const source = axios.CancelToken.source();
    cancelCallback(source.cancel);
    return axios
      .get(`/v1/school/academic/exam/${examId}/result`, {
        params: {
          has_submission: has_submission || null,
          has_score: has_score || null,
        },
        cancelToken: source.token,
      })
      .then((res) => res.data.data);
  },
  getSummary: (examId) =>
    axios
      .get(`/v1/school/academic/exam/${examId}/submission/summary`)
      .then((res) => res.data.data),
};

// const data = (id) => ({
//   student: {
//     id,
//     photo:
//       "https://hatta.storage.edulogy.id/file/teacher/photo/2020/07/16/sample-640426.JPEG",
//     code: "10101010",
//     nis: "10101111",
//     name: "Om BOB",
//   },
//   final_score: "100",
//   total_submissions: 3,
// });

// export default {
//   get: () => {
//     return Promise.resolve([data(1), data(2), data(3), data(4), data(5)]);
//   },
//   getSummary: () => {
//     return Promise.resolve({
//       total_submissions: 3,
//       total_students: 10,
//       highest_score: "70",
//       lowest_score: "40",
//       average_score: "78,56",
//     });
//   }
// };
