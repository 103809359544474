import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 100%;
  all: inherit;
  background: transparent;
  border: 1px dashed #a4a2a2;
  border-radius: 4px;
  padding: 0 2px 0 2px;
  word-break: break-word;
  cursor: text;
  position: relative;

  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    content: "";
  }

  &.placeholder {
    color: #7777ff;
  }

  &.filled:not(:hover) {
    background: inherit;
    border-color: transparent;
  }

  & ol, & ul { all: revert }
`;

export const EditorContainer = styled.div`
  & > div {
    max-width: 100%;
    cursor: text;
    min-width: 100%;
  }
  & ol, & ul { all: revert }
`;
