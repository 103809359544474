import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link as RouterLink } from "react-router-dom";

const Link = ({
  children,
  to = "#",
  onClick = null,
  className = null,
  ...props
}) => {
  if (to.trim().startsWith("http")) {
    return (
      <a
        {...props}
        className={cx(className)}
        href={to}
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick(e);
        }}
      >
        {children}
      </a>
    );
  } else {
    return (
      <RouterLink
        {...props}
        to={to}
        className={cx(className)}
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick(e);
        }}
      >
        {children}
      </RouterLink>
    );
  }
};

Link.propTypes = {
  to: PropTypes.string.isRequired,

  onClick: PropTypes.func,
};

export default Link;
