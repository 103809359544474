import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ExamSectionHeader from "./ExamSectionHeader";
import ExamSectionQuestionList from "./ExamSectionQuestionList";
import { connect } from "react-redux";
import ErrorBoundary from "components/Error/ErrorBoundary";
import ExamSectionAddForm from "./ExamSectionAddForm";

const Container = styled.div`
  & {
    border-left: 1px solid #dbdfea;
    border-right: none !important;
    height: 100%;
  }

  @media only screen and (max-width: 600px) {
    & {
      border-left: none;
      border-top: 1px solid #dbdfea;
    }
  }
`;

class RightSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSectionId: null,
      componentStateUpdatedAt: null,
    };
  }

  expand = (sectionId) => {
    this.setState({
      currentSectionId: sectionId,
      componentStateUpdatedAt: new Date().getTime(),
    });
  };

  render() {
    const { sectionOrder } = this.props;

    // TODO: apakah ini cara yang benar?
    const currentSectionId =
      this.props.ReduxStateUpdatedAt > this.state.componentStateUpdatedAt
        ? this.props.currentSectionId
        : this.state.currentSectionId;

    return (
      <Container className="card-inner-group">
        {sectionOrder.map((sectionId) => (
          <div key={sectionId}>
            <ExamSectionHeader
              sectionId={sectionId}
              onClick={() => this.expand(sectionId)}
            />
            {/* {currentSectionId === sectionId && ( */}
              <ExamSectionQuestionList sectionId={sectionId} />
            {/* )} */}
          </div>
        ))}

        <ExamSectionAddForm />
      </Container>
    );
  }
}

RightSidebar.propTypes = {};

const mapStateToProps = (state) => ({
  ReduxStateUpdatedAt: new Date().getTime(),
  sectionOrder: state.exam.sectionOrder,
  currentSectionId: state.exam.currentQuestion.sectionId,
});

const RightSidebarRedux = connect(mapStateToProps)(RightSidebar);

export default (props) => (
  <ErrorBoundary fallback={<span></span>}>
    <RightSidebarRedux {...props} />
  </ErrorBoundary>
);
