import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import truncate from "domain/String/Truncate";
import moment from "moment-timezone";
import ExamDeleteButton from "./ExamDeleteButton";
import TeacherExamListContext from "./TeacherExamListContext";
import Link from "components/Link/Link";
import env from "domain/Environment/Env"

class ExamCard extends React.Component {
  static contextType = TeacherExamListContext;

  render() {
    const { exam } = this.props;
    return (
      <>
        <div className="col-md-6 col-lg-4">
          <div
            className="card card-bordered"
            style={{
              boxShadow: "0 1px 3px 0px #ddd",
            }}
          >
            <div className="nk-wgw">
              <div className="nk-wgw-inner">
                <span className="nk-wgw-name">
                  <h6
                    className="nk-wgw-title title text-wrap"
                    style={{ lineHeight: 1.3, flex: 1, height: "41.6px" }}
                  >
                    <Link
                      to={`/class/${this.context.academicClassId}/academic/exam/${exam.id}/edit`}
                    >
                      {truncate(exam.title, 40)}
                    </Link>
                  </h6>
                  <span
                    className={cx({
                      "rounded-0": true,
                      "rounded-left": true,
                      "ml-1": true,
                      "mt-n3": true,
                      "mr-n4": true,
                      badge: true,
                      "badge-primary": exam.status === "active",
                      "badge-danger": exam.status === "inactive",
                    })}
                  >
                    {exam.formated_status}
                  </span>
                </span>
                <div className="nk-wgw-balance">
                  <div className="sp-plan-desc">
                    <ul className="row gx-1">
                      <li className="col-4 col-lg-4">
                        <small>
                          <div className="text-soft">Mulai</div>
                          <div>
                            {moment(exam.start_at).format("D MMM YYYY")}
                          </div>
                          <div>{moment(exam.start_at).format("HH:mm z")}</div>
                        </small>
                      </li>
                      <li className="col-4 col-lg-4">
                        <small>
                          <div className="text-soft">Selesai</div>
                          <div>
                            {moment(exam.finish_at).format("D MMM YYYY")}
                          </div>
                          <div>{moment(exam.finish_at).format("HH:mm z")}</div>
                        </small>
                      </li>
                      <li className="offset-1 offset-lg-1 col-3 col-lg-3">
                        <small>
                          <div className="text-soft">Soal</div>
                          <div>{exam.total_questions}</div>
                        </small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="nk-wgw-actions">
                <div className="row">
                  <div className="col-sm-6">
                    <Link
                      to={`/class/${this.context.academicClassId}/academic/exam/${exam.id}/edit`}
                    >
                      <em className="icon ni ni-pen"></em>{" "}
                      <span>Ubah Ulangan</span>
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    <Link
                      to={`/class/${this.context.academicClassId}/academic/exam/${exam.id}/result`}
                    >
                      <em className="icon ni ni-award"></em>
                      <span>Penilaian</span>
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    <a
                      href={
                        env.isProd()
                        ? `https://guru.edulogy.id/teacher/learning/classes/${this.context.academicClassId}/exam/v2/${exam.id}`
                        : `https://guru.dev.edulogy.id/teacher/learning/classes/${this.context.academicClassId}/exam/v2/${exam.id}`
                      }
                      target="_blank"
                    >
                      <em className="icon ni ni-send"></em>
                      <span>Kirim Notif</span>
                    </a>
                  </div>
                  <div className="col-sm-6">
                    <ExamDeleteButton exam={exam} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ExamCard.propTypes = {};

export default ExamCard;
