import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import InputDecimal from "components/Form/InputDecimal/InputDecimalViewModel";
import Select from "components/Form/Select";
import useFetchGrades from "hooks/api/useFetchGrades";
import useFetchLessons from "hooks/api/useFetchLessons";
import useFetchQuestionTypes from "hooks/api/useFetchQuestionTypes";
import useFetchQuestionCategories from "hooks/api/bank/useFetchQuestionCategories";
import AttachmentButton from "./AttachmentButton";
import ReactTooltip from "react-tooltip";

export default function SettingForm({ question, onChange }) {
  const gradesFetching = useFetchGrades();
  const lessonsFetching = useFetchLessons(false);
  const questionTypesFetching = useFetchQuestionTypes();
  const categoriesFetching = useFetchQuestionCategories();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!questionTypesFetching.isFetched) {
    return "Memuat...";
  }

  return (
    <Row className="gy-2">
      <Col size="12">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-text-hint">
              <span className="overline-title">Poin</span>
            </div>
            <InputDecimal
              min={1}
              max={100}
              maxDecimal={2}
              defaultValue={question.point}
              onChange={({ name, value }) => onChange("point", value)}
              className="form-control"
              placeholder="Masukan poin..."
            />
          </div>
        </div>
      </Col>
      {question.type === "multianswer" && (
        <Col size="12">
          <div className="form-control-wrap">
            <div className="form-control-select">
              <select
                className={!question.scoring_mode && "border-warning"}
                data-tip="Mode Penilaian"
                className="form-control"
                value={question.scoring_mode}
                onChange={(e) => onChange("scoring_mode", e.target.value)}
              >
                <option value="">Pilih Mode Penilaian</option>
                <option value="allornothing">Wajib Benar Semua</option>
              </select>
            </div>
          </div>
        </Col>
      )}

      <Col size="12">
        <div className="form-group">
          <Select
            className={!question.lesson_id && "border-warning"}
            data-tip="Pelajaran"
            value={question.lesson_id}
            onChange={(e) => onChange("lesson_id", parseInt(e.target.value))}
          >
            <option value="">
              {lessonsFetching.isFetched ? "Pilih Pelajaran" : "Memuat..."}
            </option>
            {lessonsFetching.data.map((row) => (
              <option value={row.id} key={row.id}>
                {row.name}
              </option>
            ))}
          </Select>
        </div>
      </Col>
      <Col size="12">
        <div className="form-group">
          <Select
            className={!question.grade_id && "border-warning"}
            data-tip="Tingkat"
            value={question.grade_id}
            onChange={(e) => onChange("grade_id", e.target.value)}
          >
            <option value="">
              {gradesFetching.isFetched ? "Pilih Tingkat" : "Memuat..."}
            </option>
            {gradesFetching.data.map((grade) => (
              <option value={grade.id} key={grade.id}>
                {grade.name}
              </option>
            ))}
          </Select>
        </div>
      </Col>
      <Col size="12">
        <div className="form-group">
          <Select
            className={!question.type && "border-warning"}
            data-tip="Jenis Soal"
            value={question.type}
            disabled={true}
          >
            {questionTypesFetching.data.map((row) => (
              <option value={row.type} key={row.type}>
                {row.formated_type}
              </option>
            ))}
          </Select>
        </div>
      </Col>
      <Col size="12">
        <Select
          className={!question.category_id && "border-warning"}
          data-tip="Kategori"
          value={question.category_id}
          onChange={(e) => onChange("category_id", parseInt(e.target.value))}
        >
          <option value="">
            {categoriesFetching.isFetched ? "Pilih Kategori" : "Memuat..."}
          </option>
          {categoriesFetching.data.map((category) => (
            <option value={category.id} key={category.id}>
              {category.name}
            </option>
          ))}
        </Select>
      </Col>
      <Col size="12">
        <AttachmentButton question={question} onChange={onChange} />
      </Col>
    </Row>
  );
}

SettingForm.propTypes = {};
