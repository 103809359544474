import React from "react";
import PropTypes from "prop-types";
import SkeletonEditor from "components/Editor/SharedEditor/SkeletonEditor";

export default function QuestionForm({ question, onChange }) {
  return (
    <div className="nk-block">
      <div className="nk-block-content">
        <div className="nk-block pt-2">
          <SkeletonEditor
            defaultValue={question.content}
            placeholder="Ketikan pertanyaan disini..."
            onChange={(content) => onChange("content", content)}
          />
        </div>
      </div>
    </div>
  );
}
