import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ModalPortal from "components/ModalPortal";
import ClickOutsideAlerter from "components/Event/ClickOutsideAlerter";

const Container = styled.div`
  &.modal-xs {
    max-width: 300px;
  }
`;

class FloatModal extends React.Component {
  constructor(props) {
    super(props);

    this.top = "auto";
    this.left = "auto";
    this.right = "auto";
    this.bottom = "auto";

    const parentRect = this.props.parentRef.current.getBoundingClientRect();

    switch (this.props.position) {
      case "left":
        this.top = parentRect.top + window.scrollY;
        this.right = window.innerWidth - parentRect.left;
        break;
      case "right":
        this.top = parentRect.top + window.scrollY;
        this.left = parentRect.left + parentRect.width + 5;
        break;
      case "bottomLeft":
        this.top = parentRect.top + parentRect.height + window.scrollY + 5;
        this.right = window.innerWidth - parentRect.left - parentRect.width / 2;
        break;
      // case "bottomRight":
      //   this.top = parentRect.top + parentRect.height + window.scrollY + 5;
      //   this.right = window.innerWidth - parentRect.left - parentRect.width / 2;
      //   break;
    }
  }

  render() {
    const { size = "xs" } = this.props;

    return (
      <ModalPortal>
        <ClickOutsideAlerter when={true} onClickOutside={this.props.onClose}>
          <Container
            className={"modal-dialog modal-dialog-top modal-" + size}
            style={{
              marginTop: 0,
              display: "block",
              position: "absolute",
              top: this.top,
              left: this.left,
              right: this.right,
              bottom: this.bottom,
              zIndex: 500,
            }}
          >
            <div
              className="modal-content bg-lighter border border-primary"
              // style={{ border: "1px solid #dbdfea" }}
            >
              {/* {this.props.title && (
              <div className="modal-header bg-light">
                <h6 className="modal-title">{this.props.title}</h6>
                <button
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.onClose}
                >
                  <em className="icon ni ni-cross text-dark"></em>
                </button>
              </div>
            )} */}
              <div className="modal-body">{this.props.children}</div>
            </div>
          </Container>
        </ClickOutsideAlerter>
      </ModalPortal>
    );
  }
}

FloatModal.propTypes = {};

export default FloatModal;
