import React from 'react';
import PropTypes from 'prop-types';

// TODO: fix typo nio -> ni
const Icon = ({ nio = null, fa = null }) => {
  if (nio) {
    return <em className={"ni ni-" + nio}></em>;
  }
  if (fa) {
    return <em className={"fa fa-" + fa}></em>;
  }
}

Icon.propTypes = {

}

export default Icon;
