import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TeacherExamListContext from "pages/TeacherExamListPage/TeacherExamListContext";
import ConfirmModal from "components/Modal/ConfirmModal";

class ExamDeleteButton extends React.Component {
  static contextType = TeacherExamListContext;

  constructor(props) {
    super(props);
    this.state = { isDeleteConfirmShow: false, isLoading: false };
  }

  delete = () => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.context.deleteExam(this.props.exam.id).then(() => {
        this.closeDeleteConfirm();
        this.setState({ isLoading: false });
      });
    }, 300);
  };

  openDeleteConfirm = (e) => {
    e.preventDefault();
    if (this.props.exam.status === "active") return;
    this.setState({ isDeleteConfirmShow: true });
  };
  closeDeleteConfirm = () => {
    this.setState({ isDeleteConfirmShow: false });
  };

  render() {
    const { exam } = this.props;
    return (
      <>
        <a
          href="#"
          onClick={this.openDeleteConfirm}
          style={{ cursor: exam.status === "active" ? "not-allowed" : "pointer" }}
          data-tip={exam.status === "active" ? "Mohon nonaktifkan status" : ""}
        >
          <em className="icon ni ni-trash" /> <span>Hapus</span>
        </a>

        {this.state.isDeleteConfirmShow && (
          <ConfirmModal
            variant="danger"
            acceptLabel="Hapus"
            onAccept={this.delete}
            onCancel={this.closeDeleteConfirm}
            isLoading={this.state.isLoading}
            message={
              <>
                <strong className="text-center">{exam.title}</strong>
                <br />
                Anda akan menghapus ulangan tersebut secara permanen.
              </>
            }
          />
        )}
      </>
    );
  }
}

ExamDeleteButton.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ExamDeleteButton);
