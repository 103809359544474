import React from "react";
// import NotifItem from "./NotifItem";
import styled from "styled-components";

const Dropdown = styled.div`
  @media only screen and (max-width: 315px) {
    right: -60px;
  }
  @media only screen and (min-width: 316px) and (max-width: 460px) {
    right: -80px;
  }
`;

class Notif extends React.Component {
  constructor(props) {
    super(props);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);

    this.dropdownRef = React.createRef();
    this.state = { isShow: false };
  }

  componentDidUpdate() {
    if (this.state.isShow) {
      this.dropdownRef.current.focus(); // so we can hide dropdown after onBlur occurs
    }
  }

  hideDropdown(e) {
    this.setState({ isShow: false });
  }

  toggleDropdown(e) {
    e.preventDefault();
    this.setState({ isShow: !this.state.isShow });
  }

  render() {
    const { isShow } = this.state;

    return (
      <li className="dropdown notification-dropdown">
        <a
          href="#"
          className="dropdown-toggle nk-quick-nav-icon"
          data-toggle="dropdown"
          onClick={this.toggleDropdown}
        >
          <div className="icon-status icon-status-info">
            <em className="icon ni ni-bell"></em>
          </div>
        </a>
        <Dropdown
          tabIndex="-1"
          onBlur={this.hideDropdown}
          ref={this.dropdownRef}
          className={
            "dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1" +
            (isShow ? " show" : "")
          }
        >
          <div className="dropdown-head">
            <span className="sub-title nk-dropdown-title">Notifikasi</span>
            <a href="#" onClick={(e) => e.preventDefault()}>Tandai Semua Dibaca</a>
          </div>
          <div className="dropdown-body">
            <div className="nk-notification">
              {/* <NotifItem />
              <NotifItem />
              <NotifItem />
              <NotifItem />
              <NotifItem />
              <NotifItem /> */}
              <p className="text-center text-soft pt-2 pb-2">Tidak ada notifikasi.</p>
            </div>
          </div>
          <div className="dropdown-foot center">
            <a href="#" onClick={(e) => e.preventDefault()}>Lihat Semua</a>
          </div>
        </Dropdown>
      </li>
    );
  }
}

export default Notif;
