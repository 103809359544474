import React from "react";
import styled from "styled-components";
import cx from "classnames";

// TODO: InstructionScroll sebaiknya hanya muncul di area tabel

const Table = styled.table`
  & thead {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.12em;
    background: #f5f6fa;
    /* background: rgba(245, 246, 250, 0.5); */
  }
  & th {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  & th,
  & td {
    font-size: 13px;
    color: #8094ae;
    ${(props) => !props.compact && "padding: 0.9  rem;"}
    ${(props) => props.compact && "padding: 0.625rem"}
  }
`;

export default ({ className = "", children, ...props }) => (
  <Table className={cx("table", className)} {...props}>
    {children}
  </Table>
);
