import api from "api";
import {
  EXAM_FETCHED,
  EXAM_INITIALIZED,
  EXAM_CREATED,
  EXAM_UPDATED,
  EXAM_STATUS_CHANGED,
  EXAM_CLEARED,
  SECTION_CREATED,
  SECTION_UPDATED,
  SECTION_DELETED,
  QUESTION_IMPORTED,
  QUESTION_INITIALIZED,
  QUESTION_SAVED,
  QUESTION_SAVED_IN_SERVER,
  QUESTION_SAVED_IN_SERVER_FAILED,
  QUESTION_DELETED,
  QUESTION_SWITCHED,
  ANSWER_INITIALIZED,
  ANSWER_SAVED,
  ANSWER_SAVED_IN_SERVER,
  ANSWER_SAVED_IN_SERVER_FAILED,
  ANSWER_DELETED,
  ATTACHMENT_SAVED,
  ATTACHMENT_DELETED,
} from "./type";

const examFetched = ({ schoolAcademicId, exam }) => ({
  type: EXAM_FETCHED,
  schoolAcademicId,
  exam,
});

const examInitialized = (schoolAcademicId) => ({
  type: EXAM_INITIALIZED,
  schoolAcademicId,
});

const examCreated = (schoolAcademicId, exam, section) => ({
  type: EXAM_CREATED,
  schoolAcademicId,
  exam,
  section,
});

const examUpdated = (exam) => ({
  type: EXAM_UPDATED,
  exam,
});

const examStatusChanged = (status) => ({
  type: EXAM_STATUS_CHANGED,
  status,
});

const examCleared = () => ({
  type: EXAM_CLEARED,
});

const sectionCreated = ({
  id,
  title,
  description,
  total_points,
  total_questions,
}) => ({
  type: SECTION_CREATED,
  section: {
    id,
    title,
    description,
    total_points,
    total_questions,
  },
});

const sectionUpdated = ({ id, title, description }) => ({
  type: SECTION_UPDATED,
  section: { id, title, description },
});

const sectionDeleted = ({ id, currentQuestion = null }) => ({
  type: SECTION_DELETED,
  id,
  currentQuestion,
});

const questionImported = ({ sectionId, question }) => ({
  type: QUESTION_IMPORTED,
  sectionId,
  question,
});

const questionInitialized = ({ generatedUUID, sectionId, questionType }) => ({
  type: QUESTION_INITIALIZED,
  generatedUUID,
  sectionId,
  questionType,
});

const questionSaved = ({ uuid, question }) => ({
  type: QUESTION_SAVED,
  uuid,
  question,
});

export const questionSavedInServer = (uuid) => ({
  type: QUESTION_SAVED_IN_SERVER,
  uuid,
});

export const questionSaveInServerFailed = (uuid) => ({
  type: QUESTION_SAVED_IN_SERVER_FAILED,
  uuid,
});

export const questionDeleted = ({ uuid, sectionId, questionSwitchedTo }) => ({
  type: QUESTION_DELETED,
  uuid,
  sectionId,
  questionSwitchedTo,
});

export const questionSwitched = ({ sectionId, uuid }) => ({
  type: QUESTION_SWITCHED,
  sectionId,
  uuid,
});

export const answerInitialized = ({ questionUUID, questionType }) => ({
  type: ANSWER_INITIALIZED,
  questionUUID,
  questionType,
});

export const answerSaved = ({ uuid, answer }) => ({
  type: ANSWER_SAVED,
  uuid,
  answer,
});

export const answerSavedInServer = ({ uuid, id = null }) => ({
  type: ANSWER_SAVED_IN_SERVER,
  uuid,
  id,
});

export const answerSaveInServerFailed = (uuid) => ({
  type: ANSWER_SAVED_IN_SERVER_FAILED,
  uuid,
});

export const answerDeleted = ({ questionUUID, uuid }) => ({
  type: ANSWER_DELETED,
  questionUUID,
  uuid,
});

export const attachmentSaved = ({ questionUUID, attachment }) => ({
  type: ATTACHMENT_SAVED,
  questionUUID,
  attachment,
});

export const attachmentDeleted = ({ questionUUID, id }) => ({
  type: ATTACHMENT_DELETED,
  questionUUID,
  id,
});

export const fetchExam = ({ schoolAcademicId, examId }) => {
  return (dispatch) =>
    api.exam.getExam(schoolAcademicId, examId).then((exam) => {
      dispatch(examFetched({ schoolAcademicId, exam }));
      return exam;
    });
};

export const initExam = (schoolAcademicId) => {
  return (dispatch) => dispatch(examInitialized(schoolAcademicId));
};

export const createExam = ({ schoolAcademicId, data }) => {
  return (dispatch) =>
    api.exam.create(schoolAcademicId, data).then((exam) => {
      dispatch(
        examCreated(schoolAcademicId, { ...data, id: exam.id }, exam.section)
      );
      return exam;
    });
};

export const updateExam = ({ schoolAcademicId, examId, data }) => {
  return (dispatch) =>
    api.exam
      .update(examId, { ...data, school_academic_id: schoolAcademicId })
      .then(() => dispatch(examUpdated(data)));
};

export const updateStatusExam = ({ examId, status }) => {
  return (dispatch) =>
    api.exam
      .changeStatus({ exam_id: examId, status })
      .then(() => dispatch(examStatusChanged(status)));
};

export const clearExam = () => (dispatch) => dispatch(examCleared());

export const createSection = ({ exam_id, title, description }) => {
  return (dispatch) =>
    api.exam
      .createSection({ exam_id, title, description })
      .then((section) => dispatch(sectionCreated(section)));
};

export const updateSection = ({ id, title, description }) => {
  return (dispatch) =>
    api.exam
      .updateSection(id, title, description)
      .then(() => dispatch(sectionUpdated({ id, title, description })));
};

export const deleteSection = ({ id, currentQuestion = null }) => {
  return (dispatch) =>
    api.exam
      .deleteSection(id)
      .then(() => dispatch(sectionDeleted({ id, currentQuestion })));
};

export const importQuestions = (questions) => {
  return (dispatch) => {
    return api.exam.question.import(questions).then((data) => {
      data.forEach((section) => {
        section.questions.forEach((question) => {
          dispatch(questionImported({ sectionId: section.id, question }));
        });
      });
    });
  };
};

export const initQuestion = ({ generatedUUID, sectionId, type }) => {
  return (dispatch) => {
    dispatch(
      questionInitialized({ generatedUUID, sectionId, questionType: type })
    );
  };
};

export const saveQuestionLocally = ({ uuid, question }) => {
  return (dispatch) => {
    dispatch(questionSaved({ uuid, question }));
  };
};

export const markQuestionSavedInServer = (uuid) => {
  return (dispatch) => {
    dispatch(questionSavedInServer(uuid));
  };
};

export const markQuestionSaveInServerFailed = (uuid) => {
  return (dispatch) => {
    dispatch(questionSaveInServerFailed(uuid));
  };
};

export const deleteQuestion = ({ question, questionSwitchedTo }) => {
  if (question.id) {
    return (dispatch) => {
      api.exam.question.delete(question.id).then(() =>
        dispatch(
          questionDeleted({
            uuid: question.uuid,
            sectionId: question.sectionId,
            questionSwitchedTo,
          })
        )
      );
    };
  } else {
    return (dispatch) =>
      dispatch(
        questionDeleted({
          uuid: question.uuid,
          sectionId: question.sectionId,
          questionSwitchedTo,
        })
      );
  }
};

export const switchQuestion = ({ sectionId, uuid }) => {
  return (dispatch) => dispatch(questionSwitched({ sectionId, uuid }));
};

export const initAnswer = ({ questionUUID, questionType }) => {
  return (dispatch) =>
    dispatch(answerInitialized({ questionUUID, questionType }));
};

export const saveAnswerLocally = ({ uuid, answer }) => {
  return (dispatch) => dispatch(answerSaved({ uuid, answer }));
};

export const markAnswerSavedInServer = ({ uuid, id = null }) => {
  return (dispatch) => dispatch(answerSavedInServer({ uuid, id }));
};

export const markAnswerSaveInServerFailed = (uuid) => {
  return (dispatch) => dispatch(answerSaveInServerFailed(uuid));
};

export const deleteAnswerLocally = ({ questionUUID, uuid }) => {
  return (dispatch) => {
    dispatch(answerDeleted({ questionUUID, uuid }));
  };
};

export const saveAttachment = ({ questionUUID, attachment }) => {
  return (dispatch) => {
    dispatch(attachmentSaved({ questionUUID, attachment }));
  };
};

export const deleteAttachment = ({ questionUUID, id }) => {
  return (dispatch) => {
    return api.exam.attachment.delete(id).then(() => {
      dispatch(attachmentDeleted({ questionUUID, id }));
    });
  };
};
