import React from "react";
import PropTypes from "prop-types";
import Alert from "components/Alert/Alert";

class ValidationError extends React.Component {
  static propTypes = {
    errors: PropTypes.array,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(
      0,
      this.ref.current.getBoundingClientRect().top + window.scrollY - 60
    );
  }

  render() {
    const { errors, onClose } = this.props;
    return (
      <div ref={this.ref}>
        <Alert
          icon="info"
          variant="warning"
          onClose={onClose}
        >
          <strong>Gagal menyimpan karena hal berikut:</strong>
          {errors.map((err, index) => {
            return (
              <div key={index}>
                <em className="icon ni ni-bullet-fill" />{" "}
                <span>{err.message}</span>
              </div>
            );
          })}
        </Alert>
      </div>
    );
  }
}

export default ValidationError;
