import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import SkeletonEditor from "components/Editor/SharedEditor/SkeletonEditor";
import { useDispatch } from "react-redux";
import { saveQuestion } from "redux/createQuestionBank/action";
import ValidationError from "components/Error/ValidationError";
import { DataContext } from "./CardContext";
import Attachments from "./shared/Attachments";
import Block from "components/Block/Block";

export default function QuestionForm(props) {
  const { question } = useContext(DataContext);

  const dispatch = useDispatch();
  function handleChange(name, value) {
    dispatch(
      saveQuestion({
        uuid: question.uuid,
        attribute: name,
        value: value,
      })
    );
  }

  return (
    <>
      <div className="nk-block">
        <div className="nk-block-content">
          {props.validationErrors.length > 0 && (
            <Block className="mb-2">
              <ValidationError errors={props.validationErrors} />
            </Block>
          )}

          {question.attachments.length > 0 && (
            <Attachments data={question.attachments} />
          )}

          <div className="nk-block pt-2">
            <SkeletonEditor
              defaultValue={question.content}
              placeholder="Ketikan pertanyaan disini..."
              onChange={(content) => handleChange("content", content)}
              style={{ minHeight: "80px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
