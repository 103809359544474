import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetch } from "redux/master/questionCategories/action";
import Select from "components/Form/Select";
import SpinnerGrow from "components/Spinner/SpinnerGrow";

class CategoryFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.questionCategories === null,
    };
  }

  componentDidMount() {
    this.props.questionCategories === null && this.props.fetch();
  }

  componentDidUpdate() {
    if (this.state.isLoading && this.props.questionCategories !== null) {
      setTimeout(() => this.setState({ isLoading: false }), 300);
    }
  }

  render() {
    const { questionCategories, fetch, ...props } = this.props;
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <SpinnerGrow variant="warning" />
        </div>
      );
    }
    return (
      <div className="form-group">
        <Select {...props}>
          <option value="">Semua Kategori</option>
          {questionCategories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Select>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questionCategories: state.masterQuestionCategories,
});

export default connect(mapStateToProps, { fetch })(CategoryFilter);
