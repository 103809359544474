import React from "react";
import PropTypes from "prop-types";
import Select from "components/Form/Select";
import Label from "components/Form/Label";

class CompletionStatusFilter extends React.Component {
  render() {
    return (
      <div className="form-group">
        <Label>Status Ketuntasan</Label>
        <Select {...this.props}>
          <option value="">Semua</option>
          <option value="true">Tuntas</option>
          <option value="false">Belum Tuntas</option>
        </Select>
      </div>
    );
  }
}

export default CompletionStatusFilter;
