import React from "react";
import PropTypes from "prop-types";
import Link from "components/Link/Link";
import Block from "components/Block/Block";
import PrettyFloat from "domain/Float/PrettyFloat";
import sum from "lodash/sum";
import values from "lodash/values";
import { connect } from "react-redux";

class StudentDetail extends React.Component {
  render() {
    const { student, submission, totalGainedPoints } = this.props;
    return (
      <Block>
        <div className="tranx-list tranx-list-stretch card card-bordered">
          <div className="tranx-item" style={{ padding: "1rem 1.5rem" }}>
            <div className="tranx-col">
              <div className="tranx-info">
                <div className="tranx-badge">
                  <span className="tranx-icon">
                    <img src={student.photo} />
                  </span>
                </div>
                <div className="tranx-data">
                  <div className="tranx-label">
                    <Link to="#">{student.name}</Link>
                  </div>
                  <div className="tranx-date">
                    {submission.total_questions === submission.total_filleds ? (
                      "Berhasil mengisi semua jawaban"
                    ) : (
                      <span>
                        Kurang{" "}
                        {submission.total_questions - submission.total_filleds}{" "}
                        soal yang tidak diisi.
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="tranx-col">
              <div className="tranx-amount">
                <div className="number">{totalGainedPoints}</div>
                <div className="number-sm">Nilai Akhir</div>
              </div>
            </div>
          </div>
        </div>
      </Block>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    student: state.examSubmissionReview.data.student,
    submission: state.examSubmissionReview.data.submission,
    totalGainedPoints: sum(
      values(
        state.examSubmissionReview.data.questions
      ).map(({ total_gained_points }) => parseFloat(total_gained_points))
    ),
  };
};

export default connect(mapStateToProps)(StudentDetail);
