import React from "react";
import PropTypes from "prop-types";
import SpinnerEllipsis from "components/Spinner/SpinnerEllipsis";

const LoadingRow = ({ colSpan }) => (
  <tr>
    <td colSpan={colSpan} className="text-center">
      <SpinnerEllipsis variant="warning" size="1.2rem" />
    </td>
  </tr>
);

LoadingRow.propTypes = {};

export default LoadingRow;
