import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import questionBankAPI from "api/bank/question";

export default function useFetchQuestion(id, callback = () => {}) {
  const [isFetched, setIsFetched] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setIsFetched(false);
    questionBankAPI.getById(id).then((data) => {
      setIsFetched(true);
      setData(data);
      callback(data);
    });
  }, [id]);

  return {
    isFetched,
    data,
  };
}

useFetchQuestion.propTypes = {};
