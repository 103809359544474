import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteSection, switchQuestion } from "redux/exam/action";
import FloatModal from "components/Modal/FloatModal";
import last from "lodash/last";
import SpinnerGrow from "components/Spinner/SpinnerGrow";

class ExamSectionRemove extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = { isConfirmShow: false, isLoading: false };
  }

  remove = () => {
    this.setState({ isLoading: true });
    // jika section yang dihapus adalah currentSection.sectionId maka
    // kita harus memindahkan currentQuestion ke question di section sebelumnya.
    let currentQuestion = null;
    if (this.props.section.id === this.props.currentQuestion.sectionId) {
      const previousSectionId = this.props.sectionOrder[
        this.props.sectionOrder.indexOf(this.props.section.id) - 1
      ];
      const nextSectionId = this.props.sectionOrder[
        this.props.sectionOrder.indexOf(this.props.section.id) + 1
      ];
      if (previousSectionId) {
        const previousQuestionUUID =
          last(this.props.questionOrder[previousSectionId]) || null;
        currentQuestion = {
          sectionId: previousSectionId,
          uuid: previousQuestionUUID,
        };
      } else if (nextSectionId) {
        const nextQuestionUUID =
          this.props.questionOrder[nextSectionId][0] || null;
        currentQuestion = {
          sectionId: nextSectionId,
          uuid: nextQuestionUUID,
        };
      } else {
        throw Error("Logika salah: sectionId kosong");
      }
    }
    this.props
      .deleteSection({
        id: this.props.section.id,
        currentQuestion,
      })
      .then(() => this.setState({ isLoading: false }));
  };

  showConfirm = (e) => {
    e.stopPropagation();
    this.setState({ isConfirmShow: true });
  };
  closeConfirm = () => {
    this.setState({ isConfirmShow: false });
  };

  render() {
    return (
      <>
        <span onClick={this.showConfirm} ref={this.ref}>
          <em className="ni ni-trash text-danger" />
        </span>

        {this.state.isConfirmShow && (
          <FloatModal
            title="Konfirmasi Hapus"
            position="left"
            parentRef={this.ref}
            onClose={this.closeConfirm}
          >
            <div className="row gy-4" onClick={(e) => e.stopPropagation()}>
              <div className="col-sm-12">
                Anda akan menghapus bagian "{this.props.section.title}" secara
                permanen.
              </div>

              <div className="col-sm-6">
                <button
                  type="button"
                  className="btn btn-sm btn-light btn-block"
                  onClick={this.closeConfirm}
                >
                  <span>Batal</span>
                </button>
              </div>
              <div className="col-sm-6">
                {this.state.isLoading ? (
                  <div className="text-center">
                    <SpinnerGrow size="1.2rem" />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm btn-danger btn-block"
                    onClick={this.remove}
                  >
                    <span>Hapus</span>
                  </button>
                )}
              </div>
            </div>
          </FloatModal>
        )}
      </>
    );
  }
}

ExamSectionRemove.propTypes = {};

const mapStateToProps = (state) => ({
  currentQuestion: state.exam.currentQuestion,
  questions: state.exam.data.questions,
  sectionOrder: state.exam.sectionOrder,
  questionOrder: state.exam.questionOrder,
});

export default connect(mapStateToProps, { deleteSection, switchQuestion })(
  ExamSectionRemove
);
