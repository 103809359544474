import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Page from "components/Page";
import { v4 as uuidv4 } from "uuid";

const UserRoute = ({
  isAuthenticated,
  component: Component,
  layout: Layout,
  location,
  ...rest
}) => {
  if (!isAuthenticated) {
    return (
      <Redirect to={`/login?to=${encodeURIComponent(location.pathname + location.search)}`} />
    );
  }

  if (!Layout) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Page>
            <Component {...props} key={uuidv4()} />
          </Page>
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Page>
            <Component {...props} key={uuidv4()} />
          </Page>
        </Layout>
      )}
    />
  );
};

UserRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.elementType,
  isAuthenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.auth.access_token,
    isLoading: state.loading,
  };
}

export default connect(mapStateToProps)(withRouter(UserRoute));
