import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import NioIcon from "components/Icon/NioIcon";
import AttachmentUploader from "./AttachmentUploader";
import Button from "components/Button/Button";

export default function AttachmentButton(props) {
  const [isModalShow, setIsModalShow] = useState(false);

  // NOTE: ini untuk mengatasi masalah modal (attachment) dalam modal sehingga
  // setelah modal attachment tutup, muncul masalah modal ini tidak bisa scroll.
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  });

  return (
    <>
      <Button
        size="sm"
        variant="lighter"
        className="text-base"
        block
        disabled={props.question.attachments.length === 5}
        onClick={(e) => {
          /* TODO: disable ketika attachment > 5 */
          e.preventDefault();
          setIsModalShow(true);
        }}
      >
        <NioIcon name="clip" />
        <span>Unggah lampiran</span>
      </Button>

      {isModalShow && (
        <AttachmentUploader
          question={props.question}
          onChange={props.onChange}
          onClose={() => setIsModalShow(false)}
        />
      )}
    </>
  );
}
