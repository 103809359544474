import React from "react";
import styled from "styled-components";

export const InlineList = styled.ul`
  overflow-x: initial;
  flex-wrap: wrap;
  display: flex;
  margin: -0.375rem;
`;

export const InlineListItem = styled.li`
  padding: 0.375rem;
`;
