import forOwn from "lodash/forOwn";
import sum from "lodash/sum";

export default class SectionEntity {
  constructor(section) {
    forOwn(section, (value, key) => (this[key] = value));
  }

  setQuestionOrder(data) {
    this._questionOrder = data;
    return this;
  }

  getTotalQuestion() {
    return this._questionOrder[this.id].length;
  }

  /**
   * Expect all question in all section
   */
  setQuestions(data) {
    this._questions = data;
    return this;
  }

  getTotalPoint() {
    return sum(
      this._questionOrder[this.id].map((questionUUID) =>
        parseFloat(this._questions[questionUUID].point)
      )
    );
  }
}
