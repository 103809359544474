import React from "react";
import PropTypes from "prop-types";
import Button from "../shared/Button";
import ImageViewer from "components/Viewer/Image";

export default class Image extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <Button
        attachmentUUID={data.uuid}
        text={data.name || data.url}
        icon="img"
      >
        <Button.Modal>
          <div className="mb-1">
            Klik gambar untuk zoom.
            <span className="text-primary">
              <em className="icon ni ni-info"></em>
            </span>
          </div>
          <div className="bg-dark p-1">
            <ImageViewer src={data.url} style={{ maxWidth: "100%" }} />
          </div>
        </Button.Modal>
      </Button>
    );
  }
}
