export const CONNECTION_ERROR_POPUP_SHOWED = 'CONNECTION_ERROR_POPUP_SHOWED';

export const CONNECTION_ERROR_POPUP_HID = 'CONNECTION_ERROR_POPUP_HID';

export const ERROR_POPUP_SHOWED = 'ERROR_POPUP_SHOWED';

export const ERROR_POPUP_HID = 'ERROR_POPUP_HID';

export const ERROR_404_POPUP_SHOWED = 'ERROR_404_POPUP_SHOWED';

export const ERROR_404_POPUP_HID = 'ERROR_404_POPUP_HID';

export const FORBIDDEN_ERROR_POPUP_SHOWED = 'FORBIDDEN_ERROR_POPUP_SHOWED';

export const FORBIDDEN_ERROR_POPUP_HID = 'FORBIDDEN_ERROR_POPUP_HID';

export const LOADER_SHOWED = 'LOADER_SHOWED';

export const LOADER_HID = 'LOADER_HID';
